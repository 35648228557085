import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventActions, EventCategories } from '../../../definitions/enums/events.enum';
import { DataNotificationLearning } from '../interfaces';

@Component({
  selector: 'app-notification-learning',
  templateUrl: './notification-learning.component.html',
  styleUrls: ['./notification-learning.component.scss']
})
export class NotificationLearningComponent {
  @Input() data: DataNotificationLearning;
  @Output() action = new EventEmitter<string>();

  readonly CATEGORIES = EventCategories;
  readonly ACTIONS = EventActions;
}
