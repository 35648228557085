import { Routes } from '@angular/router';
import { LinkTabs } from '../../core/link-tabs/definitions/link-tabs.models';

import { PermissionAction, UserType } from './permissions.enums';

export const mapRoutes = (tableComponent, managerComponent, viewComponent, type: string): Routes => [
  {
    path: '',
    component: tableComponent
  },
  {
    path: PermissionAction.create,
    component: managerComponent,
    data: {
      title: `Create ${type}`,
      breadcrumbs: `Create ${type}`,
      breadcrumbsLink: false,
      hideBreadcrumbs: false
    }
  },
  {
    path: `${PermissionAction.edit}/:id`,
    component: managerComponent,
    data: {
      title: `Edit ${type}`,
      breadcrumbs: `Edit ${type}`,
      breadcrumbsLink: false,
      hideBreadcrumbs: false
    }
  },
  {
    path: `${PermissionAction.view}/:id`,
    component: viewComponent,
    data: {
      title: type,
      breadcrumbs: `View ${type}`,
      breadcrumbsLink: false,
      hideBreadcrumbs: false
    }
  }
];

export const LINK_TABS: { [key in UserType]: LinkTabs[] } = {
  admin: [
    {
      name: 'Accounts',
      route: 'accounts'
    },
    {
      name: 'Admins',
      route: 'admins'
    },
    {
      name: 'Tiers',
      route: 'tiers'
    },
    {
      name: 'Licenses',
      route: 'licenses'
    }
  ],
  account: [
    {
      name: 'Licenses',
      route: 'licenses'
    }
  ],
  license: []
};

export const SINGULAR_LINK_TABS_NAMES = {
  admins: 'Admin',
  accounts: 'Account',
  tiers: 'Tier',
  licenses: 'License',
  roles: 'Role'
};

export const ALL_CONDITION = -1;

export const HIDDEN_CREATE_BUTTON_IN_TABS = ['licenses', 'roles'];
