import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StoreName } from '../store/definitions/store.constants';
import { UserState } from '../store/states/user.state';
import { AppState } from '../store/states/app.state';
import { STORAGE_KEY } from '../definitions/app.constants';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  srcImgAvatar = 'assets/images/profile-picture.png';
  fullName = localStorage.getItem(STORAGE_KEY.fullName);
  userType$ = this._store.pipe(
    select(StoreName.user),
    map(({ user_type }: UserState) => user_type)
  );

  constructor(private readonly _store: Store<AppState>) {}
}
