import { ActionReducerMap } from '@ngrx/store';

import { AppState } from '../states/app.state';

import { countryReducer } from '../reducers/country.reducer';
import { moduleReducer } from '../reducers/module.reducer';
import { userReducer } from '../reducers/user.reducer';

export const appReducer: ActionReducerMap<AppState> = {
  country: countryReducer,
  module: moduleReducer,
  user: userReducer
};
