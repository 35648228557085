export enum ActionNotification {
  internal = 'internal',
  external = 'external',
  modal = 'modal'
}

export enum ModalName {
  confirmation= 'confirmation'
}

export enum StyleNotification {
  primary = 'primary',
  tertiary = 'tertiary'
}
