import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LazyScrollableDataSource } from '@rappi/common';
import { AlertsType } from '@rappi/ui/alerts';
import { SelectComponent, SelectConfig } from '@rappi/ui/select';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { SamplingTypes } from '../../../../sampling-core/shared/campaign-enums';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { PricingService } from '../../services/pricing.service';
import { TYPE_SAMPLING_LABEL } from '../../shared/constants';
import { Price, SelectData } from '../../shared/interfaces';

@Component({
  selector: 'app-assistant-pricing',
  templateUrl: './assistant-pricing.component.html',
  styleUrls: ['./assistant-pricing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssistantPricingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('selectTypePrice', { static: true }) selectType: SelectComponent<SelectData>;
  @ViewChild('selectCitiesPrice', { static: true }) selectCities: SelectComponent<SelectData[]>;

  citiesDataSource = new LazyScrollableDataSource<unknown>(this.getCityList.bind(this), 10, 0);
  controlMax: AbstractControl;
  controlMin: AbstractControl;
  subscriptions = new Subscription();
  loading = false;
  needReloadList = false;
  isEdit = false;
  formPrice = new FormGroup({
    product_type: new FormControl(null, Validators.required),
    cities: new FormControl([], Validators.required),
    impact_range_min: new FormControl(null, [Validators.required, Validators.min(0)]),
    impact_range_max: new FormControl(null, [Validators.required]),
    price: new FormControl(null, [Validators.required, Validators.min(0)])
  });
  readonly rangeInfo = `You can not overlap impact ranges.
    Only use ranges like 100 - 200 impact, 201 - 300 impacts.`;
  readonly productTypeList = [
    {
      name: TYPE_SAMPLING_LABEL.SAMPLING_IDEAL,
      id: SamplingTypes.SAMPLING_IDEAL
    },
    {
      name: TYPE_SAMPLING_LABEL.SAMPLING_ON_TOP,
      id: SamplingTypes.SAMPLING_ON_TOP
    }
  ];
  readonly filterSelectConfig: SelectConfig = {
    panelClass: '',
    showTotalsChip: false,
    search: true,
    includeAll: false
  };
  readonly nameErrorKey = ['maxImpactError', 'minImpactError', 'min'];
  readonly errorMessages = {
    min: () => 'Invalid, do not use negative numbers'
  };

  constructor(
    private readonly _alertsService: AlertsService,
    private readonly _pricingService: PricingService,
    private readonly _dialogRef: MatDialogRef<AssistantPricingComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: Price
  ) {}

  ngOnInit(): void {
    this.controlMax = this.formPrice.controls.impact_range_max;
    this.controlMin = this.formPrice.controls.impact_range_min;
    this.subscriptionImpact();
    this.subscribeChange();
  }

  ngAfterViewInit() {
    this.validateEditing();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  errorMessageBind = (error: string) => this.errorMessages[error]();

  subscriptionImpact() {
    const { impact_range_min: minControl, impact_range_max: maxControl } = this.formPrice.controls;

    this.subscriptions.add(
      combineLatest([minControl.valueChanges, maxControl.valueChanges]).subscribe(([min, max]) => {
        if (min >= max) {
          maxControl.setValidators(() => ({ maxImpactError: true }));
        } else {
          maxControl.setValidators(Validators.required);
        }

        maxControl.updateValueAndValidity({ emitEvent: false });
      })
    );
  }

  subscribeChange() {
    this.subscriptions.add(
      this.formPrice.valueChanges.subscribe(() => {
        this.formPrice.setValidators(null);
        this.formPrice.updateValueAndValidity({ emitEvent: false });
      })
    );
  }

  validateEditing() {
    this.isEdit = Boolean(this.dataModal?.id);

    if (this.isEdit) {
      this.formPrice.patchValue(this.dataModal);
      this.selectType.setSelection({
        id: this.dataModal.product_type,
        name: TYPE_SAMPLING_LABEL[this.dataModal.product_type]
      });
      this.selectCities.setMultipleSelection({
        id: this.dataModal.city,
        name: this.dataModal.city
      } as unknown as SelectData[]);

      this.selectType.disabled = true;
      this.selectCities.disabled = true;
      this.formPrice.disable();
      this.formPrice.controls.price.enable();
    }
  }

  getCityList(page = 0, limit = 5, searchValue = ''): Observable<SelectData[]> {
    return this._pricingService.getCities({ page, size: limit, filter: { search_value: searchValue } }).pipe(
      map((res) =>
        res.records.map((el) => ({
          ...el,
          id: el.name
        }))
      )
    );
  }

  mapCities(el: SelectData) {
    return el.name;
  }

  save(closeAfterSave: boolean) {
    this.loading = true;
    const service = this.isEdit
      ? this._pricingService.editPrice(this.formPrice.value, this.dataModal.id)
      : this._pricingService.addPrice(this.formPrice.value);

    service
      .pipe(
        finalize(() => (this.loading = false)),
        tap(() => {
          this._alertsService.openAlerts(
            this.isEdit ? 'Price was successfully updated' : 'New price added',
            AlertsType.success
          );
        })
      )
      .subscribe(
        () => {
          this.needReloadList = true;
          if (this.isEdit || closeAfterSave) {
            this._dialogRef.close(this.needReloadList);
          } else {
            this.formPrice.patchValue(
              {
                impact_range_min: null,
                impact_range_max: null,
                price: null
              },
              { emitEvent: false }
            );
            this.formPrice.markAsUntouched();
          }
        },
        (error) => {
          const message: string = error.error?.message ?? '';

          this.formPrice.setValidators(() =>
            message.includes('this range already exists for some cities')
              ? {
                  duplicateError: true
                }
              : null
          );

          this.formPrice.updateValueAndValidity({ emitEvent: false });
        }
      );
  }

  closeModal() {
    this._dialogRef.close(false);
  }
}
