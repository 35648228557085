import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../../../../core/confirm-modal/confirm-modal.component';
import { ActionNotification, ModalName } from '../../enums';
import { DataActionable } from '../../interfaces';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { MfeSourceLoaderService } from '../../../../mfe/services/mfe-source-loader.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  readonly notificationsFirebaseChild = `/notifications`;

  private readonly MODALS_NAME = {
    [ModalName.confirmation]: ConfirmModalComponent
  };

  private readonly ACTION_TYPES_METHODS = {
    [ActionNotification.internal]: (data: DataActionable) => {
      this._router.navigateByUrl(data.link);
      this.redirectMfeRoute(data.link);
    },
    [ActionNotification.external]: (data: DataActionable) => {
      window.open(data.link, '_blank');
    },
    [ActionNotification.modal]: (data: DataActionable) => {
      this._dialog.open(this.MODALS_NAME[data.modal_name], data);
    }
  };

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _db: AngularFireDatabase,
    private readonly _mfeSourceLoaderService: MfeSourceLoaderService
  ) { }

  openNotification(type: ActionNotification, data: DataActionable) {
    this.ACTION_TYPES_METHODS[type](data);
  }

  getAllNotifications(email: string): Promise<{ val: () => unknown }> {
    return this._db.database.ref(this.notificationsFirebaseChild).orderByChild('email').equalTo(email).once('value');
  }

  addNotification$(email: string): Observable<{ key: string; notification: { country: string; email: string } }> {
    return new Observable(observer => {
      this._db.database.ref(this.notificationsFirebaseChild).
        orderByChild('email').
        equalTo(email).
        on('child_added', snap => {
          observer.next({ key: snap.key, notification: snap.val() });
        });
    });
  }

  offNotifications$(): void {
    return this._db.database.ref(this.notificationsFirebaseChild).off();
  }

  redirectMfeRoute(link: string): void {
    if (link.includes(environment.modules.analytics)) {
      this._mfeSourceLoaderService.redirectMfeRoute.next({ module: environment.modules.analytics, path: link });
    }

    if (link.includes(`${environment.modules.create}/${environment.modules.campaignManager}`)) {
      this._mfeSourceLoaderService.redirectMfeRoute.next({ module: `${environment.modules.create}/${environment.modules.campaignManager}`, path: link });
    }
  }
}
