import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../../../../core/menu/menu.service';
import { MenuBlock } from '../../../menu/menu.model';
import { MENU_ITEMS } from '../../../menu/menu.constant';
import { MenuContentMobileService } from '../../services/menu-content-mobile/menu-content-mobile.service';
import { AppState } from '../../../../store/states/app.state';
import { select, Store } from '@ngrx/store';
import { StoreName } from '../../../../store/definitions/store.constants';
import { EMPTY, Subject } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { ModulesService } from '../../../../services/modules/modules.service';
import { UserState } from '../../../../store/states/user.state';
import { Router } from '@angular/router';
import { EventsService } from '../../../../services/events/events.service';
import { STORAGE_KEY } from '../../../../definitions/app.constants';
import { EncryptService } from '../../../../services/encrypt/encrypt.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-options-navigation-menu',
  templateUrl: './options-navigation-menu.component.html',
  styleUrls: ['./options-navigation-menu.component.scss']
})
export class OptionsNavigationMenuComponent implements OnInit, OnDestroy {
  items: MenuBlock[];

  destroySubject$: Subject<void> = new Subject();
  user: UserState;

  constructor(
    public readonly menuContentService: MenuContentMobileService,
    private readonly _menuService: MenuService,
    private readonly _store: Store<AppState>,
    private readonly _modulesService: ModulesService,
    public readonly _router: Router,
    private readonly _eventsService: EventsService,
    private readonly _encryptService: EncryptService
  ) {}

  ngOnInit() {
    this._store
      .pipe(
        select(StoreName.country),
        takeUntil(this.destroySubject$),
        concatMap(() => this._modulesService.getModules()),
        catchError(() => {
          this.items = [];
          return EMPTY;
        })
      )
      .subscribe(info => {
        this.user = info.data;
        this.items = this._menuService.getItems(MENU_ITEMS, info.data.modules);
      });
  }

  displaySubmenu(indexItem: number, indexModule: number) {
    this.items[indexItem].modules[indexModule].openMenu = !this.items[indexItem].modules[indexModule].openMenu;
  }

  closeMenu() {
    this.menuContentService.changeMenuType(null);
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  emitAmplitudeEventMenuMobile(nextPath: string, currentPath: string, isProd = environment.production): void {
    const data = {
      ...this.user,
      ... { os: this._eventsService.getOS() },
      ... { isMobile: Boolean(this._eventsService.isMobile()) },
      ... { id: localStorage.getItem(STORAGE_KEY.id) },
      ... { email: localStorage.getItem(STORAGE_KEY.email) },
      ... {
        country: this._encryptService.decryptText(
          localStorage.getItem(STORAGE_KEY.country), STORAGE_KEY.country
        ),
        ...{ nextPath, currentPath }
      }
    };

    const event = {
      COUNTRY: data.country,
      PAGE_NAME: data.nextPath,
      SOURCE: data.currentPath,
      USER_EMAIL: data.email,
      TIER: data.tier_name,
      TYPE: data.email.includes('@rappi') ? 'internal' : 'external',
      ACCOUNT_ID: data.id,
      TITLE: data.account_name,
      IS_MOBILE: data.isMobile,
      DEVICE_TYPE: data.os
    };

    if (isProd) {
      this._eventsService.triggerAmplitudeEvent({
        EVENT_NAME: 'VIEW_PAGE_BBR', EVENT_PROPS: event
      });
    }
  }
}
