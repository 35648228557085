<one-card>
  <div class="title" one-card-title>Personal Information</div>
  <form one-card-content [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="one-input-wrapper">
      <one-input label="Name" formControlName="first_name" appFormErrors></one-input>
    </div>

    <div class="one-input-wrapper">
      <one-input label="Last Name" formControlName="last_name" appFormErrors></one-input>
    </div>

    <app-input-cellphone [formControl]="phone"></app-input-cellphone>

    <div class="one-input-wrapper">
      <div class="chips-wrapper">
        <span class="label">Company Access</span>
        <span class="rp-chip selection" *ngFor="let company of company.value">{{ company }}</span>
      </div>
    </div>

    <div class="one-input-wrapper">
      <one-input label="Job Title" formControlName="job_title" appFormErrors></one-input>
    </div>

    <div class="one-input-wrapper">
      <one-input label="Work Email" formControlName="email" appFormErrors></one-input>
    </div>

    <div one-card-footer>
      <button
        class="rp-button rp-filled rp-raisable brands-primary-button rp-big"
        [disabled]="form.pristine || form.invalid"
      >
        Save
      </button>
    </div>
  </form>
</one-card>
