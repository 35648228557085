import { Component, OnInit, ViewChild, OnDestroy, TemplateRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { DynamicTableCellType, DynamicTableData, DynamicTableRow } from '@rappi/ui/dynamic-table';
import { AlertsType } from '@rappi/ui/alerts';
import { CreateVanComponent } from '../dialogs/create-van/create-van.component';
import { MainManagerService } from '../services/main-manager.service';
import { StatusCheck } from '../shared/enums/statusCheck.enum';
import { WarehouseManagerService } from '../services/warehouse.manager.service';
import { ColumnsVanTable, CreateWarehouseRequest, Warehouse } from '../shared/interfaces';
import { DeleteWarehouseComponent } from '../dialogs/delete-warehouse/delete-warehouse.component';
import { environment } from '../../../../environments/environment';
import { PermissionsSamplingCore } from '../../shared/constants';
import { CellConfig, DataTableCell } from '../../../shared/interfaces';
import { AppState } from '../../../store/states/app.state';
import { StoreName } from '../../../store/definitions/store.constants';
import { CountryState } from '../../../store/states/country.state';
import { TypeModal } from '../../../core/confirm-modal/definitions/enums/type-modal.enum';
import { TypeCofirmModal } from '../../../core/confirm-modal/definitions/models/type-modal.model';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import { AlertsService } from '../../../services/alerts/alerts.service';

@Component({
  selector: 'app-van-list',
  templateUrl: './van-list.component.html',
  styleUrls: ['./van-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VanListComponent implements OnInit, OnDestroy {
  @ViewChild('statusRef', { static: true }) statusTemplateRef: TemplateRef<ElementRef>;
  @ViewChild('operationRef', { static: true }) operationTemplateRef: TemplateRef<ElementRef>;
  @ViewChild('actionRef', { static: true }) actionTemplateRef: TemplateRef<ElementRef>;

  readonly PermissionsSamplingCore = PermissionsSamplingCore;
  readonly status = StatusCheck;
  unsubscribe$: Subject<void> = new Subject();
  currentWarehouse: Warehouse;
  tableDataSource: DynamicTableData;

  private readonly COLUMNS_NAME: ColumnsVanTable = {
    reference_code: 'Id',
    name: 'Name',
    address: 'Address',
    status: 'Status',
    operation: 'Operation',
    actions: ' '
  };

  constructor(
    private readonly _warehouseManager: WarehouseManagerService,
    private readonly _dialog: MatDialog,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _alertsService: AlertsService,
    private readonly _mainManagerService: MainManagerService
  ) { }

  ngOnInit() {
    this.tableDataSource = this.initTableConfig();
    this.subscriptionWarehouse();
    this.subscribeParams();
    this.getCountry();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._warehouseManager.setWarehouse(null);
  }

  getCountry() {
    this._store.pipe(
      takeUntil(this.unsubscribe$),
      select(StoreName.country),
      filter((state: CountryState) => Boolean(state.countrySelected)),
      skip(1)
    ).subscribe(() => {
      this._router.navigate([environment.modules.samplingCoreWarehouses]);
    });
  }

  initTableConfig(): DynamicTableData {
    const pageSize = 5;
    const dataSource = new MatTableDataSource<DynamicTableRow>(
      this.setData([], this.COLUMNS_NAME)
    );

    return {
      dataSource,
      columns: Object.keys(this.COLUMNS_NAME),
      pagination: true,
      title: { value: 'LIST OF VANS' },
      paginatorOpts: {
        pageSize,
        pageSizeOptions: [5, 15, 20, 50]
      },
      cellsConfig: {
        name: {
          type: DynamicTableCellType.text,
        },
        reference_code: {
          type: DynamicTableCellType.text,
          title: this.COLUMNS_NAME.reference_code
        },
        status: {
          type: DynamicTableCellType.templateRef
        },
        address: {
          type: DynamicTableCellType.text,
        },
        operation: {
          type: DynamicTableCellType.templateRef
        },
        actions: {
          title: this.COLUMNS_NAME.actions,
          type: DynamicTableCellType.templateRef
        }
      } as CellConfig<ColumnsVanTable>
    };
  }

  setData(data: Warehouse[], columnsKey: ColumnsVanTable): DynamicTableRow[] {
    return data.map(
      (el: Warehouse) =>
      ({
        ...Object.keys(columnsKey).reduce(
          (newObject: Partial<DataTableCell<ColumnsVanTable>>, key: string) => ({
            [key]: {
              value: el[key]
            },
            ...newObject
          }),
          {
            status: {
              templateRef: this.statusTemplateRef,
              context: {
                element: el
              }
            },
            operation: {
              templateRef: this.operationTemplateRef,
              context: {
                element: el
              }
            },
            actions: {
              templateRef: this.actionTemplateRef,
              context: {
                element: el
              }
            }
          } as Partial<DataTableCell<ColumnsVanTable>>
        )
      } as DynamicTableRow)
    );
  }

  subscribeParams() {
    this.loadData(this._activatedRoute.params
      .pipe(
        filter((params) => Boolean(params.id)),
        map((params) => Boolean(params.id))
      ));
  }

  subscriptionWarehouse() {
    this._warehouseManager.getCurrentWarehouse()
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((value: Warehouse) => {
        if (!value) {
          this._router.navigate([environment.modules.samplingCoreWarehouses]);
        }
        this.currentWarehouse = value;
      });
  }

  assistVan(currentWarehouse?: Warehouse) {
    this.loadData(this._dialog.open(CreateVanComponent, {
      disableClose: true,
      autoFocus: false,
      height: '80vh',
      data: {
        parentId: this.currentWarehouse.id,
        parentCode: this.currentWarehouse.code,
        warehouse: currentWarehouse
      }
    }).afterClosed().pipe(
      filter((reload: boolean) => reload)
    ));
  }

  deleteVan(warehouse: Warehouse) {
    this.loadData(this._dialog.open(DeleteWarehouseComponent, {
      disableClose: true,
      autoFocus: false,
      width: '480px',
      panelClass: 'fix-modal',
      data: warehouse
    }).afterClosed().pipe(
      filter((res: boolean) => res)));
  }

  loadData(reloadRef: Observable<boolean>) {
    reloadRef.pipe(
      filter(() => Boolean(this.currentWarehouse)),
      switchMap(() => this._mainManagerService.getWarehouseVan(this.currentWarehouse.id)),
      map((res: Warehouse[]) => this.setData(res, this.COLUMNS_NAME))
    ).subscribe((res: DynamicTableRow[]) => {
      this.tableDataSource.dataSource.data = res;
    });
  }

  vanStatusChange(element: Warehouse) {
    const editService$ = element.status === StatusCheck.ENABLED ?
      this._dialog.open(ConfirmModalComponent, {
        disableClose: true,
        width: '40vw',
        data: {
          title: 'Turn off confirmation',
          message: `Are you sure you want to turn off <strong>${element.name}</strong>?
            <hr />Order creation will be paused`,
          textReject: 'Cancel',
          textSucces: 'Yes, turn off',
          type: TypeModal.confirm
        } as TypeCofirmModal
      }).afterClosed()
        .pipe(
          filter((res: boolean) => res),
          switchMap(() => this.changeStatusService(element))
        ) :
      this.changeStatusService(element);

    editService$.subscribe(() => { });
  }

  changeStatusService(element: Warehouse): Observable<Warehouse> {
    const newStatus = element.status === StatusCheck.ENABLED ? StatusCheck.DISABLED : StatusCheck.ENABLED;

    return this._mainManagerService.updateMainWarehouse(
      {
        ...element,
        status: newStatus,
        parent_id: element.parent_id.id
      } as unknown as CreateWarehouseRequest,
      element.id
    ).pipe(
      tap({
        next: () => {
          this._alertsService.openAlerts(`Status changes to ${newStatus.toLowerCase()} correctly`, AlertsType.success);
          element.status = newStatus;
        }
      })
    );
  }
}
