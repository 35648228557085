import { Injectable } from '@angular/core';

import { EncryptService } from '../encrypt/encrypt.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../../shared/enums';
import { LanguageSelect } from '../../shared/interfaces';

import { STORAGE_KEY } from '../../definitions/app.constants';

@Injectable()
export class TranslateApplicationService {
  _language$ = new BehaviorSubject<Language>(this.currentValue);
  languages: LanguageSelect[] = [
    {
      label: 'Español',
      value: Language.es,
      active: false
    },
    {
      label: 'Português',
      value: Language.pt,
      active: false
    },
    {
      label: 'English',
      value: Language.en,
      active: false
    }
  ];

  constructor(private readonly _encryptService: EncryptService) {
    this.setInitialValue();
  }

  get language$(): Observable<Language> {
    return this._language$.asObservable();
  }

  get currentSelectLanguage(): LanguageSelect {
    return this.languages.find((el: LanguageSelect) => el.active);
  }

  get currentValue(): Language {
    return !localStorage.getItem(STORAGE_KEY.language)
      ? null
      : (this._encryptService.decryptText(
          localStorage.getItem(STORAGE_KEY.language),
          STORAGE_KEY.language
        ) as Language);
  }

  setValue(value: Language): void {
    this._setLanguage(value);
    this.setSelectLanguage(value);
    this._language$.next(value);
  }

  private setInitialValue() {
    const currentLanguage = this.currentValue || this.getDefaultLanguage();
    this.setValue(currentLanguage);
  }

  private setSelectLanguage(newLanguage: Language): void {
    const currentLanguage = this.currentSelectLanguage;
    if (currentLanguage) {
      currentLanguage.active = false;
    }
    const newSelectLanguage = this.languages.find((el: LanguageSelect) => el.value === newLanguage);
    newSelectLanguage.active = true;
  }

  private _setLanguage(lang: string): void {
    localStorage.setItem(STORAGE_KEY.language, this._encryptService.encryptText(lang, STORAGE_KEY.language));
  }

  private getDefaultLanguage(): Language {
    const userLanguage = navigator.language;
    return Object.keys(Language).find((lang: string) => userLanguage.includes(lang)) as Language;
  }
}
