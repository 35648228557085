import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SamplingExtMfeRoutingModule } from './sampling-ext-mfe-routing.module';
import { SamplingExtMfeComponent } from './sampling-ext-mfe/sampling-ext-mfe.component';


@NgModule({
  declarations: [SamplingExtMfeComponent],
  imports: [
    CommonModule,
    SamplingExtMfeRoutingModule
  ]
})
export class SamplingExtMfeModule { }
