import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from '../../../menu/menu.model';
import { STORAGE_KEY } from '../../../../definitions/app.constants';

import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/states/app.state';
import { StoreName } from '../../../../store/definitions/store.constants';
import { UserState } from '../../../../store/states/user.state';
import { CountryState } from '../../../../store/states/country.state';
import { UserType } from '../../../../permissions/definitions/permissions.enums';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserSelectorComponent implements OnInit, OnDestroy {
  srcImgAvatar = 'assets/images/profile-picture.png';
  fullName: string;

  menuItems: MenuItem[];

  destroySubject$ = new Subject<void>();

  constructor(private readonly _router: Router, private readonly _store: Store<AppState>) {
    const user$ = this._store.pipe(select(StoreName.user));
    const country$ = this._store.pipe(select(StoreName.country));

    combineLatest([user$, country$])
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(([{ user_type }, { countrySelected }]: [UserState, CountryState]) => {
        this.setMenuItem(user_type, countrySelected);
      });
  }

  ngOnInit() {
    this.getUserName();
  }

  getUserName() {
    this.fullName = localStorage.getItem(STORAGE_KEY.fullName);
  }

  redirect(el: MenuItem) {
    if (el.openMenu) {
      window.open(el.url, '_blank');
    } else {
      this._router.navigate([`/${el.url}`]);
    }
  }

  setMenuItem(userType: UserType, country: string) {
    this.menuItems = [
      {
        name: 'menu.account',
        url: environment.modules.account,
        icon: 'one-icon-user-circle',
        active: true
      },
      {
        name: 'menu.permissions',
        url: `${environment.modules.permissions}/${userType === UserType.admin ? 'accounts' : 'licenses'}`,
        icon: 'one-icon-wheel',
        active: [UserType.admin, UserType.account].includes(userType)
      },
      {
        name: 'menu.help',
        url: environment.urlProductReport,
        icon: 'one-icon-question-circle',
        openMenu: true,
        active: true
      },
      {
        name: 'menu.controlPanel',
        url: environment.modules.controlPanel,
        icon: 'one-icon-headset',
        active: userType === UserType.admin && ['CO', 'DEV'].includes(country)
      },
      {
        name: 'menu.session',
        url: environment.modules.session,
        icon: 'one-icon-exit',
        active: true
      }
    ];
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
