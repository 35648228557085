<app-actions [id]="id" type="Admin" [action]="action" [service]="deleteAdmin"></app-actions>
<form [formGroup]="form" (submit)="onSubmit(form)">
  <app-form-group title="General Information" icon="one-icon-user-tooltip">
    <div>
      <one-input label="Admin ID" [value]="id" disabled="true"></one-input>
    </div>
    <div>
      <one-input label="Email" formControlName="email" appFormErrors></one-input>
    </div>
  </app-form-group>

  <div class="form-actions">
    <button class="rp-button rp-filled rp-raisable" (click)="goTo(viewUrl || tableUrl)" type="button">Cancel</button>
    <button class="rp-button rp-filled rp-raisable rp-success" [disabled]="form.invalid">
      {{ action | titlecase }} Admin
    </button>
  </div>
</form>
