<section [class.reverse]="reverse">
  <div>
    <ng-content></ng-content>
  </div>
  <article *ngIf="module">
    <span *ngIf="module.number">{{ module.number }}</span>
    <h1>{{ module.name | translate }}</h1>
    <h2 [innerHTML]="module.action | translate"></h2>
    <p>{{ module.description | translate }}</p>
  </article>
</section>
