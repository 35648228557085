import { NgModule } from '@angular/core';
import { GetImgCountryPipe } from './get-img-country/get-img-country.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';
import { InitialsPipe } from './initials/initials.pipe';

@NgModule({
  declarations: [GetImgCountryPipe, EllipsisPipe, CurrencySymbolPipe, InitialsPipe],
  exports: [GetImgCountryPipe, EllipsisPipe, CurrencySymbolPipe, InitialsPipe],
  providers: [EllipsisPipe]
})
export class PipesModule {}
