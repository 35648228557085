<div class="item-menu title-name">Hello, {{ fullName }}</div>
<div (click)="toggleMenuCountry()" class="item-menu">
  <div class="content-img">
    <img [src]="countryDefault.code | getImgCountry" [alt]="'flag of ' + countryDefault.name" />
  </div>
  <span class="item-label">{{ countryDefault.name | titlecase }}</span>
</div>
<div *ngFor="let item of menuOptions" (click)="redirect(item)" class="item-menu">
  <i aria-hidden="true" [class]="item.icon"></i>
  <span class="item-label">{{ item.name | translate }}</span>
</div>
