import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserResponseAuth, Module, LoginResponseAuth, Action } from '../../models/user/user.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as userActions from '../../store/actions/user.action';
import { UserState } from '../../store/states/user.state';
import { UtilService } from '../util/util.service';
import { STORAGE_KEY } from '../../definitions/app.constants';
import { UserType } from '../../permissions/definitions/permissions.enums';
import { StorageService } from '../storage/storage.service';
import { PATHS } from '../../core/landing/definitions/landing.constant';
import { Country } from '../../models/shared/shared.model';

@Injectable()
export class AuthService {
  readonly DEFAULT_PATH = `/${environment.modules.analytics}`;
  readonly MY_ACCOUNT_PATH = `/${environment.modules.account}`;

  constructor(
    private readonly _http: HttpClient,
    private readonly _storageService: StorageService,
    private readonly _router: Router,
    private readonly _store: Store<UserState>,
    private readonly _utilService: UtilService
  ) {}

  login(email: string, password: string): Observable<LoginResponseAuth> {
    return this._http.post<LoginResponseAuth>(`${this._getUrl()}/login`, { email, password });
  }

  successLogin(data: UserResponseAuth): void {
    this._storageService.saveDataOnStorage(data);
    this._store.dispatch(userActions.set({ payload: data.actions as UserState }));

    if (data.countries.some((country: Country) => country.platform_outage)) {
      this._router.navigateByUrl(`/${PATHS.platformOutage}`);
    } else {
      const hasAlert =
        (data.countries_alert &&
          data.countries.find((country) => data.countries_alert.countries.includes(country.code)));

      const alert = hasAlert ? data.countries_alert : null;
      this.navigateToDashboard(data.actions, { state: { alert } });
    }
  }

  logout(): Observable<unknown> {
    return this._http.post(`${this._getUrl()}/logout`, null, this._getHeaders());
  }

  reset(password: string, token: string): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getUrl()}/password/reset/${token}`, { password });
  }

  forgot(email: string): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getUrl()}/password/forgot`, { email });
  }

  getGoogleAuthUrl(): Observable<{ data: string }> {
    return this._http.get<{ data: string }>(`${this._getUrl()}/google/url`);
  }

  exchangeGoogleCode(state: string, code: string): Observable<{ message: string; data: UserResponseAuth }> {
    return this._http.post<{ message: string; data: UserResponseAuth }>(`${this._getUrl()}/google/callback`, {
      state,
      code
    });
  }

  navigateToDashboard({ user_type, modules }: Action, params?: unknown): void {
    const isAdmin = user_type === UserType.admin;

    this._router.navigate([isAdmin ? this.DEFAULT_PATH : this.firstModuleAvailable(modules, modules[0])], params);
  }

  firstModuleAvailable(assignedModules: Module[], moduleFound: Module): string {
    if(moduleFound.slug === environment.modules.create){
      return moduleFound.slug;
    }

    const slugFound = assignedModules.find(({ slug }) => slug.includes(`${moduleFound.slug}/`));
    return slugFound ? this.firstModuleAvailable(assignedModules, slugFound) : moduleFound.slug;
  }

  private _getUrl(): string {
    return this._utilService.concatUrlPath(environment.urlAuth, environment.production ? 'CO' : 'DEV');
  }

  private _getHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(STORAGE_KEY.token)}`
      })
    };
  }
}
