<div
  (click)="toggleMenu()"
  class="menu-trigger"
  [class.menu-open]="(menuType | async) && (menuType | async) !== MenuMobileTypes.MENU_NAVIGATOR"
>
  <div class="content-img animated fadeIn">
    <img [src]="countryDefault | getImgCountry" alt="country" />
  </div>
  <i aria-hidden="true" class="button-close animated fadeIn one-icon-cancel-filled"></i>
</div>
