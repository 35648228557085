import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SamplingCampaign } from '../shared/campaign-interfaces';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { SamplingSetting, Limitation } from '../sampling-settings/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MissionsManagementService {
  private readonly PATH_MODULE = environment.urlMissionsCommanderBase;
  private readonly PATH_CAMPAIGNS = 'missions/campaigns';
  private readonly PATH_MODULE_NEW = '/mtz/sampling';
  private readonly PATH_CAMPAIGNS_NEW = '/campaigns';

  constructor(
    private readonly _storeAux: StoreAuxService,
    private readonly _httpClient: HttpClient
  ) { }

  getSettings(): Observable<SamplingSetting[]> {
    return this._httpClient.get<SamplingSetting[]>(this._storeAux.getCountry(`${this.PATH_MODULE_NEW}${this.PATH_CAMPAIGNS_NEW}/parameters`));
  }

  setValueSettings(key: string, module: string, newValue: string | boolean): Observable<SamplingSetting> {
    return this._httpClient.post<SamplingSetting>(this._storeAux.getCountry(`${this.PATH_MODULE_NEW}${this.PATH_CAMPAIGNS_NEW}/parameters`), {
      key,
      module,
      value: newValue
    });
  }

  getCampaign(code: string): Observable<SamplingCampaign> {
    return this._httpClient.get<SamplingCampaign>(
      this._storeAux.getCountry(`${this.PATH_MODULE_NEW}${this.PATH_CAMPAIGNS_NEW}/${code}/code`)
    );
  }

  getLimitantsValue(): Observable<Limitation[]> {
    return this._httpClient.get<Limitation[]>(this._storeAux.getCountry(this.PATH_MODULE + 'parameters/ORDERS/module'));
  }

  getCampaignsAvailable(): Observable<SamplingCampaign[]> {
    return this._httpClient.get<SamplingCampaign[]>(
      this._storeAux.getCountry(`${this.PATH_MODULE_NEW}${this.PATH_CAMPAIGNS_NEW}/available`)
    );
  }
}
