import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { LandingRoutingModule } from './landing.routing';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { InputModule } from '@rappi/ui/input';
import { SelectModule } from '@rappi/ui/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';

import { LandingComponent } from './landing.component';

import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './home/home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardComponent } from './components/card/card.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProductsComponent } from './products/products.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { PricingComponent } from './pricing/pricing.component';
import { PricingCardComponent } from './components/pricing-card/pricing-card.component';
import { PricingGridComponent } from './components/pricing-grid/pricing-grid.component';
import { InputCellphoneModule } from './components/input-cellphone/input-cellphone.module';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertsModule } from '@rappi/ui/alerts';
import { ModuleDescriptionComponent } from './components/module-description/module-description.component';
import { ContactUsComponent } from './dialogs/contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { ForAgenciesComponent } from './for-agencies/for-agencies.component';
import { ParallaxContentModule } from '../../shared/directives/parallax-content/parallax-content.module';
import { ModuleLandingComponent } from './components/module-landing/module-landing.component';
import { PromotionalDemoComponent } from './dialogs/promotional-demo/promotional-demo.component';
import { CountryModalComponent } from './components/country-modal/country-modal.component';
import { LandingService } from './services/landing/landing.service';
import { VideoModalModule } from '../video-modal/video-modal.module';
import { MatTabsModule } from '@angular/material/tabs';
import { PlatformOutageComponent } from './platform-outage/platform-outage.component';
import { CardModule } from '@rappi/ui/card';
import { PipesModule } from '../../pipes/pipes.module';
import { HeroSectionModule } from './components/hero-section/hero-section.module';
import { SectionModule } from './components/section/section.module';
import { BrandsExampleModule } from './components/brands-example/brands-example.module';
import { StartUsingBannerComponent } from './components/start-using-banner/start-using-banner.component';
import { InputTextareaModule } from '@rappi/ui/input-textarea';
import { RadioCardModule } from '@rappi/ui/radio-card';

@NgModule({
  declarations: [
    LandingComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ResetPasswordComponent,
    LoginComponent,
    CardComponent,
    ChangePasswordComponent,
    ProductsComponent,
    SignUpComponent,
    ConfirmAccountComponent,
    PricingComponent,
    PricingCardComponent,
    PricingGridComponent,
    ProductDetailComponent,
    ModuleDescriptionComponent,
    ContactUsComponent,
    FaqComponent,
    ModuleLandingComponent,
    PromotionalDemoComponent,
    CountryModalComponent,
    PlatformOutageComponent,
    ForAgenciesComponent,
    StartUsingBannerComponent
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    ReactiveFormsModule,
    MatMenuModule,
    TranslateModule,
    SlickCarouselModule,
    InputModule,
    SelectModule,
    MatExpansionModule,
    MatDialogModule,
    InputCellphoneModule,
    FormErrorsDirectiveModule,
    AlertsModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    ParallaxContentModule,
    VideoModalModule,
    MatTabsModule,
    MatExpansionModule,
    CardModule,
    PipesModule,
    HeroSectionModule,
    SectionModule,
    BrandsExampleModule,
    InputTextareaModule,
    RadioCardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [LandingService]
})
export class LandingV2Module {}
