import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryModalComponent } from '../components/country-modal/country-modal.component';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { matchFields } from '../change-password/custom-validators';
import { LoginResponseAuth, PasswordValidatorConst } from '../../../models/user/user.model';

import { UsersService } from '../../../services/users/users.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ConfirmUserRequest } from '../definitions/landing.constant';
import { SupportService } from '../../../services/support/support.service';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmAccountComponent {
  form: FormGroup = new FormGroup(
    {
      first_name: new FormControl('', Validators.compose([Validators.required])),
      last_name: new FormControl('', Validators.compose([Validators.required])),
      job_title: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl(
        { value: '', disabled: true },
        Validators.compose([Validators.required, Validators.email])
      ),
      phone: new FormControl('', Validators.required),
      password: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.pattern(PasswordValidatorConst.regex)])
      ),
      confirmPassword: new FormControl('', Validators.required),
      terms: new FormControl(false)
    },
    { validators: matchFields.bind(this, 'password', 'confirmPassword') }
  );

  token: string;

  readonly PASSWORD_MATCH_ERROR_MESSAGE = `Password and Confirm Password fields doesn't match`;
  readonly ERRORS = ['required', 'pattern'];
  ERRORS_MESSAGE = {
    required: 'This field is required',
    pattern: PasswordValidatorConst.regexErrorMessage
  };

  isModal: boolean;

  constructor(
    private readonly _usersService: UsersService,
    private readonly _authService: AuthService,
    private readonly _alertsServices: AlertsService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _supportService: SupportService,
    @Optional() @Inject(MAT_DIALOG_DATA) private readonly _data: { email: string }
  ) {
    this.isModal = Boolean(_data);

    const { token, email } = this._activatedRoute.snapshot.params;
    this.token = token;

    this.form.get('email').setValue(this._data ? this._data.email : atob(email));
    this.form.get('terms').setValidators(this._data ? null : Validators.requiredTrue);
  }

  errorsMapper = (error: string): string => this.ERRORS_MESSAGE[error];

  get phone(): AbstractControl {
    return this.form.get('phone');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.form.get('confirmPassword');
  }

  onSubmit(f: FormGroup, token: string) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const preRequest = f.getRawValue();
    delete preRequest.confirmPassword;
    delete preRequest.terms;

    const body = preRequest as ConfirmUserRequest;

    const request = this.isModal ? this._supportService.confirmUser(body) : this._usersService.confirmUser(token, body);

    request.subscribe(
      ({ message, data }: LoginResponseAuth) => {
        if (!this.isModal) {
          this._authService.successLogin(data);
        } else {
          this._dialog.closeAll();
        }

        this._alertsServices.openAlerts(message, AlertsType.success);
      },
      ({ error: { message }, status }: HttpErrorResponse) => {
        this._alertsServices.openAlerts(message, AlertsType.error);
      }
    );
  }

  openCountryModal() {
    this._dialog.open(CountryModalComponent, {
      width: '92%',
      maxWidth: '510px',
      panelClass: 'overflow-inherit'
    });
  }
}
