import { HttpErrorResponse } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../services/alerts/alerts.service';
import { ConfirmDialogComponent } from '../../shared/component-library/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../shared/component-library/confirm-dialog/common/confirm-dialog';

import { AlertsType } from '@rappi/ui/alerts';
import { Permission } from '../definitions/permissions.models';

import { Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

export class DeletePermission {
  alertMessage = 'Are you sure you want to continue?';

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _alertsService: AlertsService,
    private readonly _fn: (id: number) => Observable<Permission<unknown>>
  ) {}

  delete(id: number, type: string) {
    const dialog = this._dialog.open(ConfirmDialogComponent, {
      width: '576px',
      data: {
        textToShow: {
          titleWithoutTextBox: `Delete ${type}`,
          content: this.alertMessage,
          confirmationText: 'Delete',
          cancelationText: 'Cancel'
        }
      } as ConfirmDialogData
    });

    dialog
      .afterClosed()
      .pipe(
        filter((res: string) => Boolean(res)),
        mergeMap(() => this._fn(id))
      )
      .subscribe(
        ({ message }: Permission<unknown>) => {
          this._alertsService.openAlerts(message, AlertsType.success);
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(
            message || `There was an error trying to delete the ${type} with id ${id}`,
            AlertsType.error
          );
        }
      );
  }
}
