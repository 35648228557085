<div class="content-status">
  <span [class.status-see]="!data?.status" class="status-ball"></span>
</div>
<div class="content-body">
  <div class="content-description">
    <span class="time-ago">{{ data?.time_ago }}</span>
    <p class="description">{{ data?.body }}</p>
  </div>
  <div class="content-actions" appClickStopPropagation>
    <span
      *ngFor="let actionData of data?.actionable || []"
      (click)="action.emit(actionData); closeNotificationMenu()"
      class="action-button"
      [class.action-button-internal]="actionData.action_type === ActionNotification.internal"
      [class.action-button-primary]="actionData?.style === StyleNotification.primary"
      [class.action-button-tertiary]="actionData?.style === StyleNotification.tertiary"
    >
      {{ actionData.action }}
    </span>
  </div>
</div>
