import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';

import { LazyScrollableDataSource } from '@rappi/common';
import { Country } from '../../../../models/shared/shared.model';
import { SelectComponent, SelectKeys } from '@rappi/ui/select';
import { LandingService } from '../../services/landing/landing.service';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-country-modal',
  templateUrl: './country-modal.component.html',
  styleUrls: ['./country-modal.component.scss'],
  providers: [{ provide: 'Window', useValue: window }],
  encapsulation: ViewEncapsulation.None
})
export class CountryModalComponent {
  @ViewChild('select') countrySelect: SelectComponent<Country>;

  form = new FormGroup({
    country: new FormControl(null, Validators.required)
  });
  countriesDataSource = new LazyScrollableDataSource<Country[]>(this.fetchCountriesList.bind(this), 10, 1);

  readonly COUNTRY_SELECT_KEYS: SelectKeys = {
    id: 'code',
    name: 'name'
  };

  constructor(
    private readonly _landingService: LandingService,
    private readonly _self: MatDialogRef<CountryModalComponent>,
    @Inject('Window') private readonly _window: Window,
    @Inject(MAT_DIALOG_DATA) public readonly url: Record<string, string>
  ) {}

  fetchCountriesList(page: number): Observable<Country[]> {
    return page > 1
      ? of([])
      : this._landingService.getAvailablesCountries().pipe(map(({ data }: { data: Country[] }) => data));
  }

  setCountry({ code }: Country) {
    this.form.get('country').setValue(code);
  }

  onSubmit(f: FormGroup, url: Record<string, string>) {
    if (!f.valid) {
      return;
    }

    const { country } = f.getRawValue();

    this._window.open(url[country], '_blank');
    this._self.close();
  }
}
