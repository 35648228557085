import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { mapRoutes } from '../../definitions/permissions.constants';

import { ManagerComponent } from './components/manager/manager.component';
import { TableComponent } from './components/table/table.component';
import { ViewComponent } from './components/view/view.component';

@NgModule({
  imports: [RouterModule.forChild(mapRoutes(TableComponent, ManagerComponent, ViewComponent, 'Admin'))],
  exports: [RouterModule]
})
export class AdminsRoutingModule {}
