import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MtzSamplingMgmtMfeRoutingModule } from './mtz-sampling-mgmt-mfe-routing.module';
import { MtzSamplingMgmtMfeComponent } from './mtz-sampling-mgmt-mfe/mtz-sampling-mgmt-mfe.component';


@NgModule({
  declarations: [MtzSamplingMgmtMfeComponent],
  imports: [
    CommonModule,
    MtzSamplingMgmtMfeRoutingModule
  ]
})
export class MtzSamplingMgmtMfeModule { }
