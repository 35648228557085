import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MenuMobileTypes } from '../../shared/enums';
import {
  ActionableNotification,
  DataNotificationLearning,
  DataNotificationUser
} from '../notifications-header/interfaces';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { AppState } from '../../store/states/app.state';
import { StoreName } from '../../store/definitions/store.constants';
import { UserNotificationService } from '../notifications-header/services/user-notification/user-notification.service';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '../menu/menu.service';
import { UserState } from '../../store/states/user.state';
import { environment } from '../../../environments/environment';
import { ActionNotification } from '../notifications-header/enums';
import { AngularFireAuth } from '@angular/fire/auth';
import { STORAGE_KEY } from '../../definitions/app.constants';
import { UtilsService } from './services/utils/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly subscriptions = new Subscription();
  srcLogo = 'assets/images/landing/brand.svg';
  notificationsLearning: DataNotificationLearning[] = [];
  numberNotificationsLearning = 0;
  notificationsUser: DataNotificationUser[] = [];
  numberNotificationsUser = 0;
  collapsed = false;
  collapsedStatus: Observable<boolean> = this._menuService.collapsed;

  destroySubject$: Subject<void> = new Subject();
  countrySelected: string;
  loadNotifications = false;
  user: UserState;
  interval;
  refreshFirebaseToken: string;
  readonly rawDataFile = 'Raw Data file';
  readonly actionNotification = ActionNotification;

  constructor(
    private readonly _notificationService: NotificationsService,
    private readonly _userNotificationService: UserNotificationService,
    private readonly _store: Store<AppState>,
    private readonly _router: Router,
    public readonly _menuService: MenuService,
    private readonly _afAuth: AngularFireAuth,
    public readonly _utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.initCountrySubscribe();
    this.getLearningNotification();
    this.detectRouteChanges();
    this.getUserStore();

    this.collapsedStatus.pipe(takeUntil(this.destroySubject$)).subscribe((res: boolean) => {
      this.collapsed = res;
    });

    this.getFirebaseToken();
  }

  getFirebaseToken() {
    this._notificationService.getFirebaseToken().subscribe(response => {
      this.signInFirebase(response.data);
    }, () => console.warn(`can't get forebase token`));
  }

  signInFirebase(token: string): void {
    this._afAuth.signInWithCustomToken(token).then(() => {
      this.getFirebaseNotifications();
      this.authStateFirebase();
    }).catch(err => console.warn(err));
  }

  authStateFirebase(): void {
    this._afAuth.currentUser.then(user => {
      this.interval = setInterval(() => user.getIdToken(true).catch(err => console.warn(err)), 60000 * 58);
    });
  }

  getUserStore(): void {
    this.subscriptions.add(
      this._store
        .pipe(select(StoreName.user))
        .subscribe((user) => {
          this.user = user;
        })
    );
  }

  getFirebaseNotifications(email = localStorage.getItem(STORAGE_KEY.email)): void {
    this._userNotificationService.getAllNotifications(email).then(() => {
      this.loadNotifications = true;
    });

    this.subscriptions.add(
      this._userNotificationService.addNotification$(email).subscribe((data) => {
        const isEqualCountry = data.notification.country.toUpperCase() === this.countrySelected;
        if (this.loadNotifications && isEqualCountry) {
          this._notificationService.getNotifications().toPromise().then(notifications => {
            this.notificationUpdate(notifications);
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this._userNotificationService.offNotifications$();
    clearInterval(this.interval);
    this._afAuth.signOut();
  }

  initCountrySubscribe() {
    this.subscriptions.add(
      this._store
        .pipe(
          select(StoreName.country),
          filter((country) => Boolean(country.countrySelected)),
          switchMap((country) => {
            this.countrySelected = country.countrySelected;
            return this._notificationService.getNotifications();
          }),
          filter((res: DataNotificationUser[]) => Boolean(res))
        )
        .subscribe((res: DataNotificationUser[]) => {
          this.notificationUpdate(res);
        })
    );
  }

  viewsUserNotification(idNotification: number) {
    this._notificationService
      .setViewNotification(idNotification)
      .pipe(
        switchMap(() => this._notificationService.getNotifications()),
        filter((res: DataNotificationUser[]) => Boolean(res))
      )
      .subscribe((res: DataNotificationUser[]) => {
        this.notificationUpdate(res);
      });
  }

  detectRouteChanges() {
    this.subscriptions.add(
      this._router.events
        .pipe(
          filter((val) => val instanceof NavigationEnd),
          switchMap(() => this._notificationService.getNotifications()),
          filter((res: DataNotificationUser[]) => Boolean(res))
        )
        .subscribe((res: DataNotificationUser[]) => {
          this.notificationUpdate(res);
        })
    );
  }

  notificationUpdate(notification: DataNotificationUser[]) {
    this.notificationsUser = notification;
    this.numberNotificationsUser = this.getNumberNotifications<DataNotificationUser>(notification);
  }

  getLearningNotification() {
    this._notificationService
      .getNotificationsLearning()
      .pipe(filter((res: DataNotificationLearning[]) => Boolean(res)))
      .subscribe((res: DataNotificationLearning[]) => {
        this.notificationsLearning = res;
        this.numberNotificationsLearning = this.getNumberNotifications<DataNotificationLearning>(res);
      });
  }

  viewsLearningNotification() {
    this._notificationService.setViewsAllLearning().subscribe(() => {
      this.getLearningNotification();
    });
  }

  getNumberNotifications<T extends { status: boolean }>(array: T[]): number {
    return array.filter((el) => !el.status).length;
  }

  toggleMenu(status: boolean) {
    this._menuService.toggleMenu(status);
  }

  toggleMenuMobile(): void {
    this._utilsService.menuContentService.changeMenuType(MenuMobileTypes.MENU_NAVIGATOR);
  }

  navigateLearning() {
    window.open('https://learning-brands.rappi.com.co/', '_blank');
  }

  seeNotification(action: ActionableNotification, notification: DataNotificationUser) {
    this.viewsUserNotification(notification.id);
    this.trackEventAmplitude({
      action,
      isRawDataFile: notification.body.includes(this.rawDataFile),
      country: this.countrySelected,
      user: this.user,
      componentName: 'raw_data_notification',
      accountId: Number(localStorage.getItem('id')),
      source: window.location.href.split('/#')[1],
      email: localStorage.getItem('email'),
      isProd: environment.production
    });
    this._userNotificationService.openNotification(action.action_type, action.data);
  }

  trackEventAmplitude(data: {
    action: ActionableNotification;
    isRawDataFile: boolean;
    country: string;
    user: UserState;
    componentName: string;
    accountId: number;
    source: string;
    email: string;
    isProd: boolean;
  }) {
    if (data.isProd && data.isRawDataFile) {
      const event = {
        ACCOUNT_ID: data.accountId,
        COUNTRY: data.country,
        SOURCE: data.source,
        TIER: data.user.tier_name,
        USER_TYPE: data.user.user_type,
        USER_EMAIL: data.email,
        COMPONENT_NAME: data.componentName,
        COMPONENT_SELECTION: data.action.action_type === this.actionNotification.internal ? 'go_to_raw_data' : 'download',
        TITLE: data.user.account_name
      };
      this._utilsService._eventsService.triggerAmplitudeEvent({ EVENT_NAME: 'SELECT_BBR_COMPONENT', EVENT_PROPS: event });
    }
  }
}
