<footer class="rp-text-primary-dark">
  <div class="footer-row rp-w-container-s">
    <ul class="footer-list">
      <h6 class="footer-list-title">{{ 'landing.footer.categories' | translate }}</h6>
      <li class="footer-list-elem rp-text-secondary-dark" [routerLink]="FULL_PATHS.home">
        {{ 'landing.footer.home' | translate }}
      </li>
      <li class="footer-list-elem rp-text-secondary-dark" [routerLink]="FULL_PATHS.features">
        {{ 'landing.features.name' | translate }}
      </li>
      <li class="footer-list-elem rp-text-secondary-dark" [routerLink]="FULL_PATHS.pricing">
        {{ 'landing.pricing.name' | translate }}
      </li>
    </ul>
    <ul class="footer-list">
      <h6 class="footer-list-title">{{ 'landing.footer.legal' | translate }}</h6>
      <li class="footer-list-elem rp-text-secondary-dark">
        <button class="rp-link-secondary" (click)="openCountryModal()">{{ 'landing.footer.terms' | translate }}</button>
      </li>
      <li class="footer-list-elem rp-text-secondary-dark">
        <a class="rp-link-secondary" [href]="PRIVACY_POLICY_URL" target="_blank" rel="noopener noreferrer">
          {{ 'landing.footer.privacy' | translate }}
        </a>
      </li>
    </ul>
  </div>
  <div class="footer-row rp-w-container-s">Copyright © Rappi S.A.S. {{ 'landing.footer.rights' | translate }}</div>
</footer>
