import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { StoreName } from '../../store/definitions/store.constants';
import { accountReducer } from './reducers/account.reducer';

@NgModule({
  imports: [StoreModule.forFeature(StoreName.account, accountReducer)]
})
export class PermissionsStoreModule {}
