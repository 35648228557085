import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Observable } from 'rxjs';

import { Paginate, Permission } from '../definitions/permissions.models';
import { environment } from '../../../environments/environment';
import { Role } from '../definitions/roles.models';
import { AppState } from '../../store/states/app.state';
import { Store } from '@ngrx/store';
import { UserType } from '../definitions/permissions.enums';

import { UtilPermissions } from '../utils/util-permissions.service';

@Injectable()
export class RolesService extends UtilPermissions {
  userType: UserType;
  accountId: number;

  constructor(
    private readonly _http: HttpClient,
    private readonly _storeAuxService: StoreAuxService,
    _store: Store<AppState>
  ) {
    super(_store);
  }

  getRoles(page: number, limit: number, name?: string): Observable<Permission<Paginate<Role>>> {
    const params = new HttpParams({
      fromObject: Object.assign({ page, limit, search_criteria: 'name', search_value: name || '' })
    });

    return this._http.get<Permission<Paginate<Role>>>(`${this._getCountryUrl()}/roles/`, {
      params,
      ...this.getHeaders(this.userType, this.accountId)
    });
  }

  createRole(body: Role): Observable<Permission<Role>> {
    return this._http.post<Permission<Role>>(
      `${this._getCountryUrl()}/roles/`,
      body,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  editRole(body: Role, id: number): Observable<Permission<Role>> {
    return this._http.put<Permission<Role>>(
      `${this._getCountryUrl()}/roles/${id}`,
      body,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  getRole(id: number): Observable<Permission<Role>> {
    return this._http.get<Permission<Role>>(
      `${this._getCountryUrl()}/roles/${id}`,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  deleteRole(id: number): Observable<Permission<unknown>> {
    return this._http.delete<Permission<unknown>>(
      `${this._getCountryUrl()}/roles/${id}`,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  private _getCountryUrl(): string {
    return this._storeAuxService.getCountry(environment.urlRevenue);
  }
}
