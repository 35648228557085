import { Injectable } from '@angular/core';

import { Observable, Subject, forkJoin } from 'rxjs';
import { loadSource, MfeFileSource } from '@rappi/rappi-mfe-tools/lib';

@Injectable({
  providedIn: 'root'
})
export class MfeSourceLoaderService {
  public redirectMfeRoute: Subject<{ module: string; path: string }> = new Subject();
  private sources: { [key: string]: MfeFileSource } = {};

  constructor() {}

  load(sources: MfeFileSource[], targetNodeId?: string): Observable<MfeFileSource[]> {
    this.sources = this.initializeScripts(sources);

    const promises: Promise<MfeFileSource>[] = Object.entries(this.sources).map((data: [string, MfeFileSource]) =>
      loadSource(data[1], targetNodeId)
    );

    return forkJoin(promises);
  }

  private initializeScripts(sources: MfeFileSource[]): { [key: string]: MfeFileSource } {
    return sources.reduce((acc: { [key: string]: MfeFileSource }, source: MfeFileSource) => {
      acc[source.name] = {
        loaded: false,
        ...source
      };
      return acc;
    }, {});
  }
}
