export enum CountryCodeCurrencyMap {
  DEV = 'COP',
  CO = 'COP',
  AR = 'ARS',
  BR = 'BRL',
  UY = 'UYU',
  CL = 'CLP',
  MX = 'MXN',
  PE = 'PEN',
  EC = 'USD',
  CR = 'CRC'
}

export enum CustomCurrencySymbolMap {
  PE = 'S/'
}
