import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Observable } from 'rxjs';

import { Action } from '../../models/user/user.model';

import { environment } from '../../../environments/environment';

@Injectable()
export class ModulesService {
  readonly BASE_PATH = 'users/';

  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getModules(): Observable<{ data: Action }> {
    return this._http.get<{ data: Action }>(`${this._getCountryUrl()}modules`);
  }

  private _getCountryUrl(): string {
    return `${this._storeAuxService.getCountry(environment.urlRevenue)}/${this.BASE_PATH}`;
  }
}
