import { FaqData, PricingCardData } from '../definitions/pricing.models';
export interface GeneralPricing {
  [key: string]: number | string | boolean;
}

export interface PricingProduct {
  title: string;
  subproduct?: PricingProduct[];
  modules?: string[];
}

const pricingContentData = {
  analyze1: 'landing.pricing.plans.content.analyze1',
  analyze2: 'landing.pricing.plans.content.analyze2',
  analyze3: 'landing.pricing.plans.content.analyze3',
  analyze4: 'landing.pricing.plans.content.analyze4',
  analyze5: 'landing.pricing.plans.content.analyze5',
  sense1: 'landing.pricing.plans.content.sense1',
  sense2: 'landing.pricing.plans.content.sense2',
  sense3: 'landing.pricing.plans.content.sense3',
  sense4: 'landing.pricing.plans.content.sense4',
  sense5: 'landing.pricing.plans.content.sense5',
  create1: 'landing.pricing.plans.content.create1',
  create2: 'landing.pricing.plans.content.create2',
  create3: 'landing.pricing.plans.content.create3',
  create4: 'landing.pricing.plans.content.create4'
};

export const PRICING_DATA: PricingCardData[] = [
  {
    image: 'assets/images/landing/pricing-1.png',
    title: 'landing.pricing.plans.freeTrial.title',
    description: 'landing.pricing.plans.freeTrial.description',
    info: [
      {
        title: 'Analyze',
        content: [
          { active: true, description: pricingContentData.analyze1 },
          { active: true, description: pricingContentData.analyze2 },
          { active: false, description: pricingContentData.analyze3 },
          { active: false, description: pricingContentData.analyze4 },
          { active: false, description: pricingContentData.analyze5 }
        ]
      },
      {
        title: 'Sense',
        content: [
          { active: true, description: pricingContentData.sense1 },
          { active: false, description: pricingContentData.sense2 },
          { active: false, description: pricingContentData.sense3 },
          { active: false, description: pricingContentData.sense4 },
          { active: false, description: pricingContentData.sense5 }
        ]
      },
      {
        title: 'Create',
        content: [
          { active: false, description: pricingContentData.create1 },
          { active: false, description: pricingContentData.create2 },
          { active: false, description: pricingContentData.create3 },
          { active: false, description: pricingContentData.create4 }
        ]
      }
    ]
  },
  {
    image: 'assets/images/landing/pricing-2.png',
    title: 'landing.pricing.plans.boost.title',
    description: 'landing.pricing.plans.boost.description',
    info: [
      {
        title: 'Analize',
        content: [
          { active: true, description: pricingContentData.analyze1 },
          { active: true, description: pricingContentData.analyze2 },
          { active: true, description: pricingContentData.analyze3 },
          { active: true, description: pricingContentData.analyze4 },
          { active: false, description: pricingContentData.analyze5 }
        ]
      },
      {
        title: 'Sense',
        content: [
          { active: true, description: pricingContentData.sense1 },
          { active: true, description: pricingContentData.sense2 },
          { active: true, description: pricingContentData.sense3 },
          { active: false, description: pricingContentData.sense4 },
          { active: false, description: pricingContentData.sense5 }
        ]
      },
      {
        title: 'Create',
        content: [
          { active: true, description: pricingContentData.create1 },
          { active: true, description: pricingContentData.create2 },
          { active: true, description: pricingContentData.create3 },
          { active: true, description: pricingContentData.create4 }
        ]
      }
    ]
  },
  {
    image: 'assets/images/landing/pricing-3.png',
    title: 'landing.pricing.plans.pro.title',
    description: 'landing.pricing.plans.pro.description',
    info: [
      {
        title: 'Analize',
        content: [
          { active: true, description: pricingContentData.analyze1 },
          { active: true, description: pricingContentData.analyze2 },
          { active: true, description: pricingContentData.analyze3 },
          { active: true, description: pricingContentData.analyze4 },
          { active: true, description: pricingContentData.analyze5 }
        ]
      },
      {
        title: 'Sense',
        content: [
          { active: true, description: pricingContentData.sense1 },
          { active: true, description: pricingContentData.sense2 },
          { active: true, description: pricingContentData.sense3 },
          { active: true, description: pricingContentData.sense4 },
          { active: true, description: pricingContentData.sense5 }
        ]
      },
      {
        title: 'Create',
        content: [
          { active: true, description: pricingContentData.create1 },
          { active: true, description: pricingContentData.create2 },
          { active: true, description: pricingContentData.create3 },
          { active: true, description: pricingContentData.create4 }
        ]
      }
    ]
  }
];

export const FAQ_DATA: FaqData = {
  title: 'Your questions, answered',
  questions: [
    {
      ask: 'Can I cancel anytime?',
      answer:
        'Of course. Contact our <a class="contact-us"> Client Support </a> team prior to your next billing cycle and we’ll take care of your request'
    },
    {
      ask: 'My team has credits. How do we use them?',
      answer:
        'Once your team signs up for a subscription plan, we’ll draw against your credit balance until it runs out. Only then will we start charging your subscription to your credit card'
    },
    {
      ask: 'Is there a setup fee?',
      answer:
        'We’ll make a one-time, prorated charge to your credit card to cover your new team member’s account for the remainder of the current billing period'
    },
    {
      ask: 'What are my payment options - credit card and/or invoicing?',
      answer:
        'You can use your credit card to pay for any plan. If you sign up for an Annual subscription and plan to spend at least $2,000'
    },
    {
      ask: 'Can we get a refund?',
      answer:
        'Team Owners can change the team’s subscription level. There are two options when downgrading from a paid plan to the Free plan'
    }
  ]
};
