import { BackOfficeComponent, MenuComponent, UnavailableViewComponent } from './core';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthService } from './services/auth/auth.service';
import { UsersService } from './services/users/users.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmModalComponent } from './core/confirm-modal/confirm-modal.component';
import { EncryptService } from './services/encrypt/encrypt.service';
import { PermissionGuard } from './services/guards/permissions/permissions.guard';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { ModulesService } from './services/modules/modules.service';
import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { RootStoreModule } from './store/root-store.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PermissionsService } from './services/permissions/permissions.service';
import { environment } from '../environments/environment';
import { interceptorProviders } from './services/interceptors/interceptorProviders';
import { BreadcrumbsModule } from '@rappi/ui/breadcrumbs';
import { TitleBarModule } from '@rappi/ui/title-bar';
import { AlertsModule } from '@rappi/ui/alerts';
import { OneTitleBarService } from './core/back-office/services/title-bar.service';
import { TitleSectionComponentModule } from './core/back-office/components/title-section.component.module';
import { TranslateApplicationModule } from './translate-application.module';

import { UserSelectorMobileComponent } from './core/header/components/user-selector-mobile/user-selector-mobile.component';
import { CountrySelectorComponent } from './core/header/components/country-selector/country-selector.component';
import { MenuContentMobileComponent } from './core/header/components/menu-content-mobile/menu-content-mobile.component';
import { OptionsCountryMenuComponent } from './core/header/components/options-country-menu/options-country-menu.component';
import { OptionsNavigationMenuComponent } from './core/header/components/options-navigation-menu/options-navigation-menu.component';
import { OptionsUserMenuComponent } from './core/header/components/options-user-menu/options-user-menu.component';
import { UserSelectorComponent } from './core/header/components/user-selector/user-selector.component';
import { HeaderComponent } from './core/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationsHeaderModule } from './core/notifications-header/notifications-header.module';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './services/route-reuse/route-reuse.service';
import { AlertComponent } from './core/back-office/components/alert/alert.component';
import { CustomTooltipModule } from '@rappi/ui/custom-tooltip';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    BackOfficeComponent,
    ConfirmModalComponent,
    UnavailableViewComponent,
    AlertComponent,

    // TODO: move to header module
    CountrySelectorComponent,
    HeaderComponent,
    MenuContentMobileComponent,
    OptionsCountryMenuComponent,
    OptionsNavigationMenuComponent,
    OptionsUserMenuComponent,
    UserSelectorComponent,
    UserSelectorMobileComponent
  ],
  imports: [
    NotificationsHeaderModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AlertsModule,
    TitleSectionComponentModule,
    TitleBarModule.forRoot(),
    BreadcrumbsModule.forRoot(),
    McBreadcrumbsModule.forRoot(),
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production
    }),
    PipesModule,
    RootStoreModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: true,
      maxOpened: 2
    }),
    TranslateApplicationModule,
    // Move this module to header:
    MatMenuModule,
    // Move this module to confirm modal:
    MatDialogModule,
    CustomTooltipModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: RouteReuseService },
    interceptorProviders,
    { provide: 'TitleBarActionsService', useClass: OneTitleBarService },
    PermissionGuard,
    AuthService,
    UsersService,
    // TODO: Remove this service
    EncryptService,
    // TODO: use new alerts
    ToastrService,
    // TODO: move with back office
    PermissionsService,
    ModulesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
