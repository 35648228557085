<h1 mat-dialog-title>Select your country</h1>
<div mat-dialog-content>
  <button type="button" class="close-btn one-icon-times" mat-dialog-close aria-label="Close modal"></button>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form, url)" novalidate id="form">
    <one-select
      class="one-select"
      label="Country"
      [dataSource]="countriesDataSource"
      [keys]="COUNTRY_SELECT_KEYS"
      (selectionChanged)="setCountry($event)"
    ></one-select>
  </form>
</div>
<div mat-dialog-actions>
  <div></div>
  <button class="rp-button rp-small cancel-button" mat-dialog-close>Cancel</button>
  <button class="rp-button rp-filled rp-primary rp-small" [disabled]="form.invalid" type="submit" form="form">Confirm</button>
</div>
