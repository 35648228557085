import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FULL_PATHS } from '../../definitions/landing.constant';
import { PricingCardData } from '../../definitions/pricing.models';

@Component({
  selector: 'app-pricing-grid',
  templateUrl: './pricing-grid.component.html',
  styleUrls: ['./pricing-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricingGridComponent {
  @Input() princingData: PricingCardData[];

  readonly FULL_PATHS = FULL_PATHS;
}
