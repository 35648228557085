import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { TierService } from '../../../../services/tier.service';

import { Tier } from '../../../../definitions/tier.models';
import { Permission } from '../../../../definitions/permissions.models';
import { AlertsService } from '../../../../..//services/alerts/alerts.service';
import { PermissionComponent } from '../../../../definitions/component.models';
import { ACTIONS } from '../../../../definitions/actions.enums';
import { MODULES_ICON } from '../../../../definitions/table-modules.enums';

import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { catchError, map, skip, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnDestroy {
  id: number;
  data$: Observable<Tier>;
  VIEW = ACTIONS.view;

  deleteTier: (id: number) => Observable<Permission<unknown>>;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _tierService: TierService,
    private readonly _alertsService: AlertsService,
    private readonly _location: Location,
    private readonly _store: Store<AppState>
  ) {
    this.id = Number(this._activatedRoute.snapshot.params.id);
    this.deleteTier = (id: number) => this._tierService.deleteTier(id).pipe(tap(() => this._location.back()));

    this._store
      .pipe(
        select(StoreName.country),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this._location.back())
      )
      .subscribe();
  }

  ngOnInit() {
    this.data$ = this._tierService.getTier(this.id).pipe(
      map(
        ({ data }: Permission<Tier>): Tier => {
          data.components = (data.components || []).map((d: PermissionComponent) => {
            d.class = MODULES_ICON[d.name];
            return d;
          });

          return data;
        }
      ),
      catchError(({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || 'There was an error trying to get tier', AlertsType.error);
        return EMPTY;
      })
    );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
