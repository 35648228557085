import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { AnalyzeMfeRoutingModule } from './analyze-mfe-routing.module';

import { AnalyzeComponent } from './analyze/analyze.component';

@NgModule({
  declarations: [AnalyzeComponent],
  imports: [CommonModule, AnalyzeMfeRoutingModule]
})
export class AnalyzeMfeModule {}
