<ng-container *ngFor="let item of items; let indexItem = index">
  <ul class="side-menu-list" *ngIf="item.active" routerLinkActive="link-active">
    <h2 class="side-menu-title" *ngIf="item.product as heading">{{ heading | translate }}</h2>
    <ng-container *ngFor="let module of item.modules; let indexModule = index">
      <ng-container *ngIf="module.children; else noChildren">
        <li class="side-menu-item" *ngIf="module.active" routerLinkActive="active">
          <span class="side-menu-link" (click)="displaySubmenu(indexItem, indexModule)">
            <i [class]="module.icon" aria-hidden="true"></i>
            <span class="side-menu-link-text">{{ module.name | translate }}</span>
            <i class="one-icon-chevron-left" [class.one-submenu-open]="module.openMenu" aria-hidden="true"></i>
          </span>
          <ul class="side-submenu-item" [class.show-submenu]="module.openMenu">
            <ng-container *ngFor="let child of module.children">
              <li class="side-menu-item" *ngIf="child.active">
                <a
                  class="side-menu-link side-submenu-link"
                  (click)="closeMenu(); emitAmplitudeEventMenuMobile('/' + module.url + '/' + child.url, _router.url)"
                  [routerLink]="['/' + module.url + '/' + child.url]"
                  routerLinkActive="submenu-active"
                >
                  <span class="side-menu-link-text">{{ child.name | translate }} </span>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
      <ng-template #noChildren>
        <li class="side-menu-item" *ngIf="module.active">
          <a class="side-menu-link" (click)="closeMenu(); emitAmplitudeEventMenuMobile('/'+module.url, _router.url)" [routerLink]="['/' + module.url]" routerLinkActive="active">
            <i [class]="module.icon" aria-hidden="true"></i>
            <span class="side-menu-link-text">{{ module.name | translate }}</span>
          </a>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</ng-container>
