import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacePipe'
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, toReplace = ' ', regEx: RegExp | string = /_/g): string {
    return value ? value.replace(regEx, toReplace) : '';
  }
}
