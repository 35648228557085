<app-actions [id]="id" type="Tier" [action]="action" [service]="deleteTier"></app-actions>

<form [formGroup]="form" (submit)="onSubmit(form)">
  <app-form-group icon="one-icon-user-tooltip" title="General Information">
    <div>
      <one-input label="Tier ID" [value]="id" disabled="true"></one-input>
    </div>
    <div>
      <one-input label="Tier Title" formControlName="name" appFormErrors></one-input>
    </div>
    <div>
      <one-select
        #typeSelect
        [dataSource]="typeDataSource"
        label="Tier Type"
        (selectionChanged)="setType($event)"
        appFormErrors
      ></one-select>
    </div>
    <div></div>
    <div class="span-2">
      <one-input label="Description" formControlName="description" appFormErrors></one-input>
    </div>
  </app-form-group>

  <app-form-group icon="one-icon-diagonal-tag" title="Modules and Components">
    <one-tree
      class="span-2"
      [columns]="2"
      #tree
      [data]="(components$ | async) || []"
      (selectionChanged)="setComponents($event)"
      [showCountAllChildsActiveInTree]="true"
    ></one-tree>
  </app-form-group>

  <div class="form-actions">
    <button class="rp-button rp-filled rp-raisable" (click)="goTo(viewUrl || tableUrl)" type="button">Cancel</button>
    <button class="rp-button rp-filled rp-raisable rp-success" [disabled]="form.invalid">
      {{ action | titlecase }} Tier
    </button>
  </div>
</form>
