import { Component, ViewEncapsulation } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { CountryModalComponent } from '../country-modal/country-modal.component';

import { FULL_PATHS, PRIVACY_POLICY_URL, TERMS_AND_CONDITION_URLS } from '../../definitions/landing.constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  readonly FULL_PATHS = FULL_PATHS;
  readonly PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;

  constructor(private readonly _dialog: MatDialog) {}

  openCountryModal() {
    this._dialog.open(CountryModalComponent, {
      width: '92%',
      maxWidth: '510px',
      data: TERMS_AND_CONDITION_URLS
    });
  }
}
