<button class="trigger one-icon-three-dots" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger"></button>

<mat-menu #menu="matMenu" backdropClass="custom-actions-menu">
  <a class="one-icon-eye table-menu-item" mat-menu-item disableRipple [routerLink]="['view', id]"> View {{ type }} </a>
  <button
    class="one-icon-trash table-menu-item"
    mat-menu-item
    disableRipple
    (click)="deletePermission.delete(id, type)"
  >
    Delete {{ type }}
  </button>
  <button
    *ngIf="(isAdmin$ | async) && action"
    class="one-icon-compare-arrows table-menu-item"
    mat-menu-item
    disableRipple
    (click)="action()"
  >
    Support {{ type }}
  </button>
  <button
    *ngIf="isAdmin$ | async"
    class="one-icon-torn-paper table-menu-item"
    mat-menu-item
    disableRipple
    (click)="confirmAccount()"
  >
    Confirm {{ type }}
  </button>
  <button
    *ngIf="sendEmail"
    class="one-icon-lightning table-menu-item"
    mat-menu-item
    disableRipple
    (click)="sendEmail()"
  >
    Resend confirmation email
  </button>
  <button
    *ngIf="(isAdmin$ | async) && resetPassword"
    class="one-icon-reset-password table-menu-item"
    mat-menu-item
    disableRipple
    (click)="resetPassword()"
  >
    Reset password
  </button>
</mat-menu>
