import { Store, select } from '@ngrx/store';

import { CountryCodeCurrencyMap, CustomCurrencySymbolMap } from './store-aux.service.enum';
import { CountryState } from '../../store/states/country.state';
import { EncryptService } from '../encrypt/encrypt.service';
import { Injectable } from '@angular/core';
import { StoreName } from '../../store/definitions/store.constants';
import { UtilService } from '../util/util.service';
import { environment } from '../../../environments/environment';
import { AppState } from '../../store/states/app.state';
import { SYMBOL_NARROW } from './store-aux.service.constant';

@Injectable({
  providedIn: 'root'
})
export class StoreAuxService {
  country: string;

  constructor(private readonly _store: Store<AppState>, private readonly _utilService: UtilService) {
    this._store.pipe(select(StoreName.country)).subscribe((state: CountryState) => {
      this.country = state.countrySelected;
    });
  }

  getCountry(path: string, countryAux?: string): string {
    const countrySend = countryAux || this.country;
    return this._utilService.concatUrlPath(path, countrySend);
  }

  getCountryCurrency(): string {
    return CountryCodeCurrencyMap[this.country];
  }

  getCountryBySelector(): string {
    return environment.urlImage[this.country];
  }

  getCountryCurrencySymbol(): string {
    return CustomCurrencySymbolMap[this.country] || SYMBOL_NARROW;
  }
}
