export enum COUNTRY_NAME {
  DEV = 'Desarrollo',
  CO = 'Colombia',
  AR = 'Argentina',
  BR = 'Brasil',
  CL = 'Chile',
  MX = 'México',
  PE = 'Perú',
  UY = 'Uruguay',
  EC = 'Ecuador',
  CR = 'Costa Rica'
}
