export enum StatusCampaignManagement {
  DRAFT = 'DRAFT',
  ENABLED = 'ENABLED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED'
}

export enum SamplingTypes {
  SAMPLING_ON_TOP = 'SAMPLING_ON_TOP',
  EXPANSION = 'EXPANSION',
  SAMPLING_IDEAL = 'SAMPLING_IDEAL'
}

export enum SurveyExecutionRuleDef {
  AT_SAME_DAY = 'AT_SAME_DAY',
  AFTER_ONE_DAY = 'AFTER_ONE_DAY',
  AFTER_THERE_DAY = 'AFTER_THERE_DAY',
  AFTER_ONE_WEEK = 'AFTER_ONE_WEEK',
  NEVER = 'NEVER'
}

export enum CampaignSubType {
  RAPPI_PRIME = 'RAPPI_PRIME',
  TRADITIONAL_SEGMENTED = 'TRADITIONAL_SEGMENTED'
}
