import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Module } from '../../../models/user/user.model';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/states/app.state';
import { StoreName } from '../../../store/definitions/store.constants';
import { UserState } from '../../../store/states/user.state';
import { UserType } from '../../../permissions/definitions/permissions.enums';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionsService {

  private modules: Module[];
  private userType: string;

  constructor(
    private readonly _store: Store<AppState>
  ) {
    this.subscribeUserStore();
  }

  checkPermission(permission: string): boolean {
    return Boolean(
      this.userType === UserType.admin ||
      (this.modules || []).map((module: Module) => module.slug).some((slug: string) => slug === permission)
    );
  }

  private subscribeUserStore() {
    this._store.pipe(select(StoreName.user)).subscribe((user: UserState) => {
      this.userType = user.user_type;
      this.modules = user.modules;
    });
  }
}
