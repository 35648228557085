import { Component, Input, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../../services/alerts/alerts.service';

import { DeletePermission } from '../../utils/delete';

import { Observable } from 'rxjs';
import { Permission } from '../../definitions/permissions.models';
import { ACTIONS } from '../../definitions/actions.enums';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  @Input() type: string;
  @Input() id: number;
  @Input() action: string;
  @Input() alertMessage: string;
  @Input() service: (id: number) => Observable<Permission<unknown>>;

  readonly ACTIONS = ACTIONS;
  deletePermission: DeletePermission;

  constructor(private readonly _dialog: MatDialog, private readonly _alertService: AlertsService) {}

  ngOnInit() {
    this.deletePermission = new DeletePermission(this._dialog, this._alertService, this.service);
    this.setMessage();
  }

  setMessage() {
    if (this.alertMessage) {
      this.deletePermission.alertMessage = this.alertMessage;
    }
  }
}
