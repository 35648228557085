// / <reference path="../../index.d.ts" />
import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateApplicationService } from './services/translate/translate-application.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Language } from './shared/enums';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnDestroy {
  destroySubject$: Subject<void> = new Subject();

  constructor(
    private readonly _translate: TranslateService,
    private readonly _translateApplicationService: TranslateApplicationService
  ) {
    this.loadInfo();
  }

  loadInfo() {
    this._translateApplicationService.language$.pipe(takeUntil(this.destroySubject$)).subscribe((lang: Language) => {
      if (this._translate.getDefaultLang()) {
        this._translate.use(lang);
      } else {
        this._translate.setDefaultLang(lang);
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
