import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { SidenavService } from './services/sidenav.service';
import { Subject } from 'rxjs';
import { takeUntil, filter, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import * as moduleActions from '../../store/actions/module.action';
import { AppState } from '../../store/states/app.state';
import { ControlPanelAlert } from '../../models/user/user.model';
import { AlertComponent } from './components/alert/alert.component';

import { TitleBarRouteData, TitleBarService } from '@rappi/ui/title-bar';

@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BackOfficeComponent implements OnInit, OnDestroy {
  destroySubject$: Subject<void> = new Subject();
  showBreadcrumbs = true;
  showModuleTitle = true;
  showActions = true;
  showTitleBar = true;

  constructor(
    private readonly _sidenavService: SidenavService,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _activatedRoute: ActivatedRoute,
    public readonly titleBarService: TitleBarService,
    private readonly _location: Location,
    private readonly _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.titleBarService.routeData.subscribe((data: TitleBarRouteData) => {
      this.showBreadcrumbs = !data.hideBreadcrumbs;
      this.showModuleTitle = !data.hideModuleTitle;
      this.showActions = !data.hideActions;
      this.showTitleBar = !(data.hideBreadcrumbs && data.hideModuleTitle);
    });

    this.listenModuleByRouteChange();
    this.listenAlert((this._location.getState() as { alert: ControlPanelAlert }).alert);
  }

  listenModuleByRouteChange() {
    this._router.events
      .pipe(
        takeUntil(this.destroySubject$),
        filter((event) => event instanceof NavigationEnd),
        startWith({ urlAfterRedirects: this._router.url } as NavigationEnd)
      )
      .subscribe(() => {
        this.dispatchAction(this.getParam(this._activatedRoute.snapshot));
        this.dispatchAction(this.getParam(this._activatedRoute.snapshot, 'module'), 'setSubmodule');

        this._sidenavService.closeSidePanel();
      });
  }

  listenAlert(state: ControlPanelAlert) {
    if (state) {
      this._dialog.open(AlertComponent, {
        data: state,
        width: '80%',
        maxWidth: '1152px'
      });
    }
  }

  getParam(snapshot: ActivatedRouteSnapshot, param = 'parentModule'): string {
    return snapshot.firstChild?.data[param] ? this.getParam(snapshot.firstChild, param) : snapshot.data[param];
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  dispatchAction(payload: string, action = 'set') {
    this._store.dispatch(moduleActions[action]({ payload }));
  }
}
