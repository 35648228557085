import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(value: string, condition = true) {
    return condition
      ? value
          .split(' ')
          .slice(0, 2)
          .map((word: string) => word[0])
          .join('')
          .toUpperCase()
      : value;
  }
}
