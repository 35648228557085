import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { ChangePasswordComponent } from '../core/landing/change-password/change-password.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'reset/:token',
        component: ChangePasswordComponent
      },
      {
        path: '',
        component: LogoutComponent
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SessionRoutingModule {}
