import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PATHS } from './definitions/landing.constant';

import { LandingComponent } from './landing.component';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { ProductsComponent } from './products/products.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForAgenciesComponent } from './for-agencies/for-agencies.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { PlatformOutageComponent } from './platform-outage/platform-outage.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: PATHS.home,
        component: HomeComponent
      },
      {
        path: PATHS.login,
        component: LoginComponent
      },
      {
        path: PATHS.signUp,
        component: SignUpComponent
      },
      {
        path: PATHS.changePassword,
        component: ChangePasswordComponent
      },
      {
        path: `${PATHS.confirmAccount}/:token/:email`,
        component: ConfirmAccountComponent
      },
      {
        path: PATHS.resetPassword,
        component: ResetPasswordComponent
      },
      {
        path: PATHS.features,
        component: ProductsComponent
      },
      {
        path: PATHS.forAgencies,
        component: ForAgenciesComponent
      },
      {
        path: PATHS.pricing,
        component: PricingComponent
      },
      {
        path: PATHS.faq,
        component: FaqComponent
      },
      {
        path: PATHS.platformOutage,
        component: PlatformOutageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule {}
