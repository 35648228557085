export const PathsSampling = {
  MANAGEMENT: 'management',
  OPERATOR: 'operator',
  SETTINGS: 'settings',
  WAREHOUSES: 'warehouses',
  CONTROL_PANEL: 'control-panel',
  PRICING: 'pricing',
};

export const PathsOperator = {
  DELIVERY: 'delivery',
  INVENTORY: 'inventory',
  UNITS_INVENTORY: 'units-inventory',
  UNITS_PENDING_INVENTORY: 'units-pending-inventory'
};


export const PermissionsSamplingCore = {
  OperateInventory: 'internal-tools/sampling-ops/warehouses/operate-inventory',
  AddWarehouse: 'internal-tools-sampling-ops-warehouses-add-warehouse',
  WarehouseMenuDeleteEdit: 'internal-tools-sampling-ops-warehouses-menu-edit-delete-warehouse',
  EditWarehouse: 'internal-tools-sampling-ops-warehouses-edit-warehouse',
  DeleteWarehouse: 'internal-tools-sampling-ops-warehouses-delete-warehouse',
  AddVan: 'internal-tools-sampling-ops-warehouses-vans-create-van',
  VanMenuDeleteEdit: 'internal-tools-sampling-ops-warehouses-vans-menu-edit-delete-van',
  EditVan: 'internal-tools-sampling-ops-warehouses-vans-edit-van',
  DeleteVan: 'internal-tools-sampling-ops-warehouses-vans-delete-van',
};

export const CountryCurrency = {
  BR: 'Reais',
  CO: 'Peso Colombiano',
  MX: 'Peso Mexicano',
  PE: 'Soles',
  CL: 'Peso Chileno',
  AR: 'Peso Argentino',
  EC: 'USD',
  DEFAULT: 'USD'
};
