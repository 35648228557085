import { Component } from '@angular/core';

@Component({
  selector: 'app-unavailable-view',
  templateUrl: './unavailable-view.component.html',
  styleUrls: ['./unavailable-view.component.scss']
})
export class UnavailableViewComponent {

  unavailableImage = 'assets/images/unplugged.png';

}
