import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-maker-list',
  templateUrl: './maker-list.component.html',
  styleUrls: ['./maker-list.component.scss']
})
export class MakerListComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public makers: string[]) {}
}
