import { Component } from '@angular/core';
import { CreateManage } from '../definitions/create-core.constant';

@Component({
  selector: 'app-create-core',
  templateUrl: './create-core.component.html',
  styleUrls: ['./create-core.component.scss']
})
export class CreateCoreComponent {
  createManage = CreateManage;
}
