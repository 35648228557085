import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { ControlPanelComponent } from './components/control-panel/control-panel.component';

const routes: Routes = [
  {
    path: '',
    component: ControlPanelComponent,
    data: {
      title: 'Control Panel'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ControlPanelRoutingModule {}
