import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsMfeRoutingModule } from './reports-mfe-routing.module';
import { ReportsMfeComponent } from './reports-mfe/reports-mfe.component';


@NgModule({
  declarations: [ReportsMfeComponent],
  imports: [
    CommonModule,
    ReportsMfeRoutingModule
  ]
})
export class ReportsMfeModule { }
