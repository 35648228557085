import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Warehouse } from '../shared/interfaces';

@Injectable()
export class WarehouseManagerService {
  currentWarehouse$: BehaviorSubject<Warehouse> = new BehaviorSubject(null);

  getCurrentWarehouse(): Observable<Warehouse> {
    return this.currentWarehouse$.asObservable();
  }

  setWarehouse(data: Warehouse) {
    this.currentWarehouse$.next(data);
  }
}
