import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainManagerService } from '../../services/main-manager.service';
import { SelectProductDelivery, DataProductDelivery, ReturnProductDelivery } from '../../shared/interfaces';
import { finalize, map } from 'rxjs/operators';

@Component({
  selector: 'app-product-delivery',
  templateUrl: './product-delivery.component.html',
  styleUrls: ['./product-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDeliveryComponent implements OnInit {
  loading = false;
  maxValue = 0;
  formDelivery = this._fb.group({
    units: [
      null,
      [Validators.required, Validators.min(1), (control: AbstractControl) => Validators.max(this.maxValue)(control)]
    ],
    select_id: [null, Validators.required]
  });

  selectList: { id: string | number; name: string; maxValue?: number }[] = [];

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _dialogRef: MatDialogRef<ProductDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: DataProductDelivery,
    private readonly _mainService: MainManagerService
  ) {}

  ngOnInit() {
    this.getSelectList();
  }

  close(type: ReturnProductDelivery = null) {
    this._dialogRef.close(type);
  }

  confirm() {
    if (this.formDelivery.valid) {
      this.loading = true;

      const service =
        this.dataModal.titleEntity === 'Van'
          ? this._mainService.createTransaction(
              Number(this.dataModal.inventory.source_code),
              this.dataModal.inventory.id,
              this.formDelivery.value.units,
              this.formDelivery.value.select_id
            )
          : this._mainService.receiveProduct(
              this.formDelivery.value.select_id,
              this.formDelivery.value.units,
              Number(this.dataModal.inventory.source_code)
            );

      service.pipe(finalize(() => (this.loading = false))).subscribe(() => {
        this.close({
          units: this.formDelivery.value.units,
          selectName: this.getElementName(this.formDelivery.value.select_id)
        });
      });
    }
  }

  getSelectList() {
    this.loading = true;
    this.dataModal.getSelectList
    .pipe(
      finalize(() => this.loading = false),
      map(
        (list: SelectProductDelivery[]) => list.map(({ key, value, maxValue }) => ({ id: key, name: value , maxValue }))
      )
    ).subscribe((res) => {
      this.selectList = res;
    });
  }

  getErrorMessage(): string {
    return (this.formDelivery.controls.units as FormControl).hasError('max')
      ? `The value cannot be greater than ${this.maxValue}`
      : (this.formDelivery.controls.units as FormControl).hasError('min')
      ? 'Incorrect Value'
      : 'This field is required';
  }

  getElementName(elementId: number): string {
    return this.selectList.find((element) => element.id === elementId)?.name || '';
  }

  setMaxValue(newValue: number) {
    this.maxValue = newValue || this.dataModal.inventory.current_quantity;
  }
}
