<div mat-dialog-title>
  <div class="title-content">
    <h1 class="title-modal">Units edition</h1>
    <button [mat-dialog-close]="false" class="one-icon-close-material-v2 close-icon"></button>
  </div>
  <mat-progress-bar mode="indeterminate" class="rp-loading-bar" [class.hidden]="!loading"></mat-progress-bar>
</div>

<div class="content-info" mat-dialog-content>
  <p>Current state: {{ dataModal?.campaign_name }} - {{ dataModal?.current_quantity }} units.</p>
  <p>Type the new number of units.</p>
  <form #myform="ngForm" [formGroup]="formUnits" (ngSubmit)="confirm()" id="formUnits">
    <div class="content-inputs">
      <div class="field-content">
        <one-input autocomplete="off" formControlName="units" type="number" label="Units"></one-input>
      </div>
      <div class="field-content">
        <one-select
          #selectComponent
          (selectionChanged)="formUnits.patchValue({ reason: $event.id })"
          [dataSource]="campaignVarietyList"
          label="Reason"
        >
        </one-select>
      </div>
    </div>
    <div class="content-error">
      <span
        *ngIf="
          (formUnits.get('units').hasError('required') ||
            formUnits.get('units').hasError('max') ||
            formUnits.get('units').hasError('min') ||
            formUnits.get('reason').hasError('required')) &&
          myform.submitted
        "
        class="error-message"
      >
        {{ getErrorMessage() }}
      </span>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="content-buttons">
    <button class="rp-button rp-primary rp-outlined rp-small" [matDialogClose]="false">Cancel</button>
    <button form="formUnits" type="submit" class="rp-button rp-filled rp-primary rp-small">Confirm</button>
  </div>
</div>
