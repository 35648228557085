import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { TypeModal } from '../../../core/confirm-modal/definitions/enums/type-modal.enum';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import { TypeCofirmModal } from '../../../core/confirm-modal/definitions/models/type-modal.model';
import { ControlPanelAlert } from '../../../models/user/user.model';

import { ControlPanelService } from '../../../services/control-panel/control-panel.service';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {
  @ViewChild('checkbox') checkbox: ElementRef<HTMLInputElement>;

  saved = false;
  alert$: BehaviorSubject<ControlPanelAlert> = new BehaviorSubject(null);

  constructor(private readonly _controlPanelService: ControlPanelService, private readonly _dialog: MatDialog) {}

  ngOnInit(): void {
    this.getCurrentAlert();
  }

  getCurrentAlert(): void {
    this._controlPanelService
      .getAlert()
      .pipe(
        map(({ data }) => data),
        filter((alert) => !!alert)
      )
      .subscribe((alert) => {
        this.alert$.next(alert);
        this.saved = true;
        this.checkbox.nativeElement.checked = true;
      });
  }

  switch(event: PointerEvent, saved: boolean) {
    if ((event.target as EventTarget & { checked: boolean }).checked) {
      this.alert$.next({} as ControlPanelAlert);
    } else if (!saved) {
      this.alert$.next(null);
    } else {
      event.preventDefault();
      this._dialog
        .open(ConfirmModalComponent, {
          maxWidth: '492px',
          data: {
            title: 'Are you sure you want to deactivate it?',
            message: 'User will not see any information about system issues.',
            type: TypeModal.confirm,
            textReject: 'Cancel',
            textSucces: 'Confirm'
          } as TypeCofirmModal
        })
        .afterClosed()
        .pipe(
          switchMap((cancel: boolean) => {
            if (cancel) {
              const { is_active, ...alert } = this.alert$.getValue();
              return this._controlPanelService.deactivateAlert(alert).pipe(map(() => true));
            }

            return of(false);
          }),
          filter((cancel) => cancel)
        )
        .subscribe(() => {
          this.alert$.next(null);
          this.checkbox.nativeElement.checked = false;
        });
    }
  }

  setAlert(alert: ControlPanelAlert) {
    this.alert$.next(alert);
  }
}
