import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateWarehouseRequest, OffPeakHour, VanDataModal, Warehouse, Zone } from '../../shared/interfaces';
import { FormGroup, Validators, FormControl, FormArray, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { StatusCheck } from '../../shared/enums/statusCheck.enum';
import { WarehouseType } from '../../shared/enums/warehouseType.enum';
import { MainManagerService } from '../../services/main-manager.service';
import { TimeValue } from '@rappi/ui/input-time';
import { SamplingOrdersService } from '../../../services/sampling-oders.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { LOCATION } from '../../../../shared/constants';

export const errorZoneIdKey = 'errorZoneId';
export const errorZoneId = { [errorZoneIdKey]: true };

@Component({
  selector: 'app-create-van',
  templateUrl: './create-van.component.html',
  styleUrls: ['./create-van.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateVanComponent implements OnInit, OnDestroy {
  readonly errorZoneId = errorZoneIdKey;
  userId: number;
  currentId: number = null;
  requiredMessage = 'This field is required';
  rangeSaturationMessage = 'Invalid. Type a number between 0 and 3. Ex: 1.22';
  mockValidationZoneId = of(errorZoneId);
  subscriptions = new Subscription();
  loadZoneId = false;


  vanForm: FormGroup = new FormGroup({
    parent_id: new FormControl({ value: this.currentId, disabled: true }, Validators.required),
    name: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    code: new FormControl('', [Validators.required, Validators.min(1)]),
    location_lat: new FormControl(null, Validators.required),
    location_lng: new FormControl(null, Validators.required),
    zone_id: new FormControl(null, Validators.required),
    manager_id: new FormControl('', Validators.required),
    user_dispatcher_id: new FormControl('', Validators.required),
    user_dispatcher_address_id: new FormControl('', Validators.required),
    status: new FormControl(true, Validators.required),
    warehouse_type: new FormControl(WarehouseType.VAN, Validators.required),
    time_between_orders: new FormControl(null, Validators.min(65)),
    off_peak_hours: new FormArray([], Validators.maxLength(2)),
    saturation: new FormControl('', [Validators.required, Validators.min(0), Validators.max(3)])
  });

  readonly errorMessages = {
    [errorZoneIdKey]: () => 'Not found',
    min: () => 'Type a value greater than 65 without any points'
  };

  readonly labelsName = {
    parent_id: 'Main parent',
    name: 'Name',
    city: 'City',
    address: 'Address',
    zone_id: 'Zone Id',
    city_address_id: 'City address id',
    code: 'Store code',
    location_lat: 'Latitude',
    location_lng: 'Longitude',
    manager_id: 'Manager Id',
    user_dispatcher_id: 'Application User Id',
    user_dispatcher_address_id: 'Address Id',
    status: 'Status',
    warehouse_type: 'Warehouse type',
    saturation: 'Maximum saturation for order creation',
    time_between_orders: 'Time between orders (seconds)'
  };

  loading = false;
  warehouse: Warehouse;
  buttonText = 'Create';
  titleText = 'Create';
  toggleStatus: boolean;
  statusCheck: StatusCheck;
  markerPosition: google.maps.LatLngLiteral;
  // TODO: add country property
  centerLocation = {
    lat: LOCATION.CO.lat,
    lng: LOCATION.CO.lng
  };


  constructor(
    private readonly _dialogRef: MatDialogRef<CreateVanComponent>,
    private readonly _alertsService: AlertsService,
    private readonly _warehouseService: MainManagerService,
    private readonly _samplingOrdersService: SamplingOrdersService,
    @Inject(MAT_DIALOG_DATA) public dataModal: VanDataModal
  ) { }

  errorMessageBind = (error: string) => this.errorMessages[error]();

  ngOnInit() {
    this.subscribeLatLngChanges();
    this.validateZoneId();
    this.setModal();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get offPeakHours(): FormArray {
    return this.vanForm.get('off_peak_hours') as FormArray;
  }

  addOffPeakHour(value?: OffPeakHour) {
    const store = this.offPeakHours;
    if (store.length < 2) {
      store.push(this.newFormGroup(value));
    }
  }

  deleteOffPeakHour(index: number) {
    const store = this.offPeakHours;
    const value = (store.value as OffPeakHour[])[index];
    if (value.id) {
      this.loading = true;
      this._samplingOrdersService.deleteValleyHours(value.id)
        .pipe(finalize(() => this.loading = false))
        .subscribe(() => {
          store.removeAt(index);
        });
    } else {
      store.removeAt(index);
    }
  }

  newFormGroup(value: OffPeakHour): FormGroup {
    return new FormGroup(
      {
        init_time: new FormControl(this.getTimeValue(value?.init_time), Validators.required),
        end_time: new FormControl(this.getTimeValue(value?.end_time), Validators.required),
        id: new FormControl(value?.id)
      },
      this.getValidationRangeTime()
    );
  }

  getTimeValue(value: string): TimeValue {
    if (value) {
      const data = value.split(':');
      return {
        hours: data.shift(),
        minutes: data.shift()
      };
    }
    return null;
  }

  setModal() {
    this.vanForm.patchValue({ parent_id: this.dataModal.parentId });
    if (this.dataModal.warehouse?.reference_code) {
      this._warehouseService.getVan(this.dataModal.warehouse.reference_code).subscribe((res: Warehouse) => {
        this.warehouse = res;
        this.buildEditForm();
      });
      this.titleText = 'Edit';
      this.buttonText = 'Save';
    }
  }

  buildEditForm() {
    this.vanForm.patchValue({
      ...this.warehouse,
      code: this.warehouse.reference_code,
      parent_id: this.dataModal.parentId,
      status: this.warehouse?.status === StatusCheck.ENABLED,
      off_peak_hours: []
    });

    this.markerPosition = {
      lat: this.warehouse.location_lat,
      lng: this.warehouse.location_lng
    };
    this.addOffPeakHours(this.warehouse.off_peak_hours || []);
  }

  addOffPeakHours(value: OffPeakHour[]) {
    value.forEach((el: OffPeakHour) => this.addOffPeakHour(el));
  }

  subscribeLatLngChanges() {
    this.subscriptions.add(combineLatest([
      this.vanForm.controls.location_lat.valueChanges,
      this.vanForm.controls.location_lng.valueChanges
    ]).subscribe(([lat, lng]: number[]) => {
      this.setMarketInput(Number(lat), Number(lng));
    }));
  }

  addMarker(event: google.maps.MouseEvent) {
    this.markerPosition = event.latLng.toJSON();
    this.vanForm.patchValue(
      {
        location_lat: this.markerPosition.lat,
        location_lng: this.markerPosition.lng
      }
    );
  }

  setMarketInput(lat: number, lng: number) {
    this.markerPosition = {
      lat: lat || this.centerLocation.lat,
      lng: lng || this.centerLocation.lng
    };
  }

  getOffPeakHours(value: OffPeakHour<TimeValue>): OffPeakHour {
    return {
      init_time: value.init_time ? `${value.init_time.hours}:${value.init_time.minutes}` : null,
      end_time: value.end_time ? `${value.end_time.hours}:${value.end_time.minutes}` : null,
      id: value?.id
    };
  }

  submit() {
    if (this.vanForm.valid) {
      this.loading = true;
      const payload: CreateWarehouseRequest = {
        ...this.vanForm.getRawValue(),
        status: this.getValueStatus(this.vanForm.value.status),
        off_peak_hours: (this.vanForm.value
          .off_peak_hours as OffPeakHour<TimeValue>[]).map((el: OffPeakHour<TimeValue>) => this.getOffPeakHours(el))
      };

      if (!this.warehouse) {
        this._warehouseService.createMainWarehouse(payload)
          .pipe(finalize(() => this.loading = false))
          .subscribe(() => {
            this._alertsService.openAlerts('Van created successfully!', AlertsType.success);
            this._dialogRef.close(true);
          });
      } else {
        this._warehouseService.updateMainWarehouse(payload, this.warehouse.id)
          .pipe(finalize(() => this.loading = false))
          .subscribe(() => {
            this._alertsService.openAlerts('Van updated successfully!', AlertsType.success);
            this._dialogRef.close(true);
          });
      }
    }
  }

  getValueStatus(value: boolean): string {
    return value ? StatusCheck.ENABLED : StatusCheck.DISABLED;
  }

  validateZoneId() {
    const refLat = this.vanForm.controls.location_lat;
    const refLng = this.vanForm.controls.location_lng;
    const refZoneId = this.vanForm.controls.zone_id;

    this.subscriptions.add(combineLatest([
      refLat.valueChanges,
      refLng.valueChanges
    ]).pipe(
      debounceTime(800),
      distinctUntilChanged()
    ).subscribe(() => {
      this.getValidationZoneId(refLat, refLng, refZoneId);
    }));
  }

  getValidationZoneId(lat: AbstractControl, lng: AbstractControl, zoneId: AbstractControl) {

    if (lat.value && lng.value) {
      lat.setValidators(() => errorZoneId);
      lat.updateValueAndValidity({ emitEvent: false });
      lng.setValidators(() => errorZoneId);
      lng.updateValueAndValidity({ emitEvent: false });
      zoneId.setValidators(() => errorZoneId);
      zoneId.updateValueAndValidity({ emitEvent: false });

      this.loadZoneId = true;
      this._warehouseService.getZoneId(Number(lat.value), Number(lng.value))
        .pipe(
          map((res: { zones: Zone[] }) => res.zones.filter(el => el.id)),
          finalize(() => this.loadZoneId = false)
        ).subscribe((res: Zone[]) => {
          if (res.length) {
            lat.setValidators(Validators.required);
            lat.updateValueAndValidity({ emitEvent: false });
            lng.setValidators(Validators.required);
            lng.updateValueAndValidity({ emitEvent: false });
            zoneId.setValidators(Validators.required);
            zoneId.updateValueAndValidity({ emitEvent: false });
            zoneId.setValue(res[0].id);
          } else {
            zoneId.reset();
          }
        });
    } else {
      lat.setValidators(Validators.required);
      lat.updateValueAndValidity({ emitEvent: false });
      lng.setValidators(Validators.required);
      lng.updateValueAndValidity({ emitEvent: false });
      zoneId.setValidators(Validators.required);
      zoneId.updateValueAndValidity({ emitEvent: false });
      zoneId.reset();
    }
  }

  private getValidationRangeTime(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const initTime = group.controls['init_time'];
      const endTime = group.controls['end_time'];
      const value = this.getOffPeakHours(group.value);

      if (value.init_time && value.end_time && value.init_time >= value.end_time) {
        const error = { errorRange: true };
        initTime.setValidators(() => error);
        endTime.setValidators(() => error);
        initTime.updateValueAndValidity({ onlySelf: true });
        endTime.updateValueAndValidity({ onlySelf: true });
        return error;
      } else {
        initTime.setValidators(Validators.required);
        endTime.setValidators(Validators.required);
        initTime.updateValueAndValidity({ onlySelf: true });
        endTime.updateValueAndValidity({ onlySelf: true });
        return null;
      }
    };
  }

}
