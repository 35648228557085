import { HttpErrorResponse } from '@angular/common/http';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import { AlertsService } from '../../../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';

import { ACTIONS } from '../../../../definitions/actions.enums';
import { Admin } from '../../../../definitions/admin.models';
import { Permission } from '../../../../definitions/permissions.models';

import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, skip, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html'
})
export class ViewComponent implements OnInit, OnDestroy {
  id: number;
  data$: Observable<Admin>;
  VIEW = ACTIONS.view;

  deleteAdmin: (id: number) => Observable<Permission<unknown>>;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _adminService: AdminService,
    private readonly _alertsService: AlertsService,
    private readonly _location: Location,
    private readonly _store: Store<AppState>
  ) {
    this.id = Number(this._activatedRoute.snapshot.params.id);
    this.deleteAdmin = (id: number) => this._adminService.deleteAdmin(id).pipe(tap(() => this._location.back()));

    this._store
      .pipe(
        select(StoreName.country),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this._location.back())
      )
      .subscribe();
  }

  ngOnInit() {
    this.data$ = this._adminService.getAdmin(this.id).pipe(
      map(({ data }: Permission<Admin>): Admin => data),
      catchError(({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || 'There was an error trying to get admin', AlertsType.error);
        return EMPTY;
      })
    );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
