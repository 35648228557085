interface Paths {
  [key: string]: string;
}

export const PATHS: Paths = {
  home: '',
  features: 'features',
  forAgencies: 'for-agencies',
  pricing: 'pricing',
  login: 'login',
  signUp: 'sign-up',
  changePassword: 'change-password',
  confirmAccount: 'confirm-account',
  resetPassword: 'reset-password',
  productDetail: 'product-detail',
  signingUpSuccessfully: 'signing-up-successfully',
  faq: 'faq',
  platformOutage: 'platform-outage'
};

export const FULL_PATHS: Paths = Object.keys(PATHS).reduce((acc, curr) => {
  acc[curr] = `/${PATHS[curr]}`;
  return acc;
}, {});

const HEADER_ITEMS = [PATHS.forAgencies, PATHS.features, PATHS.pricing, PATHS.faq, PATHS.signUp];

const HEADER_ITEMS_ICON = {
  [PATHS.forAgencies]: 'forAgencies',
  [PATHS.features]: 'duotone-ecommerce',
  [PATHS.pricing]: 'dollar-sign',
  [PATHS.faq]: 'info-flat',
  [PATHS.signUp]: 'dollar-sign'
};

const HEADER_ITEMS_LABEL = {
  [PATHS.forAgencies]: 'landing.forAgencies.name',
  [PATHS.features]: 'landing.features.name',
  [PATHS.pricing]: 'landing.pricing.name',
  [PATHS.faq]: 'landing.faq.name',
  [PATHS.signUp]: 'landing.contactUs'
};

export const FULL_HEADER_ITEMS = HEADER_ITEMS.map((path: string) => ({
  icon: HEADER_ITEMS_ICON[path],
  path,
  label: HEADER_ITEMS_LABEL[path]
}));

export interface ConfirmUserRequest {
  company_code: string;
  country_code: string;
  email: string;
  first_name: string;
  last_name: string;
  job_title: string;
  password: string;
  phone: string;
}

export interface ContactUsDTO {
  description?: string;
  email: string;
  first_name: string;
  job_title?: string;
  phone: string;
  brands_users?: boolean;
  company?: string;
  type_of_industry?: string;
}

export interface PreRegisterRequest {
  first_name: string;
  email: string;
  job_title: string;
  phone: string;
  is_agency: boolean;
}

export const TERMS_AND_CONDITION_URLS: Record<string, string> = {
  DEV: '/assets/files/terms-conditions/co.pdf',
  CO: '/assets/files/terms-conditions/co.pdf',
  MX: '/assets/files/terms-conditions/mx.pdf',
  BR: '/assets/files/terms-conditions/br.pdf',
  AR: '/assets/files/terms-conditions/ar.pdf',
  CL: '/assets/files/terms-conditions/cl.pdf',
  PE: '/assets/files/terms-conditions/pe.pdf',
  UY: '/assets/files/terms-conditions/uy.pdf',
  EC: '/assets/files/terms-conditions/ec.pdf',
  CR: '/assets/files/terms-conditions/cr.pdf'
};

export const PRIVACY_POLICY_URL =
  'https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/';

export const PRIVACY_POLICY_URLS: Record<string, string> = {
  DEV: 'https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/',
  CO: 'https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/',
  MX: 'https://legal.rappi.com/mexico/terminos-condiciones-app-soy-shopper/',
  BR: 'https://legal.rappi.com/brazil/termos-e-condicoes-app-soy-shopper/',
  AR: 'https://legal.rappi.com/argentina/terminos-condiciones-app-soy-shopper-2/',
  CL: 'https://legal.rappi.com/chile/terminos-condiciones-app-soy-shopper-3/',
  PE: 'https://legal.rappi.com/peru/terminos-condiciones-app-soy-shopper-7/',
  UY: 'https://legal.rappi.com/uruguay/terminos-condiciones-app-soy-shopper-8/',
  EC: 'https://legal.rappi.com/ecuador/terminos-condiciones-app-soy-shopper-5/',
  CR: 'https://legal.rappi.com/costa-rica/terminos-condiciones-app-soy-shopper-6/'
};
