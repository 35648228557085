import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../../../services/storage/storage.service';
import { environment } from '../../../../environments/environment';
import { UserType } from '../../../permissions/definitions/permissions.enums';
import { StoreName } from '../../../store/definitions/store.constants';
import { AppState } from '../../../store/states/app.state';
import { UserState } from '../../../store/states/user.state';
import { FULL_PATHS } from '../definitions/landing.constant';
import { Country } from '../../../models/shared/shared.model';

@Component({
  selector: 'app-platform-outage',
  templateUrl: './platform-outage.component.html',
  styleUrls: ['./platform-outage.component.scss']
})
export class PlatformOutageComponent implements OnInit, OnDestroy {
  readonly FULL_PATHS = FULL_PATHS;
  readonly DEFAULT_PATH = `/${environment.modules.analytics}`;

  isAdmin: boolean;
  destroySubject$ = new Subject<void>();
  countriesOutage: Country[];
  isAllCountriesOutage: boolean;

  constructor(private readonly _store: Store<AppState>, private readonly _storageService: StorageService) {
    this._store.pipe(select(StoreName.user), takeUntil(this.destroySubject$)).subscribe(({ user_type }: UserState) => {
      this.isAdmin = user_type === UserType.admin;
    });
  }

  ngOnInit() {
    const countries = this._storageService.getCountries();
    this.countriesOutage = countries.filter((country) => country.platform_outage);
    this.isAllCountriesOutage = countries.every((country) => country.platform_outage);
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
