import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MasterDataComponent } from './master-data/master-data.component';
import { MasterDataMfeRoutingModule } from './master-data-mfe.routing';

@NgModule({
  declarations: [MasterDataComponent],
  imports: [CommonModule, MasterDataMfeRoutingModule]
})
export class MasterDataMfeModule {}
