import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import {
  MfeAttribute,
  MfeEvent,
  MfeFileSource,
} from '@rappi/rappi-mfe-tools/lib';
import { MicroFrontendWrapperComponent } from '../../mfe/models/abstracts';
import { MfeSourceLoaderService } from '../../mfe/services';
import { AppState } from '../../store/states/app.state';
import { EncryptService } from '../../services/encrypt/encrypt.service';
import { TranslateApplicationService } from '../../services/translate/translate-application.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-amplify',
  templateUrl: '../../mfe/models/abstracts/MicroFrontendWrapper.html',
  styleUrls: ['../../mfe/models/abstracts/MicroFrontendWrapper.scss']
})
export class DeeplinksComponent extends MicroFrontendWrapperComponent implements OnInit, OnDestroy {
  protected elName: string;
  protected mfeSources: MfeFileSource[];
  protected mfeAttributes: MfeAttribute[];
  protected mfeEvents: MfeEvent[];

  constructor(
    public readonly sourceLoader: MfeSourceLoaderService,
    public readonly cd: ChangeDetectorRef,
    public readonly encryptService: EncryptService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly store: Store<AppState>,
    public readonly translateApplicationService: TranslateApplicationService
  ) {
    super(sourceLoader, cd, encryptService, router, activatedRoute, store, translateApplicationService);
    this.elName = 'mfe-deeplinks';
    this.url = environment.urlDeeplinksMfeSourceRoot;
    this.prefixBreadcrumb = {
      navigate: `/${environment.modules.create}`,
      label: 'Create'
    };
  }
}


