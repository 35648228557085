<div class="pricing-wrapper">
  <div
    class="pricing-card"
    [class.isHover]="data.isHover"
    *ngFor="let data of princingData"
    (mouseover)="data.isHover = true"
    (mouseout)="data.isHover = false"
  >
    <div class="pricing-header">
      <img loading="lazy" [src]="data.image" alt="pricing-image" />
      <h2>{{ data.title | translate }}</h2>
      <p [innerHTML]="data.description | translate"></p>
      <div class="try-it-now-button">
        <button
          [ngClass]="data.isHover ? 'brands-primary-button' : 'brands-primary-button-underline'"
          [routerLink]="FULL_PATHS.signUp"
        >
          {{ 'landing.pricing.tryItNow' | translate }}
        </button>
      </div>
    </div>

    <div *ngFor="let info of data.info">
      <h3 class="info-title">{{ info.title | translate }}</h3>
      <ul class="info-content">
        <li
          [ngClass]="{ 'one-icon-check': content.active, desactive: !content.active }"
          *ngFor="let content of info.content"
        >
          {{ content.description | translate }}
        </li>
      </ul>
    </div>
    <div class="feature-message-wrapper">
      <a [routerLink]="FULL_PATHS.features">{{ 'landing.pricing.learnMore' | translate }}</a>
    </div>
  </div>

  <div class="enterprice-card">
    <img loading="lazy" src="assets/images/landing/pricing-4.png" alt="pricing image" />
    <div class="enterprice-card-info">
      <p class="enterprice-card-info-message">Pro + Plus</p>
      <p class="enterprice-card-info-title">Enterprise</p>
      <p class="enterprise-card-info-description">
        {{ 'landing.pricing.plans.enterprise.description' | translate }}
      </p>
    </div>
    <div class="button-wrapper">
      <button class="brands-primary-button" [routerLink]="FULL_PATHS.signUp">
        {{ 'landing.contactUs' | translate }}
      </button>
    </div>
  </div>
</div>
