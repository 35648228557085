import { Component, Input } from '@angular/core';
import { ModuleDescription } from './models';

@Component({
  selector: 'app-module-description',
  templateUrl: './module-description.component.html',
  styleUrls: ['./module-description.component.scss']
})
export class ModuleDescriptionComponent {
  @Input()
  module: ModuleDescription;
}
