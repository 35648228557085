<div mat-dialog-title>
  <div class="title-content">
    <h1 class="title-modal">{{ titleText }} Van</h1>
    <span mat-dialog-close class="one-icon-close-material-v2 close-icon"></span>
  </div>
</div>

<div class="content-dialog" mat-dialog-content>
  <div *ngIf="vanForm.controls.zone_id.errors?.errorZoneId && !loadZoneId" class="message-error">
    <span class="one-icon-alert-rounded"></span
    ><span class="alert-text">Zone was not found with the coordinates given.</span>
  </div>
  <form class="content-form" id="myForm" [formGroup]="vanForm" (ngSubmit)="submit()">
    <div class="content-in-form">
      <div class="section-content content-left">
        <div class="field-content">
          <span class="require-message">*Required fields</span>
        </div>
        <div class="field-content">
          <one-input formControlName="parent_id" appFormErrors type="text" [label]="labelsName.parent_id"></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="name" appFormErrors type="text" [label]="labelsName.name"></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="city" appFormErrors type="text" [label]="labelsName.city"></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="address" appFormErrors type="text" [label]="labelsName.address"></one-input>
        </div>
        <div class="field-content">
          <one-input
            formControlName="saturation"
            appFormErrors
            type="number"
            [label]="labelsName.saturation"
          ></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="code" appFormErrors type="number" [label]="labelsName.code"></one-input>
        </div>

        <div class="content-location">
          <div class="field-content">
            <one-input
              formControlName="location_lat"
              appFormErrors
              type="number"
              [label]="labelsName.location_lat"
              [customFormatErrors]="[errorZoneId]"
              [customFormatFunction]="errorMessageBind"
            ></one-input>
          </div>
          <div class="field-content">
            <one-input
              formControlName="location_lng"
              appFormErrors
              type="number"
              [label]="labelsName.location_lng"
              [customFormatErrors]="[errorZoneId]"
              [customFormatFunction]="errorMessageBind"
            ></one-input>
          </div>
        </div>

        <div class="field-content">
          <one-input formControlName="zone_id" appFormErrors type="number" [label]="labelsName.zone_id" [readonly]="true"></one-input>
        </div>
        <div class="field-content">
          <one-input
            formControlName="manager_id"
            appFormErrors
            [label]="labelsName.manager_id"
          ></one-input>
        </div>
        <div class="field-content">
          <one-input
            formControlName="user_dispatcher_id"
            appFormErrors
            type="number"
            [label]="labelsName.user_dispatcher_id"
          ></one-input>
        </div>
        <div class="field-content">
          <one-input
            formControlName="user_dispatcher_address_id"
            appFormErrors
            type="number"
            [label]="labelsName.user_dispatcher_address_id"
          ></one-input>
        </div>
        <div class="field-content">
          <one-input
            formControlName="time_between_orders"
            appFormErrors
            type="number"
            [label]="labelsName.time_between_orders"
            [customFormatErrors]="['min']"
            [customFormatFunction]="errorMessageBind"
          ></one-input>
        </div>

        <div class="content-valley-hours">
          <div class="title-valley-hours">Valley Hours</div>
          <ng-container formArrayName="off_peak_hours">
            <div
              *ngFor="let offPeakHour of offPeakHours.controls; let i = index"
              [formGroup]="offPeakHour"
              class="group-range-time"
            >
              <div class="content-range-time">
                <one-input-time formControlName="init_time"></one-input-time>
                <span>to</span>
                <one-input-time formControlName="end_time"></one-input-time>
                <i (click)="deleteOffPeakHour(i)" aria-hidden="true" class="pointer one-icon-trash"></i>
              </div>
              <span [class.hidden-error]="!offPeakHour.hasError('errorRange')" class="error-range-time">
                Invalid. You can not use an end hour lower than the start hour.
              </span>
            </div>
          </ng-container>
          <div (click)="addOffPeakHour()" [class.disabled]="offPeakHours.length >= 2" class="btn-create-hour-valley">
            <span class="content-icon one-icon-add-circle-outline-v2"></span>
            <span>Add Valley Hour</span>
          </div>
        </div>

        <div class="section-toggle">
          <input formControlName="status" type="checkbox" class="rp-switch" />
          <span>Active</span>
        </div>
      </div>
      <section class="section-content section-map">
        <google-map
          width="100%"
          height="100%"
          [center]="markerPosition || centerLocation"
          (mapClick)="addMarker($event)"
        >
          <map-marker *ngIf="markerPosition" [position]="markerPosition"> </map-marker>
        </google-map>
      </section>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="content-buttons">
    <button class="rp-button rp-outlined rp-primary rp-small" mat-dialog-close>Cancel</button>
    <button
      [disabled]="loadZoneId || loading || vanForm.invalid"
      form="myForm"
      type="submit"
      class="rp-button rp-filled rp-primary rp-small"
    >
      {{ buttonText }}
    </button>
  </div>
</div>
