import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateCoreRoutingModule } from './create-core-routing.module';
import { CreateCoreComponent } from './main/create-core.component';
import { CardModule } from '@rappi/ui/card';
import { HasPermissionModule } from '../shared/directives/has-permissions/has-permissions.module';

@NgModule({
  declarations: [CreateCoreComponent],
  imports: [
    CommonModule,
    CreateCoreRoutingModule,
    CardModule,
    HasPermissionModule
  ]
})
export class CreateCoreModule { }
