<section>
  <video loop [autoplay]="true" [muted]="true" playsinline>
    <source [src]="backgroundImage" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <img
    *ngIf="floatingImage; else template"
    class="floating"
    loading="lazy"
    appParallaxContent
    [direction]="floatingImageDirectionParalax"
    [src]="floatingImage"
    alt="Decorative image"
  />
  <ng-template #template>
    <ng-content select="[floating-image]"></ng-content>
  </ng-template>
  <div>
    <img class="watermark" loading="lazy" *ngIf="watermark" [src]="watermark" alt="watermark">
    <h1 class="rp-f-heading">{{ title | translate }}</h1>
    <p class="rp-f-text" [textContent]="description | translate"></p>
    <a (click)="openVideo(videoUrl)">{{ 'landing.watchVideo' | translate }} ></a>
    <button class="rp-button rp-filled rp-primary rp-large" [routerLink]="FULL_PATHS.signUp">
      {{ 'landing.tryItForFree' | translate }}
    </button>
  </div>
</section>
