import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslatePipe } from '@ngx-translate/core';
import { DataVideoModal } from '../constants';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoModalComponent implements OnInit {
  safeSrc: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _dataModal: DataVideoModal,
    private readonly _sanitizer: DomSanitizer,
    private readonly _translate: TranslatePipe
  ) { }

  ngOnInit() {
    this.initUrl(this._dataModal);
  }

  initUrl(dataUrl: DataVideoModal) {
    const urlValue = dataUrl.urlVideo || this._translate.transform(dataUrl.urlTranslate);
    this.safeSrc = this.sanitizerUrl(urlValue);
  }

  sanitizerUrl(url: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
