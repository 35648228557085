<div class="platform-outage-container">
  <one-card class="platform-outage-card">
    <div>
      <img
        loading="lazy"
        class="logo"
        src="/assets/images/landing/brand.svg"
        alt="brand"
        [routerLink]="FULL_PATHS.home"
      />
      <h1>We are under maintenance</h1>

      <p class="countries-outage-text">Countries out of service</p>

      <div class="countries-list" *ngFor="let country of countriesOutage">
        <img [src]="country.code | getImgCountry" [alt]="'flag of ' + country.name" />
        <p>{{ country.name }}</p>
      </div>

      <p class="card-info">Sorry for the hassle this may cause, we’re working hard to improve your experience.</p>

      <button
        *ngIf="isAdmin || !isAllCountriesOutage; else notAdminMessage"
        class="btn primary"
        [routerLink]="[DEFAULT_PATH]"
      >
        Continue
      </button>

      <ng-template #notAdminMessage>
        <p>We will restore access as soon as possible.</p>
      </ng-template>
    </div>
    <img
      loading="lazy"
      class="platform-outage-image"
      src="assets/images/landing/platform-outage/platform-outage-image.jpg"
      alt="platform-outage"
    />
  </one-card>
</div>
