<div mat-dialog-title>
  <h2>Important</h2>
  <button class="close-btn one-icon-times" mat-dialog-close aria-label="Close modal"></button>
</div>

<article mat-dialog-content>
  <img [src]="ALERT_IMAGE" alt="decorative image">
  <h1>{{data.title}}</h1>
  <p>{{data.message}}</p>
</article>

<div mat-dialog-actions>
  <button class="rp-button rp-filled rp-raisable brands-primary-button" mat-dialog-close>
    Continue
  </button>
</div>
