import { Card } from '../modules/licenses/components/card/definitions/card.models';

interface FormattedCard {
  [k: string]: Partial<Card>;
}

export const FORMATTED_CARD: FormattedCard = {
  available_spots: {
    icon: 'one-icon-star-regular',
    iconColor: '#0CC665',
    iconBgColor: '#c1fdd2',
    label: 'Available Licenses'
  },
  total_spots: {
    icon: 'one-icon-star-solid',
    iconColor: '#4589FF',
    iconBgColor: '#d1eeff',
    label: 'Total Licenses'
  },
  used_spots: {
    icon: 'one-icon-star-half-alt',
    iconColor: '#A56EFF',
    iconBgColor: '#f0e2ff',
    label: 'Licenses in Use'
  }
};
