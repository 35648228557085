import { Directive, HostListener, Input } from '@angular/core';

import { EventsService } from '../../services/events/events.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trackClick]'
})
export class TrackClickDirective {
  @Input() category: string;
  @Input() action: string;
  @Input() label: string;

  constructor(private readonly _eventsService: EventsService) {}

  @HostListener('click') onClick() {
    this._eventsService.send(this.category, this.action, this.label);
  }
}
