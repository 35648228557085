import { environment } from '../../../environments/environment';

const {
  create, amplify, samplingExt, deeplinksMfe, ads, audiencesMfe, requestHubMfe, campaignManager
} = environment.modules;

export const PermissionsCreate = {
  amplify: `${create}/${amplify}`,
  deeplinks: `${create}/${deeplinksMfe}`,
  ads: `${create}/${ads}`,
  sampling: `${create}/${samplingExt}`,
  audiences: `${create}/${audiencesMfe}`,
  tradeMarketing: `${create}/trade`,
  requestHub: `${create}/${requestHubMfe}`,
  campaignManager: `${create}/${campaignManager}`
};

export const CreateManage = [
  {
    title: 'Amplify',
    description: 'Create and track your Media campaigns',
    background: '#f0e2ff',
    image: '../../../assets/images/create/amplify.png',
    url: `/${create}/${amplify}`,
    module: PermissionsCreate.amplify,
    locked: false
  },
  {
    title: 'Deeplinks',
    description: 'Create and manage your Rappi links',
    background: '#f6e1f6',
    image: '../../../assets/images/create/deeplinks.png',
    url: `/${create}/${deeplinksMfe}/manager`,
    module: PermissionsCreate.deeplinks,
    locked: false
  },
  {
    title: 'Ads',
    description: 'Create, manage and track your Facebook campaigns',
    background: '#feecde',
    image: '../../../assets/images/create/ads.png',
    url: `/${create}/${ads}/campaigns`,
    module: PermissionsCreate.ads,
    locked: false
  },
  {
    title: 'Sampling',
    description: 'Create, track and manage your samples',
    background: '#d7f0fe',
    image: '../../../assets/images/create/sampling.png',
    url: `/${create}/${samplingExt}`,
    module: PermissionsCreate.sampling,
    locked: false
  },
  {
    title: 'Trade Marketing',
    description: 'Create, track and manage your samples',
    background: '#e8fbe7',
    image: '../../../assets/images/create/trade.png',
    url: `/${create}`,
    module: 'create/reporting',
    locked: true
  },
  {
    title: 'Audiences',
    description: 'Reach audiences based on your goals',
    background: '#fff3dc',
    image: '../../../assets/images/create/audiences/audiences.png',
    url: `/${create}/${audiencesMfe}/list`,
    module: PermissionsCreate.audiences+'/list',
    locked: false
  },
  {
    title: 'Campaign Manager',
    description: 'Grow brand exposure with dynamic Ad campaigns',
    background: '#E5DCFF',
    image: '../../../assets/images/create/campaign-manager.png',
    url: `/${PermissionsCreate.campaignManager}`,
    module: PermissionsCreate.campaignManager,
    locked: false
  },
  {
    title: 'Performance Predictor',
    description: 'Estimate execution rate before your pitch!',
    background: '#FFF0F7',
    image: '../../../assets/images/create/performance-predictor.png',
    url: `/create/performance-predictor`,
    module: `create/performance-predictor`,
    locked: false
  }
];
