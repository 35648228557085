import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TiersRoutingModule } from './tiers.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { CardModule } from '@rappi/ui/card';
import { DynamicTableModule } from '@rappi/ui/dynamic-table';
import { InputModule } from '@rappi/ui/input';
import { SelectModule } from '@rappi/ui/select';
import { TreeModule } from '@rappi/ui/tree';
import { ActionsModule } from '../../components/actions/actions.module';
import { FormGroupModule } from '../../components/form-group/form-group.module';
import { ViewFormFieldModule } from '../../components/view-form-field/view-form-field.module';

import { TableComponent } from './components/table/table.component';
import { ManagerComponent } from './components/manager/manager.component';
import { ViewComponent } from './components/view/view.component';

@NgModule({
  declarations: [TableComponent, ManagerComponent, ViewComponent],
  imports: [
    CommonModule,
    TiersRoutingModule,
    ReactiveFormsModule,
    CardModule,
    DynamicTableModule,
    InputModule,
    SelectModule,
    TreeModule,
    ActionsModule,
    FormGroupModule,
    ViewFormFieldModule
  ]
})
export class TiersModule {}
