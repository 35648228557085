import { Injectable } from '@angular/core';
import { StoreAuxService } from '../storeAux/store-aux.service';
import { Observable } from 'rxjs';
import { FileUploadResponse } from './definitions/models/file-upload.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileManagementService {
  constructor(private readonly _storeAuxService: StoreAuxService, private readonly _http: HttpClient) {}

  uploadFile(file: File): Observable<string> {
    const input = new FormData();
    input.append('file', file);
    input.append('folder', 'MasterData');

    return this.upload(input).pipe(map(({ url }: FileUploadResponse) => url));
  }

  upload(data: FormData): Observable<FileUploadResponse> {
    return this._http.post<FileUploadResponse>(`${this.getCountryUrl()}/upload`, data);
  }

  getCountryUrl(url = environment.urlFileManagement): string {
    return this._storeAuxService.getCountry(url);
  }
}
