import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SamplingSettingsRoutingModule } from './sampling-settings-routing.module';
import { SettingListComponent } from './setting-list/setting-list.component';
import { NotDataDisplayModule } from '../../core/not-data-display/not-data-display.module';
import { BreadcrumbsModule } from '../../core/breadcrumbs/breadcrumbs.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [SettingListComponent],
  imports: [
    CommonModule,
    SamplingSettingsRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatCardModule,
    BreadcrumbsModule,
    NotDataDisplayModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class SamplingSettingsModule {}
