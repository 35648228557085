import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SenseComponent } from './sense/sense.component';
import { SenseMfeRoutingModule } from './sense-mfe-routing.module';

@NgModule({
  declarations: [SenseComponent],
  imports: [CommonModule, SenseMfeRoutingModule]
})
export class SenseMfeModule {}
