import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { McBreadcrumbsService, IBreadcrumb } from 'ngx-breadcrumbs';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() showBreadcrumbs = true;
  @Input() titlecaseOn = true;
  @Input() lastbreadcrumb: string;
  currentModule: string;
  breadcrumbList: string[] = [];

  destroySubject$: Subject<void> = new Subject();

  constructor(private readonly _bcService: McBreadcrumbsService) {}

  ngOnInit() {
    this._bcService.crumbs$
      .pipe(
        takeUntil(this.destroySubject$),
        filter((route: IBreadcrumb[]) => Boolean(route.length))
      )
      .subscribe((route: IBreadcrumb[]) => {
        this.currentModule = route[route.length - 1].text;
        if (route.length > 1) {
          this.breadcrumbList = route.map((el: IBreadcrumb) => el.text);
        }
        if (this.lastbreadcrumb) {
          this.breadcrumbList.push(this.lastbreadcrumb);
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
