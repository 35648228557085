import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { resetStores } from '@datorama/akita';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _storageService: StorageService
    ) {}

  ngOnInit() {
    resetStores();
    this.resetUserSession();
  }

  resetUserSession() {
    this._authService
      .logout()
      .pipe(
        finalize(() => {
          this._storageService.clearStorageSession();
          this._router.navigate(['/']);
        })
      )
      .subscribe(
        () => {},
        (err: HttpErrorResponse) => console.error(`Error on logout ${err.error.message || err.message}`)
      );
  }
}
