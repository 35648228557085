import { Directive, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData, DefaultDialogTextToShow, DialogTextToShow } from './common/confirm-dialog';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ConfirmDialog<T> implements OnInit {
  confirmationForm: FormGroup;
  APPROVED = 'APPROVED';

  defaultTextToShow: DialogTextToShow = {
    titleWithTextBox: DefaultDialogTextToShow.titleWithTextBox,
    titleWithoutTextBox: DefaultDialogTextToShow.titleWithoutTextBox,
    textBoxPlaceholder: DefaultDialogTextToShow.textBoxPlaceholder,
    content: DefaultDialogTextToShow.content
  };

  constructor(
    protected readonly formBuilder: FormBuilder,
    protected readonly dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  ngOnInit() {
    this.buildForm();
    if (!this.data.textToShow) {
      this.data.textToShow = this.defaultTextToShow;
    }
  }

  buildForm() {
    this.confirmationForm = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  get description(): AbstractControl {
    return this.confirmationForm.get('description');
  }

  shouldTriggerClose() {
    if (!(this.confirmationForm.invalid && this.data.showTextbox)) {
      this.dialogRef.close(this.description.value || this.APPROVED);
    }
  }
}
