<app-actions [id]="id" [type]="'Licenses'" [action]="action" [service]="deleteLicense"></app-actions>
<form [formGroup]="form" (submit)="onSubmit(form)">
  <app-form-group title="General Information" icon="one-icon-user-tooltip">
    <div>
      <one-input label="License ID" [value]="id" disabled="true"></one-input>
    </div>
    <div>
      <one-input label="Email Address" [disabled]="id" formControlName="email" appFormErrors></one-input>
    </div>
  </app-form-group>

  <div class="form-actions">
    <button class="rp-button rp-filled rp-raisable" (click)="goTo(viewUrl || tableUrl)" type="button">Cancel</button>
    <button class="rp-button rp-filled rp-raisable rp-success" [disabled]="form.invalid">
      {{ action | titlecase }} License
    </button>
  </div>
</form>
