import { Injectable } from '@angular/core';
import { MenuBlock, MenuItem } from './menu.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class MenuService {
  private readonly COLLAPSED_MENU: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get collapsed(): Observable<boolean> {
    return this.COLLAPSED_MENU.asObservable();
  }

  toggleMenu(status: boolean) {
    this.COLLAPSED_MENU.next(status);
  }

  getItems(all: MenuBlock[], assigned: { slug: string }[]): MenuBlock[] {
    return assigned ? this._filterItems(all, assigned) : all;
  }

  private _filterItems(all: MenuBlock[], assigned: { slug: string }[]): MenuBlock[] {
    return all.map((item: MenuBlock) => {
      const filteredModules = item.modules.map((module: MenuItem) => {
        let filteredChildren: Partial<MenuItem>[];
        if (module.children) {
          filteredChildren = module.children.map((child: Partial<MenuItem>) => ({
            ...child,
            active: assigned.some(({ slug }: { slug: string }) => slug === `${module.url}/${child.url}`)
          }));
        }

        return {
          ...module,
          children: filteredChildren,
          active: assigned.some(({ slug }: { slug: string }) => slug === module.url)
        };
      });

      return { ...item, modules: filteredModules, active: Boolean(filteredModules.length) };
    });
  }
}
