import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-view-form-field',
  templateUrl: './view-form-field.component.html',
  styleUrls: ['./view-form-field.component.scss']
})
export class ViewFormFieldComponent implements AfterViewInit {
  @Input() label: string;
  @Input() value: string;
  @Input() disabled: boolean;

  @ViewChild('content') content: ElementRef;
  showContent = false;

  constructor(private readonly _cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.showContent = !!this.content?.nativeElement?.innerHTML;

    this._cd.detectChanges();
  }
}
