import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getImgCountry'
})
export class GetImgCountryPipe implements PipeTransform {

  transform(value: string): string {
    return value && value !== 'DEV'
      ? `assets/images/landing/${value.toLowerCase()}.svg`
      : 'assets/images/dev.svg';
  }

}
