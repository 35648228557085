import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertsService } from '../alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Injectable({
  providedIn: 'root'
})
export class Error401InterceptorService implements HttpInterceptor {
  constructor(private readonly _alertsService: AlertsService, private readonly _router: Router) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 401) {
          this._alertsService.openAlerts(
            errorResponse.error.message || 'Your session has expired. Please log in.',
            AlertsType.error
          );
          if (localStorage.getItem('token')) {
            this._router.navigate(['/session']);
          }
        }
        return throwError(errorResponse);
      })
    );
  }
}
