import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ControlPanelAlert } from '../../../../models/user/user.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  readonly ALERT_IMAGE = '/assets/images/control-panel/alert-bg.png';

  constructor(@Inject(MAT_DIALOG_DATA) public data: ControlPanelAlert) {}
}
