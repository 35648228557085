import { Component, OnDestroy, ViewChild } from '@angular/core';

import {
  DynamicTableCellType,
  DynamicTableComponent,
  DynamicTableData,
  DynamicTableRow,
  LazyTableDataSource
} from '@rappi/ui/dynamic-table';
import { GenericDict } from '@rappi/common';

import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';

import { UsersService } from '../../../../../services/users/users.service';
import { AdminService } from '../../../../services/admin.service';
import { Admin } from '../../../../definitions/admin.models';
import { AlertsService } from '../../../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { HttpErrorResponse } from '@angular/common/http';
import { BAD_REQUEST } from 'http-status-codes';
import { Paginate, Permission } from '../../../../definitions/permissions.models';
import { TableMenuTriggerComponent } from '../../../../components/table-menu-trigger/table-menu-trigger.component';

import { map, skip, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnDestroy {
  @ViewChild('table') table: DynamicTableComponent;

  readonly PAGE_SIZE = 5;
  readonly PAGE_LIMIT = 1;

  readonly TABLE_DATA: DynamicTableData = {
    title: { value: 'ADMINS MANAGEMENT' },
    dataSource: new LazyTableDataSource<DynamicTableRow>(
      this.getAdmins.bind(this),
      this.PAGE_SIZE,
      '',
      this.PAGE_LIMIT
    ),
    lazyLoaded: true,
    pagination: true,
    paginatorOpts: { pageSize: this.PAGE_SIZE },
    columns: ['email', 'actions'],
    cellsConfig: {
      email: { type: DynamicTableCellType.text },
      actions: {
        type: DynamicTableCellType.customComponent,
        properties: {
          component: TableMenuTriggerComponent,
          inputs: ['id', 'type', 'service', 'sendEmail'],
          outputs: []
        },
        hideTitle: true
      }
    }
  };

  destroySubject$ = new Subject<void>();

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _adminService: AdminService,
    private readonly _usersService: UsersService,
    private readonly _alertsService: AlertsService
  ) {
    this._store.pipe(select(StoreName.country), skip(1), takeUntil(this.destroySubject$)).subscribe(() => {
      this.table.resetTable();
    });
  }

  getAdmins(page = this.PAGE_LIMIT, size = this.PAGE_SIZE, name: string): Observable<GenericDict[]> {
    return this._adminService.getAdmins(page, size, name).pipe(
      map(({ data: { records, total_record } }: Permission<Paginate<Admin>>): GenericDict[] => {
        const offset = this.TABLE_DATA.dataSource.data.length;

        if (!offset && total_record && this.TABLE_DATA.paginator) {
          this.TABLE_DATA.paginator.length = total_record;
        }

        return (records || []).map(({ email, id }: Admin) => ({
          email: { value: email },
          actions: {
            inputs: {
              id,
              type: 'Admin',
              service: (i: number) => this._adminService.deleteAdmin(i).pipe(tap(() => this.table.resetTable())),
              sendEmail: () => {
                this._usersService.sendEmail(email).subscribe(
                  ({ message }: { message: string }) => {
                    this._alertsService.openAlerts(message, AlertsType.success);
                  },
                  ({ error: { message }, status }: HttpErrorResponse) => {
                    this._alertsService.openAlerts(
                      status === BAD_REQUEST ? 'This email has already been verified' : message,
                      AlertsType.error
                    );
                  }
                );
              }
            }
          }
        }));
      })
    );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
