import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment.local';
import { AmplifyComponent } from './amplify/amplify.component';

const { create, amplify } = environment.modules;

const BASE_MODULE = `${create}/${amplify}`;

const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: BASE_MODULE
    },
    children: [
      {
        path: '',
        component: AmplifyComponent,
        data: {
          isMfe: true,
          module: BASE_MODULE,
          submodule: BASE_MODULE,
          hideBreadcrumbs: true,
          reuse: true
        }
      },
      {
        path: 'adset',
        component: AmplifyComponent,
        data: {
          isMfe: true,
          module: BASE_MODULE,
          submodule: 'adset',
          hideBreadcrumbs: true,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: AmplifyComponent,
            data: {
              isMfe: true,
              module: BASE_MODULE,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'creation',
        component: AmplifyComponent,
        data: {
          isMfe: true,
          module: BASE_MODULE,
          submodule: `creation`,
          hideBreadcrumbs: true,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: AmplifyComponent,
            data: {
              isMfe: true,
              module: BASE_MODULE,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'campaign/:id',
        component: AmplifyComponent,
        data: {
          isMfe: true,
          module: BASE_MODULE,
          hideBreadcrumbs: true,
          reuse: true
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmplifyMfeRoutingModule {}
