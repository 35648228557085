import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdsMfeRoutingModule } from './ads-mfe-routing.module';
import { AdsComponent } from './ads/ads.component';


@NgModule({
  declarations: [AdsComponent],
  imports: [
    CommonModule,
    AdsMfeRoutingModule
  ]
})
export class AdsMfeModule { }
