import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MenuMobileTypes } from '../../../../shared/enums';
import { AppState } from '../../../../store/states/app.state';
import { StoreName } from '../../../../store/definitions/store.constants';
import { CountryState } from '../../../../store/states/country.state';
import { MenuContentMobileService } from '../../services/menu-content-mobile/menu-content-mobile.service';
import * as countryActions from '../../../../store/actions/country.action';
import { StorageService } from '../../../../services/storage/storage.service';

@Component({
  selector: 'app-user-selector-mobile',
  templateUrl: './user-selector-mobile.component.html',
  styleUrls: ['./user-selector-mobile.component.scss']
})
export class UserSelectorMobileComponent implements OnInit, OnDestroy {
  readonly MenuMobileTypes = MenuMobileTypes;

  countryDefault: string;
  menuType: Observable<MenuMobileTypes> = this.menuContentService.typeObserver;
  subscription = new Subscription();

  constructor(
    private readonly _store: Store<AppState>,
    public readonly menuContentService: MenuContentMobileService,
    readonly _storageService: StorageService
  ) {}

  ngOnInit() {
    this.getCurrentCountry();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCurrentCountry() {
    this.subscription.add(
      this._store.pipe(select(StoreName.country)).subscribe((state: CountryState) => {
        this.countryDefault = state.countrySelected || this._storageService.getCountry().code;

        if (!state.countrySelected) {
          this._store.dispatch(countryActions.set({ payload: this.countryDefault }));
        }
      })
    );
  }

  toggleMenu() {
    this.menuContentService.changeMenuType(MenuMobileTypes.MENU_USER);
  }
}
