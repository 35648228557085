import { Routes } from '@angular/router';

import { WarehouseComponent } from './warehouse/warehouse.component';
import { VanListComponent } from './van-list/van-list.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';

export const MainManagerRoutes: Routes = [
  {
    path: '',
    component: WarehouseComponent,
    data: {
      title: 'Warehouses'
    }
  },
  {
    path: ':id/vans',
    component: VanListComponent,
    data: {
      breadcrumbs: 'Vans',
      title: 'Vans'
    }
  },
  {
    path: ':id/:name/inventory',
    component: InventoryListComponent,
    data: {
      breadcrumbs: 'Inventory',
      title: 'Inventory'
    }
  }
];

