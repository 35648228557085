import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { BasicAnalyticsComponent } from './basic-analytics/basic-analytics.component';
import { environment } from '../../environments/environment';

export const routes: Routes = [
  {
    path: '',
    component: BasicAnalyticsComponent,
    data: {
      isMfe: true,
      module: environment.modules.basicAnalysis,
      hideBreadcrumbs: true,
      hideModuleTitle: true,
      reuse: true
    },
    children: [
      {
        path: '**',
        component: BasicAnalyticsComponent,
        data: {
          isMfe: true,
          module: environment.modules.basicAnalysis,
          hideBreadcrumbs: true,
          hideModuleTitle: true,
          reuse: true
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BasicAnalyticsMfeRoutingModule { }
