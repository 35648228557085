import { Component, Input } from '@angular/core';
import { FULL_PATHS } from '../../definitions/landing.constant';

@Component({
  selector: 'app-start-using-banner',
  templateUrl: './start-using-banner.component.html',
  styleUrls: ['./start-using-banner.component.scss']
})
export class StartUsingBannerComponent {
  @Input() title: string;
  @Input() description: string;

  readonly FULL_PATHS = FULL_PATHS;

  srcLightiningImg = 'assets/images/landing/landing-lightning.png';
}
