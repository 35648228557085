import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../services/storage/storage.service';
import { STORAGE_KEY } from '../../../../definitions/app.constants';
import { MenuContentMobileService } from '../../services/menu-content-mobile/menu-content-mobile.service';
import { MenuMobileTypes } from '../../../../shared/enums';
import { MenuItem } from '../../../menu/menu.model';
import { environment } from '../../../../../environments/environment';
import { Country } from '../../../../models/shared/shared.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/states/app.state';
import { StoreName } from '../../../../store/definitions/store.constants';
import { CountryState } from '../../../../store/states/country.state';
import { COUNTRY_NAME } from '../../../../definitions/countries.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-options-user-menu',
  templateUrl: './options-user-menu.component.html',
  styleUrls: ['./options-user-menu.component.scss']
})
export class OptionsUserMenuComponent implements OnInit, OnDestroy {
  menuOptions: MenuItem[] = [
    {
      name: 'menu.account',
      url: environment.modules.account,
      icon: 'one-icon-wheel',
      active: true
    },
    {
      name: 'menu.help',
      url: environment.urlProductReport,
      openMenu: true,
      icon: 'one-icon-question-circle',
      active: true
    },
    {
      name: 'menu.session',
      url: 'session',
      icon: 'one-icon-exit',
      active: true
    }
  ];

  fullName = '';
  countryDefault: Country = {} as Country;
  assignedCountries: Country[] = [];

  destroySubject$ = new Subject();

  constructor(
    private readonly _router: Router,
    private readonly _storageService: StorageService,
    public readonly menuContentService: MenuContentMobileService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit() {
    this.fullName = localStorage.getItem(STORAGE_KEY.fullName);
    this.loadCountry();
  }

  loadCountry() {
    this.assignedCountries = this._storageService.getCountries();

    this._store
      .pipe(select(StoreName.country), takeUntil(this.destroySubject$))
      .subscribe(({ countrySelected }: CountryState) => {
        this.countryDefault = { name: COUNTRY_NAME[countrySelected], code: countrySelected };
      });
  }

  toggleMenuCountry() {
    if (this.assignedCountries.length > 1) {
      this.menuContentService.changeMenuType(MenuMobileTypes.MENU_COUNTRY);
    }
  }

  redirect(item: MenuItem) {
    this.menuContentService.changeMenuType(null);
    if (item.openMenu) {
      window.open(item.url, '_blank');
    } else {
      this._router.navigate([`/${item.url}`]);
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
