import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {

  maxLengthAllowed = 200;

  transform(target: string, maxLength = this.maxLengthAllowed) {
    const htmlStripped = target.replace(/(<([^>]+)>)/gi, '');
    if (htmlStripped.length > maxLength) {
      return `${htmlStripped.slice(0, maxLength)}...`;
    }
    return htmlStripped;
  }
}
