<div class="content-header">
  <span class="title-header">{{ data?.title }}</span>
  <i class="one-icon-{{ data?.icon }}" aria-hidden="true"></i>
</div>
<p class="content-description">{{ data?.description }}</p>
<span
  (click)="action.emit(data?.title)"
  class="content-action"
  trackClick
  [category]="CATEGORIES.learningHub"
  [action]="ACTIONS.clickGoLearningHub"
>
  {{ data?.action }}
</span>
