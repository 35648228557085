import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appReducer } from './reducers/app.reducer';

import { environment } from '../../environments/environment';
@NgModule({
  imports: [
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25, name: '[Brands By Rappi] Store DevTools' })
  ]
})
export class RootStoreModule {}
