import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Warehouse } from '../../shared/interfaces';
import { finalize } from 'rxjs/operators';
import { MainManagerService } from '../../services/main-manager.service';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Component({
  selector: 'app-delete-warehouse',
  templateUrl: './delete-warehouse.component.html',
  styleUrls: ['./delete-warehouse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteWarehouseComponent {
  readonly typesTitle = {
    MAIN: 'warehouse',
    VAN: 'van'
  };

  loading = false;
  controlDelete = new FormControl(null, [this.differentToDeleteValidator]);

  constructor(
    private readonly _alertsService: AlertsService,
    private readonly _dialogRef: MatDialogRef<DeleteWarehouseComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: Warehouse,
    private readonly _mainManagerService: MainManagerService
  ) {}

  close(type = false) {
    this._dialogRef.close(type);
  }

  differentToDeleteValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (String(control.value).toUpperCase() !== 'DELETE') {
      return { differentToDelete: true };
    }
    return null;
  }

  confirm() {
    if (this.controlDelete.valid) {
      this.loading = true;

      this._mainManagerService
        .deleteWarehouse(this.dataModal.id)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this._alertsService.openAlerts(
            `You have successfully deleted the ${this.typesTitle[this.dataModal.type]} ${this.dataModal.name}.`,
            AlertsType.success
          );
          this.close(true);
        });
    } else {
      this.controlDelete.markAllAsTouched();
      this.controlDelete.updateValueAndValidity();
    }
  }
}
