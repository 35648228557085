<div class="header-faq">
  <h3 class="secondary-title">{{ 'landing.faq.name' | translate }}</h3>
  <h1 class="principal-title">{{ 'landing.faq.title' | translate }}</h1>
</div>
<div class="content-faq">
  <mat-tab-group>
    <mat-tab [label]="'landing.faq.service.name' | translate">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let data of services; index as i"
          (closed)="indexOpenService = null"
          (afterExpand)="indexOpenService = i"
          hideToggle
        >
          <mat-expansion-panel-header>
            <mat-panel-title [class.active-panel]="i === indexOpenService">
              {{ 'landing.faq.service.question_' + (i + 1) + '.question' | translate }}
            </mat-panel-title>
            <mat-panel-description>
              <i
                aria-hidden="true"
                class="one-icon-general-{{ i === indexOpenService ? 'remove' : 'add' }}-circle-outline"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p [innerHTML]="'landing.faq.service.question_'+(i+1)+'.response' | translate"></p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>
    <mat-tab [label]="'landing.faq.platform.name' | translate">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let data of platforms; index as i"
          (closed)="indexOpenPlatform = null"
          (afterExpand)="indexOpenPlatform = i"
          hideToggle
        >
          <mat-expansion-panel-header>
            <mat-panel-title [class.active-panel]="i === indexOpenPlatform">
              {{ 'landing.faq.platform.question_' + (i + 1) + '.question' | translate }}
            </mat-panel-title>
            <mat-panel-description>
              <i
                aria-hidden="true"
                class="one-icon-general-{{ i === indexOpenPlatform ? 'remove' : 'add' }}-circle-outline"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p [innerHTML]="'landing.faq.platform.question_'+(i+1)+'.response' | translate"></p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="footer-faq">
  <h2 class="footer-title-faq">{{ 'landing.faq.footer.title' | translate }}</h2>
  <p class="footer-description-faq" [innerHTML]="'landing.faq.footer.description' | translate"></p>
</div>
