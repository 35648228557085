import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatMenuTrigger } from '@angular/material/menu';
import { StorageService } from '../../../../services/storage/storage.service';

import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../store/states/app.state';
import * as countryActions from '../../../../store/actions/country.action';
import { COUNTRY_NAME } from '../../../../definitions/countries.constants';
import { Country } from '../../../../models/shared/shared.model';
import { UserType } from '../../../../permissions/definitions/permissions.enums';
import { CountryState } from '../../../../store/states/country.state';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountrySelectorComponent implements OnDestroy {
  @ViewChild('menuAction') menuAction: MatMenuTrigger;

  countries: Country[] = [];
  countrySelected: Country = {} as Country;

  destroySubject$ = new Subject();

  constructor(private readonly _store: Store<AppState>, private readonly _storageService: StorageService) {
    this.loadData();
  }

  loadData() {
    this.countries = this._storageService.getCountries();
    this.countries.sort((a, b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    this._store.pipe(takeUntil(this.destroySubject$)).subscribe((store: AppState) => {
      if (store.user.user_type === UserType.admin) {
        this.countries.forEach((country: Country) => (country.platform_outage = false));
      }

      this.setCountrySelected(store.country);

      if (!store.country.countrySelected) {
        this.selected(this.countrySelected);
      }
    });
  }

  selected(newCountry: Country) {
    this._storageService.setStorageCountry(newCountry.code);
    this._store.dispatch(countryActions.set({ payload: newCountry.code }));
  }

  toggleMenu() {
    if (this.countries.length > 1 && this.menuAction.menuOpen) {
      this.menuAction.openMenu();
    } else {
      this.menuAction.closeMenu();
    }
  }

  setCountrySelected(country: CountryState): void {
    if (country.countrySelected && this.hasCountryAvailable(country.countrySelected)) {
      this.countrySelected = {
        name: COUNTRY_NAME[country.countrySelected],
        code: country.countrySelected
      };
    } else {
      const defaultCountry = this._storageService.getCountry();
      this.countrySelected = this.hasCountryAvailable(defaultCountry.code)
        ? defaultCountry
        : this.countries.find((elem) => !elem.platform_outage);
    }
  }

  hasCountryAvailable(code: string): boolean {
    return this.countries.some((country) => country.code === code && !country.platform_outage);
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
