import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfirmUserRequest } from '../../core/landing/definitions/landing.constant';
import { LoginResponseAuth, User } from '../../models/user/user.model';

import { environment } from '../../../environments/environment';

import { StoreAuxService } from '../storeAux/store-aux.service';

@Injectable()
export class UsersService {
  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getProfile(): Observable<{ data: User }> {
    return this._http.get<{ data: User }>(`${this._getCountryUrl()}/me`, this._getHeaders());
  }

  updateUser(user: User): Observable<{ message: string }> {
    return this._http.put<{ message: string }>(`${this._getCountryUrl()}/me`, user, this._getHeaders());
  }

  changePassword(password: string, currentPassword?: string): Observable<{ message: string }> {
    return this._http.put<{ message: string }>(
      `${this._getCountryUrl()}/me/password`,
      { password, current_password: currentPassword },
      this._getHeaders()
    );
  }

  confirmUser(token: string, request: ConfirmUserRequest): Observable<LoginResponseAuth> {
    const country = environment.production ? 'CO' : 'DEV';
    return this._http.post<LoginResponseAuth>(`${this._getCountryUrl(country)}/${token}/confirm`, request);
  }

  sendEmail(email: string, emailType = 'confirm_user'): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getCountryUrl()}/send/email`, { email, email_type: emailType });
  }

  private _getCountryUrl(country?: string): string {
    return `${this._storeAuxService.getCountry(environment.urlAuth, country)}/users`;
  }

  private _getHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      })
    };
  }
}
