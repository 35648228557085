import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { PathsSampling } from './shared/constants';

const routes: Routes = [
  {
    path: PathsSampling.SETTINGS,
    loadChildren: () => import('./sampling-settings/sampling-settings.module').then((m) => m.SamplingSettingsModule),
    data: {
      module: environment.modules.samplingCoreSettings,
      breadcrumbs: 'Settings'
    }
  },
  {
    path: PathsSampling.WAREHOUSES,
    loadChildren: () => import('./main-manager/main-manager.module').then((m) => m.MainManagerModule),
    data: {
      module: environment.modules.samplingCoreWarehouses,
      breadcrumbs: 'Warehouses',
      breadcrumbsLink: true
    }
  },
  {
    path: PathsSampling.PRICING,
    loadChildren: () => import('./pricing/pricing.module').then((m) => m.PricingModule),
    data: {
      module: environment.modules.samplingCorePricing,
      breadcrumbs: 'Pricing'
    }
  },
  {
    path: PathsSampling.MANAGEMENT,
    loadChildren: () => import('./mtz-sampling-mgmt-mfe/mtz-sampling-mgmt-mfe.module').then((m) => m.MtzSamplingMgmtMfeModule),
    data: {
      module: environment.modules.samplingCoreManagement
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SamplingCoreRoutingModule {}
