import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ParallaxContentModule } from '../../../../shared/directives/parallax-content/parallax-content.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeroSectionComponent } from './hero-section.component';

@NgModule({
  declarations: [HeroSectionComponent],
  imports: [CommonModule, ParallaxContentModule, RouterModule, TranslateModule],
  exports: [HeroSectionComponent]
})
export class HeroSectionModule {}
