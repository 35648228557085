import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogComponent } from './catalog/catalog.component';
import { CatalogMfeRoutingModule } from './catalog-mfe-routing.module';



@NgModule({
  declarations: [CatalogComponent],
  imports: [
    CommonModule,
    CatalogMfeRoutingModule
  ]
})
export class CatalogMfeModule { }
