<one-card class="create-content">
  <div class="c-header d-flex">
    <div class="c-title">How can we help you today?</div>
    <div class="c-description">Select a product to manage</div>
  </div>
  <div class="cards-content">
    <ng-container *ngFor="let item of createManage">
      <ng-container
        *hasPermission="item.module" 
        [ngTemplateOutlet]="cardTemplate" 
        [ngTemplateOutletContext]="{ data: item }"
      >
      </ng-container>
    </ng-container>
  </div>
</one-card>

<ng-template let-data="data" #cardTemplate>
  <div class="create-card" [routerLink]="data.url" [style.background]="data.background" [ngClass]="data.locked ? 'no-drop' : 'pointer'">
    <img class="card-image" [src]="data.image" height="82px" alt="amplify">
    <div class="d-flex c-card-header">
      <div class="c-card-title">{{data.title}}</div>
      <div class="c-card-description">{{data.description}}</div>
    </div>
  </div>
</ng-template>