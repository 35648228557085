import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainManagerService } from '../../services/main-manager.service';
import { CampaignInventory } from '../../../shared/sampling.models';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Component({
  selector: 'app-edit-units-inventory',
  templateUrl: './edit-units-inventory.component.html',
  styleUrls: ['./edit-units-inventory.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditUnitsInventoryComponent {
  loading = false;
  formUnits = this._fb.group({
    units: [null, [Validators.required, Validators.min(0), Validators.max(this.dataModal.current_quantity - 1)]],
    reason: [null, Validators.required]
  });
  readonly campaignVarietyList = [{
    name: 'Product devolution',
    id: 'Product devolution'
  }, {
    name: 'Product expired',
    id: 'Product expired'
  },  {
    name: 'Internal error',
    id: 'Internal error'
  }];

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _alertsService: AlertsService,
    private readonly _dialogRef: MatDialogRef<EditUnitsInventoryComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: CampaignInventory,
    private readonly _mainService: MainManagerService
  ) {}

  close(type = false) {
    this._dialogRef.close(type);
  }

  confirm() {
    if (this.formUnits.valid) {
      this.loading = true;

      this._mainService
        .editUnitsInventiory(
          this.dataModal.current_quantity - this.formUnits.value.units,
          this.formUnits.value.reason,
          this.dataModal.slot,
          this.dataModal.source_code
        )
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this._alertsService.openAlerts(
            `The new number of units for the campaign
              ${this.dataModal.campaign_name} is
              ${this.formUnits.value.units}`,
            AlertsType.success
          );
          this.close(true);
        });
    }
  }

  getErrorMessage(): string {
    if ((this.formUnits.controls.units as FormControl).hasError('max')) {
      return 'Note: You can only reduce units, go to Product Reception to add them.';
    } else if ((this.formUnits.controls.units as FormControl).hasError('min')) {
      return 'Incorrect Value';
    } else {
      return 'Fill in the blanks';
    }
  }
}
