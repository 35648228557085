import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { Entity, Paginate, Permission } from '../definitions/permissions.models';
import { FilterParams, FilterRequest, FiltersDependency } from '../definitions/filters.models';
import { SearchCriteria } from '../definitions/permissions.enums';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  readonly BASE_PATH = 'filters/';

  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getEntities(body: FilterRequest, paramsObj: FilterParams): Observable<Permission<Paginate<Entity>>> {
    const params = new HttpParams({ fromObject: Object.assign({ ...paramsObj }) });

    return this._http.post<Permission<Paginate<Entity>>>(this.getCountryUrl(), body, { params });
  }

  getStores(body: FilterRequest, paramsObj: FilterParams): Observable<Permission<Paginate<Entity>>> {
    const params = new HttpParams({ fromObject: Object.assign({ ...paramsObj }) });

    return this._http.post<Permission<Paginate<Entity>>>(this.getCountryUrl()+'stores', body, { params });
  }

  getCountryUrl(): string {
    return `${this._storeAuxService.getCountry(environment.urlRevenue)}/${this.BASE_PATH}`;
  }

  getBody(module: string, entity: string, dependency?: FiltersDependency[]): FilterRequest {
    return { module, entity, dependency };
  }

  getParams(page: number, limit: number, search: string, criteria = SearchCriteria.name): FilterParams {
    return { page, limit, search_criteria: criteria, search_value: search || '' };
  }
}
