import { ChangeDetectorRef, Component } from '@angular/core';

import { MfeSourceLoaderService } from '../../mfe/services';
import { TranslateApplicationService } from '../../services/translate/translate-application.service';
import { Store } from '@ngrx/store';
import { EncryptService } from '../../services/encrypt/encrypt.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MicroFrontendWrapperComponent } from '../../mfe/models/abstracts';

import { AppState } from '../../store/states/app.state';
import { environment } from '../../../environments/environment';
import { MfeAttribute, MfeEvent, MfeFileSource } from '@rappi/rappi-mfe-tools/lib';

@Component({
  selector: 'app-catalog',
  templateUrl: '../../mfe/models/abstracts/MicroFrontendWrapper.html',
  styleUrls: ['../../mfe/models/abstracts/MicroFrontendWrapper.scss']
})
export class CatalogComponent extends MicroFrontendWrapperComponent {
  protected elName: string;
  protected mfeSources: MfeFileSource[];
  protected mfeAttributes: MfeAttribute[];
  protected mfeEvents: MfeEvent[];

  constructor(
    public readonly sourceLoader: MfeSourceLoaderService,
    public readonly cd: ChangeDetectorRef,
    public readonly encryptService: EncryptService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly store: Store<AppState>,
    public readonly translateApplicationService: TranslateApplicationService
  ) {
    super(sourceLoader, cd, encryptService, router, activatedRoute, store, translateApplicationService);
    this.elName = 'catalog-client-mfe';
    this.url = environment.urlCatalogClientMfeSourceRoot;
  }
}
