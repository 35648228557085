import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/states/app.state';
import * as countryActions from '../../../../store/actions/country.action';
import { Country } from '../../../../models/shared/shared.model';

import { StorageService } from '../../../../services/storage/storage.service';
import { MenuContentMobileService } from '../../services/menu-content-mobile/menu-content-mobile.service';

@Component({
  selector: 'app-options-country-menu',
  templateUrl: './options-country-menu.component.html',
  styleUrls: ['./options-country-menu.component.scss']
})
export class OptionsCountryMenuComponent implements OnInit {
  listCountries: Country[] = [];

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _menuContentService: MenuContentMobileService,
    private readonly _storageService: StorageService
  ) {}

  ngOnInit() {
    this.loadCountry();
  }

  loadCountry() {
    this.listCountries = this._storageService.getCountries();
  }

  selected(newCountry: Country) {
    this._store.dispatch(countryActions.set({ payload: newCountry.code }));
    this._menuContentService.changeMenuType(null);
  }
}
