import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SamplingCoreRoutingModule } from './sampling-core.routing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorResponseInterceptor } from './interceptor/http-error.interceptor';
import { SamplingOrdersService } from './services/sampling-oders.service';
import { MissionsManagementService } from './services/missions-management.service';
import { MainManagerService } from './main-manager/services/main-manager.service';
import { interceptorProviders } from '../services/interceptors/interceptorProviders';
import { FileManagementService } from '../services/file-upload/file-management.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, SamplingCoreRoutingModule, HttpClientModule],
  providers: [
    SamplingOrdersService,
    MissionsManagementService,
    MainManagerService,
    FileManagementService,
    ...interceptorProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true
    }
  ]
})
export class SamplingCoreModule {}
