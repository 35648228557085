import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { PageableRequest, PaginatedResponse } from '../../shared/interfaces';
import {
  OrderRt,
  BlackListRt,
  BlackListUpload,
  ValleyHour,
  ValleyHourDTO,
  OrderOperator,
  ValidationResponse,
} from '../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SamplingOrdersService {
  private readonly PATH_MODULE = environment.urlSamplingBase + '/orders';

  constructor(private readonly _storeAux: StoreAuxService, private readonly _httpClient: HttpClient) { }

  getOrdersOperation(): Observable<OrderOperator[]> {
    return this._httpClient.get<OrderOperator[]>(this._storeAux.getCountry(this.PATH_MODULE + '/actives'));
  }

  getCodesValidation(samplingId: string): Observable<string[]> {
    return this._httpClient.get<string[]>(this._storeAux.getCountry(this.PATH_MODULE + `/${samplingId}/codes`));
  }

  validateCode(samplingId: string, code: string): Observable<ValidationResponse> {
    return this._httpClient.post<ValidationResponse>(
      this._storeAux.getCountry(this.PATH_MODULE + `/${samplingId}/validate-code`),
      { code }
    );
  }

  confirmDelivery(samplingId: string): Observable<void> {
    return this._httpClient.post<void>(
      this._storeAux.getCountry(this.PATH_MODULE + `/product-delivered/${samplingId}`),
      {}
    );
  }

  getValleyHours(): Observable<ValleyHour[]> {
    return this._httpClient.get<ValleyHour[]>(this._storeAux.getCountry(this.PATH_MODULE + '/off-peak-hours'));
  }

  addValleyHours(valleyHour: ValleyHourDTO): Observable<ValleyHour> {
    return this._httpClient.post<ValleyHour>(
      this._storeAux.getCountry(this.PATH_MODULE + '/off-peak-hours'),
      valleyHour
    );
  }

  deleteValleyHours(valleHourId: string): Observable<ValleyHour> {
    return this._httpClient.delete<ValleyHour>(
      this._storeAux.getCountry(this.PATH_MODULE + `/off-peak-hours/${valleHourId}`)
    );
  }

  uploadBlackListFile(file: File): Observable<BlackListUpload> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this._httpClient.post<BlackListUpload>(
      this._storeAux.getCountry(`${environment.urlSamplingBase}/fraud/black-list/load`),
      formData
    );
  }

  getOrderById(orderId: string): Observable<OrderRt> {
    return this._httpClient.get<OrderRt>(this._storeAux.getCountry(`${this.PATH_MODULE}/${orderId}/order`));
  }

  getPaginateBlackList(pageable: PageableRequest<BlackListRt> = null): Observable<PaginatedResponse<BlackListRt>> {
    const filterStorekeeper = pageable?.filter?.storekeeper_id
      ? {
        storekeeperId: String(pageable.filter.storekeeper_id)
      }
      : {};
    const params = new HttpParams({
      fromObject: {
        page: (pageable && String(pageable.page)) || '0',
        size: (pageable && String(pageable.size)) || '10',
        ...filterStorekeeper
      }
    });

    return this._httpClient.get<PaginatedResponse<BlackListRt>>(
      this._storeAux.getCountry(`${environment.urlSamplingBase}/fraud/black-list`),
      {
        params
      }
    );
  }

  deleteRtBlackList(rtIds: number[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        storekeeper_ids: rtIds
      }
    };

    return this._httpClient.delete<void>(
      this._storeAux.getCountry(`${environment.urlSamplingBase}/fraud/black-list`),
      options
    );
  }

  releaseOrder(orderId: string): Observable<ValidationResponse> {
    return this._httpClient.put<ValidationResponse>(
      this._storeAux.getCountry(`${this.PATH_MODULE}/${orderId}/release`),
      {}
    );
  }
}
