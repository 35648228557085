export enum PermissionAction {
  create = 'create',
  view = 'view',
  edit = 'edit'
}

export enum UserType {
  account = 'account',
  admin = 'admin',
  license = 'license'
}

export enum SearchCriteria {
  name = 'name',
  email = 'email',
  role = 'role'
}

export enum AccountStatus {
  active = 'active',
  per_expire = 'per_expire',
  per_active = 'per_active',
  expired = 'expired'
}

export enum AccountStatusColor {
  per_expire = '#f27d31',
  expired = 'red'
}

export enum AccountStatusMessage {
  per_expire = 'This PO is about to expire',
  expired = 'This PO is expired'
}

export enum PermissionsType {
  accounts = 'Accounts',
  admins = 'Admins',
  tiers = 'Tiers',
  licenses = 'Licenses',
  roles = 'Roles'
}
