import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { environment } from '../../environments/environment';
import { CatalogComponent } from './catalog/catalog.component';

export const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: environment.modules.catalog,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: 'products',
        component: CatalogComponent,
        data: {
          isMfe: true,
          module: environment.modules.catalog,
          hideBreadcrumbs: true,
          submodule: 'products',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: CatalogComponent,
            data: {
              isMfe: true,
              module: environment.modules.catalog,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: '',
        redirectTo: 'products',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogMfeRoutingModule {}
