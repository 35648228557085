<ol class="breadcrumbs">
  <li class="breadcrumbs-title">{{ titlecaseOn ? (currentModule | titlecase) : currentModule }}</li>
  <ng-container *ngIf="showBreadcrumbs">
    <li
      class="breadcrumbs-item"
      [ngClass]="{ 'one-icon-after-caret-up': !last }"
      *ngFor="let breadcrumbItem of breadcrumbList; let last = last"
    >
      {{ titlecaseOn ? (breadcrumbItem | titlecase) : breadcrumbItem }}
    </li>
  </ng-container>
</ol>
