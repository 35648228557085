import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { DeleteBoxMessage } from './delete-box.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-delete-box',
  templateUrl: './delete-box.component.html',
  styleUrls: ['./delete-box.component.scss']
})
export class DeleteBoxComponent {
  @Input() displayLabel = true;
  @Input() subject = new Subject<DeleteBoxMessage>();
  @Input() key: number | string;

  deleteClicked() {
    const message: DeleteBoxMessage = {
      data: {
        key: this.key
      }
    };

    this.subject.next(message);
  }
}
