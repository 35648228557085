import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Warehouse,
  ReceptionOrder,
  CreateWarehouseRequest,
  MissionCustomization,
  DeleteWarehouseDTO,
  Zone
} from '../shared/interfaces';
import { InventoryResponse } from '../../shared/sampling.models';
import { ReceptionOrderDto } from '../../../models/reception-order-dto/reception-order.dto';
import { StoreAuxService } from '../../../services/storeAux/store-aux.service';
import { environment } from '../../../../environments/environment';
import { StatusCheck } from '../shared/enums/statusCheck.enum';

@Injectable({
  providedIn: 'root'
})
export class MainManagerService {
  private readonly PATH_MODULE = environment.urlMissionsInventoryBase;

  constructor(
    private readonly _storeAux: StoreAuxService,
    private readonly _http: HttpClient
  ) {}

  getWarehouseMain(): Observable<Warehouse[]> {
    return this._http.get<Warehouse[]>(this.getCountryUrl('warehouses/main'));
  }

  getWarehouseVan(parentId: number | string, status?: StatusCheck): Observable<Warehouse[]> {
    const params = new HttpParams({
      fromObject: status ? { status } : {}
    });
    return this._http.get<Warehouse[]>(this.getCountryUrl(`warehouses/van/${parentId}/parent`), { params });
  }

  getWarehouseInventory(id: number): Observable<InventoryResponse> {
    return this._http.get<InventoryResponse>(this.getCountryUrl(`home/${id}`));
  }

  getReceptionOrdersPending(managerId: number): Observable<ReceptionOrder[]> {
    return this._http.get<ReceptionOrder[]>(this.getCountryUrl(`reception-orders/${managerId}/manager`));
  }

  createMainWarehouse(data: CreateWarehouseRequest): Observable<Warehouse> {
    return this._http.post<Warehouse>(this.getCountryUrl('warehouses'), data);
  }

  updateMainWarehouse(data: CreateWarehouseRequest, warehouseId: number): Observable<Warehouse> {
    return this._http.put<Warehouse>(this.getCountryUrl('warehouses/' + warehouseId), data);
  }

  createReceptionsOrder(receptionOrder: ReceptionOrderDto): Observable<number> {
    return this._http.post<number>(this.getCountryUrl('reception-orders'), receptionOrder);
  }

  editReceptionsOrder(receptionOrder: ReceptionOrderDto, orderId: number): Observable<ReceptionOrderDto> {
    return this._http.put<ReceptionOrderDto>(this.getCountryUrl('reception-orders/' + orderId), receptionOrder);
  }

  getCustomizationSlot(slot: string): Observable<string> {
    return this._http.get<string>(this.getCountryUrl(`customizations/${slot}/quantity`));
  }

  setCustomizationSlot(data: MissionCustomization): Observable<string> {
    return this._http.post<string>(this.getCountryUrl('customizations/create-or-update'), data);
  }

  confirmReceived(inventoryId: number): Observable<void> {
    return this._http.post<void>(this.getCountryUrl(`transactions/confirm/${inventoryId}`), {});
  }

  confirmReturn(quantity: number, inventoyId: number, warehouseId: number): Observable<void> {
    return this._http.post<void>(this.getCountryUrl('transactions'), {
      inventory_id: inventoyId,
      quantity,
      warehouse_id: warehouseId
    });
  }

  rejectReceived(inventoryId: number): Observable<void> {
    return this._http.post<void>(this.getCountryUrl(`transactions/reject/${inventoryId}`), {});
  }

  deleteWarehouse(wareHouseId: number): Observable<DeleteWarehouseDTO> {
    return this._http.delete<DeleteWarehouseDTO>(this.getCountryUrl(`warehouses/${wareHouseId}`));
  }

  editUnitsInventiory(newValue: number, reason: string, campaignCode: string, warehouseId: string) {
    return this._http.post<void>(this.getCountryUrl('reduce-inventory'), {
      campaign_code: campaignCode,
      reason,
      value: newValue,
      warehouse_id: Number(warehouseId)
    });
  }

  createTransaction(warehouseId: number, inventoryId: number, quantity: number, targetId: number) {
    return this._http.post<void>(this.getCountryUrl(`transactions/${warehouseId}`), {
      inventory_id: inventoryId,
      quantity,
      warehouse_id: targetId
    });
  }

  confirmMainTransaction(inventoryId: number, warehouseId: number): Observable<void> {
    return this._http.post<void>(
      this.getCountryUrl(`transactions/confirm/${inventoryId}/wh-target/${warehouseId}`),
      {}
    );
  }

  rejectMainTransaction(inventoryId: number, warehouseId: number): Observable<void> {
    return this._http.post<void>(this.getCountryUrl(`transactions/reject/${inventoryId}/wh-target/${warehouseId}`), {});
  }

  receiveProduct(campaingCode: number, quantity: number, mainId: number) {
    return this._http.post<void>(this.getCountryUrl('transactions/receive'), {
      campaign_code: campaingCode,
      quantity,
      main_id: mainId
    });
  }

  getVan(code: number): Observable<Warehouse> {
    const params = new HttpParams({
      fromObject: {
        bringOffPeakHours: 'true'
      }
    });
    return this._http.get<Warehouse>(this.getCountryUrl(`warehouses/van/${code}/code`), { params });
  }

  getZoneId(lat: number, lng: number): Observable<{zones: Zone[]}> {
    const params = new HttpParams({
      fromObject: {
        lat: JSON.stringify(lat),
        lng: JSON.stringify(lng)
      }
    });
    return this._http.get<{zones: Zone[]}>(this.getCountryUrl(`warehouses/ops-zones/location`), { params });
  }


  private getCountryUrl(path: string): string {
    return this._storeAux.getCountry(this.PATH_MODULE + path);
  }
}
