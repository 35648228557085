<div (click)="openNotification.emit()" [matMenuTriggerFor]="settingsMenu" class="content-notification">
  <i class="one-icon-{{ iconName }}" aria-hidden="true"></i>
  <span *ngIf="numberNotifications" class="number-notifications">{{ numberNotifications }}</span>
</div>

<mat-menu
  xPosition="before"
  (closed)="closedNotification.emit()"
  backdropClass="notification-menu"
  class="menu-content-notifications"
  #settingsMenu
>
  <span *ngIf="titleNotification" class="title-notification">{{ titleNotification }}</span>
  <div class="menu-item">
    <ng-content></ng-content>
  </div>
</mat-menu>
