import { Component, Input } from '@angular/core';

import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { ButonSlideTextProperties } from '../definitions/button-slide-text.model';

@Component({
  selector: 'app-button-slide-text',
  templateUrl: './button-slide-text.component.html',
  styleUrls: ['./button-slide-text.component.scss']
})
export class ButtonSlideTextComponent {
  @Input() set properties(p: ButonSlideTextProperties) {
    this._properties = p;
    this.textLength = this._sanitizer.bypassSecurityTrustStyle(`${p.text.length * 0.7}rem`);
  }
  get properties() {
    return this._properties;
  }

  textLength: SafeStyle = '4.2rem';

  private _properties: ButonSlideTextProperties = {
    text: 'Create',
    icon: 'add',
    color: '#f9fbfd',
    background: '#2ec4b6'
  };

  constructor(private readonly _sanitizer: DomSanitizer) {}
}
