import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Observable } from 'rxjs';

import { Permission } from '../definitions/permissions.models';
import { PermissionComponent } from '../definitions/component.models';

import { environment } from '../../../environments/environment';

@Injectable()
export class ComponentService {
  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getComponents(): Observable<Permission<PermissionComponent[]>> {
    return this._http.get<Permission<PermissionComponent[]>>(`${this._getCountryUrl()}/components/`);
  }

  private _getCountryUrl(): string {
    return this._storeAuxService.getCountry(environment.urlRevenue);
  }
}
