<one-card class="no-padding">
  <div class="content-add-head">
    <div class="add-content">
      <button (click)="openAssistantPrice()" class="rp-button rp-filled rp-primary rp-small">
        <span class="content-icon one-icon-add"></span>
        Add new price
      </button>
    </div>
  </div>

  <div class="header-content">
    <div class="header-title">
      <span class="title"> PRICING LIST </span>
      <div>
        <one-input
          (input)="setFilters($event.target.value, formFilters.value.cities)"
          [value]="formFilters.value.impacts"
          autocomplete="off"
          label="Search"
          type="search"
          class="search"
        >
        </one-input>
      </div>
    </div>
    <div class="content-filter">
      <div class="filter-selects">
        <one-select
          #selectType
          label="Product type"
          [config]="filterSelectConfig"
          [dataSource]="productTypeList"
          (selectionChanged)="formFilters.patchValue({ product_type: $event.id })"
          size="small"
          [multiple]="false"
        ></one-select>
        <one-select
          #selectCities
          label="City"
          size="small"
          multiple="true"
          [config]="filterSelectConfig"
          [dataSource]="citiesDataSource"
          (selectionChanged)="setFilters(formFilters.value.impacts, $event.map(mapCities))"
        ></one-select>
      </div>
      <div class="filter-buttons">
        <button class="rp-button rp-small rp-outlined" (click)="resetFilters()">Reset</button>
        <button class="rp-button rp-small rp-filled rp-dark" (click)="applyFilters()">Apply filters</button>
      </div>
    </div>
  </div>

  <one-dynamic-table #myTable [tableData]="dataTable" *ngIf="dataTable"></one-dynamic-table>
</one-card>

<ng-template #actionRef let-element="element">
  <div class="content-actions">
    <button
      mat-icon-button
      [disableRipple]="true"
      [matMenuTriggerData]="{ element: element }"
      [matMenuTriggerFor]="appMenu"
    >
      <span class="one-icon-more-vert-v2"></span>
    </button>
  </div>
</ng-template>

<mat-menu #appMenu="matMenu" class="menu-pricing">
  <ng-template matMenuContent let-element="element">
    <div class="content-pricing-actions">
      <span class="pointer icon-menu one-icon-edit-material-v2" (click)="openAssistantPrice(element)"> Edit </span>
    </div>
    <div class="content-pricing-actions">
      <span class="pointer icon-menu one-icon-trash-v2" (click)="deletePrice(element)"> Delete </span>
    </div>
  </ng-template>
</mat-menu>
