import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StandardResponse } from '../../shared/interfaces';
import { DataNotificationLearning, DataNotificationUser } from '../../core/notifications-header/interfaces';
import { StoreAuxService } from '../storeAux/store-aux.service';
import { EncryptService } from '../encrypt/encrypt.service';
import { STORAGE_KEY } from '../../definitions/app.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private readonly NOTIFICATIONS_LEARNING: DataNotificationLearning[] = [
    {
      title: 'Platform Guide',
      icon: 'filled-favorite',
      description: 'Quick video tutorials, tips, and everything you need to know about the platform is here.',
      action: 'Start learning',
      status: false
    }
  ];

  get notificationsLearning(): DataNotificationLearning[] {
    let learningItems = localStorage.getItem(STORAGE_KEY.learningNotifications);

    if (!learningItems) {
      localStorage.setItem(
        STORAGE_KEY.learningNotifications,
        this._encryptService.encryptObject(this.NOTIFICATIONS_LEARNING, STORAGE_KEY.learningNotifications)
      );
      learningItems = localStorage.getItem(STORAGE_KEY.learningNotifications);
    }
    return this._encryptService.decryptObject(learningItems, STORAGE_KEY.learningNotifications);
  }

  constructor(
    private readonly _http: HttpClient,
    private readonly _storeAuxService: StoreAuxService,
    private readonly _encryptService: EncryptService
  ) {}

  getNotifications(): Observable<DataNotificationUser[]> {
    return this._http
      .get<StandardResponse<DataNotificationUser[]>>(`${this._getCountryUrl()}/me?limit=100`)
      .pipe(map((res: StandardResponse<DataNotificationUser[]>) => res.data));
  }

  setViewNotification(id: number): Observable<void> {
    return this._http.patch<void>(`${this._getCountryUrl()}/me/${id}`, {});
  }

  getNotificationsLearning(): Observable<DataNotificationLearning[]> {
    return of(this.notificationsLearning);
  }

  setViewsAllLearning(): Observable<void> {
    let notifications = this.notificationsLearning;
    notifications = notifications.map((el) => {
      el.status = true;
      return el;
    });
    localStorage.setItem(
      STORAGE_KEY.learningNotifications,
      this._encryptService.encryptObject(notifications, STORAGE_KEY.learningNotifications)
    );
    return of(null);
  }

  sendEmail(to: string[], type: string, templateData?: unknown): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getCountryUrl()}/email`, { to, type, template_data: templateData });
  }

  getFirebaseToken(): Observable<{ data: string }> {
    return this._http.post<{ data: string }>(`${this._getCountryUrl()}/firebase/token`, {});
  }

  private _getCountryUrl(): string {
    return `${this._storeAuxService.getCountry(environment.urlNotifications)}`;
  }
}
