import { Component, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { VideoModalComponent } from '../../../video-modal/video-modal/video-modal.component';
import { DataVideoModal } from '../../../video-modal/constants';
import { DirectionParallax } from '../../../../shared/directives/parallax-content/parallax-content.directive';

import { FULL_PATHS } from '../../definitions/landing.constant';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent {
  @Input() watermark: string;
  @Input() title: string;
  @Input() description: string;
  @Input() videoUrl: string;

  @Input() backgroundImage: string;
  @Input() floatingImage: string;
  @Input() floatingImageDirectionParalax: DirectionParallax = DirectionParallax.INITIAL_DOWN;

  readonly FULL_PATHS = FULL_PATHS;

  constructor(private readonly _dialog: MatDialog) {}

  openVideo(videoUrl: string) {
    this._dialog.open<VideoModalComponent, DataVideoModal>(VideoModalComponent, {
      width: '70%',
      height: '60%',
      panelClass: 'app-video-modal',
      autoFocus: false,
      closeOnNavigation: false,
      data: {
        urlTranslate: videoUrl
      }
    });
  }
}
