import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { DynamicTableCellType, DynamicTableData, DynamicTableRow, LazyTableDataSource, TableCellAlign } from '@rappi/ui/dynamic-table';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { CellConfig, DataTableCell } from '../../../shared/interfaces';
import { StoreName } from '../../../store/definitions/store.constants';
import { AppState } from '../../../store/states/app.state';
import { CountryState } from '../../../store/states/country.state';
import { PermissionsSamplingCore } from '../../shared/constants';
import { CreateWarehouseComponent } from '../dialogs/create-warehouse/create-warehouse.component';
import { DeleteWarehouseComponent } from '../dialogs/delete-warehouse/delete-warehouse.component';
import { MainManagerService } from '../services/main-manager.service';
import { WarehouseManagerService } from '../services/warehouse.manager.service';
import { StatusCheck } from '../shared/enums/statusCheck.enum';
import { ColumnsWarehouseTable, Warehouse } from '../shared/interfaces';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WarehouseComponent implements OnDestroy, OnInit {
  @ViewChild('statusRef', { static: true }) statusTemplateRef: TemplateRef<ElementRef>;
  @ViewChild('actionRef', { static: true }) actionTemplateRef: TemplateRef<ElementRef>;

  readonly PermissionsSamplingCore = PermissionsSamplingCore;
  readonly status = StatusCheck;
  unsubscribe$: Subject<void> = new Subject();
  currentWarehouse: Warehouse;
  tableDataSource: DynamicTableData;

  private readonly COLUMNS_NAME: ColumnsWarehouseTable = {
    reference_code: 'Id',
    name: 'Name',
    address: 'Address',
    status: 'Status',
    inventory: 'Inventory',
    actions: ' '
  };

  constructor(
    private readonly _warehouseManager: WarehouseManagerService,
    private readonly _dialog: MatDialog,
    private readonly _mainManagerService: MainManagerService,
    private readonly _store: Store<AppState>
  ) {
    this.getCountry();
  }

  ngOnInit() {
    this.tableDataSource = this.initTableConfig();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initTableConfig(): DynamicTableData {
    const pageSize = 5;
    const dataSource = new MatTableDataSource<DynamicTableRow>(
      this.setData([], this.COLUMNS_NAME)
    );

    return {
      dataSource,
      columns: Object.keys(this.COLUMNS_NAME),
      pagination: true,
      title: { value: 'LIST OF WAREHOUSES' },
      paginatorOpts: {
        pageSize,
        pageSizeOptions: [5, 15, 20, 50]
      },
      cellsConfig: {
        reference_code: {
          type: DynamicTableCellType.text,
          title: this.COLUMNS_NAME.reference_code
        },
        name: {
          type: DynamicTableCellType.text,
        },
        address: {
          type: DynamicTableCellType.text,
        },
        status: {
          type: DynamicTableCellType.templateRef
        },
        inventory: {
          type: DynamicTableCellType.route
        },
        actions: {
          type: DynamicTableCellType.templateRef,
          title: this.COLUMNS_NAME.actions,
          align: TableCellAlign.RIGHT
        }
      } as CellConfig<ColumnsWarehouseTable>
    };
  }

  setData(data: Warehouse[], columnsKey: ColumnsWarehouseTable): DynamicTableRow[] {
    return data.map(
      (el: Warehouse) =>
      ({
        ...Object.keys(columnsKey).reduce(
          (newObject: Partial<DataTableCell<ColumnsWarehouseTable>>, key: string) => ({
            [key]: {
              value: el[key]
            },
            ...newObject
          }),
          {
            inventory: {
              value: 'View details',
              route: `./${el.id}/${el.name}/inventory`
            },
            status: {
              templateRef: this.statusTemplateRef,
              context: {
                element: el
              }
            },
            actions: {
              templateRef: this.actionTemplateRef,
              context: {
                element: el
              }
            }
          } as Partial<DataTableCell<ColumnsWarehouseTable>>
        )
      } as DynamicTableRow)
    );
  }

  getCountry() {
    this.loadData(
      this._store.pipe(
        takeUntil(this.unsubscribe$),
        select(StoreName.country),
        filter((state: CountryState) => Boolean(state.countrySelected)),
        map(() => true)
      )
    );
  }

  setWarehouse(currentWarehouse: Warehouse) {
    this._warehouseManager.setWarehouse(currentWarehouse);
  }

  assistWarehouse(currentWarehouse?: Warehouse) {
    this.loadData(this._dialog.open(CreateWarehouseComponent, {
      disableClose: true,
      autoFocus: false,
      height: '80vh',
      data: currentWarehouse ?
        {
          warehouse: currentWarehouse
        } : null
    }).afterClosed().pipe(
      filter((res: boolean) => res)
    ));
  }

  deleteWarehouse(warehouse: Warehouse) {
    this.loadData(this._dialog.open(DeleteWarehouseComponent, {
      disableClose: true,
      autoFocus: false,
      width: '480px',
      panelClass: 'fix-modal',
      data: warehouse
    }).afterClosed().pipe(
      filter((res: boolean) => res)
    ));
  }

  loadData(reloadRef: Observable<boolean>) {
    reloadRef.pipe(
      switchMap(() => this._mainManagerService.getWarehouseMain()),
      map((res: Warehouse[]) => this.setData(res, this.COLUMNS_NAME))
    ).subscribe((res: DynamicTableRow[]) => {
      this.tableDataSource.dataSource.data = res;
    });
  }
}
