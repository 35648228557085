import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TradeComponent } from './trade/trade.component';
import { TradeRoutingModule } from './trade-core-routing.module';
import { TradeCampaignPermalinkComponent } from './trade/components/trade-campaign-permalink/trade-campaign-permalink.component';

@NgModule({
  declarations: [TradeComponent, TradeCampaignPermalinkComponent],
  imports: [CommonModule, TradeRoutingModule]
})
export class TradeCoreModule {}
