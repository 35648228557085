import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertsService } from '../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  constructor(private readonly _alert: AlertsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((ex: HttpErrorResponse) => {
        const errorMessage: string = (ex?.status !== 500 && (ex?.error?.error?.message || ex?.error?.error?.msg)) || 'An error has occurred';
        this._alert.openAlerts(errorMessage, AlertsType.error);
        return throwError(ex);
      })
    );
  }
}
