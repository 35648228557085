<app-hero-section
  title="landing.home.firstContainer.title"
  description="landing.home.firstContainer.subtitle"
  videoUrl="landing.home.firstContainer.urlWatchVideo"
  [backgroundImage]="srcCellphoneVideo"
>
  <img
    class="floating-img floating"
    floating-image
    loading="lazy"
    appParallaxContent
    [direction]="DirectionParallax.INITIAL_DOWN"
    [src]="srcOpportunityImg"
    alt="decorative image"
  />
</app-hero-section>

<app-section title="landing.home.steps.title" description="landing.home.secondContainer.message"></app-section>

<app-module-landing [module]="analyzeFeature" [widthAction]="200">
  <img class="floating-img analyze-img" loading="lazy" appParallaxContent [src]="srcAccumulatedMarkerSalesImg" alt="Accumulated Maker Sales" />
  <video class="analyze-video" loop [autoplay]="true" [muted]="true" playsinline>
    <source [src]="srcAccumulatedMarkerSalesVide" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</app-module-landing>

<app-module-landing [module]="senseFeature" reverse="true">
  <img class="floating-img sense-img" loading="lazy" appParallaxContent [src]="srcYourShopperImg" alt="Your shopper" />
  <img class="sense-video" loading="lazy" [src]="srcShopperGraphicImg" alt="Shopper" />
</app-module-landing>

<app-module-landing [module]="createFeature">
  <div class="grid-image">
    <img loading="lazy" [src]="srcGoogleFacebookAdsImg" alt="Ads on Google and Facebook" />
    <img loading="lazy" [src]="srcTradeMarketingImg" alt="Trade Marketing" />
    <img loading="lazy" [src]="srcCampaignManagerImg" alt="Campaign Manager" />
    <img loading="lazy" [src]="srcProductTestImg" alt="Product Test" />
  </div>
</app-module-landing>

<app-section [title]="'landing.home.thirdContainer.title'" [showDescription]="false"></app-section>

<app-brands-example [imageToShow]="srcAnalyzeImg"></app-brands-example>

<app-start-using-banner
  [title]="'landing.home.fourthContainer.title'"
  [description]="'landing.home.fourthContainer.subtitle'"
></app-start-using-banner>
