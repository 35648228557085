import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertsType } from '@rappi/ui/alerts';
import { EventActions, EventCategories, EventLabels } from '../../../../definitions/enums/events.enum';
import { EventsService } from '../../../../services/events/events.service';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { LandingService } from '../../services/landing/landing.service';
import { FULL_PATHS } from '../../definitions/landing.constant';
import { Language } from '../../../../shared/enums';

@Component({
  selector: 'app-promotional-demo',
  templateUrl: './promotional-demo.component.html',
  styleUrls: ['./promotional-demo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionalDemoComponent implements OnInit {
  readonly FULL_PATHS = FULL_PATHS;

  promotionalForm: FormGroup = new FormGroup({
    first_name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    type_of_industry: new FormControl('', Validators.required)
  });
  typeOfIndrustryDataSource = [];

  constructor(
    private readonly _landingService: LandingService,
    public dialogRef: MatDialogRef<PromotionalDemoComponent>,
    private readonly _alertsService: AlertsService,
    private readonly _eventService: EventsService
  ) {}

  ngOnInit() {
    this._landingService
      .getTypeOfIndustry(Language.es)
      .subscribe(
        ({ data }: { data: string[] }) =>
          (this.typeOfIndrustryDataSource = data.map((industry: string) => ({ id: industry, name: industry })))
      );
  }

  setTypeOfIndustry(indrustry: { id: string }) {
    this.promotionalForm.get('type_of_industry').setValue(indrustry.id);
  }

  sendForm() {
    if (this.promotionalForm.valid) {
      this._landingService.sendContactUs(this.promotionalForm.value).subscribe(
        () => {
          this._alertsService.openAlerts('Request sent, thank you for contacting us.', AlertsType.success);
          this._eventService.send(
            EventCategories.landing,
            EventActions.demoRequestFormSubmission,
            EventLabels.successfully
          );
          this.dialogRef.close();
        },
        () => {
          this._alertsService.openAlerts('There was an error trying to submit the form', AlertsType.error);
          this._eventService.send(EventCategories.landing, EventActions.demoRequestFormSubmission, EventLabels.error);
        }
      );
    }
  }
}
