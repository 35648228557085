import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MtzSamplingMgmtMfeComponent } from './mtz-sampling-mgmt-mfe/mtz-sampling-mgmt-mfe.component';

export const routes: Routes = [
  {
    path: '**',
    component: MtzSamplingMgmtMfeComponent,
    data: {
      isMfe: true,
      hideBreadcrumbs: true,
      hideModuleTitle: true,
      module: environment.modules.samplingCoreManagement,
      submodule: environment.modules.samplingCoreManagement,
      reuse: true
    }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MtzSamplingMgmtMfeRoutingModule {}
