import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationButtonComponent {
  @Input() numberNotifications = 0;
  @Input() iconName = 'outline-notification-v2';
  @Input() titleNotification = '';
  @Output() openNotification = new EventEmitter<void>();
  @Output() closedNotification = new EventEmitter<void>();
}
