import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

import { AccountStatusColor } from '../../../../../permissions/definitions/permissions.enums';
import { LicensesService } from '../../../../../permissions/services/licenses.service';
import { License } from '../../../../../permissions/definitions/licenses.models';

@Component({
  selector: 'app-licenses-list-dialog',
  templateUrl: './licenses-list-dialog.component.html',
  styleUrls: ['./licenses-list-dialog.component.scss']
})
export class LicensesListDialogComponent implements OnInit, OnDestroy {
  licenses: License[] = [];
  readonly IDS = new Set<number>();

  readonly ALERT_COLOR = AccountStatusColor.per_expire;

  get ids() {
    return Array.from(this.IDS);
  }

  constructor(
    private readonly _licensesService: LicensesService,
    @Inject(MAT_DIALOG_DATA) public readonly data: { id: number; licensesNumber: number }
  ) {}

  ngOnInit(): void {
    this._licensesService.accountId = this.data.id;

    this._licensesService
      .getLicenses(1, 10000)
      .pipe(map(({ data: { records } }) => records))
      .subscribe((licenses) => (this.licenses = licenses));
  }

  toggleId(id: number): void {
    this.IDS[this.IDS.has(id) ? 'delete' : 'add'](id);
  }

  ngOnDestroy(): void {
    this._licensesService.accountId = null;
  }
}
