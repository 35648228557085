import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { LicensesRoutingModule } from './licenses.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { InputModule } from '@rappi/ui/input';
import { SelectModule } from '@rappi/ui/select';
import { FormGroupModule } from '../../components/form-group/form-group.module';
import { CardModule } from '@rappi/ui/card';
import { DynamicTableModule } from '@rappi/ui/dynamic-table';

import { TableComponent } from './components/table/table.component';
import { ManagerComponent } from './components/manager/manager.component';
import { CardComponent } from './components/card/card.component';
import { ActionsModule } from '../../components/actions/actions.module';
import { ViewComponent } from './components/view/view.component';
import { ViewFormFieldModule } from '../../components/view-form-field/view-form-field.module';

@NgModule({
  declarations: [TableComponent, ManagerComponent, ViewComponent, CardComponent],
  imports: [
    CommonModule,
    LicensesRoutingModule,
    ReactiveFormsModule,
    FormErrorsDirectiveModule,
    InputModule,
    SelectModule,
    FormGroupModule,
    CardModule,
    DynamicTableModule,
    ActionsModule,
    ViewFormFieldModule
  ]
})
export class LicensesModule {}
