import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Country } from '../../models/shared/shared.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  concatUrlPath(pathService: string, codeCountry: string): string {
    return environment.basePaths.find((country: Country) => country.code === codeCountry).url + pathService;
  }
}
