<div class="content-country">
  <i aria-hidden="true" class="one-icon-language"></i>
  <span class="country-info">Select your country</span>
</div>
<div class="content-country" *ngFor="let item of listCountries; let i = index" (click)="selected(item, i)">
  <div class="content-img">
    <img [src]="item.code | getImgCountry" [alt]="'flag of ' + item.name" />
  </div>
  <span class="country-name">{{ item.name }}</span>
</div>
