import { Action, createReducer, on } from '@ngrx/store';

import { initialState, UserState } from '../states/user.state';

import * as actions from '../actions/user.action';

const reducer = createReducer(
  initialState,
  on(actions.set, (state: UserState, { payload }) => ({ ...state, ...payload }))
);

export const userReducer = (state: UserState, action: Action) => reducer(state, action);
