<div [class.border-message]="border">
  <div  *ngIf="!hasContent" class="content-menssage">
    <div class="content-img">
      <img alt="no-image" [src]="iconPath" />
    </div>
    <span class="text-entity">No {{ entity }} listed</span>
    <span class="text-message">Add new {{ entity }}</span>
  </div>
  <div #wrapper>
    <ng-content></ng-content>
  </div>
</div>
