import { Component, Input } from '@angular/core';

import { LandingDescriptionModule } from './models';

@Component({
  selector: 'app-module-landing',
  templateUrl: './module-landing.component.html',
  styleUrls: ['./module-landing.component.scss']
})
export class ModuleLandingComponent {
  @Input() module: LandingDescriptionModule;
  @Input() widthAction = 350;
  @Input() reverse = false;
}
