import { Component, ViewEncapsulation, Input, HostBinding } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { FormElement } from '@rappi/common';

@Component({
  selector: 'app-input-cellphone',
  templateUrl: './input-cellphone.component.html',
  styleUrls: ['./input-cellphone.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputCellphoneComponent extends FormElement<string> {
  @Input() formControl: FormControl;
  @Input() customErrorsMapper: (formControlErrors: ValidationErrors) => string[];

  invalid: boolean;

  @HostBinding('class.has-error') get errorBinding() {
    return this.formControl.touched && this.errors.length;
  }

  public get errors(): string[] {
    const errors: ValidationErrors = this.formControl.errors || {};

    return this.customErrorsMapper ? this.customErrorsMapper(errors) : this._errorsMapper(errors);
  }

  setNumber(e: string) {
    this.formControl.markAsTouched();
    this.formControl.setValue(e);
    this.writeValue(e);
  }

  hasError(valid: boolean) {
    this.invalid = !valid;
  }

  private _errorsMapper(formControlErrors: ValidationErrors): string[] {
    const errors: string[] = Object.keys(formControlErrors);

    const mappedErrors: string[] = errors.map((err: string) => {
      let message: string;

      switch (err) {
        case 'required':
          message = 'This field is required';
          break;
        default:
          message = 'This field is invalid';
          break;
      }

      return message;
    });

    return mappedErrors;
  }
}
