import { createFeatureSelector, createSelector } from '@ngrx/store';

import { STORAGE_KEY } from '../../definitions/app.constants';
import { StoreName } from '../definitions/store.constants';

import { CountryState } from '../states/country.state';

export const getCountry = createFeatureSelector<CountryState>(StoreName.country);

export const getUserAuthIdByCountry = createSelector(
  getCountry,
  ({ countrySelected }: CountryState) => `${localStorage.getItem(STORAGE_KEY.id)}-${countrySelected}`
);
