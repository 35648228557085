export class Constants {
  public static readonly GET_METHOD = 'GET';
  public static readonly POST_METHOD = 'POST';
  public static readonly PUT_METHOD = 'PUT';
  public static readonly PATCH_METHOD = 'PATCH';
  public static readonly DELETE_METHOD = 'DELETE';
  public static readonly CAMPAIGNS_PATH = 'campaignsModulePath';
  public static readonly LANDING_REPORTS_PATH = 'landingReportsPath';
  public static readonly RAPPI_BASE_URL = 'rappiBaseUrl';
  public static readonly DEV = 'DEV';
  public static readonly DEEPLINKS_BASE_PATH = 'deeplinksModulePath';
}

export const IconsPaths = {
  PLUS_ROUND: '/assets/images/plus-round.svg',
  DOWNLOAD: '/assets/images/download.svg',
  EDIT: '/assets/images/edit-pencil.svg',
  VAN: '/assets/images/van.svg',
  ORDER: '/assets/images/order.svg',
  NOT_DATA: '/assets/images/not-data.svg'
};

export const ImagesPaths = {
  emptyChart: 'assets/images/empty_chart.svg'
};

export const ExtDocuments = {
  xls: 'xls',
  xlsx: 'xlsx',
  csv: 'csv',
  pdf: 'pdf'
};

export const DateFormats = {
  DTO_DATE: 'YYYY-MM-DD HH:mm:ss',
  DTO_TIME: 'HH:mm:ss',
  VIEW_DATE: 'dd/MM/yyyy',
  DTO_DATE_MOMENT: 'YYYY-MM-DD',
  VIEW_DATE_TIME: 'dd/MM/yyyy: hh:mm a'
};

export const SessionStorageKeys = {
  attrCompanyName: '_co',
  attrCompanyId: '_co_id',
  attrId: 'id',
  attrEmail: 'email',
  attrFullName: 'full_name',
  attrModulesName: 'module',
  attrModulesData: 'modulesData',
  attrRoleId: '_ri',
  attrRoleName: '_rn',
  attrCountry: '_cntr'
};

export const DateFormat = {
  DTO_DATE: 'YYYY-MM-DD HH:mm:ss'
};

export const Pagination = {
  direction: {
    ASC: 'ASC',
    DESC: 'DESC'
  },
  orderBy: {
    ID: 'id',
    NAME: 'name',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt'
  }
};

export const FORMATS_DATE = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
    shortDate: 'shortDate'
  }
};

export const HeaderWithoutAuthorization = 'without-authorization';

export const MOBILE_WIDTH = 599;

export const SamplingSettings = {
  ON_TOP_TURBO: 'ON_TOP_TURBO',
  ON_TOP_MACROSEGMENTS: 'ON_TOP_MACROSEGMENTS'
};

export const MacroSegments = {
  ALL_ORDERS: 'ALL_ORDERS',
  PRIME_USERS: 'PRIME_USERS',
  BEVERAGES: 'BEVERAGES',
  SNACKS: 'SNACKS',
  EAN: 'EAN'
};

export const LOCATION = {
  DEV: {
    lat: 4.6657016,
    lng: -74.0755449
  },
  CO: {
    lat: 4.6657016,
    lng: -74.0755449
  },
  AR: {
    lat: -34.6131500,
    lng: -58.3772300
  },
  BR: {
    lat: -15.7797203,
    lng: -47.9297218
  },
  CL: {
    lat: -33.4569397,
    lng: -70.6482697
  },
  MX: {
    lat: 19.4284706,
    lng: -99.1276627
  },
  PE: {
    lat: -12.0431805,
    lng: -77.0282364
  },
  UY: {
    lat: -34.9032784,
    lng: -56.1881599
  },
  EC: {
    lat: -0.225219,
    lng:  -78.5248
  }
};

export enum FilterType {
  city = 'city',
  maker = 'maker',
  brand = 'brand',
  trademark = 'trademark',
  store = 'store',
  subcategory = 'subcategory',
  segment = 'segment',
  dataAggregation = 'data_aggregation',
  period = 'period',
  category = 'category'
}
