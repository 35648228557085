<form [formGroup]="form" (ngSubmit)="onSubmit(form)" id="control-panel-form">
  <div class="column">
    <legend>Select Affected Countries</legend>
    <one-select #countrySelect label="Countries" [dataSource]="COUNTRIES" [multiple]="true" [config]="CONFIG" (selectionChanged)="setCountries($event, countrySelect.allSelected, emit)"></one-select>
    <legend>Add message to display</legend>
    <div class="input-wrapper">
      <one-input label="Title" formControlName="title" appFormErrors></one-input>
    </div>
    <div class="input-wrapper">
      <one-input-textarea label="Message max. 200 Characters" formControlName="message" rows="3" appFormErrors></one-input-textarea>
    </div>
  </div>
  <div class="column preview">
    <span class="legend">Preview</span>
    <div class="preview-bg">
      <p class="preview-title">{{ title }}</p>
      <p class="preview-message" [innerHTML]="message"></p>
    </div>
  </div>
</form>
<div class="metadata" *ngIf="updatedAt">
  <i class="one-icon-clock" aria-hidden="true"></i>
  Last modified: <span class="weighted">{{ updatedAt | date: 'mediumDate' }}</span>
  | by: <span class="weighted">{{ modifier || creator }}</span>
</div>
<div class="actions">
  <button class="rp-button rp-filled rp-primary rp-big" [disabled]="form.invalid || isSaved" form="control-panel-form">Save</button>
</div>
