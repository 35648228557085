import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuMobileTypes } from '../../../../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class MenuContentMobileService {
  private readonly MENU_TYPE: BehaviorSubject<MenuMobileTypes> = new BehaviorSubject(null);

  get typeObserver(): Observable<MenuMobileTypes> {
    return this.MENU_TYPE.asObservable();
  }

  changeMenuType(newType: MenuMobileTypes) {
    this.MENU_TYPE.next(this.MENU_TYPE.getValue() === newType ? null : newType);
  }
}
