import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PerformancePredictorMfeComponent } from './performance-predictor-mfe.component';

export const routes: Routes = [
  {
    path: '',
    component: PerformancePredictorMfeComponent,
    data: {
      isMfe: true,
      module: 'create/performance-predictor',
      hideBreadcrumbs: true,
      hideModuleTitle: true,
      reuse: true
    },
    children: [
      {
        path: '**',
        component: PerformancePredictorMfeComponent,
        data: {
          isMfe: true,
          module: 'create/performance-predictor',
          hideBreadcrumbs: true,
          hideModuleTitle: true,
          reuse: true
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PerformancePredictorMfeRoutingModule { }
