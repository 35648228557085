<app-actions [id]="id" type="Tier" [action]="VIEW" [service]="deleteTier"></app-actions>

<ng-container *ngIf="data$ | async as data">
  <app-form-group icon="one-icon-user-tooltip" title="General Information">
    <app-view-form-field label="Tier ID" [value]="id" [disabled]="true"></app-view-form-field>
    <app-view-form-field label="Tier Title" [value]="data.name"></app-view-form-field>
    <app-view-form-field label="Tier Type" [value]="data.type"></app-view-form-field>
    <app-view-form-field label="Description" [value]="data.description" class="span-2"></app-view-form-field>
  </app-form-group>

  <app-form-group icon="one-icon-diagonal-tag" title="Modules and Components">
    <one-tree
      class="span-2"
      [readonly]="true"
      [columns]="2"
      [data]="data.components"
      [initialSelection]="data.components"
      [showCountAllChildsActiveInTree]="true"
    ></one-tree>
  </app-form-group>
</ng-container>
