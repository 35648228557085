const SIMILAR_DATA = [
  { id: 'facebok/instagram', name: 'Facebook/Instagram' },
  { id: 'linkedIn', name: 'LinkedIn' }
];

export const SOURCE_DATA_SOURCE_EN = [
  ...SIMILAR_DATA,
  { id: 'googleSearch', name: 'Google Search' },
  { id: 'referral', name: 'Referral' },
  { id: 'event', name: 'Event' },
  { id: 'other', name: 'Other' }
];

export const SOURCE_DATA_SOURCE_ES = [
  ...SIMILAR_DATA,
  { id: 'googleSearch', name: 'Búsqueda de Google' },
  { id: 'referral', name: 'Referido' },
  { id: 'event', name: 'Evento' },
  { id: 'otro', name: 'Otro' }
];

export const SOURCE_DATA_SOURCE_PT = [
  ...SIMILAR_DATA,
  { id: 'googleSearch', name: 'Pesquisa do Google' },
  { id: 'referral', name: 'Indicação de Conhecido' },
  { id: 'event', name: 'Evento' },
  { id: 'outro', name: 'Outro' }
];

export const RADIO_CARD_DATA_SOURCE_EN = {
  brandCard: {
    title: 'I’m a brand',
    description: 'Our company wanna try Brands by Rappi'
  },
  agenciesCard: {
    title: 'I’m an agency',
    description: 'My agency wants to know more about Rappi’s solutions'
  }
};

export const RADIO_CARD_DATA_SOURCE_ES = {
  brandCard: {
    title: 'Soy una marca',
    description: 'Mi compañía quiere probar Brands by Rappi'
  },
  agenciesCard: {
    title: 'Soy una agencia',
    description: 'Mi agencia quiere conocer las soluciones de Rappi'
  }
};

export const RADIO_CARD_DATA_SOURCE_PT = {
  brandCard: {
    title: 'Eu sou uma marca',
    description: 'Minha empresa quer experimentar Brands by Rappi'
  },
  agenciesCard: {
    title: 'Eu sou uma agência',
    description: 'Minha agência quer conhecer as soluções Rappi'
  }
};

export enum REGISTER_TYPE {
  brands = 'brands',
  agencies = 'agencies'
}

export const ADSMOVIL_URLS: Record<string, string> = {
  DEV: '/assets/files/adsmovil/es.pdf',
  CO: '/assets/files/adsmovil/es.pdf',
  MX: '/assets/files/adsmovil/es.pdf',
  BR: '/assets/files/adsmovil/pt.pdf',
  AR: '/assets/files/adsmovil/es.pdf',
  CL: '/assets/files/adsmovil/es.pdf',
  PE: '/assets/files/adsmovil/es.pdf',
  UY: '/assets/files/adsmovil/es.pdf',
  EC: '/assets/files/adsmovil/es.pdf',
  CR: '/assets/files/adsmovil/es.pdf'
};
