<div class="features-container">
  <h1 class="section-title" [innerHTML]="'landing.features.title' | translate"></h1>

  <div class="section-analyze-content">
    <div class="section-analyze-content-image">
      <img loading="lazy" src="assets/images/landing/products/analyze.png" alt="analyze" />
      <img
        loading="lazy"
        src="assets/images/landing/products/analyze-filter.png"
        alt="analyze"
        appParallaxContent
        [parallaxArea]="30"
      />
    </div>
    <app-module-description [module]="analyze"></app-module-description>
  </div>

  <div class="section-sense-content">
    <div class="section-sense-content-image">
      <img loading="lazy" src="assets/images/landing/products/sense.png" alt="sense" />
      <img loading="lazy" src="assets/images/landing/products/sense-moments.png" alt="sense" appParallaxContent />
    </div>
    <app-module-description [module]="sense"></app-module-description>
  </div>

  <div class="section-create-content">
    <div class="section-create-content-image">
      <img loading="lazy" src="assets/images/landing/products/create.png" alt="create" />
      <img loading="lazy" src="assets/images/landing/products/create-awareness.png" alt="create" appParallaxContent />
    </div>
    <app-module-description [module]="create"></app-module-description>
  </div>
</div>
