import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { MasterDataComponent } from './master-data/master-data.component';
import { environment } from '../../environments/environment';

const BASE_SUBMODULE = environment.modules.internalTools + '/' + environment.modules.masterData + '/';

export const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: environment.modules.internalTools + '/' + environment.modules.masterData,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.modules.mdEntityManager
      },
      {
        path: environment.modules.mdEntityManager,
        component: MasterDataComponent,
        data: {
          isMfe: true,
          hideBreadcrumbs: true,
          submodule: BASE_SUBMODULE + environment.modules.mdEntityManager,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: MasterDataComponent,
            data: {
              isMfe: true,
              module: BASE_SUBMODULE + environment.modules.mdEntityManager,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: environment.modules.mdApprovals,
        component: MasterDataComponent,
        data: {
          isMfe: true,
          hideBreadcrumbs: true,
          submodule: BASE_SUBMODULE + environment.modules.mdApprovals,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: MasterDataComponent,
            data: {
              isMfe: true,
              module: BASE_SUBMODULE + environment.modules.mdApprovals,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: environment.modules.mdBulkLoads,
        component: MasterDataComponent,
        data: {
          isMfe: true,
          hideBreadcrumbs: true,
          submodule: BASE_SUBMODULE + environment.modules.mdBulkLoads,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: MasterDataComponent,
            data: {
              isMfe: true,
              module: BASE_SUBMODULE + environment.modules.mdBulkLoads,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: environment.modules.mdCatalogManagement,
        component: MasterDataComponent,
        data: {
          isMfe: true,
          hideBreadcrumbs: true,
          submodule: BASE_SUBMODULE + environment.modules.mdCatalogManagement,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: MasterDataComponent,
            data: {
              isMfe: true,
              module: BASE_SUBMODULE + environment.modules.mdCatalogManagement,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterDataMfeRoutingModule {}
