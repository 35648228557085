import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MissionsManagementService } from '../../services/missions-management.service';
import { finalize } from 'rxjs/operators';
import { SamplingSetting } from '../shared/interfaces';

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingListComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  readonly numberItems = [5, 10, 20];
  readonly columnsName = {
    module: 'Module',
    key: 'Key',
    description: 'Description',
    value: 'Value'
  };

  selectId: string;
  subscription = new Subscription();
  displayColumns: string[] = Object.values(this.columnsName);
  dataSource: MatTableDataSource<SamplingSetting> = new MatTableDataSource();
  loading = false;

  constructor(private readonly _missionManagement: MissionsManagementService) {}

  ngOnInit() {
    this.configTable();
    this.getSettings();
  }

  getSettings() {
    this.loading = true;
    this._missionManagement
      .getSettings()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((res: SamplingSetting[]) => {
        this.dataSource.data = res;
      });
  }

  configTable() {
    this.dataSource.paginator = this.paginator;
  }

  search(event: string) {
    this.dataSource.filter = event.trim().toLocaleLowerCase();
  }

  resetSelect() {
    delete this.selectId;
  }

  selected(element: SamplingSetting) {
    this.selectId = element.id;
  }

  changeValue(element: SamplingSetting, newValue: string) {
    this._missionManagement.setValueSettings(element.key, element.module, newValue).subscribe(() => {
      element.value = newValue;
      this.resetSelect();
    });
  }
}
