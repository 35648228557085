import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HeaderWithoutAuthorization } from '../../shared/constants';

interface HttpHeaders {
  [key: string]: string;
}
interface RequestAttributes {
  url?: string;
  setHeaders?: {
    [name: string]: string | string[];
  };
}

@Injectable({
  providedIn: 'any'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestAttributes: RequestAttributes = {};

    if(request.headers.get(HeaderWithoutAuthorization)) {
      request.headers.delete(HeaderWithoutAuthorization);
    } else {
      const token = localStorage.getItem('token');
      requestAttributes.setHeaders = token ? ({ Authorization: `Bearer ${token}` } as HttpHeaders) : {};
    }
    request = request.clone(requestAttributes);
    return next.handle(request);
  }
}
