import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  DynamicTableCellType,
  DynamicTableComponent,
  DynamicTableData,
  DynamicTableRow,
  LazyTableDataSource
} from '@rappi/ui/dynamic-table';
import { Observable, Subject } from 'rxjs';
import { TierService } from '../../../../services/tier.service';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { map, skip, takeUntil, tap } from 'rxjs/operators';
import { GenericDict } from '@rappi/common';
import { TableMenuTriggerComponent } from '../../../../components/table-menu-trigger/table-menu-trigger.component';
import { Paginate, Permission } from '../../../../definitions/permissions.models';
import { Tier } from '../../../../definitions/tier.models';
import { TableModulesComponent } from '../../../../components/table-modules/table-modules.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: DynamicTableComponent;
  @ViewChild('modulesTemplateRef') modulesTemplateRef: TemplateRef<ElementRef>;

  tiersTableData: DynamicTableData;

  destroySubject$ = new Subject<void>();

  private readonly PAGE_SIZE = 5;
  private readonly INIT_PAGE = 1;

  constructor(private readonly _store: Store<AppState>, private readonly _tierService: TierService) {}

  ngOnInit() {
    this.tiersTableData = this.initTableConfig();

    this._store.pipe(select(StoreName.country), skip(1), takeUntil(this.destroySubject$)).subscribe(() => {
      this.table.resetTable();
    });
  }

  initTableConfig(): DynamicTableData {
    const dataSource = new LazyTableDataSource<DynamicTableRow>(
      this.getTierList.bind(this),
      this.PAGE_SIZE,
      '',
      this.INIT_PAGE
    );

    return {
      title: {
        value: 'TIERS MANAGEMENT'
      },
      dataSource,
      lazyLoaded: true,
      pagination: true,
      paginatorOpts: { pageSize: this.PAGE_SIZE },
      columns: ['tier', 'type', 'modules', 'description', 'actions'],
      cellsConfig: {
        tier: {
          type: DynamicTableCellType.text
        },
        type: {
          type: DynamicTableCellType.text
        },
        modules: {
          type: DynamicTableCellType.customComponent,
          properties: {
            component: TableModulesComponent,
            inputs: ['tableModules'],
            outputs: []
          }
        },
        description: {
          type: DynamicTableCellType.text
        },
        actions: {
          type: DynamicTableCellType.customComponent,
          properties: {
            component: TableMenuTriggerComponent,
            inputs: ['id', 'type', 'service'],
            outputs: []
          },
          hideTitle: true
        }
      }
    };
  }

  getTierList(page = this.INIT_PAGE, size = this.PAGE_SIZE, filterName: string): Observable<GenericDict> {
    return this._tierService.getTiers(page, size, filterName).pipe(
      map(({ data }: Permission<Paginate<Tier>>) => {
        const offset = this.tiersTableData.dataSource.data.length;

        if (!offset && data.total_record && this.tiersTableData.paginator) {
          this.tiersTableData.paginator.length = data.total_record;
        }

        return (data.records || []).map((elem: Tier) => ({
          tier: {
            value: elem.name
          },
          type: {
            value: elem.type
          },
          modules: {
            inputs: {
              tableModules: elem.modules
            }
          },
          description: {
            value: elem.description
          },
          actions: {
            inputs: {
              id: elem.id,
              type: 'Tier',
              service: (i: number) => this._tierService.deleteTier(i).pipe(tap(() => this.table.resetTable()))
            }
          }
        }));
      })
    );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
