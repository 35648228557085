import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { PermissionsRoutingModule } from './permissions.routing';
import { PermissionsStoreModule } from './store/permissions-store.module';
import { LinkTabsModule } from '../core/link-tabs/link-tabs.module';
import { TableMenuTriggerComponent } from './components/table-menu-trigger/table-menu-trigger.component';
import { MatMenuModule } from '@angular/material/menu';

import { AdminService } from './services/admin.service';
import { AccountsService } from './services/accounts.service';
import { TierService } from './services/tier.service';
import { LicensesService } from './services/licenses.service';
import { RolesService } from './services/roles.service';
import { ComponentService } from './services/component.service';
import { FiltersService } from './services/filters.service';

import { PermissionsComponent } from './permissions.component';
import { TableModulesComponent } from './components/table-modules/table-modules.component';
import { CustomTooltipModule } from '@rappi/ui/custom-tooltip';
import { ComponentLibraryModule } from '../shared/component-library/component-library.module';

@NgModule({
  declarations: [PermissionsComponent, TableMenuTriggerComponent, TableModulesComponent],
  imports: [
    CommonModule,
    PermissionsRoutingModule,
    PermissionsStoreModule,
    LinkTabsModule,
    MatMenuModule,
    CustomTooltipModule,
    ComponentLibraryModule
  ],
  providers: [
    AdminService,
    AccountsService,
    TierService,
    LicensesService,
    RolesService,
    ComponentService,
    FiltersService
  ]
})
export class PermissionsModule {}
