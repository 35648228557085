import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmplifyMfeRoutingModule } from './amplify-mfe-routing.module';
import { AmplifyComponent } from './amplify/amplify.component';


@NgModule({
  declarations: [AmplifyComponent],
  imports: [
    CommonModule,
    AmplifyMfeRoutingModule
  ]
})
export class AmplifyMfeModule { }
