import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputCellphoneComponent } from './input-cellphone.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from '@rappi/ui/input';
import { NumericInputModule } from './../../../../shared/directives/numeric-input/numeric-input.module';

@NgModule({
  declarations: [InputCellphoneComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, InputModule, NumericInputModule],
  exports: [InputCellphoneComponent]
})
export class InputCellphoneModule {}
