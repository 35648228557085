import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import { AuthService } from '../../../services/auth/auth.service';
import { LoginResponseAuth } from '../../../models/user/user.model';

import { FULL_PATHS } from '../definitions/landing.constant';
import { NgxSpinnerService } from 'ngx-spinner';

import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { HttpErrorResponse } from '@angular/common/http';
import { EventsService } from '../../../services/events/events.service';
import { EventActions, EventCategories, EventLabels } from '../../../definitions/enums/events.enum';
import { environment } from '../../../../environments/environment';
import { TriggerAmplitudeEntity } from '../../../services/events/event.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  readonly FULL_PATHS = FULL_PATHS;
  readonly BG_IMAGE_URL = '/assets/images/landing/login-bg.jpg';
  readonly LOGO_IMAGE_URL = '/assets/images/landing/brands-by-rappi-logo2x.png';
  readonly IS_PROD = environment.production;
  readonly INTERNAL_USERS = '@rappi';

  loginForm: FormGroup;

  constructor(
    private readonly _loading: NgxSpinnerService,
    private readonly _formBuilder: FormBuilder,
    private readonly _authService: AuthService,
    private readonly _alertsService: AlertsService,
    private readonly _eventsService: EventsService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginForm = this._formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    });
  }

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  onSubmit(f: FormGroup) {
    if (!f.valid) {
      return;
    }

    const { email, password } = f.getRawValue();

    this._loading.show();
    this._authService.login(email, password).subscribe(
      (response: LoginResponseAuth) => {
        this._loading.hide();
        const userType = email.toLocaleLowerCase().includes(this.INTERNAL_USERS) ?
          EventLabels.loginRappi : EventLabels.loginThirdParties;
        this._authService.successLogin(response.data);
        this._eventsService.send(
          EventCategories.landing,
          EventActions.login,
          userType
        );

        if (!response.data.platform_outage) {
          this._alertsService.openAlerts('Login successfully', AlertsType.success);
        }
        const event = this.eventLoginAmplitude(email,'Manual','success', 'Login successfully');
        this.trackAmplitudeLogin(event);
      },
      (err: HttpErrorResponse) => {
        const message = err.error?.message || 'There was an error trying to login';
        const event = this.eventLoginAmplitude(email,'Manual','fail', message);
        this.trackAmplitudeLogin(event);
        this._loading.hide();
        this._alertsService.openAlerts(message, AlertsType.error);
      }
    );
  }

  loginWithGoogle() {
    this._authService.getGoogleAuthUrl().subscribe((res: { data: string }) => {
      window.location.assign(res.data);
    });
  }

  eventLoginAmplitude(email: string, method: string, result: string, message: string): TriggerAmplitudeEntity {
    return {
      EVENT_NAME: 'LOGIN_BBR',
      EVENT_PROPS: {
        USER_EMAIL: email,
        METHOD: method,
        RESULT: result,
        MESSAGE: message
      }
    };
  }

  trackAmplitudeLogin(event: TriggerAmplitudeEntity, isProd = this.IS_PROD,): void {
    if (isProd) {
      this._eventsService.triggerAmplitudeEvent(event);
    }
  }
}
