import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeeplinksComponent } from './deeplinks/deeplinks.component';
import { environment } from '../../environments/environment.local';

const { create, deeplinksMfe } = environment.modules;

const BASE_MODULE = `${create}/${deeplinksMfe}`;

const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: BASE_MODULE
    },
    children: [
      {
        path: 'manager',
        component: DeeplinksComponent,
        data: {
          isMfe: true,
          module: BASE_MODULE,
          submodule: BASE_MODULE,
          hideBreadcrumbs: true,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: DeeplinksComponent,
            data: {
              isMfe: true,
              module: BASE_MODULE,
              submodule: BASE_MODULE,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'creation',
        component: DeeplinksComponent,
        data: {
          isMfe: true,
          module: BASE_MODULE,
          submodule: BASE_MODULE,
          hideBreadcrumbs: true,
          reuse: true
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeeplinksMfeRoutingModule {}
