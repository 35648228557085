<div class="title-text">
  <h4 class="title" [appCustomTooltip]="title" tooltipPosition="top" [showToolTip]="false">
    {{ title }}
  </h4>
  <p *ngIf="showDescription && description" class="title-description">{{ description }}</p>
  <div #refContentDownload></div>
</div>
<ng-container [ngSwitch]="action" *ngIf="actions?.length">
  <div class="action">
    <div class="action-wrapper" [class]="action" [class.disabled]="disabled">
      <div class="btn-wrapper" (click)="actionFunction()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 20 18"
          *ngSwitchCase="actionCases.link"
        >
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M10 2.5c.427 0 .78.322.828.736l.005.097v5.833h5.834c.46 0 .833.374.833.834 0 .427-.322.78-.736.828l-.097.005h-5.834v5.834c0 .46-.373.833-.833.833-.427 0-.78-.322-.828-.736l-.005-.097-.001-5.834H3.333c-.46 0-.833-.373-.833-.833 0-.427.322-.78.736-.828l.097-.005h5.833V3.332c0-.46.374-.833.834-.833z"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          *ngSwitchCase="actionCases.report"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
              <g>
                <g>
                  <g>
                    <path
                      d="M14.56 1.667c1.162 0 2.107.945 2.107 2.106v12.454c0 1.161-.945 2.106-2.107 2.106H5.44c-1.163 0-2.107-.945-2.107-2.105V3.771c0-1.16.944-2.105 2.106-2.105h9.121zm0 1.666H5.44c-.243 0-.44.197-.44.44v12.454c0 .243.197.44.44.44h9.12c.242 0 .44-.197.44-.44V3.773c0-.243-.197-.44-.44-.44zm-4.262 8.48c.46 0 .833.372.833.833 0 .46-.373.833-.833.833H6.965c-.46 0-.834-.372-.834-.833 0-.461.373-.834.833-.834h3.334zm2.5-3.334c.46 0 .833.373.833.833 0 .461-.372.834-.833.834H6.965c-.46 0-.834-.373-.834-.834 0-.46.373-.833.833-.833h5.834zm0-3.333c.46 0 .833.372.833.833 0 .46-.372.833-.833.833H6.965c-.46 0-.834-.372-.834-.833 0-.46.373-.833.833-.833h5.834z"
                      transform="translate(-1295 -130) translate(296 96) translate(993 28) translate(6 6)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          *ngSwitchCase="actionCases.download"
        >
          <g fill="706967" fill-rule="evenodd">
            <path
              d="M18.698 20c.552 0 1 .448 1 1 0 .513-.386.936-.883.993l-.117.007H5c-.552 0-1-.448-1-1 0-.513.386-.935.883-.993L5 20h13.698z"
              opacity=".5"
              transform="translate(.333)"
            />
            <path
              d="M11.772 2c.513 0 .936.386.993.884l.007.116v12.393l3.579-3.627c.357-.363.924-.395 1.319-.093l.095.083c.363.358.394.925.092 1.32l-.082.094-5.291 5.366c-.362.367-.936.395-1.33.085l-.094-.085-5.327-5.403c-.388-.393-.384-1.026.01-1.414.363-.358.93-.382 1.32-.074l.094.084 3.615 3.666V3c0-.552.448-1 1-1z"
              transform="translate(.333)"
            />
          </g>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          *ngSwitchCase="actionCases.quit"
          class="quit-btn"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="currentColor">
              <g>
                <g>
                  <path
                    d="M5.293 5.293c.39-.39 1.024-.39 1.414 0l5.6 5.6 5.602-5.6c.36-.36.928-.388 1.32-.083l.094.083c.39.39.39 1.024 0 1.414l-5.601 5.602 5.601 5.601c.36.36.388.928.083 1.32l-.083.094c-.39.39-1.024.39-1.414 0l-5.601-5.601-5.6 5.601c-.361.36-.929.388-1.32.083l-.095-.083c-.39-.39-.39-1.024 0-1.414l5.6-5.602-5.6-5.6c-.36-.361-.388-.928-.083-1.32z"
                    transform="translate(-1354 -128) translate(296 96) translate(1058 32)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>

        <p class="btn-text">
          {{ btnText | titlecase }}
        </p>
      </div>
    </div>
  </div>
</ng-container>
