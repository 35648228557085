<div class="btn-actions-container">
  <button
    class="rp-chip no-shadow one-icon-trash"
    *ngIf="action !== ACTIONS.create"
    (click)="deletePermission.delete(id, type)"
  >
    Delete {{ type }}
  </button>
  <a class="rp-chip no-shadow one-icon-pencil" [class.hidden]="type=='Admin' || type=='License'" *ngIf="action === ACTIONS.view" [routerLink]="['../../edit', id]">
    Edit {{ type }}
  </a>
</div>
