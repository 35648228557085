import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParallaxContentDirective } from './parallax-content.directive';

@NgModule({
  declarations: [ParallaxContentDirective],
  exports: [ParallaxContentDirective],
  imports: [CommonModule]
})
export class ParallaxContentModule { }
