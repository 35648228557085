<mat-card class="content-head">
  <mat-card-title class="content-title">
    <span class="card-title">Setting list</span>

    <div fxLayout="row" fxLayoutGap="15px">
      <span class="search-box block">
        <input (input)="search($event.target.value)" id="search" placeholder="Search" />
        <i aria-hidden="true" class="one-icon-search"></i>
      </span>
    </div>
  </mat-card-title>
  <mat-progress-bar mode="indeterminate" class="loading-bar" *ngIf="loading; else dataSetting"></mat-progress-bar>
  <ng-template #dataSetting class="content-table">
    <app-not-data-display *ngIf="!dataSource?.data.length; else settingTabla"></app-not-data-display>
    <ng-template #settingTabla class="content-table">
      <mat-table class="desktop-table" #table [dataSource]="dataSource" matSort>
        <ng-container [matColumnDef]="columnsName.module">
          <mat-header-cell *matHeaderCellDef class="header-title module-col">
            {{ columnsName.module }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="module-col">
            {{ element.module }}
          </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnsName.key">
          <mat-header-cell *matHeaderCellDef class="header-title key-column">
            {{ columnsName.key }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="key-column">
            {{ element.key }}
          </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnsName.description">
          <mat-header-cell *matHeaderCellDef class="header-title">
            {{ columnsName.description }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.description }}
          </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnsName.value">
          <mat-header-cell *matHeaderCellDef class="header-title">
            {{ columnsName.value }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" (click)="selected(element)" class="column-value">
            <span *ngIf="!(element.id === selectId)">
              {{ element.value }}
            </span>
            <div *ngIf="element.id === selectId" class="content-input">
              <mat-form-field appearance="outline">
                <input #valInput [value]="element.value" matInput required />
              </mat-form-field>
              <div class="icon-input">
                <i
                  aria-hidden="true"
                  appClickStopPropagation
                  (click)="changeValue(element, valInput.value)"
                  class="one-icon-check"
                ></i>
                <i aria-hidden="true" appClickStopPropagation (click)="resetSelect()" class="one-icon-wrong"></i>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns"> </mat-row>
      </mat-table>
    </ng-template>
  </ng-template>
  <mat-paginator #paginator [pageSizeOptions]="numberItems"></mat-paginator>
</mat-card>
