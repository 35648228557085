import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PricingRoutingModule } from './pricing-routing.module';
import { PricingComponent } from './pricing/pricing.component';
import { MatMenuModule } from '@angular/material/menu';
import { CardModule } from '@rappi/ui/card';
import { DynamicTableModule } from '@rappi/ui/dynamic-table';
import { MatButtonModule } from '@angular/material/button';
import { SelectModule } from '@rappi/ui/select';
import { InputModule } from '@rappi/ui/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssistantPricingComponent } from './dialogs/assistant-pricing/assistant-pricing.component';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomTooltipModule } from '@rappi/ui/custom-tooltip';
import { PricingService } from './services/pricing.service';

@NgModule({
  declarations: [PricingComponent, AssistantPricingComponent],
  imports: [
    CommonModule,
    PricingRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    CardModule,
    DynamicTableModule,
    SelectModule,
    InputModule,
    FormErrorsDirectiveModule,
    MatProgressBarModule,
    MatDialogModule,
    CustomTooltipModule
  ],
  providers: [PricingService]
})
export class PricingModule {}
