<button matDialogClose class="close-modal one-icon-times"></button>
<div mat-dialog-content class="content-form">
  <div class="content-header">
    <span class="principal-title">{{ 'landing.demo.title' | translate }}</span>
    <span class="sub-title">{{ 'landing.demo.subtitle' | translate }}</span>
  </div>
  <div class="button-container">
    <button class="btn primary big" [routerLink]="FULL_PATHS.signUp" matDialogClose>
      {{ 'landing.tryItForFree' | translate }}
    </button>
  </div>
</div>
<img src="/assets/images/landing/promotional-demo/promotional-demo-background.png" alt="demo" />
