<button
  [matMenuTriggerFor]="menu"
  #trigger="matMenuTrigger"
  class="menu-trigger one-icon-after-chevron-down"
  [ngClass]="{ 'menu-open': trigger.menuOpen }"
>
  <div class="account-switcher-wrapper">
    <div class="content-avatar">
      <img class="account-switcher-avatar" [src]="srcImgAvatar" alt="avatar" />
    </div>
    <span *ngIf="fullName" class="account-switcher-text">{{ fullName | titlecase }}</span>
  </div>
</button>

<mat-menu #menu="matMenu" xPosition="before" backdropClass="custom-menu">
  <ng-container *ngFor="let menuItem of menuItems">
    <button mat-menu-item disableRipple (click)="redirect(menuItem)" *ngIf="menuItem.active">
      <i [class]="menuItem.icon" aria-hidden="true"></i> <span>{{ menuItem.name | translate }}</span>
    </button>
  </ng-container>
</mat-menu>
