import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandsExampleComponent } from './brands-example.component';
import { ParallaxContentModule } from '../../../../shared/directives/parallax-content/parallax-content.module';

@NgModule({
  declarations: [BrandsExampleComponent],
  imports: [CommonModule, ParallaxContentModule],
  exports: [BrandsExampleComponent]
})
export class BrandsExampleModule {}
