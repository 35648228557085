<button
  #menuAction="matMenuTrigger"
  [matMenuTriggerFor]="countryMenu"
  [class.opened]="menuAction.menuOpen"
  (click)="toggleMenu()"
  class="country one-icon-after-chevron-down"
  aria-label="Select country"
>
  <div class="content-flag">
    <img [src]="countrySelected.code | getImgCountry" [alt]="'flag of ' + countrySelected.name" />
  </div>
</button>

<mat-menu #countryMenu="matMenu" class="countries-menu">
  <ng-container *ngFor="let item of countries">
    <button
      mat-menu-item
      *ngIf="item.code !== countrySelected.code"
      class="country"
      (click)="selected(item)"
      [disabled]="item.platform_outage"
      [class.country-disabled]="item.platform_outage"
    >
      <img [src]="item.code | getImgCountry" [alt]="'flag of ' + item.name" />
      {{ item.name }}
    </button>
  </ng-container>
</mat-menu>
