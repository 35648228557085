<ng-content></ng-content>
<div *ngIf="module" class="content-module">
  <div class="content-head">
    <i aria-hidden="true" class="content-title-icon {{ module.icon }}"></i>
    <h1 class="title-module">{{ module.title | translate }}</h1>
  </div>
  <div class="content-description">{{ module.description | translate }}</div>
  <div class="content-internal-module">
    <div *ngFor="let data of module.internalModules" class="module-description">
      <h5 class="title-internal-module">
        {{ data.title | translate }}
        <span *ngIf="data.isNew" class="new-internal-module"> New </span>
      </h5>
      <p class="description-internal-module">{{ data.description | translate }}</p>
    </div>
  </div>
</div>
