import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { DeleteBoxComponent } from './delete-box/delete-box.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [DeleteBoxComponent, ConfirmDialogComponent],
  imports: [CommonModule, MatDialogModule, ReactiveFormsModule],
  exports: [DeleteBoxComponent, ConfirmDialogComponent]
})
export class ComponentLibraryModule {}
