import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AudiencesComponent } from './audiences/audiences.component';
import { environment } from '../../environments/environment';

const BASE_SUBMODULE = `${environment.modules.create}/`;

export const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: BASE_SUBMODULE + environment.modules.audiencesMfe + '/list',
      hideBreadcrumbs: true
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: AudiencesComponent,
        data: {
          isMfe: true,
          hideBreadcrumbs: true,
          hideModuleTitle: true,
          module: BASE_SUBMODULE + environment.modules.audiencesMfe + '/list',
          submodule: BASE_SUBMODULE + environment.modules.audiencesMfe + '/list',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: AudiencesComponent,
            data: {
              isMfe: true,
              hideBreadcrumbs: true,
              hideModuleTitle: true,
              module: BASE_SUBMODULE + environment.modules.audiencesMfe + '/list',
              reuse: true
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AudiencesMfeRoutingModule { }
