<ng-container *ngIf="isModal; else noModal">
  <div class="modal">
    <p mat-dialog-title>
      Confirm Account
      <button class="close-btn one-icon-times" mat-dialog-close aria-label="close"></button>
    </p>
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #noModal>
  <div class="layout">
    <ng-template #title>Let's <span class="rp-text-primary">confirm</span> your account</ng-template>
    <ng-template #subtitle>
      <p class="subtitle">
        To start using your Brands by Rappi account, all you have to do is complete the following information
      </p>
    </ng-template>
    <app-card [title]="title" [subtitle]="subtitle">
      <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </app-card>
  </div>
</ng-template>

<ng-template #formTemplate>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form, token)" novalidate>
    <div class="grid">
      <div class="one-input-wrapper">
        <one-input label="Name" formControlName="first_name" appFormErrors></one-input>
      </div>

      <div class="one-input-wrapper">
        <one-input label="Last Name" formControlName="last_name" appFormErrors></one-input>
      </div>

      <div class="one-input-wrapper">
        <one-input label="Job Title" formControlName="job_title" appFormErrors></one-input>
      </div>

      <div class="one-input-wrapper">
        <one-input label="Work Email" formControlName="email" appFormErrors></one-input>
      </div>

      <app-input-cellphone [formControl]="phone"></app-input-cellphone>

      <div class="one-input-wrapper">
        <one-input
          label="Password"
          type="password"
          formControlName="password"
          appFormErrors
          [customFormatErrors]="ERRORS"
          [customFormatFunction]="errorsMapper"
        ></one-input>
      </div>

      <div class="one-input-wrapper">
        <one-input
          label="Confirm Password"
          type="password"
          formControlName="confirmPassword"
          appFormErrors
          [customFormatErrors]="ERRORS"
          [customFormatFunction]="errorsMapper"
        ></one-input>
        <span class="form-error" *ngIf="!password.errors && !confirmPassword.errors && form.errors?.match">
          <small class="error"> {{ PASSWORD_MATCH_ERROR_MESSAGE }} </small>
        </span>
      </div>
    </div>

    <label class="confirmation" *ngIf="!isModal">
      <input class="rp-checkbox" type="checkbox" formControlName="terms" />
      <span>
        By signing up you agree to our
        <button class="rp-link-secondary link-button" (click)="openCountryModal()" type="button">Terms</button>
        and
        <a
          class="rp-link-secondary"
          href="https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy</a
        >.
      </span>
    </label>

    <div class="form-actions">
      <button class="rp-button rp-filled rp-raisable brands-primary-button rp-big" [disabled]="form.invalid">
        Submit
      </button>
    </div>
  </form>
</ng-template>
