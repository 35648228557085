import { Language } from '../shared/enums';

export const STORAGE_KEY = {
  token: 'token',
  id: 'id',
  email: 'email',
  fullName: 'fullName',
  country: 'country',
  countries: 'countries',
  actions: 'actions',
  language: 'language',
  banners: 'banners',
  learningNotifications: 'learning',
  urlNewModulesVisited: 'urlNewModulesVisited',
  makerSelected:'makerSelected',
  firebaseToken: 'firebaseToken'
};

export const DEFAULT_LANGUAGE = Language.en;
