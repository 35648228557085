import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private readonly SIDE_PANEL_OPENED = new BehaviorSubject<boolean>(false);

  get sidePanelStatus() {
    return this.SIDE_PANEL_OPENED.asObservable();
  }

  toggleSidePanel() {
    this.SIDE_PANEL_OPENED.next(!this.SIDE_PANEL_OPENED.getValue());
  }

  openSidePanel() {
    this.SIDE_PANEL_OPENED.next(true);
  }

  closeSidePanel() {
    this.SIDE_PANEL_OPENED.next(false);
  }
}
