import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { LandingService } from '../services/landing/landing.service';
import { AlertsType } from '@rappi/ui/alerts';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertsService } from '../../../services/alerts/alerts.service';
import {
  PreRegisterRequest,
  PRIVACY_POLICY_URL,
  PRIVACY_POLICY_URLS,
  TERMS_AND_CONDITION_URLS
} from '../definitions/landing.constant';
import { EventsService } from '../../../services/events/events.service';
import { EventCategories, EventActions, EventLabels } from '../../../definitions/enums/events.enum';
import {
  SOURCE_DATA_SOURCE_EN,
  SOURCE_DATA_SOURCE_ES,
  SOURCE_DATA_SOURCE_PT,
  REGISTER_TYPE,
  RADIO_CARD_DATA_SOURCE_EN,
  RADIO_CARD_DATA_SOURCE_ES,
  RADIO_CARD_DATA_SOURCE_PT,
  ADSMOVIL_URLS
} from './sign-up.constant';
import { TranslateApplicationService } from '../../../services/translate/translate-application.service';
import { Language } from '../../../shared/enums';
import { ActivatedRoute } from '@angular/router';
import { CountryModalComponent } from '../components/country-modal/country-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  readonly BRANDS = REGISTER_TYPE.brands;
  readonly AGENCIES = REGISTER_TYPE.agencies;

  form: FormGroup = new FormGroup({
    type: new FormControl(null, Validators.required),
    first_name: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
    phone: new FormControl(null, Validators.required),
    role: new FormControl(null),
    company: new FormControl(null),
    type_of_industry: new FormControl(null),
    source: new FormControl(null),
    description: new FormControl(null),
    brands_users: new FormControl(false),
    terms: new FormControl(false)
  });
  isSuccess = false;

  typeOfIndrustryDataSource = [];

  foundUsDataSource = SOURCE_DATA_SOURCE_EN;

  dataSourceRadioCard = [
    {
      code: this.BRANDS,
      copy: '',
      description: '',
      image: '/assets/images/landing/sign-up/for-brand.png',
      checked: false
    },
    {
      code: this.AGENCIES,
      copy: '',
      description: '',
      image: '/assets/images/landing/sign-up/for-agencies.png',
      checked: false
    }
  ];

  readonly ERRORS: string[] = ['required', 'email', 'unallowedProvider'];
  readonly ERRORS_MESSAGE: { [key: string]: string } = {
    required: 'This field is required.',
    email: 'This is not an email.',
    unallowedProvider: 'Only corporate emails allowed.'
  };

  readonly BG_IMAGE_URL = '/assets/images/landing/pre-sign-up-bg.jpg';
  readonly IMAGE_CHECK_URL = '/assets/images/landing/check.png';

  readonly TERMS_URLS = TERMS_AND_CONDITION_URLS;
  readonly PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;
  readonly PRIVACY_POLICY_URLS = PRIVACY_POLICY_URLS;
  readonly ADS_URLS = ADSMOVIL_URLS;

  readonly REQUIRED_FIELDS: Record<REGISTER_TYPE, { field: string; validator: ValidatorFn }[]> = {
    [REGISTER_TYPE.brands]: [
      { field: 'company', validator: Validators.required },
      { field: 'type_of_industry', validator: Validators.required },
      { field: 'source', validator: Validators.required }
    ],
    [REGISTER_TYPE.agencies]: [
      { field: 'role', validator: Validators.required },
      { field: 'terms', validator: Validators.requiredTrue }
    ]
  };

  constructor(
    private readonly _landingService: LandingService,
    private readonly _alertsServices: AlertsService,
    private readonly _eventService: EventsService,
    private readonly _translateApplicationService: TranslateApplicationService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _dialog: MatDialog
  ) {}

  readonly errorsMapper = (error: string): string => this.ERRORS_MESSAGE[error];

  get phone(): AbstractControl {
    return this.form.get('phone');
  }

  get type(): string {
    return this.form.get('type').value as string;
  }

  ngOnInit() {
    this.checkFoundUsListLanguage();

    const amplifyParam = this._activatedRoute.snapshot.queryParams.amplify;
    if (amplifyParam) {
      sessionStorage.setItem('SOURCE_AMPLIFY', amplifyParam);
    }

    this.listenType();
  }

  onSubmit(f: FormGroup) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    this.setAmplifyMessage();
    const formValue = f.getRawValue();
    delete formValue.terms;
    delete formValue.policy;

    const request: PreRegisterRequest = { ...formValue, is_agency: formValue.type === REGISTER_TYPE.agencies };

    this._landingService.preRegisterUser(request).subscribe(
      ({ message }: { message: string }) => {
        this._alertsServices.openAlerts(message || 'Preregister created successfully', AlertsType.success);
        this._eventService.send(
          EventCategories.landing,
          EventActions.tryForFreeFormSubmission,
          EventLabels.successfully
        );
        this.isSuccess = true;
      },
      ({ error: { message } }: HttpErrorResponse) => {
        this._alertsServices.openAlerts(message, AlertsType.error);
        this._eventService.send(EventCategories.landing, EventActions.tryForFreeFormSubmission, EventLabels.error);
      }
    );
  }

  setTypeOfIndustry(indrustry: { id: string }) {
    this.form.get('type_of_industry').setValue(indrustry.id);
  }

  setSource(source: { name: string }) {
    this.form.get('source').setValue(source.name);
  }

  setAmplifyMessage() {
    const description = this.form.get('description').value;
    const amplifyMessage = JSON.parse(sessionStorage.getItem('SOURCE_AMPLIFY')) ? '(SOURCE: AMPLIFY)' : '';
    this.form.get('description').setValue(`${description} ${amplifyMessage}`);
  }

  listenType() {
    this.form.get('type').valueChanges.subscribe((type: REGISTER_TYPE) => {
      Object.entries(this.REQUIRED_FIELDS).forEach(([key, validators]) => {
        if (String(type) === key) {
          const validatorsToSet = validators;
          validatorsToSet.forEach(({ field, validator }) => {
            const control: FormControl = this.form.get(field) as FormControl;

            control.setValidators(validator);
            control.setValue('');
          });
        } else {
          const validatorsToCrear = validators;
          validatorsToCrear.forEach(({ field }) => {
            this.form.get(field).clearValidators();
            this.form.get(field).updateValueAndValidity();
          });
        }
      });
    });
  }

  openCountryModal(urls: Record<string, string>) {
    this._dialog.open(CountryModalComponent, {
      width: '92%',
      maxWidth: '510px',
      data: urls
    });
  }

  checkFoundUsListLanguage(): void {
    this._translateApplicationService.language$.subscribe(() => {
      switch (this._translateApplicationService.currentSelectLanguage.value) {
        case Language.en:
          this.getTypeOfIndustry(Language.en);
          this.foundUsDataSource = SOURCE_DATA_SOURCE_EN;
          this.dataSourceRadioCard[0].copy = RADIO_CARD_DATA_SOURCE_EN.brandCard.title;
          this.dataSourceRadioCard[0].description = RADIO_CARD_DATA_SOURCE_EN.brandCard.description;
          this.dataSourceRadioCard[1].copy = RADIO_CARD_DATA_SOURCE_EN.agenciesCard.title;
          this.dataSourceRadioCard[1].description = RADIO_CARD_DATA_SOURCE_EN.agenciesCard.description;
          break;
        case Language.es:
          this.getTypeOfIndustry(Language.es);
          this.foundUsDataSource = SOURCE_DATA_SOURCE_ES;
          this.dataSourceRadioCard[0].copy = RADIO_CARD_DATA_SOURCE_ES.brandCard.title;
          this.dataSourceRadioCard[0].description = RADIO_CARD_DATA_SOURCE_ES.brandCard.description;
          this.dataSourceRadioCard[1].copy = RADIO_CARD_DATA_SOURCE_ES.agenciesCard.title;
          this.dataSourceRadioCard[1].description = RADIO_CARD_DATA_SOURCE_ES.agenciesCard.description;
          break;
        case Language.pt:
          this.getTypeOfIndustry(Language.pt);
          this.foundUsDataSource = SOURCE_DATA_SOURCE_PT;
          this.dataSourceRadioCard[0].copy = RADIO_CARD_DATA_SOURCE_PT.brandCard.title;
          this.dataSourceRadioCard[0].description = RADIO_CARD_DATA_SOURCE_PT.brandCard.description;
          this.dataSourceRadioCard[1].copy = RADIO_CARD_DATA_SOURCE_PT.agenciesCard.title;
          this.dataSourceRadioCard[1].description = RADIO_CARD_DATA_SOURCE_PT.agenciesCard.description;
          break;
        default:
          break;
      }
    });
  }

  getTypeOfIndustry(language: Language): void {
    this._landingService
      .getTypeOfIndustry(language)
      .subscribe(
        ({ data }: { data: string[] }) =>
          (this.typeOfIndrustryDataSource = data.map((industry: string) => ({ id: industry, name: industry })))
      );
  }
}
