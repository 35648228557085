import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequestHubMfeRoutingModule } from './request-hub-mfe-routing.module';
import { RequestHubMfeComponent } from './request-hub-mfe/request-hub-mfe.component';


@NgModule({
  declarations: [RequestHubMfeComponent],
  imports: [
    CommonModule,
    RequestHubMfeRoutingModule
  ]
})
export class RequestHubMfeModule { }
