import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SessionRoutingModule } from './session.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LogoutComponent } from './logout/logout.component';

import { AuthService } from '../services/auth/auth.service';
@NgModule({
  imports: [CommonModule, SessionRoutingModule, ReactiveFormsModule, FormsModule],
  declarations: [LogoutComponent],
  providers: [AuthService]
})
export class SessionModule {}
