import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Inject,
  ViewEncapsulation
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, takeUntil, take, finalize } from 'rxjs/operators';
import {
  DynamicTableData,
  TableMessage,
  CellsConfig,
  CellValue,
  DynamicTableRow,
  MessageData,
  DynamicTableCellType,
  DynamicTableComponent,
  TableCellAlign
} from '@rappi/ui/dynamic-table';
import { Icons } from '../../../shared/icons.constants';
import { MatDialog } from '@angular/material/dialog';

import { MainManagerService } from '../services/main-manager.service';
import {
  Warehouse,
  DataProductDelivery,
  SelectProductDelivery,
  ReturnProductDelivery,
  ParamsInventoryList
} from '../shared/interfaces';
import { InventoryResponse, CampaignInventory } from '../../shared/sampling.models';
import { InventoryType } from '../shared/enums/inventory.enum';
import { ProductDeliveryComponent } from '../dialogs/product-delivery/product-delivery.component';
import { EditUnitsInventoryComponent } from '../dialogs/edit-units-inventory/edit-units-inventory.component';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import { TypeModal } from '../../../core/confirm-modal/definitions/enums/type-modal.enum';
import { TypeCofirmModal } from '../../../core/confirm-modal/definitions/models/type-modal.model';
import { MatTableDataSource } from '@angular/material/table';
import { StatusCheck } from '../shared/enums/statusCheck.enum';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { SelectStatus, StatusNames } from '@rappi/ui/select-status';
import { SamplingCampaign } from '../../shared/campaign-interfaces';
import { MissionsManagementService } from '../../services/missions-management.service';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
  providers: [{ provide: 'Window', useValue: window }],
  encapsulation: ViewEncapsulation.None
})
export class InventoryListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('actualInventoryTableComponent') actualComponent: DynamicTableComponent;
  @ViewChild('incomingInventoryTableComponent') incomingComponent: DynamicTableComponent;
  @ViewChild('outgoingInventoryTableComponent') outgoingComponent: DynamicTableComponent;
  @ViewChild('units') unitsTemplateRef: TemplateRef<ElementRef>;
  @ViewChild('statusRef') statusTemplateRef: TemplateRef<ElementRef>;
  readonly classSelectModal = 'fix-select-modal';
  warehouseId: number;
  warehouseName: string;

  actualInventoryTable: DynamicTableData;
  deliverSubject$ = new Subject<TableMessage>();
  actualInventoryTotal = 0;

  incomingInventoryTable: DynamicTableData;
  receiveSubject$ = new Subject<TableMessage>();
  incomingInventoryTotal = 0;

  outgoingInventoryTable: DynamicTableData;
  viewSubject$ = new Subject<TableMessage>();
  outgoingInventoryTotal = 0;

  destroySubject$ = new Subject<void>();

  loading = new BehaviorSubject<boolean>(false);

  readonly mobileWidth = 599;

  readonly statusObj: { [key: string]: SelectStatus } = {
    Activa: {
      label: 'Active',
      value: 'Active',
      class: StatusNames.active
    }
  };

  readonly ListStatus = Object.values(this.statusObj);

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _alertsService: AlertsService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _mainManagerService: MainManagerService,
    private readonly _managementService: MissionsManagementService,
    @Inject('Window') public readonly window: Window
  ) {}

  ngOnInit() {
    this.setTables();
    this.getInventoryData();
  }

  getInventoryData() {
    this.loading.next(true);

    this._activatedRoute.params
      .pipe(
        filter((params: ParamsInventoryList) => Boolean(params.id)),
        take(1),
        switchMap((params: ParamsInventoryList) => {
          this.warehouseId = Number(params.id);
          this.warehouseName = params.name;
          return this._mainManagerService.getWarehouseInventory(this.warehouseId);
        }),
        finalize(() => this.loading.next(false))
      )
      .subscribe((res: InventoryResponse) => {
        this.actualInventoryTotal = res.actual_inventory.total;
        this.actualInventoryTable.dataSource.data = this.setData(
          res.actual_inventory.inventories,
          InventoryType.actual
        );

        this.incomingInventoryTotal = res.incoming_inventory.total;
        this.incomingInventoryTable.dataSource.data = this.setData(
          res.incoming_inventory.inventories,
          InventoryType.incoming
        );

        this.outgoingInventoryTotal = res.outgoing_inventory.total;
        this.outgoingInventoryTable.dataSource.data = this.setData(
          res.outgoing_inventory.inventories,
          InventoryType.outgoing
        );
      });
  }

  setTables() {
    this.actualInventoryTable = this.initTableConfig(InventoryType.actual);
    this.incomingInventoryTable = this.initTableConfig(InventoryType.incoming);
    this.outgoingInventoryTable = this.initTableConfig(InventoryType.outgoing);

    this.initSubjectSubscription(this.deliverSubject$, InventoryType.actual);
    this.initSubjectSubscription(this.receiveSubject$, InventoryType.incoming);
    this.initSubjectSubscription(this.viewSubject$, InventoryType.outgoing);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initLoader();
      (this.actualInventoryTable
        .dataSource as MatTableDataSource<DynamicTableRow>).filterPredicate = this.filterByCampaignName;
      (this.incomingInventoryTable
        .dataSource as MatTableDataSource<DynamicTableRow>).filterPredicate = this.filterByCampaignName;
      (this.outgoingInventoryTable
        .dataSource as MatTableDataSource<DynamicTableRow>).filterPredicate = this.filterByCampaignName;
    });
  }

  filterByCampaignName(data: DynamicTableRow, searchValue: string): boolean {
    return ((data.campaign as CellValue).value as string).toLowerCase().includes(searchValue.toLowerCase());
  }

  initLoader() {
    this.actualComponent.dataLoading = this.loading.asObservable();
    this.incomingComponent.dataLoading = this.loading.asObservable();
    this.outgoingComponent.dataLoading = this.loading.asObservable();
  }

  initTableConfig(type: InventoryType): DynamicTableData {
    const dataSource = new MatTableDataSource<DynamicTableRow>();

    const columns =
      type === InventoryType.actual
        ? ['campaign', 'units', 'status', 'action']
        : ['campaign', 'units', 'status', 'van', 'action'];

    const cellsConfig: CellsConfig = {
      campaign: { title: 'Campaign name' ,type: DynamicTableCellType.text },
      units: {
        type: type === InventoryType.actual ? DynamicTableCellType.templateRef : DynamicTableCellType.text
      },
      status: { type: DynamicTableCellType.templateRef },
      action: {
        type: DynamicTableCellType.dialog,
        hideTitle: true,
        subject: this.getSubject(type),
        align: TableCellAlign.CENTER
      }
    };

    if (type !== InventoryType.actual) {
      cellsConfig.van = { type: DynamicTableCellType.text };
    }

    return {
      dataSource,
      columns,
      hideSearch: true,
      pagination: true,
      paginatorOpts: { pageSize: 5, pageSizeOptions: [5, 15, 20, 50] },
      message: {
        subtitle: 'No data found or waiting for data',
        icon: Icons.IconEmpty
      },
      cellsConfig
    };
  }

  setData(data: CampaignInventory[], type: InventoryType): DynamicTableRow[] {
    return data.map(
      (el: CampaignInventory) =>
        (({
          id: { value: el },
          campaign: {
            value: el.campaign_name,
            trimmed: true,
            maxWidth: this.window.innerWidth < this.mobileWidth ? '50px' : null,
            tooltip: {
              message: el.campaign_name,
              position: 'below'
            }
          },
          ...((type === InventoryType.actual && {
            units: {
              templateRef: this.unitsTemplateRef,
              context: { element: el }
            }
          }) || { units: { value: el.current_quantity } }),
          status: {
            templateRef: this.statusTemplateRef,
            context: {
              element: el
            }
          },
          ...((type !== InventoryType.actual && {
            van: {
              value: this.getVanName(type, el),
              trimmed: true,
              maxWidth: this.window.innerWidth < this.mobileWidth ? '50px' : null,
              tooltip: {
                message: this.getVanName(type, el),
                position: 'below'
              }
            }
          }) ||
            {}),
          action: { value: this.getActionName(type) }
        } as unknown) as DynamicTableRow)
    );
  }

  initSubjectSubscription(subject: Subject<TableMessage>, type: InventoryType) {
    subject.pipe(takeUntil(this.destroySubject$)).subscribe((msg: MessageData) => {
      this.openModal(type, msg);
    });
  }

  getSubject(type: InventoryType): Subject<TableMessage> {
    switch (type) {
      case InventoryType.actual:
        return this.deliverSubject$;
      case InventoryType.incoming:
        return this.receiveSubject$;
      case InventoryType.outgoing:
        return this.viewSubject$;
      default:
        return null;
    }
  }

  getVanName(type: InventoryType, el: CampaignInventory): string {
    switch (type) {
      case InventoryType.incoming:
        return el.source_name;
      case InventoryType.outgoing:
        return el.target_name;
      default:
        return null;
    }
  }

  getActionName(type: InventoryType): string {
    switch (type) {
      case InventoryType.actual:
        return 'Deliver';
      case InventoryType.incoming:
        return 'Receive';
      case InventoryType.outgoing:
        return 'View';
      default:
        return null;
    }
  }

  openModal(type: InventoryType, msg: MessageData) {
    switch (type) {
      case InventoryType.actual:
        this.producDelivery(msg.data.id.value);
        break;
      case InventoryType.incoming:
        this.receivedProducts(msg.data.id.value);
        break;
      case InventoryType.outgoing:
        this.viewPendingToDelivery(msg.data.id.value);
        break;
      default:
        // TODO: Open one and another modal
        console.log(msg);
        break;
    }
  }

  applyFilter(filterValue: string, table: DynamicTableData) {
    (table.dataSource as MatTableDataSource<DynamicTableRow>).filter = filterValue;
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  producDelivery(inventory: CampaignInventory) {
    const getList = this._mainManagerService.getWarehouseVan(this.warehouseId, StatusCheck.ENABLED).pipe(
      map((vanList: Warehouse[]) =>
        vanList.map(
          (van: Warehouse) =>
            ({
              key: van.id,
              value: van.name
            } as SelectProductDelivery)
        )
      )
    );

    const dialogRef = this._dialog.open(ProductDeliveryComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: this.classSelectModal,
      width: '40%',
      data: {
        inventory: {
          ...inventory,
          source_code: String(this.warehouseId)
        },
        getSelectList: getList,
        titleEntity: 'Van',
        title: 'Product Delivery',
        message: `Select the van and number of products of the
          campaign <span>${inventory?.campaign_name}</span> to deliver.`
      } as DataProductDelivery
    });

    dialogRef
      .afterClosed()
      .pipe(filter((res: ReturnProductDelivery) => Boolean(res)))
      .subscribe((value: ReturnProductDelivery) => {
        this._alertsService.openAlerts(
          `${value.units} units of the campaign
            ${inventory.campaign_name} have been sent to the
            van ${value.selectName}`,
          AlertsType.success
        );
        this.getInventoryData();
      });
  }

  editCurrentUnit(inventory: CampaignInventory) {
    const dialogRef = this._dialog.open(EditUnitsInventoryComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: this.classSelectModal,
      width: '40%',
      data: {
        ...inventory,
        source_code: this.warehouseId
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter((res: boolean) => res))
      .subscribe(() => {
        this.getInventoryData();
      });
  }

  viewPendingToDelivery(inventory: CampaignInventory) {
    this._dialog.open(ConfirmModalComponent, {
      disableClose: true,
      autoFocus: false,
      width: '500px',
      data: {
        title: 'Pending to deliver',
        message: `You have <strong>${inventory.current_quantity} units</strong> of
              the <strong>campaign ${inventory.campaign_name}</strong> pending to be
              received by the <strong>van ${inventory.target_name}</strong>.`,
        textSucces: 'Accept',
        type: TypeModal.notification
      } as TypeCofirmModal
    });
  }

  receivedProducts(inventory: CampaignInventory) {
    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      disableClose: true,
      autoFocus: false,
      width: '500px',
      panelClass: 'confirm-padding',
      data: {
        title: 'Receive Products',
        message: `<p class="title-source">${inventory.source_name} is sending:</p>
                  <p><strong>${inventory.campaign_name} - ${inventory.current_quantity} units</strong></p>`,
        textSucces: 'Accept units',
        textReject: 'Cancel',
        type: TypeModal.confirm
      } as TypeCofirmModal
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((res: boolean) => typeof res === 'boolean'),
        switchMap((res: boolean) =>
          (res
            ? this._mainManagerService.confirmMainTransaction(inventory.id, this.warehouseId)
            : this._mainManagerService.rejectMainTransaction(inventory.id, this.warehouseId)
          ).pipe(map(() => res))
        )
      )
      .subscribe((res: boolean) => {
        if (res) {
          this._alertsService.openAlerts(
            `${inventory.current_quantity} units of the campaign
            ${inventory.campaign_name} have been added to your inventory.`,
            AlertsType.success
          );
        } else {
          this._alertsService.openAlerts(
            'You have declined the transaction and the products were not added to your inventory.',
            AlertsType.error
          );
        }
        this.getInventoryData();
      });
  }

  productReception() {
    const getList = this.getCampaignAvailable();

    const dialogRef = this._dialog.open(ProductDeliveryComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: this.classSelectModal,
      width: '40%',
      data: {
        inventory: {
          source_code: String(this.warehouseId)
        },
        getSelectList: getList,
        titleEntity: 'Campaign',
        title: 'Product Reception',
        message: 'Select the campaign and number of products to receive.'
      } as DataProductDelivery
    });

    dialogRef
      .afterClosed()
      .pipe(filter((res: ReturnProductDelivery) => Boolean(res)))
      .subscribe((value: ReturnProductDelivery) => {
        this._alertsService.openAlerts(
          `${value.units} units of the campaign
            ${value.selectName} have been added to your inventory.`,
          AlertsType.success
        );
        this.getInventoryData();
      });
  }

  getCampaignAvailable() {
    return this._managementService.getCampaignsAvailable().pipe(
      map((campaignList: SamplingCampaign[]) =>
        campaignList
          .filter((campaign: SamplingCampaign) => campaign.inventory?.warehouse_id === this.warehouseId)
          .map(
            (campaign: SamplingCampaign) =>
              ({
                key: campaign.campaign_code,
                value: campaign.name,
                maxValue: campaign.inventory.quantity_expected - campaign.inventory.received_quantity
              } as SelectProductDelivery)
          )
      )
    );
  }
}
