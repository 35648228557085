import { ModuleDescription } from '../components/module-description/models';

export interface SlideData {
  firstTitle: string;
  secondTitle: string;
  subtitle: string;
  colorCircleGreat: string;
  colorCircleLittle: string;
  colorsIconGradient: string[];
  classIcon: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

const INSIGHTS_BASE: Partial<SlideData>[] = [
  {
    secondTitle: 'Analyze',
    subtitle: 'Allow brands to measure and understand their performance.',
    classIcon: 'one-icon-chart-bars',
    data: {
      image: 'assets/images/landing/products/my-company.png',
      features: [
        'Brand Power Index',
        'Maker and Brand Performance',
        'Channel Performance',
        'Category Performance',
        'Predictive Performance'
      ]
    }
  },
  {
    secondTitle: 'Sense',
    subtitle: 'Understand your audience and create segments for targeting/advertising.',
    classIcon: 'one-icon-user-plus-frame',
    data: {
      image: 'assets/images/landing/products/audiences.png',
      features: [
        'Demographics',
        'Loyalty and Switching',
        'Cross Basket',
        'Consumer clusters & Dynamic audiences',
        'User Journeys',
        'Trends and Topics'
      ]
    }
  }
];

const MARKETING_BASE: Partial<SlideData>[] = [
  {
    secondTitle: 'Sampling',
    subtitle: 'When something new happens, turn it into a video is the fastest way to make it go viral',
    classIcon: 'one-icon-present',
    data: {
      image: 'assets/images/landing/products/ads.png',
      features: []
    }
  },
  {
    secondTitle: 'Ads',
    subtitle: 'Reach consumers through programmatic, Facebook or Google allowing them to buy in one click.',
    classIcon: 'one-icon-keyboard',
    data: {
      image: 'assets/images/landing/products/banners.png',
      features: []
    }
  }
];

export const INSIGHTS_PRODUCTS: SlideData[] = INSIGHTS_BASE.map(
  (prod) =>
    ({
      firstTitle: 'INSIGHTS',
      colorsIconGradient: ['#31d3ff', '#1a60ff'],
      ...prod
    } as SlideData)
);
export const MARKETING_PRODUCTS: SlideData[] = MARKETING_BASE.map(
  (prod) =>
    ({
      firstTitle: 'CREATE',
      colorsIconGradient: ['#ffcd00', '#ff7200'],
      ...prod
    } as SlideData)
);

export const PRODUCTS: SlideData[] = [...INSIGHTS_PRODUCTS, ...MARKETING_PRODUCTS];

let FILTERS: string[] = PRODUCTS.map((el: SlideData) => el.firstTitle.toLocaleLowerCase());
FILTERS = FILTERS.filter((e: string, i: number) => FILTERS.indexOf(e) === i);
FILTERS.unshift('all');

export const FILTERS_PRODUCTS: string[] = FILTERS;

export const FEATURE_ANALYZE: ModuleDescription = {
  icon: 'one-icon-chart-bars',
  title: 'landing.features.analyze.title',
  description: 'landing.features.analyze.description',
  internalModules: [
    {
      title: 'landing.features.analyze.internalModules.title1',
      description: 'landing.features.analyze.internalModules.description1'
    },
    {
      title: 'landing.features.analyze.internalModules.title2',
      description: 'landing.features.analyze.internalModules.description2'
    },
    {
      title: 'landing.features.analyze.internalModules.title3',
      description: 'landing.features.analyze.internalModules.description3'
    },
    {
      title: 'landing.features.analyze.internalModules.title4',
      description: 'landing.features.analyze.internalModules.description4'
    },
    {
      title: 'landing.features.analyze.internalModules.title5',
      description: 'landing.features.analyze.internalModules.description5',
      isNew: true
    }
  ]
};

export const FEATURE_SENSE: ModuleDescription = {
  icon: 'one-icon-light',
  title: 'landing.features.sense.title',
  description: 'landing.features.sense.description',
  internalModules: [
    {
      title: 'landing.features.sense.internalModules.title1',
      description: 'landing.features.sense.internalModules.description1'
    },
    {
      title: 'landing.features.sense.internalModules.title2',
      description: 'landing.features.sense.internalModules.description2'
    },
    {
      title: 'landing.features.sense.internalModules.title3',
      description: 'landing.features.sense.internalModules.description3'
    },
    {
      title: 'landing.features.sense.internalModules.title4',
      description: 'landing.features.sense.internalModules.description4',
      isNew: true
    }
  ]
};

export const FEATURE_CREATE: ModuleDescription = {
  icon: 'one-icon-tools',
  title: 'landing.features.create.title',
  description: 'landing.features.create.description',
  internalModules: [
    {
      title: 'landing.features.create.internalModules.title1',
      description: 'landing.features.create.internalModules.description1'
    },
    {
      title: 'landing.features.create.internalModules.title2',
      description: 'landing.features.create.internalModules.description2'
    },
    {
      title: 'landing.features.create.internalModules.title3',
      description: 'landing.features.create.internalModules.description3'
    }
  ]
};
