<div class="form rp-w-container-s" *ngIf="!isSuccess">
  <h2>{{ 'landing.signUp.hey' | translate }}</h2>
  <h1 [innerHTML]="'landing.signUp.title' | translate"></h1>
  <p>{{ 'landing.signUp.subtitle' | translate }}</p>

  <form [formGroup]="form" (ngSubmit)="onSubmit(form)" novalidate>
    <legend>{{ 'landing.signUp.typeLabel' | translate }}</legend>
    <div class="radio-card-content">
      <one-radio-card
        appearance="horizontal"
        [formControl]="form.get('type')"
        [dataSource]="dataSourceRadioCard"
      ></one-radio-card>
    </div>

    <ng-container *ngIf="type">
      <legend>{{ 'landing.signUp.form.agencies.title' | translate }}</legend>

      <div class="grid">
        <div class="one-input-wrapper">
          <one-input [label]="'formValues.name' | translate" formControlName="first_name" appFormErrors></one-input>
        </div>

        <div class="one-input-wrapper">
          <one-input
            [label]="'formValues.workEmail' | translate"
            formControlName="email"
            appFormErrors
            [customFormatErrors]="ERRORS"
            [customFormatFunction]="errorsMapper"
          ></one-input>
        </div>

        <app-input-cellphone [formControl]="phone"></app-input-cellphone>

        <ng-container *ngIf="type === BRANDS">
          <div class="one-input-wrapper">
            <one-input
              formControlName="company"
              [label]="'formValues.company' | translate"
              autocomplete="off"
              appFormErrors
            ></one-input>
          </div>

          <div class="one-input-wrapper">
            <one-select
              class="type-of-industry"
              [label]="'formValues.typeOfIndustry' | translate"
              [dataSource]="typeOfIndrustryDataSource"
              (selectionChanged)="setTypeOfIndustry($event)"
            ></one-select>
          </div>

          <div class="one-input-wrapper">
            <one-select
              [label]="'formValues.source' | translate"
              [dataSource]="foundUsDataSource"
              (selectionChanged)="setSource($event)"
            ></one-select>
          </div>
        </ng-container>

        <div class="one-input-wrapper" *ngIf="type === AGENCIES">
          <one-input
            [label]="'formValues.rol' | translate"
            formControlName="role"
            appFormErrors
          ></one-input>
        </div>
      </div>

      <div class="one-input-wrapper">
        <one-input-textarea
          [label]="'formValues.textAreaMessage' | translate"
          formControlName="description"
        ></one-input-textarea>
      </div>

      <label *ngIf="type === BRANDS">
        <input class="rp-checkbox" type="checkbox" formControlName="brands_users" />
        <span class="checkbox-message">{{ 'landing.signUp.checkboxMessage' | translate }}</span>
      </label>

      <label *ngIf="type === AGENCIES">
        <input class="rp-checkbox" type="checkbox" formControlName="terms" />
        <span class="checkbox-message">
          {{ 'landing.signUp.form.agencies.t&c.start' | translate }}
          <button translate type="button" class="rp-text-primary" (click)="openCountryModal(TERMS_URLS)">landing.signUp.termAndCodition</button>
          {{ 'landing.signUp.form.agencies.t&c.firstConnector' | translate }}
          <a class="rp-text-primary" [href]="PRIVACY_POLICY_URL" rel="noopener noreferrer" target="_blank">
            {{ 'landing.signUp.privacyPolicy' | translate }}
          </a>
          {{ 'landing.signUp.form.agencies.t&c.secondConnector' | translate }}
          <button translate type="button" class="rp-text-primary" (click)="openCountryModal(ADS_URLS)">landing.signUp.adsMovil</button>
        </span>
      </label>

      <div class="form-actions">
        <button class="rp-button rp-filled rp-primary" [disabled]="form.invalid">
          {{ 'landing.contactUs' | translate }}
        </button>
      </div>
    </ng-container>
  </form>
</div>

<div class="section-content" *ngIf="isSuccess">
  <div class="section-content-great">
    <img loading="lazy" [src]="IMAGE_CHECK_URL" alt="check" />
    <h1>{{ 'landing.signUp.great' | translate }}</h1>
    <p>
      {{ 'landing.signUp.successMessage' | translate }}
    </p>
  </div>
</div>

<div class="bg-image">
  <img loading="lazy" [src]="BG_IMAGE_URL" alt="decorative image" />
</div>
