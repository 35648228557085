<header class="header rp-text-primary-dark" [class.hidden]="scrollDown && lastScrollY > 64">
  <img
    class="header-brand"
    src="/assets/images/landing/brand.svg"
    alt="Rappi One brand"
    [routerLink]="FULL_PATHS.home"
    loading="lazy"
  />
  <button
    class="header-menu-button one-icon-menu-hamburger"
    [ngClass]="{ 'header-menu-open': trigger.menuOpen }"
    #trigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    aria-label="Toggle menu"
  ></button>
  <nav class="header-nav">
    <div class="header-nav-section">
      <ng-container *ngFor="let item of HEADER_ITEMS">
        <a class="header-nav-elem rp-text-gray-10" [routerLink]="item.path" routerLinkActive="active">
          {{ item.label | translate }}
        </a>
      </ng-container>
    </div>
    <div class="header-nav-section">
      <div class="header-nav-elem content-language">
        <span class="btn-language" [matMenuTriggerFor]="languageMenu">
          {{ currentLanguage?.label }}
          <em class="icon-language one-icon-chevron-down"></em>
        </span>
      </div>
      <a class="header-nav-elem" [routerLink]="FULL_PATHS.login">
        <i aria-hidden="true" class="one-icon-user-circle"></i>
        {{ 'landing.login' | translate }}
      </a>
      <a class="header-nav-elem" [routerLink]="FULL_PATHS.signUp">
        <button class="header-nav-button rp-br-8" type="button">
          {{ 'landing.tryItForFree' | translate }}
        </button>
      </a>
    </div>
  </nav>
</header>

<mat-menu #menu="matMenu" class="header-menu header-list rp-text-secondary-dark" [overlapTrigger]="false">
  <ng-container *ngFor="let item of HEADER_ITEMS">
    <button
      class="header-list-elem rp-text-secondary-dark"
      [routerLink]="item.path"
      mat-menu-item
      [disableRipple]="true"
    >
      <i aria-hidden="true" [class]="'rp-text-primary-dark one-icon-' + item.icon"></i>
      {{ item.label | translate }}
    </button>
  </ng-container>
  <button
    class="header-list-elem rp-text-secondary-dark"
    [routerLink]="FULL_PATHS.login"
    mat-menu-item
    [disableRipple]="true"
  >
    <i aria-hidden="true" class="one-icon-duotone-key rp-text-primary-dark"></i>
    {{ 'landing.login' | translate }}
  </button>
  <button
    class="header-list-elem rp-text-secondary-dark btn-language-mobile"
    mat-menu-item
    [matMenuTriggerFor]="languageMenu"
    [disableRipple]="true"
  >
    {{ currentLanguage?.label }}
  </button>
</mat-menu>

<mat-menu #languageMenu="matMenu">
  <ng-container *ngFor="let language of languages">
    <button *ngIf="!language.active" mat-menu-item (click)="setLanguage(language.value)">
      {{ language.label }}
    </button>
  </ng-container>
</mat-menu>
