import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { StoreAuxService } from '../storeAux/store-aux.service';

import { Observable } from 'rxjs';
import { ControlPanelAlert } from '../../models/user/user.model';

import { environment } from '../../../environments/environment';

@Injectable()
export class ControlPanelService {
  readonly BASE_PATH = '/alerts';

  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getAlert(): Observable<{ data: ControlPanelAlert }> {
    return this._http.get<{ data: ControlPanelAlert }>(this._getBasePath());
  }

  createAlert(alert: ControlPanelAlert): Observable<ControlPanelAlert> {
    return this._http.post<ControlPanelAlert>(this._getBasePath(), alert);
  }

  deactivateAlert(alert: ControlPanelAlert): Observable<unknown> {
    return this._http.put<unknown>(`${this._getBasePath()}/${alert.id}`, alert);
  }

  private _getBasePath(): string {
    return this._storeAuxService.getCountry(environment.urlAlert) + this.BASE_PATH;
  }
}
