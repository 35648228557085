<div class="wrapper">
  <div class="title-section">
    <small class="title-meta">{{ data?.meta }}</small>
    <h2 class="title-title">{{ data?.title }}</h2>
  </div>
  <div class="image-section">
    <img [src]="data?.image" [alt]="data?.title + ' image'">
  </div>
  <p class="description-section">{{ data?.description }}</p>
  <ul class="features-section">
    <h3 class="features-title">Features</h3>
    <li class="features-elem one-icon-check" *ngFor="let feature of data?.features">{{ feature }}</li>
  </ul>
</div>

<button class="rp-button rp-filled rp-raisable rp-primary rp-br-8 float-btn" [routerLink]="FULL_PATHS.signUp" mat-dialog-close>Create Account</button>
<button class="close-btn one-icon-times" mat-dialog-close aria-label="close"></button>
