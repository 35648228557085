import { rappiBaseUrl, breadcrumbs, NoPhotoUrl, modules, rumAuth } from './environment.global';
export const api = 'api/cauth/revenue/access/proxy';
export const environment = {
  rumAuth,
  rappiBaseUrl,
  breadcrumbs,
  NoPhotoUrl,
  modules,
  production: false,
  environment: 'DEV',
  urlAuth: '/access/auth',
  urlAlert: '/mtz/notifications',
  urlDeeplinks: '//deeplinks-dev-dot-rappi-202719.appspot.com/api/v1',
  urlStockout: '//dev-api.rappione.com/stockout',
  urlStockout_v2: '//dev-api.rappione.com/stockout',
  urlFileManagement: '/file/manager',
  urlSamplingBase: '/sampling',
  urlMissionsCommanderBase: '/missions/commander/',
  urlMissionsInventoryBase: '/missions/inventory/',
  baseUrl: 'https://microservices.dev.rappi.com/api/one',
  urlNotifications: '/revenue/notifications',
  urlRevenue: '/access/manager',
  urlImage: {
    AR: 'https://images.rappi.com.ar/products/',
    BR: 'https://images.rappi.com.br/products/',
    CL: 'https://images.rappi.cl/products/',
    CO: 'https://images.rappi.com/products/',
    MX: 'https://images.rappi.com.mx/products/',
    PE: 'https://images.rappi.pe/products/',
    UY: 'https://images.rappi.com.uy/products/',
    CR: 'https://images.rappi.co.cr/products/',
    DEV: 'https://images.rappi.com/products/'
  },
  urlProductReport: 'https://support-one.rappi.com',
  urlTradeMfeSourceRoot: 'https://trade.dev.rappi.com',
  urlSenseMfeSourceRoot: 'https://revenue-sense.dev.rappi.com',
  urlAnalyzeMfeSourceRoot: 'https://revenue-analyze-mfe.dev.rappi.com',
  urlSamplingExtMfeSourceRoot: 'https://mtz-sampling-ext-mfe.dev.rappi.com',
  urlMtzSamplingMgmtMfeSourceRoot: 'https://mtz-sampling-mgmt.dev.rappi.com',
  urlMasterDataMfeSourceRoot: 'https://revenue-master-data.dev.rappi.com',
  urlSettingsAmplifyMfeSourceRoot: 'https://mtz-amplify-config.dev.rappi.com',
  urlSettingsRequestHubMfeSourceRoot: 'https://mtz-request-hub-config.dev.rappi.com',
  urlAdsMfeSourceRoot: 'https://revenue-ads.dev.rappi.com',
  urlBasicAnalyticsSourceRoot: 'https://revenue-basic-analytics.dev.rappi.com',
  urlAudiencesSourceRoot: 'https://revenue-audiences.dev.rappi.com',
  urlDeeplinksMfeSourceRoot: 'https://revenue-deeplinks.dev.rappi.com',
  urlAmplifyMfeSourceRoot: 'https://revenue-amplify.dev.rappi.com',
  urlCatalogClientMfeSourceRoot: 'https://mtz-catalog-client.dev.rappi.com',
  urlMtzRequestHubMfeSourceRoot: 'https://mtz-request-hub-mfe.dev.rappi.com',
  urlMtzReportsMfeSourceRoot: 'https://mtz-reports-mfe.dev.rappi.com',
  urlPerformancePredictorMfeSourceRoot: 'https://performance-predictor.dev.rappi.com',

  roles: {
    super: 'superadmin',
    maker: 'adminmaker',
    agency: 'agency',
    user: 'userbrand'
  },
  basePaths: [
    {
      code: 'DEV',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'CO',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'AR',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'BR',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'CL',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'MX',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'PE',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'UY',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'EC',
      url: `https://microservices.dev.rappi.com/${api}`
    },
    {
      code: 'CR',
      url: `https://microservices.dev.rappi.com/${api}`
    }
  ],
  route_hash: true,
  firebase: {
    apiKey: 'AIzaSyC_Vfp6OXuhcXp3o-APJBm1pbfe9XEzp_g',
    authDomain: 'brandsbyrappidev-fcec0.firebaseapp.com',
    databaseURL: 'https://brandsbyrappidev-fcec0-default-rtdb.firebaseio.com',
    projectId: 'brandsbyrappidev-fcec0',
    storageBucket: 'brandsbyrappidev-fcec0.appspot.com',
    messagingSenderId: '146194732805',
    appId: '1:146194732805:web:3129bedd72d7417959896c',
    measurementId: 'G-TSH8ENHGMF'
  }
};
