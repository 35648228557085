import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { LazyScrollableDataSource } from '@rappi/common';
import { AlertsType } from '@rappi/ui/alerts';
import { DynamicTableCellType, DynamicTableData, DynamicTableRow, LazyTableDataSource } from '@rappi/ui/dynamic-table';
import { SelectComponent, SelectConfig } from '@rappi/ui/select';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import { TypeModal } from '../../../core/confirm-modal/definitions/enums/type-modal.enum';
import { TypeCofirmModal } from '../../../core/confirm-modal/definitions/models/type-modal.model';
import { CellConfig, DataTableCell, PaginatedResponse } from '../../../shared/interfaces';
import { StoreName } from '../../../store/definitions/store.constants';
import { AppState } from '../../../store/states/app.state';
import { CountryState } from '../../../store/states/country.state';
import { AssistantPricingComponent } from '../dialogs/assistant-pricing/assistant-pricing.component';
import { PricingService } from '../services/pricing.service';
import { TYPE_SAMPLING_LABEL } from '../shared/constants';
import { ColumnsPricingTable, Price, SelectData } from '../shared/interfaces';
import { SamplingTypes } from '../../shared/campaign-enums';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricingComponent implements OnDestroy, OnInit {
  @ViewChild('actionRef', { static: true }) actionTemplateRef: TemplateRef<ElementRef>;
  @ViewChild('selectType', { static: true }) selectType: SelectComponent<SelectData>;
  @ViewChild('selectCities', { static: true }) selectCities: SelectComponent<SelectData[]>;

  formFilters = new FormGroup({
    product_type: new FormControl(),
    impacts: new FormControl(),
    cities: new FormControl()
  });
  readonly productTypeList = [
    {
      name: TYPE_SAMPLING_LABEL.SAMPLING_IDEAL,
      id: SamplingTypes.SAMPLING_IDEAL
    },
    {
      name: TYPE_SAMPLING_LABEL.SAMPLING_ON_TOP,
      id: SamplingTypes.SAMPLING_ON_TOP
    }
  ];
  citiesDataSource = new LazyScrollableDataSource<unknown>(this.getCityList.bind(this), 10, 0);
  unsubscribe$: Subject<void> = new Subject();
  dataTable: DynamicTableData;
  readonly filterSelectConfig: SelectConfig = {
    maxNumberOfChips: 1,
    panelClass: '',
    showTotalsChip: false,
    search: true,
    includeAll: true
  };

  readonly COLUMNS_NAME: ColumnsPricingTable = {
    product_type: 'Product type',
    city: 'City',
    impact_range: 'Impact Range',
    price: 'Price (USD)',
    actions: 'Actions'
  };

  constructor(
    private readonly _alertsService: AlertsService,
    private readonly _pricingService: PricingService,
    public readonly dialog: MatDialog,
    private readonly _store: Store<AppState>
  ) {
    this.getCountry();
  }

  ngOnInit() {
    this.dataTable = this.initTableConfig();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initTableConfig(): DynamicTableData {
    const pageSize = 5;
    const dataSource = new LazyTableDataSource<DynamicTableRow>(this.getPricing.bind(this), pageSize);

    return {
      dataSource,
      columns: Object.keys(this.COLUMNS_NAME),
      pagination: true,
      hideSearch: true,
      lazyLoaded: true,
      message: {
        title: 'There is no data to show',
        subtitle: ' '
      },
      paginatorOpts: {
        pageSize,
        pageSizeOptions: [5, 15, 20, 50]
      },
      cellsConfig: {
        product_type: {
          type: DynamicTableCellType.text,
          title: this.COLUMNS_NAME.product_type
        },
        city: {
          type: DynamicTableCellType.text,
          title: this.COLUMNS_NAME.city
        },
        impact_range: {
          type: DynamicTableCellType.text,
          title: this.COLUMNS_NAME.impact_range
        },
        price: {
          type: DynamicTableCellType.text,
          title: this.COLUMNS_NAME.price
        },
        actions: {
          type: DynamicTableCellType.templateRef,
          title: this.COLUMNS_NAME.actions
        }
      } as CellConfig<ColumnsPricingTable>
    };
  }

  getPricing(page = 0, size = 5): Observable<DynamicTableRow[]> {
    return this._pricingService
      .getPricing({
        page,
        size,
        filter: { ...this.formFilters.value }
      })
      .pipe(
        map((res: PaginatedResponse<Price>) => {
          this.dataTable.paginator.length = this.validateOffset(
            this.dataTable.dataSource.data.length,
            res.total_elements,
            this.dataTable.paginator.length
          );
          return this.setData(res.content, this.COLUMNS_NAME);
        })
      );
  }

  validateOffset(length: number, totalElements: number, paginatorLength: number): number {
    return length ? paginatorLength : totalElements;
  }

  setData(data: Price[], columnsKey: ColumnsPricingTable): DynamicTableRow[] {
    return data.map(
      (el: Price) =>
        ({
          ...Object.keys(columnsKey).reduce(
            (newObject: Partial<DataTableCell<ColumnsPricingTable>>, key: string) => ({
              [key]: {
                value: el[key]
              },
              ...newObject
            }),
            {
              product_type: {
                value: TYPE_SAMPLING_LABEL[el.product_type]
              },
              impact_range: {
                value: `${el.impact_range_min} - ${el.impact_range_max}`
              },
              price: {
                value: `$ ${new Intl.NumberFormat('es-ES').format(el.price)} USD`
              },
              actions: {
                templateRef: this.actionTemplateRef,
                context: {
                  element: el
                }
              }
            } as Partial<DataTableCell<ColumnsPricingTable>>
          )
        } as DynamicTableRow)
    );
  }

  getCityList(page = 0, limit = 5, searchValue = ''): Observable<SelectData[]> {
    return this._pricingService
      .getCities({ page, size: limit, filter: { search_value: searchValue } })
      .pipe(map((res) => res.records || []));
  }

  getCountry() {
    this._store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(StoreName.country),
        filter((state: CountryState) => Boolean(state.countrySelected))
      )
      .subscribe(() => {
        this.resetFilters();
      });
  }

  resetFilters() {
    this.selectType.clearSelection();
    this.selectCities.clearSelection();
    this.formFilters.reset();
    (this.dataTable.dataSource as LazyTableDataSource<DynamicTableRow>).resetTable();
  }

  applyFilters() {
    (this.dataTable.dataSource as LazyTableDataSource<DynamicTableRow>).resetTable();
  }

  mapCities(el: SelectData) {
    return el.name;
  }

  setFilters(search: number, cities: string[]) {
    this.formFilters.patchValue({
      impacts: !search || isNaN(search) ? null : Number(search),
      cities
    });
  }

  openAssistantPrice(price?: Price) {
    this.dialog
      .open(AssistantPricingComponent, {
        width: '40vw',
        data: price ?? {}
      })
      .afterClosed()
      .pipe(filter((res: boolean) => res))
      .subscribe(() => {
        (this.dataTable.dataSource as LazyTableDataSource<DynamicTableRow>).resetTable();
      });
  }

  deletePrice(price: Price) {
    this.dialog
      .open(ConfirmModalComponent, {
        disableClose: true,
        minWidth: '30vw',
        data: {
          title: 'Are you sure you want to delete this price?',
          message: 'Campaigns already created will not be affected.',
          type: TypeModal.confirm,
          textReject: 'Cancel',
          textSucces: 'Remove'
        } as TypeCofirmModal
      })
      .afterClosed()
      .pipe(
        filter((el) => el),
        switchMap(() => this._pricingService.deletePrice(price.id))
      )
      .subscribe(() => {
        this._alertsService.openAlerts('Price was successfully deleted', AlertsType.success);
        (this.dataTable.dataSource as LazyTableDataSource<DynamicTableRow>).resetTable();
      });
  }
}
