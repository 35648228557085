import { HttpHeaders } from '@angular/common/http';
import { Directive } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { UserState } from '../../store/states/user.state';
import { StoreName } from '../../store/definitions/store.constants';
import { AppState } from '../../store/states/app.state';
import { AccountState } from '../store/states/account.state';

import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { UserType } from '../definitions/permissions.enums';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class UtilPermissions {
  userType: UserType;
  accountId: number;

  readonly ACCOUNT_ID_PARAM = 'account_id';

  constructor(private readonly _store: Store<AppState>) {
    this.setAccountId();
  }

  getHeaders(userType: UserType, accountId: number): { headers: HttpHeaders } {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return {
      headers: userType !== UserType.admin ? headers : headers.set(this.ACCOUNT_ID_PARAM, String(accountId || ''))
    };
  }

  setAccountId() {
    this._store
      .pipe(
        select(StoreName.user),
        filter(({ user_type }: UserState) => Boolean(user_type)),
        tap(({ user_type }: UserState) => {
          this.userType = user_type;
        }),
        switchMap(
          ({ user_type }: UserState): Observable<number> =>
            user_type === UserType.admin
              ? this._store.select(StoreName.account).pipe(
                  filter(({ id }: AccountState) => Boolean(id)),
                  map(({ id }: AccountState) => id)
                )
              : of(null)
        )
      )
      .subscribe((id: number) => {
        this.accountId = id;
      });
  }
}
