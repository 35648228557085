<div mat-dialog-title>
  <div class="title">
    <span class="label">Multiple Maker</span>
    <span class="icon one-icon-cancel-filled" mat-dialog-close></span>
  </div>
</div>
<div mat-dialog-content>
  <div class="content">
    <ul *ngIf="makers?.length">
      <li *ngFor="let maker of makers" class="one-icon-check">
        {{ maker }}
      </li>
    </ul>
  </div>
</div>

<div mat-dialog-actions class="actions-wrapper">
  <button mat-button mat-dialog-close class="btn success">Ok</button>
</div>
