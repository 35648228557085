import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OtherPaginatedResponse, PageableRequest, PaginatedResponse } from '../../../shared/interfaces';
import { StoreAuxService } from '../../../services/storeAux/store-aux.service';
import { FilterPrice, Price, SelectData } from '../shared/interfaces';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  private readonly PATCH_MODULE = '/mtz/sampling';

  constructor(private readonly _storeAux: StoreAuxService, private readonly _httpClient: HttpClient) {}

  getPricing(pageable?: PageableRequest<Partial<FilterPrice>>): Observable<PaginatedResponse<Price>> {
    const body = pageable?.filter;
    const params = new HttpParams({
      fromObject: {
        page: String((pageable?.page ?? 0) + 1),
        limit: String(pageable?.size ?? 10)
      }
    });

    return this._httpClient.post<PaginatedResponse<Price>>(
      this._storeAux.getCountry(`${this.PATCH_MODULE}/financial/prices/search`),
      body,
      { params }
    );
  }

  getCities(pageable?: PageableRequest<Partial<{ search_value: string }>>) {
    const params = new HttpParams({
      fromObject: {
        page: String(pageable.page + 1),
        limit: String(pageable.size),
        search_criteria: 'name',
        search_value: pageable.filter.search_value ?? ''
      }
    });

    return this._httpClient
      .post<{ data: OtherPaginatedResponse<SelectData> }>(
        this._storeAux.getCountry('/access/manager/filters/cities'),
        {},
        { params }
      )
      .pipe(map((res) => res.data));
  }

  addPrice(price: Price): Observable<void> {
    return this._httpClient.post<void>(this._storeAux.getCountry(`${this.PATCH_MODULE}/financial/prices`), {
      ...price
    });
  }

  editPrice(price: Price, id: string): Observable<void> {
    return this._httpClient.patch<void>(this._storeAux.getCountry(`${this.PATCH_MODULE}/financial/prices/${id}`), {
      price: price.price
    });
  }

  deletePrice(priceId: string): Observable<void> {
    return this._httpClient.delete<void>(this._storeAux.getCountry(`${this.PATCH_MODULE}/financial/prices/${priceId}`));
  }
}
