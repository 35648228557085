export enum ActivationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum GenderType {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum MenuMobileTypes {
  MENU_USER = 'MENU_USER',
  MENU_COUNTRY = 'MENU_COUNTRY',
  MENU_NAVIGATOR = 'MENU_NAVIGATOR'
}

export enum Language {
  en = 'en',
  es = 'es',
  pt = 'pt'
}

export enum MasterDataEntity {
  product = 'product',
  products = 'products',
  global_maker = 'global_maker',
  maker = 'maker',
  trademark = 'trademark',
  trademarks = 'trademarks',
  macro_category = 'macro_category',
  category = 'category',
  categories = 'categories',
  subcategory = 'subcategory',
  segment = 'segment',
  unit = 'unit',
  group = 'group',
  vertical = 'vertical',
  master_store = 'master_store',
  master_stores = 'master_stores',
  brand = 'brand',
  brands = 'brands',
  audience = 'audience',
  audiences = 'audiences',
  store_brand = 'store_brand'
}

