import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicAnalyticsComponent } from './basic-analytics/basic-analytics.component';
import { BasicAnalyticsMfeRoutingModule } from './basic-analytics-mfe-routing.module';


@NgModule({
  declarations: [BasicAnalyticsComponent],
  imports: [
    CommonModule,
    BasicAnalyticsMfeRoutingModule
  ]
})
export class BasicAnalyticsMfeModule { }
