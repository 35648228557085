import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsMfeRoutingModule } from './settings-mfe-routing.module';
import { AmplifyComponent } from './amplify/amplify.component';
import { RequestHubConfigComponent } from './request-hub-config/request-hub-config.component';


@NgModule({
  declarations: [AmplifyComponent, RequestHubConfigComponent],
  imports: [
    CommonModule,
    SettingsMfeRoutingModule
  ]
})
export class SettingsMfeModule { }
