import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { AccountComponent } from './account.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

import { PersonalInformationComponent } from './components/personal-information/personal-information.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'personal-information'
  },
  {
    path: '',
    component: AccountComponent,
    data: {
      title: 'My Account'
    },
    children: [
      {
        path: 'personal-information',
        component: PersonalInformationComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
