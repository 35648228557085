import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-notification',
  templateUrl: './empty-notification.component.html',
  styleUrls: ['./empty-notification.component.scss']
})

export class EmptyNotificationComponent  {
}
