<app-header></app-header>
<ng-template #title>Change your <span class="rp-text-primary">password</span></ng-template>
<ng-template #subtitle>Type your new password</ng-template>
<app-card [title]="title" [subtitle]="subtitle">
  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit(changePasswordForm)" novalidate>
    <div>
      <one-input
        label="New Password"
        type="password"
        formControlName="password"
        appFormErrors
        [customFormatErrors]="ERRORS"
        [customFormatFunction]="errorsMapper"
      ></one-input>
    </div>
    <div>
      <one-input
        label="Confirm Password"
        type="password"
        formControlName="confirmPassword"
        appFormErrors
        [customFormatErrors]="ERRORS"
        [customFormatFunction]="errorsMapper"
      ></one-input>
      <span
        class="form-error"
        *ngIf="!password?.errors && !confirmPassword?.errors && changePasswordForm.errors?.match"
      >
        <small class="error">
          {{ PASSWORD_MATCH_ERROR_MESSAGE }}
        </small>
      </span>
    </div>
    <button
      class="rp-button rp-filled rp-raisable brands-primary-button rp-big"
      [disabled]="changePasswordForm?.invalid"
    >
      Reset password
    </button>
  </form>
</app-card>
<app-footer></app-footer>
