import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { SamplingExtMfeComponent } from './sampling-ext-mfe/sampling-ext-mfe.component';


const { create, samplingExt } = environment.modules;
const BASE_SUBMODULE = `${create}/${samplingExt}`;

const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: BASE_SUBMODULE
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'campaign-manager'
      },
      {
        path: '**',
        component: SamplingExtMfeComponent,
        data: {
          isMfe: true,
          module: BASE_SUBMODULE,
          hideBreadcrumbs: true,
          hideModuleTitle: true,
          submodule: BASE_SUBMODULE + '/campaign-manager',
          reuse: true
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SamplingExtMfeRoutingModule { }
