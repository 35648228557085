import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { PermissionsType } from './definitions/permissions.enums';

import { PermissionsComponent } from './permissions.component';

const BREADCRUMBS_DATA = {
  title: 'Permissions Manager'
};

const routes: Routes = [
  {
    path: '',
    component: PermissionsComponent,
    children: [
      {
        path: 'admins',
        loadChildren: () => import('./modules/admins/admins.module').then((m) => m.AdminsModule),
        data: {
          title: BREADCRUMBS_DATA.title,
          breadcrumbs: PermissionsType.admins,
          breadcrumbsLink: true,
          hideBreadcrumbs: true
        }
      },
      {
        path: 'accounts',
        loadChildren: () => import('./modules/accounts/accounts.module').then((m) => m.AccountsModule),
        data: {
          title: BREADCRUMBS_DATA.title,
          breadcrumbs: PermissionsType.accounts,
          breadcrumbsLink: true,
          hideBreadcrumbs: true
        }
      },
      {
        path: 'tiers',
        loadChildren: () => import('./modules/tiers/tiers.module').then((m) => m.TiersModule),
        data: {
          title: BREADCRUMBS_DATA.title,
          breadcrumbs: PermissionsType.tiers,
          breadcrumbsLink: true,
          hideBreadcrumbs: true
        }
      },
      {
        path: 'licenses',
        loadChildren: () => import('./modules/licenses/licenses.module').then((m) => m.LicensesModule),
        data: {
          title: BREADCRUMBS_DATA.title,
          breadcrumbs: PermissionsType.licenses,
          breadcrumbsLink: true,
          hideBreadcrumbs: true
        }
      }
    ],
    data: { title: BREADCRUMBS_DATA.title, breadcrumbs: 'Permissions', breadcrumbsLink: false, hideBreadcrumbs: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PermissionsRoutingModule {}
