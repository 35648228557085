import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { PATHS } from './definitions/landing.constant';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {
  showHeader: boolean;
  constructor(private readonly _router: Router) {}

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith({ url: this._router.url })
      )
      .subscribe((val: NavigationEnd) => {
        this.showHeader = !val.url.includes(PATHS.login) && !val.url.includes(PATHS.platformOutage);
      });
  }
}
