<h1 class="card-title rp-text-primary-dark">
  <ng-container *ngTemplateOutlet="title"></ng-container>
</h1>
<p class="card-subtitle rp-text-secondary-dark">
  <ng-container *ngTemplateOutlet="subtitle"></ng-container>
</p>
<div class="error-login-with-google" *ngIf="errorWithGoogle">
  <ng-container *ngTemplateOutlet="error"></ng-container>
</div>
<div #ref [ngClass]="{ 'card-frame': ref.children.length }">
  <ng-content></ng-content>
</div>
