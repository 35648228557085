import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertsType } from '@rappi/ui/alerts';
import { EventCategories, EventActions, EventLabels } from '../../../../definitions/enums/events.enum';
import { EventsService } from '../../../../services/events/events.service';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { LandingService } from '../../services/landing/landing.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent {
  activeControl = new FormControl(false);

  contactForm: FormGroup = new FormGroup({
    first_name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    company: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    brands_users: new FormControl(false),
    description: new FormControl(null)
  });

  constructor(
    private readonly _landingService: LandingService,
    public dialogRef: MatDialogRef<ContactUsComponent>,
    private readonly _alertsService: AlertsService,
    private readonly _eventService: EventsService
  ) {}

  sendForm() {
    if (this.contactForm.valid) {
      this._landingService.sendContactUs(this.contactForm.value).subscribe(
        () => {
          this._alertsService.openAlerts('Request sent, thank you for contacting us.', AlertsType.success);
          this._eventService.send(
            EventCategories.landing,
            EventActions.contactUsFormSubmission,
            EventLabels.successfully
          );
          this.dialogRef.close();
        },
        () => {
          this._alertsService.openAlerts('There was an error trying to submit the form', AlertsType.error);
          this._eventService.send(EventCategories.landing, EventActions.contactUsFormSubmission, EventLabels.error);
        }
      );
    }
  }
}
