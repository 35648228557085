import { Component, Input, ElementRef, AfterContentInit, ViewChild } from '@angular/core';
import { IconsPaths } from '../../shared/constants';

@Component({
  selector: 'app-not-data-display',
  templateUrl: './not-data-display.component.html',
  styleUrls: ['./not-data-display.component.scss']
})
export class NotDataDisplayComponent implements AfterContentInit {
  @Input() message = 'No data to display';
  @Input() border = false;
  @Input() entity = 'data';

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  hasContent = false;

  readonly iconPath = IconsPaths.NOT_DATA;

  ngAfterContentInit() {
    this.hasContent = this.wrapper.nativeElement.childElementCount;
  }
}
