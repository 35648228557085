<one-card>
  <div class="content-info">
    <div class="content-avatar">
      <img [src]="srcImgAvatar" alt="avatar" />
    </div>
    <div class="content-info-account">
      <span class="name-user">{{ fullName | titlecase }}</span>
      <span class="user-type">{{ userType$ | async | titlecase }}</span>
    </div>
  </div>
  <div class="content-options">
    <a class="one-icon-user-box" routerLinkActive="active-option" [routerLink]="['./personal-information']">
      Personal Information
    </a>
    <a class="one-icon-key" routerLinkActive="active-option" [routerLink]="['./change-password']"> Change Password </a>
  </div>
</one-card>
<div class="layout"><router-outlet></router-outlet></div>
