import { Component, Input } from '@angular/core';
import { DirectionParallax } from '../../../../shared/directives/parallax-content/parallax-content.directive';

@Component({
  selector: 'app-brands-example',
  templateUrl: './brands-example.component.html',
  styleUrls: ['./brands-example.component.scss']
})
export class BrandsExampleComponent {
  @Input() imageToShow: string;

  readonly DirectionParallax = DirectionParallax;
}
