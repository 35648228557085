import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ControlPanelRoutingModule } from './control-panel.routing';
import { CardModule } from '@rappi/ui/card';
import { SelectModule } from '@rappi/ui/select';
import { InputModule } from '@rappi/ui/input';
import { InputTextareaModule } from '@rappi/ui/input-textarea';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ControlPanelService } from '../services/control-panel/control-panel.service';

import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { FormComponent } from './components/form/form.component';

@NgModule({
  imports: [
    CommonModule,
    ControlPanelRoutingModule,
    CardModule,
    SelectModule,
    InputModule,
    InputTextareaModule,
    FormErrorsDirectiveModule,
    ReactiveFormsModule
  ],
  declarations: [ControlPanelComponent, FormComponent],
  providers: [ControlPanelService]
})
export class ControlPanelModule {}
