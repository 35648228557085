import { Component, OnDestroy } from '@angular/core';

import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

import { LinkTabs } from '../core/link-tabs/definitions/link-tabs.models';
import { AppState } from '../store/states/app.state';
import { UserState } from '../store/states/user.state';
import { AccountState } from './store/states/account.state';
import { StoreName } from '../store/definitions/store.constants';
import { ACTIONS } from './definitions/actions.enums';
import { LINK_TABS, SINGULAR_LINK_TABS_NAMES, HIDDEN_CREATE_BUTTON_IN_TABS } from './definitions/permissions.constants';
import { UserType } from './definitions/permissions.enums';

import * as AccountActions from './store/actions/account.actions';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnDestroy {
  readonly SINGULAR_NAME = SINGULAR_LINK_TABS_NAMES;
  readonly CREATOR_PATH = ACTIONS.create;
  readonly IS_ADMIN = UserType.admin;
  readonly HIDDEN_CREATE_BUTTON_TABS = HIDDEN_CREATE_BUTTON_IN_TABS;
  LINK_TABS: LinkTabs[];

  type$: Observable<string>;
  isRootPath$: Observable<boolean>;
  account$: Observable<AccountState>;
  userType: string;

  destroySubject$ = new Subject<void>();

  constructor(private readonly _router: Router, private readonly _store: Store<AppState>) {
    const route$ = this._router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      startWith({ urlAfterRedirects: this._router.url } as NavigationEnd)
    );

    this._store.pipe(select(StoreName.user), takeUntil(this.destroySubject$)).subscribe(({ user_type }: UserState) => {
      this.userType = user_type;
      this.LINK_TABS = LINK_TABS[user_type];
    });

    this.type$ = route$.pipe(map(({ urlAfterRedirects }: NavigationEnd) => urlAfterRedirects.split('/')[2]));

    this.isRootPath$ = route$.pipe(
      map(({ urlAfterRedirects }: NavigationEnd) => urlAfterRedirects.split('/').length === 3)
    );

    this.account$ = this._store.select(StoreName.account);
  }

  ngOnDestroy() {
    this._store.dispatch(AccountActions.set({ payload: { id: null, email: '' } }));
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
