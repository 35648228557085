import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationButtonComponent } from './notification-button/notification-button.component';
import { NotificationLearningComponent } from './notification-learning/notification-learning.component';
import { NotificationUserComponent } from './notification-user/notification-user.component';
import { TrackClickModule } from '../track-click/track-click.module';
import { ClickStopPropagationDirectiveModule } from '../../directives/click-stop-propagation/click-stop-propagation.directive.module';
import { EmptyNotificationComponent } from './empty-notification/empty-notification.component';

@NgModule({
  declarations: [
    NotificationButtonComponent,
    NotificationLearningComponent,
    NotificationUserComponent,
    EmptyNotificationComponent
  ],
  exports: [
    NotificationButtonComponent,
    NotificationLearningComponent,
    NotificationUserComponent,
    EmptyNotificationComponent
  ],
  imports: [CommonModule, MatMenuModule, TrackClickModule, ClickStopPropagationDirectiveModule]
})
export class NotificationsHeaderModule {}
