<div class="brands-example-content">
  <div>
    <img
      appParallaxContent
      [direction]="DirectionParallax.INITIAL_DOWN"
      [parallaxArea]="200"
      [delay]="0.4"
      class="analyze-img"
      [src]="imageToShow"
      alt="Image"
    />
  </div>
</div>
