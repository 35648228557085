<div class="header">
  <div class="content-left">
    <button (click)="toggleMenu(!collapsed)" class="header-menu-button">
      <em
        class="one-icon-outline-menu"
        [contentTemplate]="!collapsed ? tooltipHide : tooltipShow"
        tooltipPosition="bottom"
        appCustomTooltip
      >
    </em>
    </button>
    <button (click)="toggleMenuMobile()" class="header-menu-button-mobile one-icon-outline-menu"></button>

    <div class="content-img">
      <img [src]="srcLogo" alt="logo rappi one" />
    </div>
  </div>

  <div class="header-options">
    <div class="items-headers-mobile">
      <app-user-selector-mobile></app-user-selector-mobile>
    </div>

    <div class="items-headers">
      <app-country-selector></app-country-selector>
    </div>

    <div class="items-headers">
      <app-notification-button [numberNotifications]="numberNotificationsUser" titleNotification="Notifications">
        <app-notification-user
          *ngFor="let notification of notificationsUser"
          [data]="notification"
          (action)="seeNotification($event, notification)"
          (click)="seeNotification(notification.actionable[0], notification)"
        >
        </app-notification-user>
        <app-empty-notification *ngIf="!notificationsUser.length"></app-empty-notification>
      </app-notification-button>
    </div>

    <div class="items-headers">
      <app-user-selector></app-user-selector>
    </div>
  </div>
</div>
<app-menu-content-mobile *ngIf="_utilsService.menuContentService.typeObserver | async"></app-menu-content-mobile>

<ng-template #tooltipHide>
  <div class="msg-tooltip">
    {{ 'menu.hideMenu' | translate }}
  </div>
</ng-template>

<ng-template #tooltipShow>
  <div class="msg-tooltip">
    {{ 'menu.showMenu' | translate }}
  </div>
</ng-template>
