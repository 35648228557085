import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActionableNotification, DataNotificationUser } from '../interfaces';
import { ActionNotification, StyleNotification } from '../enums';

@Component({
  selector: 'app-notification-user',
  templateUrl: './notification-user.component.html',
  styleUrls: ['./notification-user.component.scss']
})
export class NotificationUserComponent {
  @Input() data: DataNotificationUser;
  @Output() action = new EventEmitter<ActionableNotification>();
  readonly ActionNotification = ActionNotification;
  readonly StyleNotification = StyleNotification;

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  closeNotificationMenu(): void {
    const matMenuRef: HTMLElement = this._document.querySelector('.mat-menu-content');
    matMenuRef.click();
  }
}
