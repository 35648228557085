import { Component, Input } from '@angular/core';

import { FULL_PATHS } from '../../definitions/landing.constant';
import { GeneralPricing, PricingProduct } from '../../pricing/pricing.constant';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent {
  @Input() general: GeneralPricing;
  @Input() products: PricingProduct[];

  readonly FULL_PATHS = FULL_PATHS;
  readonly collapsedHeight = '80px';
  readonly expandedHeight = '80px';
}
