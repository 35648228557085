import { Injectable } from '@angular/core';

import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { StorageService } from '../../storage/storage.service';

import { AlertsType } from '@rappi/ui/alerts';
import { select, Store } from '@ngrx/store';
import { Observable, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppState } from '../../../store/states/app.state';
import { UserState } from '../../../store/states/user.state';
import { StoreName } from '../../../store/definitions/store.constants';
import { STORAGE_KEY } from '../../../definitions/app.constants';
import { UserType } from '../../../permissions/definitions/permissions.enums';

import { environment } from '../../../../environments/environment';
import { AlertsService } from '../../alerts/alerts.service';

@Injectable()
export class PermissionGuard implements CanActivateChild {
  ACCOUNT_URL = '/account';
  PERMISSIONS_MODULE = environment.modules.permissions;

  constructor(
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _storageService: StorageService,
    private readonly _alertsService: AlertsService
    ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      const currentUrl = state.url;

      if (!localStorage.getItem(STORAGE_KEY.token)) {
        this._router.navigate(['session']);
        subscriber.next(false);
        return;
      }

      if (currentUrl === this.ACCOUNT_URL) {
        subscriber.next(true);
        return;
      }

      const currentModule: string = route.data.module;

      if (currentModule === undefined) {
        subscriber.next(true);
        return;
      }

      this.validStore(currentModule, subscriber, route);
    });
  }

  validStore(currentModule: string, subscriber: Subscriber<boolean>, route: ActivatedRouteSnapshot): void {
    this._store.pipe(select(StoreName.user), take(1)).subscribe((userState: UserState) => {
      const user: UserState = Object.keys(userState).length ? userState : this._storageService.getUserAction();

      if (currentModule === this.PERMISSIONS_MODULE && user.user_type === UserType.account) {
        subscriber.next(true);
        return;
      }

      if (route.data.redirect && !route.queryParams.redirect) {
        const validChild = (route.routeConfig.children || []).find(({ path }) => (user.modules || []).some((e) => e.slug.includes(`${currentModule}/${path}`)));
        const validPath = `${currentModule}/${user.user_type === UserType.admin ? route.routeConfig.children[0].path : validChild.path}`;

        this._router.navigate([validPath], { queryParams: { redirect: true } });
        subscriber.next(false);
        return;
      }

      if (user.user_type === UserType.admin) {
        subscriber.next(true);
        return;
      }

      if (user.modules.find((e) => e.slug === currentModule)) {
        subscriber.next(true);
        return;
      }

      if(!route.queryParams['stay-page']) {
        this._router.navigate(['account']);
      }

      if(route.queryParams['show-alert-guard']) {
        this._alertsService.openAlerts(
          'You don\'t have access to this module, please contact your commercial representative',
          AlertsType.error
        );
      }

      subscriber.next(false);
    });
  }
}
