import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { RequestHubMfeComponent } from './request-hub-mfe/request-hub-mfe.component';


const { create, campaignManager } = environment.modules;

export const routes: Routes = [
  {
    path: '',
    component: RequestHubMfeComponent,
    data: {
      isMfe: true,
      module: `${create}/${campaignManager}`,
      hideBreadcrumbs: true,
      hideModuleTitle: true,
      reuse: true
    },
    children: [
      {
        path: '**',
        component: RequestHubMfeComponent,
        data: {
          isMfe: true,
          module: `${create}/${campaignManager}`,
          hideBreadcrumbs: true,
          hideModuleTitle: true,
          reuse: true
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestHubMfeRoutingModule { }
