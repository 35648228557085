import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { LicensesService } from '../../../../services/licenses.service';
import { RolesService } from '../../../../services/roles.service';
import { select, Store } from '@ngrx/store';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { AppState } from '../../../../../store/states/app.state';

import { License } from '../../../../definitions/licenses.models';
import { Role } from '../../../../../permissions/definitions/roles.models';
import { AlertsService } from '../../../../../../app/services/alerts/alerts.service';

import { Observable, Subject } from 'rxjs';
import { map, skip, takeUntil, tap } from 'rxjs/operators';

import { Paginate, Permission } from '../../../../definitions/permissions.models';
import { PermissionAction } from '../../../../definitions/permissions.enums';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements AfterViewInit, OnDestroy {
  action: string;
  form: FormGroup = new FormGroup({
    email: new FormControl(null, Validators.compose([Validators.required, Validators.email]))
  });

  id: number;

  deleteLicense: (id: number) => Observable<Permission<unknown>>;

  viewUrl: string;
  tableUrl: string;

  destroySubject$: Subject<void> = new Subject();

  private readonly LICENSE_CREATION_MESSAGE = 'License created successfully';
  private readonly LICENSE_CREATION_ERROR = 'There was an error trying to create license';

  private readonly LICENSE_UPDATE_MESSAGE = 'License updated successfully';
  private readonly LICENSE_UPDATE_ERROR = 'There was an error trying to update license with id';

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _licensesService: LicensesService,
    private readonly _rolesService: RolesService,
    private readonly _alertsService: AlertsService,
    private readonly _store: Store<AppState>
  ) {
    const routeArr = this._router.url.split('/');

    this.id = Number(routeArr[4]);
    this.action = routeArr[3];

    routeArr.length = 3;

    this.tableUrl = routeArr.join('/');
    this.viewUrl = this.id ? `${this.tableUrl}/${PermissionAction.view}/${this.id}` : null;
    this.deleteLicense = (id: number) =>
      this._licensesService.deleteLicense(id).pipe(tap(() => this.goTo(this.tableUrl)));

    this._store
      .pipe(
        select(StoreName.country),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this.goTo(this.tableUrl))
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (this.id) {
      this._licensesService.getLicense(this.id).subscribe(
        ({ data }: Permission<License>) => {
          this.form.patchValue(data);
          this._cd.detectChanges();
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(message || 'There was an error trying to get license', AlertsType.error);
        }
      );
    }
  }

  getRoles(page: number, limit: number, name: string): Observable<Role[]> {
    return this._rolesService
      .getRoles(page, limit, name)
      .pipe(map(({ data: { records } }: Permission<Paginate<Role>>) => records || []));
  }

  setRoleId(selection: Role) {
    this.form.get('role_id').setValue(selection.id);
  }

  goTo(url: string) {
    this._router.navigateByUrl(url);
  }

  onSubmit(f: FormGroup) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const request = f.getRawValue() as License;

    const action = this.id
      ? this._licensesService.editLicense(request, this.id)
      : this._licensesService.createLicense(request);

    const MESSAGE: string = this.id ? this.LICENSE_UPDATE_MESSAGE : this.LICENSE_CREATION_MESSAGE;
    const ERROR: string = this.id ? `${this.LICENSE_UPDATE_ERROR} ${this.id}` : this.LICENSE_CREATION_ERROR;

    action.subscribe(
      ({ data: { id } }: Permission<License>) => {
        this._alertsService.openAlerts(MESSAGE, AlertsType.success);
        this.goTo(this.viewUrl || `${this.tableUrl}/${PermissionAction.view}/${id}`);
      },
      ({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || ERROR, AlertsType.error);
      }
    );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
