import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudiencesComponent } from './audiences/audiences.component';
import { AudiencesMfeRoutingModule } from './audiences-mfe-routing.module';


@NgModule({
  declarations: [AudiencesComponent],
  imports: [
    CommonModule,
    AudiencesMfeRoutingModule
  ]
})
export class AudiencesMfeModule { }
