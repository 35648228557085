import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { FULL_PATHS } from '../definitions/landing.constant';
import { AlertsType } from '@rappi/ui/alerts';
import { GoogleStateCode, UserResponseAuth } from '../../../models/user/user.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { AlertsService } from '../../../services/alerts/alerts.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ANALYZE_FEATURE, SENSE_FEATURE, CREATE_FEATURE, SAW_PROMOTION } from './home.constants';
import { DirectionParallax } from '../../../shared/directives/parallax-content/parallax-content.directive';
import { PromotionalDemoComponent } from '../dialogs/promotional-demo/promotional-demo.component';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { VideoModalComponent } from '../../video-modal/video-modal/video-modal.component';
import { DataVideoModal } from '../../video-modal/constants';
import { EventsService } from '../../../services/events/events.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  srcOpportunityImg = 'assets/images/landing/landing-opportunity.png';
  srcCellphoneVideo = 'assets/images/landing/iphone_animated.mp4';
  srcAnalyzeImg = 'assets/images/landing/analyse-enterprise.png';
  srcAccumulatedMarkerSalesImg = 'assets/images/landing/accumulated-maker-sales.png';
  srcAccumulatedMarkerSalesVide = 'assets/images/landing/accumulated-maker-sales.mp4';
  srcYourShopperImg = 'assets/images/landing/your-shopper.png';
  srcShopperGraphicImg = 'assets/images/landing/shopper-graphic.png';
  srcGoogleFacebookAdsImg = 'assets/images/landing/google-facebook-ads.png';
  srcTradeMarketingImg = 'assets/images/landing/trade-marketing.png';
  srcCampaignManagerImg = 'assets/images/landing/campaign-manager.png';
  srcProductTestImg = 'assets/images/landing/product-test.png';

  readonly FULL_PATHS = FULL_PATHS;
  readonly analyzeFeature = ANALYZE_FEATURE;
  readonly senseFeature = SENSE_FEATURE;
  readonly createFeature = CREATE_FEATURE;
  readonly DirectionParallax = DirectionParallax;

  private readonly IS_BREAKWIDTH$: Observable<boolean> = this._breakpointObserver
    .observe('(max-width: 699px)')
    .pipe(map((result: BreakpointState) => result.matches));

  private readonly FREQUENTLY_USER = 3;

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthService,
    private readonly _alertsService: AlertsService,
    private readonly _activeRoute: ActivatedRoute,
    private readonly _loading: NgxSpinnerService,
    private readonly _breakpointObserver: BreakpointObserver,
    private readonly _eventsService: EventsService
  ) {}

  ngOnInit() {
    this.openPromotionalDemo();
    this.getParamsFromGoogleAuth();
  }

  getParamsFromGoogleAuth() {
    this._loading.show();
    this._activeRoute.queryParams
      .pipe(
        take(1),
        filter(({ state, code }: GoogleStateCode) => Boolean(state && code)),
        switchMap(({ state, code }: GoogleStateCode) => this._authService.exchangeGoogleCode(state, code)),
        finalize(() => this._loading.hide())
      )
      .subscribe(
        ({ message, data }: { message: string; data: UserResponseAuth }) => {
          const event = {
            EVENT_NAME: 'LOGIN_BBR',
            EVENT_PROPS: {
              USER_EMAIL: data.user.email,
              METHOD: 'google',
              RESULT: 'success',
              MESSAGE: message
            }
          };
          this._eventsService.triggerAmplitudeEvent(event);
          this._alertsService.openAlerts(message, AlertsType.success);
          this._authService.successLogin(data);
        },
        () => {
          const messageError = 'There was an error trying to login with Google';
          this._alertsService.openAlerts(messageError, AlertsType.error);
          const event = {
            EVENT_NAME: 'LOGIN_BBR',
            EVENT_PROPS: {
              USER_EMAIL: '',
              METHOD: 'google',
              RESULT: 'fail',
              MESSAGE: messageError
            }
          };
          this._eventsService.triggerAmplitudeEvent(event);
        }
      );
  }

  openPromotionalDemo() {
    const sawPromotionTimes = Number(localStorage.getItem(SAW_PROMOTION));

    if (sawPromotionTimes < this.FREQUENTLY_USER) {
      localStorage.setItem(SAW_PROMOTION, String(sawPromotionTimes + 1));

      this.IS_BREAKWIDTH$.pipe(
        take(1),
        filter((isAboveLandscape: boolean) => !isAboveLandscape)
      ).subscribe(() => {
        this._dialog.open(PromotionalDemoComponent, {
          autoFocus: false,
          closeOnNavigation: false,
          panelClass: 'dialog-promotional-demo'
        });
      });
    }
  }

  openVideo() {
    this._dialog.open<VideoModalComponent, DataVideoModal>(VideoModalComponent, {
      width: '70%',
      height: '60%',
      panelClass: 'app-video-modal',
      autoFocus: false,
      closeOnNavigation: false,
      data: {
        urlTranslate: 'landing.home.firstContainer.urlWatchVideo'
      }
    });
  }
}
