import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { ViewFormFieldComponent } from './view-form-field.component';

@NgModule({
  declarations: [ViewFormFieldComponent],
  imports: [CommonModule],
  exports: [ViewFormFieldComponent]
})
export class ViewFormFieldModule {}
