import { LandingDescriptionModule } from '../components/module-landing/models';

export const ANALYZE_FEATURE: LandingDescriptionModule = {
  number: '1',
  name: 'landing.home.steps.analyze.name',
  description: 'landing.home.steps.analyze.description',
  action: 'landing.home.steps.analyze.action'
};

export const SENSE_FEATURE: LandingDescriptionModule = {
  number: '2',
  name: 'landing.home.steps.sense.name',
  description: 'landing.home.steps.sense.description',
  action: 'landing.home.steps.sense.action'
};

export const CREATE_FEATURE: LandingDescriptionModule = {
  number: '3',
  name: 'landing.home.steps.create.name',
  description: 'landing.home.steps.create.description',
  action: 'landing.home.steps.create.action'
};

export const SAW_PROMOTION = 'SAW_PROMOTION';
