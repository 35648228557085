import { Component, ViewEncapsulation, HostListener, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';

import { TranslateApplicationService } from '../../../../services/translate/translate-application.service';
import { FULL_PATHS, FULL_HEADER_ITEMS } from '../../definitions/landing.constant';
import { LanguageSelect } from '../../../../shared/interfaces';
import { Language } from '../../../../shared/enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('trigger') trigger: MatMenuTrigger;

  readonly FULL_PATHS = FULL_PATHS;
  readonly HEADER_ITEMS = FULL_HEADER_ITEMS;
  subscriptions = new Subscription();
  languages: LanguageSelect[] = [];
  lastScrollY: number;
  scrollDown: boolean;
  currentLanguage: LanguageSelect;

  constructor(private readonly _translateApplicationService: TranslateApplicationService) {}

  @HostListener('window:resize', ['$event.target.innerWidth']) resize(width: number) {
    const isDesktop = width > 599;
    if (this.trigger.menuOpen && isDesktop) {
      this.trigger.closeMenu();
    }
  }

  ngOnInit() {
    this.subscribeLanguage();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeLanguage() {
    this.subscriptions.add(
      this._translateApplicationService.language$.subscribe(() => {
        this.currentLanguage = this._translateApplicationService.currentSelectLanguage;
        this.languages = this._translateApplicationService.languages;
      })
    );
  }

  setLanguage(newLanguage: Language) {
    this._translateApplicationService.setValue(newLanguage);
  }
}
