<div class="section-card-header">
  <p class="rp-text-brand">{{ general?.name }}</p>
  <h6 class="rp-text-secondary-dark">{{ general?.users }}</h6>
  <h6 class="description rp-text-secondary-dark">{{ general?.target }}</h6>
  <a class="rp-button rp-outlined rp-raisable rp-primary rp-medium" [routerLink]="FULL_PATHS.signUp">Get this plan</a>
</div>
<mat-accordion displayMode="flat" multi class="section-product" *ngFor="let product of products">
  <mat-expansion-panel #ex hideToggle>
    <mat-expansion-panel-header [collapsedHeight]="collapsedHeight" [expandedHeight]="expandedHeight">
      <mat-panel-title>
        <h6 class="section-product-title rp-text-primary-dark">
          {{ product.title }}<i class="section-product-toggle" aria-hidden="true"></i>
        </h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngIf="product.subproduct?.length; else single">
      <ng-container *ngFor="let subprod of product.subproduct">
        <p class="section-product-subtitle f-subhead rp-text-primary-dark">{{ subprod.title }}</p>
        <p class="section-product-elem rp-text-secondary-dark one-icon-check" *ngFor="let prod of subprod.modules">
          {{ prod }}
        </p>
      </ng-container>
    </ng-container>
    <ng-template #single>
      <p class="section-product-elem rp-text-secondary-dark one-icon-check" *ngFor="let prod of product.modules">
        {{ prod }}
      </p>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
