import { Component } from '@angular/core';

import { FIRST_FEATURE, SECOND_FEATURE, THIRD_FEATURE, FOURTH_FEATURE } from './for-agencies.constants';

@Component({
  selector: 'app-for-agencies',
  templateUrl: './for-agencies.component.html',
  styleUrls: ['./for-agencies.component.scss']
})
export class ForAgenciesComponent {
  readonly HERO_TITLE = 'landing.forAgencies.firstSection.title';
  readonly HERO_DESCRIPTION = 'landing.forAgencies.firstSection.description';
  readonly HERO_VIDEO_URL = 'landing.forAgencies.firstSection.urlWatchVideo';

  readonly WATERMARK = 'assets/images/landing/for-agencies/hero-watermark.png';
  readonly FLOATING_IMAGE = 'assets/images/landing/for-agencies/hero-floating-img.png';
  readonly BACKGROUND_VIDEO = 'assets/images/landing/for-agencies/hero-background.mp4';

  readonly TITLE = 'landing.forAgencies.secondSection.title';
  readonly DESCRIPTION = 'landing.forAgencies.secondSection.description';

  readonly FIRST_FEATURE = FIRST_FEATURE;
  readonly FIRST_FEATURE_IMAGE = 'assets/images/landing/for-agencies/first-feature-floating-image.png';
  readonly FIRST_FEATURE_VIDEO = 'assets/images/landing/for-agencies/first-feature.mp4';

  readonly SECOND_FEATURE = SECOND_FEATURE;
  readonly SECOND_FEATURE_IMAGE = 'assets/images/landing/for-agencies/second-feature-floating-image.png';
  readonly SECOND_FEATURE_VIDEO = 'assets/images/landing/for-agencies/second-feature.mp4';

  readonly THIRD_FEATURE = THIRD_FEATURE;
  readonly THIRD_FEATURE_IMAGE = 'assets/images/landing/for-agencies/third-feature-floating-image.png';
  readonly THIRD_FEATURE_VIDEO = 'assets/images/landing/for-agencies/third-feature.mp4';

  readonly FOURTH_FEATURE = FOURTH_FEATURE;
  readonly FOURTH_FEATURE_IMAGE = 'assets/images/landing/for-agencies/fourth-feature-floating-image.png';
  readonly FOURTH_FEATURE_VIDEO = 'assets/images/landing/for-agencies/fourth-feature.mp4';

  readonly BRANDS_EXAMPLE_TITLE = 'landing.forAgencies.thirdSection.title';
  readonly BRANDS_EXAMPLE_IMAGE = 'assets/images/landing/for-agencies/create_amplify.png';

  readonly START_USING_BANNER_TITLE = 'landing.forAgencies.fourthSection.title';
  readonly START_USING_BANNER_DESCRIPTION = 'landing.forAgencies.fourthSection.description';
}
