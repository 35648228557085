import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { MainManagerRoutes } from './main-manager.routing';
import { CreateWarehouseComponent } from './dialogs/create-warehouse/create-warehouse.component';
import { CreateVanComponent } from './dialogs/create-van/create-van.component';
import { ButtonSlideTextModule } from '../../core/button-slide-text/button-slide-text.module';
import { VanListComponent } from './van-list/van-list.component';
import { WarehouseManagerService } from './services/warehouse.manager.service';
import { NotDataDisplayModule } from './../../core/not-data-display/not-data-display.module';
import { CustomTooltipModule } from '@rappi/ui/custom-tooltip';
import { ReplacePipeModule } from '../../shared/pipes/replace/replace.pipe.module';
import { BreadcrumbsModule } from '../../core/breadcrumbs/breadcrumbs.module';
import { DeleteWarehouseComponent } from './dialogs/delete-warehouse/delete-warehouse.component';
import { ProductDeliveryComponent } from './dialogs/product-delivery/product-delivery.component';
import { EditUnitsInventoryComponent } from './dialogs/edit-units-inventory/edit-units-inventory.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { DynamicTableModule } from '@rappi/ui/dynamic-table';
import { InputTimeModule } from '@rappi/ui/input-time';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CardModule } from '@rappi/ui/card';
import { InputModule } from '@rappi/ui/input';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { HasPermissionModule } from '../../shared/directives/has-permissions/has-permissions.module';
import { MatMenuModule } from '@angular/material/menu';
import { SelectStatusModule } from '@rappi/ui/select-status';
import { SelectModule } from '@rappi/ui/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    RouterModule.forChild(MainManagerRoutes),
    ButtonSlideTextModule,
    NotDataDisplayModule,
    CustomTooltipModule,
    ReplacePipeModule,
    BreadcrumbsModule,
    MatDialogModule,
    MatFormFieldModule,
    DynamicTableModule,
    InputModule,
    FormErrorsDirectiveModule,
    GoogleMapsModule,
    InputTimeModule,
    MatTableModule,
    MatCardModule,
    MatProgressBarModule,
    MatPaginatorModule,
    CardModule,
    HasPermissionModule,
    MatMenuModule,
    SelectStatusModule,
    SelectModule
  ],
  providers: [WarehouseManagerService],
  declarations: [
    WarehouseComponent,
    CreateWarehouseComponent,
    CreateVanComponent,
    VanListComponent,
    DeleteWarehouseComponent,
    ProductDeliveryComponent,
    EditUnitsInventoryComponent,
    InventoryListComponent
  ]
})
export class MainManagerModule {}
