import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Observable } from 'rxjs';

import { Paginate, Permission } from '../definitions/permissions.models';
import { SearchCriteria } from '../definitions/permissions.enums';
import { Tier } from '../definitions/tier.models';
import { environment } from '../../../environments/environment';

@Injectable()
export class TierService {
  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) { }

  getTiers(page: number, limit: number, name?: string, email?: string): Observable<Permission<Paginate<Tier>>> {
    const params = new HttpParams({
      fromObject: Object.assign({ page, limit, search_criteria: SearchCriteria.name, search_value: name || '' })
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      account_email: email
    });

    let body = { params } as unknown;

    if (email) {
      body = { params, headers };
    }

    return this._http.get<Permission<Paginate<Tier>>>(`${this._getCountryUrl()}/tiers/`, body);
  }

  createTier(body: Tier): Observable<Permission<Tier>> {
    return this._http.post<Permission<Tier>>(`${this._getCountryUrl()}/tiers/`, body);
  }

  editTier(body: Tier, id: number): Observable<Permission<Tier>> {
    return this._http.put<Permission<Tier>>(`${this._getCountryUrl()}/tiers/${id}`, body);
  }

  getTier(id: number): Observable<Permission<Tier>> {
    return this._http.get<Permission<Tier>>(`${this._getCountryUrl()}/tiers/${id}`);
  }

  deleteTier(id: number): Observable<Permission<unknown>> {
    return this._http.delete<Permission<unknown>>(`${this._getCountryUrl()}/tiers/${id}`);
  }

  getTiersTypes() {
    return this._http.get(`${this._getCountryUrl()}/tiers/types`);
  }

  private _getCountryUrl(): string {
    return this._storeAuxService.getCountry(environment.urlRevenue);
  }
}
