<div class="bg-image">
  <img loading="lazy" class="bbr-logo" [src]="LOGO_IMAGE_URL" alt="BBR logo" [routerLink]="FULL_PATHS.home" />
  <img loading="lazy" [src]="BG_IMAGE_URL" alt="decorative image" />
</div>
<div class="form rp-w-container-s">
  <ng-template #title>Login</ng-template>
  <ng-template #subtitle>or <a class="rp-link" [routerLink]="FULL_PATHS.signUp">create free account</a></ng-template>
  <ng-template #error><span aria-hidden="true" class="one-icon-alert-rounded error-span"></span>There was an error trying to login with Google</ng-template>
  <app-card [title]="title" [subtitle]="subtitle" [error]="error" [errorWithGoogle]="email.value?.toLocaleLowerCase().includes(INTERNAL_USERS) && IS_PROD">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" novalidate>
      <div>
        <one-input label="Email Address" [formControl]="email" type="email" appFormErrors></one-input>
      </div>
      <div>
        <one-input label="Password" [formControl]="password" type="password" appFormErrors></one-input>
      </div>
      <small><a class="rp-link-secondary" [routerLink]="FULL_PATHS.resetPassword">Forgot password?</a></small>
      <button class="rp-button rp-filled rp-raisable brands-primary-button rp-big" [disabled]="!loginForm?.valid || (email.value?.toLocaleLowerCase().includes(INTERNAL_USERS) && IS_PROD)">
        Login
      </button>
    </form>
    <div class="rp-divider">o</div>
    <button class="rp-button rp-shadowed" (click)="loginWithGoogle()">
      <img loading="lazy" src="/assets/images/google.svg" alt="Google brand" />
      <span>Log In with Google</span>
    </button>
  </app-card>
</div>
