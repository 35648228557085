<div mat-dialog-title>
  <div class="title-content">
    <h1 class="title-modal">Delete confirmation</h1>
    <span [matDialogClose]="false" class="one-icon-close-material-v2 close-btn"></span>
  </div>
</div>

<div class="content-info" mat-dialog-content>
  <mat-progress-bar mode="indeterminate" class="loading-bar" *ngIf="loading"></mat-progress-bar>
  <div class="content-instruction">
    <p>
      Are you sure you want to delete the {{ typesTitle[dataModal.type] }} {{ dataModal.name }}? This action can not be
      undone.
    </p>
    <p>To delete type <span>“DELETE”</span></p>
  </div>
  <div class="content-input">
    <div class="all-input">
      <mat-form-field class="input-delete" appearance="outline">
        <input class="input-uppercase" [formControl]="controlDelete" autocomplete="off" matInput />
        <mat-error class="error-message">Incorrect value.</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="content-buttons">
    <button class="rp-button rp-outlined rp-primary rp-small" [matDialogClose]="false">Cancel</button>
    <button class="rp-button rp-filled rp-primary rp-small button-submit" (click)="confirm()">Confirm</button>
  </div>
</div>
