import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent {
  indexOpenService: number;
  indexOpenPlatform: number;

  services = Array(5);
  platforms = Array(7);
}
