import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserState } from '../../../store/states/user.state';
import { Module } from '../../../models/user/user.model';
import { StoreName } from '../../../store/definitions/store.constants';
import { AppState } from '../../../store/states/app.state';
import { UserType } from '../../../permissions/definitions/permissions.enums';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  @Input()
  set hasPermission(val: string) {
    this.permission = val;
  }

  private readonly DESTROY_SUBJECT$ = new Subject<void>();

  private permission: string;
  private modules: Module[];
  private userType: string;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly _templateRef: TemplateRef<any>,
    private readonly _viewContainer: ViewContainerRef,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit() {
    this.subscribeUserStore();
  }

  ngOnDestroy() {
    this.DESTROY_SUBJECT$.next();
    this.DESTROY_SUBJECT$.complete();
  }

  private subscribeUserStore() {
    this._store.pipe(select(StoreName.user), takeUntil(this.DESTROY_SUBJECT$)).subscribe((user: UserState) => {
      this.userType = user.user_type;
      this.modules = user.modules;
      this.updateView();
    });
  }

  private updateView() {
    this._viewContainer.clear();
    if (this.checkPermission()) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }

  private checkPermission(): boolean {
    return (
      this.userType === UserType.admin ||
      (this.modules || []).map((module: Module) => module.slug).some((slug: string) => slug === this.permission)
    );
  }
}
