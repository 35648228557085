import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeModal } from './definitions/enums/type-modal.enum';

import { TypeCofirmModal } from './definitions/models/type-modal.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  readonly TypeModal = TypeModal;
  readonly DEFAULT_TEXT_REJECT = 'No, thanks';
  readonly DEFAULT_TEXT_SUCCESS = 'Yes, sure';

  constructor(@Inject(MAT_DIALOG_DATA) public dataModal: TypeCofirmModal) {}
}
