<div class="second-toolbar-wrapper">
  <div class="second-toolbar-content rp-w-container-s">
    <div class="second-toolbar-text">
      <h5 class="rp-f-heading" [innerHTML]="title | translate"></h5>
      <p class="rp-f-text">
        {{ description | translate }}
      </p>
      <button class="brands-primary-button landing-button" [routerLink]="FULL_PATHS.signUp">
        {{ 'landing.tryItForFree' | translate }}
      </button>
    </div>
    <img class="content-img" [src]="srcLightiningImg" alt="clock" />
  </div>
</div>
