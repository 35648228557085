import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsType } from '@rappi/ui/alerts';
import { combineLatest, Subscription } from 'rxjs';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { MainManagerService } from '../../services/main-manager.service';
import { StatusCheck } from '../../shared/enums/statusCheck.enum';
import { WarehouseType } from '../../shared/enums/warehouseType.enum';
import { Warehouse } from '../../shared/interfaces';
import { finalize } from 'rxjs/operators';
import { LOCATION } from '../../../../shared/constants';

@Component({
  selector: 'app-create-warehouse',
  templateUrl: './create-warehouse.component.html',
  styleUrls: ['./create-warehouse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateWarehouseComponent implements OnInit, OnDestroy {
  readonly labelsName = {
    name: 'Name',
    city: 'City',
    address: 'Address',
    code: 'Store code',
    location_lat: 'Latitude',
    location_lng: 'Longitude',
    manager_id: 'Manager Id',
    warehouse_type: 'Warehouse type',
    status: 'Status'
  };
  warehouseForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    code: new FormControl('', [Validators.required, Validators.min(1)]),
    location_lat: new FormControl('', Validators.required),
    location_lng: new FormControl('', Validators.required),
    manager_id: new FormControl('', Validators.required),
    warehouse_type: new FormControl(WarehouseType.MAIN, Validators.required),
    status: new FormControl(true, Validators.required)
  });

  loading = false;
  subscriptions = new Subscription();
  warehouse: Warehouse;
  titleText = 'Create';
  buttonText = 'Create';
  markerPosition: google.maps.LatLngLiteral;

  constructor(
    private readonly _alertsService: AlertsService,
    private readonly _warehouseService: MainManagerService,
    private readonly _dialogRef: MatDialogRef<CreateWarehouseComponent>,
    @Inject(MAT_DIALOG_DATA) public dataModal: { warehouse: Warehouse }
  ) {
    if (dataModal) {
      this.warehouse = dataModal.warehouse;
    }
  }

  ngOnInit(): void {
    this.setMarketInput();
    this.subscribeLatLngChanges();
    this.setModal();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeLatLngChanges() {
    this.subscriptions.add(combineLatest([
      this.warehouseForm.controls.location_lat.valueChanges,
      this.warehouseForm.controls.location_lng.valueChanges
    ]).subscribe(([lat, lng]: number[]) => {
      this.setMarketInput(lat, lng);
    }));
  }

  setModal() {
    if (!this.warehouse) {
      this.titleText = 'Create';
      this.buttonText = 'Create';
    } else {
      this.titleText = 'Edit';
      this.buttonText = 'Save';
      this.buildEditForm();
    }
  }

  buildEditForm() {
    this.warehouseForm.patchValue({
      ...this.warehouse,
      code: this.warehouse.reference_code,
      status: this.warehouse && this.warehouse.status === StatusCheck.ENABLED
    });
    this.setMarketInput(this.warehouse.location_lat, this.warehouse.location_lng);
  }

  addMarker(event: google.maps.MouseEvent) {
    this.markerPosition = event.latLng.toJSON();
    this.warehouseForm.patchValue(
      {
        location_lat: this.markerPosition.lat,
        location_lng: this.markerPosition.lng
      }
    );
  }

  // TODO: add country property
  setMarketInput(lat = LOCATION.CO.lat, lng = LOCATION.CO.lng) {
    this.markerPosition = { lat, lng };
  }

  submit() {
    if (this.warehouseForm.valid) {
      this.loading = true;
      const payload = { ...this.warehouseForm.value, status: this.getValueStatus() };

      if (!this.warehouse) {
        this._warehouseService.createMainWarehouse(payload)
          .pipe(finalize(() => this.loading = false))
          .subscribe(
            () => {
              this._alertsService.openAlerts('Warehouse created successfully!', AlertsType.success);
              this._dialogRef.close(true);
            }
          );
      } else {
        this._warehouseService.updateMainWarehouse(payload, this.warehouse.id)
          .pipe(finalize(() => this.loading = false))
          .subscribe(
            () => {
              this._alertsService.openAlerts('Warehouse updated successfully!', AlertsType.success);
              this._dialogRef.close(true);
            }
          );
      }
    }
  }

  getValueStatus(): string {
    return this.warehouseForm.value.status ? StatusCheck.ENABLED : StatusCheck.DISABLED;
  }
}
