import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { environment } from '../../environments/environment';

import { AnalyzeComponent } from './analyze/analyze.component';

export const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: environment.modules.analytics,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: environment.modules.analyzePerformance,
        component: AnalyzeComponent,
        data: {
          isMfe: true,
          module: environment.modules.analytics,
          hideBreadcrumbs: true,
          submodule: environment.modules.analyzePerformance,
          reuse: true
        }
      },
      {
        path: environment.modules.analyzeRawData,
        component: AnalyzeComponent,
        data: {
          isMfe: true,
          module: environment.modules.analytics,
          hideBreadcrumbs: true,
          submodule: environment.modules.analyzeRawData,
          reuse: true
        }
      },
      {
        path: '',
        redirectTo: environment.modules.analyzePerformance,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnalyzeMfeRoutingModule {}
