export enum SINGULAR_STATEMENT {
  cities = 'city',
  verticals = 'vertical',
  makers = 'maker',
  trademarks = 'trademark',
  subcategories = 'subcategory'
}

export enum PLURAL_STATEMENT {
  city = 'cities',
  vertical = 'verticals',
  maker = 'makers',
  trademark = 'trademarks',
  subcategory = 'subcategories'
}
