import { Component } from '@angular/core';

import { PRICING_DATA, FAQ_DATA } from './pricing.constant';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {
  readonly PRICING_DATA = PRICING_DATA;
  readonly FAQ_DATA = FAQ_DATA;
}
