import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilService } from '../../../../services/util/util.service';

import { Country } from '../../../../models/shared/shared.model';
import { PreRegisterRequest, ContactUsDTO } from '../../definitions/landing.constant';

import { environment } from '../../../../../environments/environment';

@Injectable()
export class LandingService {
  constructor(private readonly _http: HttpClient, private readonly _utilService: UtilService) {}

  preRegisterUser(request: PreRegisterRequest): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getUrl()}/try-platform`, request);
  }

  getAvailablesCountries(): Observable<{ data: Country[] }> {
    return this._http.get<{ data: Country[] }>(`${this._getUrl()}/available-countries`);
  }

  getTypeOfIndustry(language: string) {
    return this._http.get<{ data: string[] }>(`${this._getUrl()}/industries?language=${language}`);
  }

  sendContactUs(data: ContactUsDTO): Observable<void> {
    return this._http.post<void>(`${this._getUrl()}/contact-us`, data);
  }

  private _getUrl(): string {
    return this._utilService.concatUrlPath(environment.urlAuth, environment.production ? 'CO' : 'DEV');
  }
}
