import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AlertsType } from '@rappi/ui/alerts';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, skip, takeUntil, tap } from 'rxjs/operators';
import { COUNTRY_NAME } from '../../../../../definitions/countries.constants';
import { ACTIONS } from '../../../../definitions/actions.enums';
import { License } from '../../../../definitions/licenses.models';
import { Permission } from '../../../../definitions/permissions.models';
import { LicensesService } from '../../../../services/licenses.service';
import { AppState } from '../../../../../store/states/app.state';
import { CountryState } from '../../../../../store/states/country.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { AlertsService } from '../../../../../services/alerts/alerts.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html'
})
export class ViewComponent implements OnInit, OnDestroy {
  id: number;
  data$: Observable<License>;
  VIEW = ACTIONS.view;

  deleteLicense: (id: number) => Observable<Permission<unknown>>;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _licensesService: LicensesService,
    private readonly _alertsService: AlertsService,
    private readonly _location: Location,
    private readonly _store: Store<AppState>
  ) {
    this.id = Number(this._activatedRoute.snapshot.params.id);
    this.deleteLicense = (id: number) => this._licensesService.deleteLicense(id).pipe(tap(() => this._location.back()));

    this._store
      .pipe(
        select(StoreName.country),
        map(({ countrySelected }: CountryState): string => COUNTRY_NAME[countrySelected]),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this._location.back())
      )
      .subscribe();
  }

  ngOnInit() {
    this.data$ = this._licensesService.getLicense(this.id).pipe(
      map(({ data }: Permission<License>): License => data),
      catchError(({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || 'There was an error trying to get license', AlertsType.error);
        return EMPTY;
      })
    );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
