export interface ConfirmDialogData {
  showTextbox: boolean;
  textToShow: DialogTextToShow;
}

export interface DialogTextToShow {
  titleWithTextBox?: string;
  titleWithoutTextBox?: string;
  textBoxPlaceholder?: string;
  content?: string;
  confirmationText?: string;
  cancelationText?: string;
}

export enum DefaultDialogTextToShow {
  titleWithTextBox = 'Why do you make this change?',
  titleWithoutTextBox = 'Confirmation',
  textBoxPlaceholder = 'write all the reasons',
  content = 'Are you sure you want to do this action?',
  rejectText = 'Why are these changes not approved?',
  editProduct = 'Why do you want to edit this product?',
  createProduct = 'Why do you want to create this product?',
  confirmationText = 'Yes',
  cancelationText = 'No'
}
