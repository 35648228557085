import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map, takeUntil } from 'rxjs/operators';
import { StorageService } from '../../../../services/storage/storage.service';
import { StoreName } from '../../../../store/definitions/store.constants';
import { AppState } from '../../../../store/states/app.state';
import * as countryActions from '../../../../store/actions/country.action';
import { combineLatest, Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';

const typeCampaign = {
  campaigns: 'campaigns',
  smartCampaign: 'smart-campaigns'
};
@Component({
  template: '',
  styles: []
})
export class TradeCampaignPermalinkComponent implements OnInit, OnDestroy {
  type: string;

  private readonly DESTROY_SUBJECT$: Subject<void> = new Subject();

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _storageService: StorageService
  ) {}

  ngOnInit(): void {
    const currentCountryCode$ = this._store.pipe(
      select(StoreName.country),
      map((country) => country.countrySelected)
    );

    this._route.url.subscribe((res: UrlSegment[]) => {
      this.type = res[0].path === 'campaigns' ? typeCampaign.campaigns : typeCampaign.smartCampaign;
    });

    const countryInRouteParams$ = this._route.params.pipe(
      filter((params: { country: string }) => !!params && !!params.country),
      map((params) => params.country.toUpperCase())
    );

    const campaignIdInRouteParams$ = this._route.params.pipe(
      filter((params: { id: string }) => !!params && !!params.id),
      map((params) => params.id)
    );

    const actionInRouteParams$ = this._route.params.pipe(
      filter((params: { action: string }) => !!params && ['detail', 'update', 'duplicate'].includes(params.action)),
      map((params) => params.action)
    );

    combineLatest([currentCountryCode$, countryInRouteParams$, campaignIdInRouteParams$, actionInRouteParams$])
      .pipe(takeUntil(this.DESTROY_SUBJECT$))
      .subscribe(([currentCountry, countryInParams, campaignIdInParams, actionInParams]) => {
        const countriesForUser = this._storageService.getCountries();
        const availableCountry = countriesForUser.find((country) => country.code.toUpperCase() === countryInParams);
        if (!!availableCountry && currentCountry !== countryInParams) {
          this._store.dispatch(countryActions.set({ payload: countryInParams }));
        }

        this._router.navigate([
          environment.modules.internalTools,
          environment.modules.tradeCore,
          this.type,
          actionInParams,
          campaignIdInParams
        ]);
      });
  }

  ngOnDestroy(): void {
    this.DESTROY_SUBJECT$.next();
  }
}
