import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { SenseComponent } from './sense/sense.component';
import { environment } from '../../environments/environment';

export const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: environment.modules.shppperInsights,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: environment.modules.senseBehaviour,
        component: SenseComponent,
        data: {
          isMfe: true,
          module: environment.modules.shppperInsights,
          hideBreadcrumbs: true,
          submodule: environment.modules.senseBehaviour,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: SenseComponent,
            data: {
              isMfe: true,
              module: environment.modules.shppperInsights,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: environment.modules.senseAudiences,
        component: SenseComponent,
        data: {
          isMfe: true,
          module: environment.modules.shppperInsights,
          hideBreadcrumbs: true,
          submodule: environment.modules.senseAudiences,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: SenseComponent,
            data: {
              isMfe: true,
              module: environment.modules.shppperInsights,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: '',
        redirectTo: environment.modules.senseBehaviour,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SenseMfeRoutingModule {}
