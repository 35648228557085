import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { VideoModalComponent } from './video-modal/video-modal.component';

@NgModule({
  declarations: [VideoModalComponent],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  providers: [TranslatePipe]
})
export class VideoModalModule { }
