<span class="label" [class.float]="value || showContent">{{ label }}</span>
<span class="single value" [class.disabled]="disabled" *ngIf="value; else contentTemplate">
  {{ value }}
</span>

<ng-template #contentTemplate>
  <div class="multiple value" [class.disabled]="disabled" #content>
    <ng-content></ng-content>
  </div>
</ng-template>
