import { Component, Input, TemplateRef, ElementRef } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() title: TemplateRef<ElementRef>;
  @Input() subtitle: TemplateRef<ElementRef>;
  @Input() error: TemplateRef<ElementRef>;
  @Input() errorWithGoogle = false;
}
