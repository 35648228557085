<one-card class="no-padding">
  <div *hasPermission="PermissionsSamplingCore.AddVan" class="content-add-head">
    <div class="add-content">
      <button (click)="assistVan()" class="rp-button rp-filled rp-primary rp-small">
        <span class="content-icon one-icon-add"></span>
        Create van
      </button>
    </div>
  </div>
  <one-dynamic-table #myTable [tableData]="tableDataSource" *ngIf="tableDataSource"></one-dynamic-table>
</one-card>

<ng-template #statusRef let-element="element">
  <div class="content-status {{ element.status }}">
    {{ element.status === status.ENABLED ? 'Active' : 'Inactive' }}
  </div>
</ng-template>

<ng-template #operationRef let-element="element">
  <div class="content-operation">
    <input
      [checked]="element.status === status.ENABLED"
      (click)="$event.preventDefault(); vanStatusChange(element)"
      type="checkbox"
      class="rp-switch"
    />
  </div>
</ng-template>

<ng-template #actionRef let-element="element">
  <div class="content-actions">
    <button
      *hasPermission="PermissionsSamplingCore.VanMenuDeleteEdit"
      mat-icon-button
      [disableRipple]="true"
      [matMenuTriggerData]="{ element: element }"
      [matMenuTriggerFor]="appMenu"
    >
      <span class="one-icon-more-vert-v2"></span>
    </button>
  </div>
</ng-template>

<mat-menu #appMenu="matMenu" class="menu-vans">
  <ng-template matMenuContent let-element="element">
    <div class="content-van-actions">
      <span
        *hasPermission="PermissionsSamplingCore.EditVan"
        class="pointer icon-menu one-icon-outline-edit"
        (click)="assistVan(element)"
      >
        Edit
      </span>

      <span
        *hasPermission="PermissionsSamplingCore.DeleteVan"
        class="pointer icon-menu one-icon-trash-outline"
        (click)="deleteVan(element)"
      >
        Delete
      </span>
    </div>
  </ng-template>
</mat-menu>
