<ng-container *ngIf="data.showTextbox">
  <div mat-dialog-title>
    <div class="title">
      <span class="label">
        {{ data.textToShow.titleWithTextBox }}
      </span>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="content">
      <form [formGroup]="confirmationForm">
        <textarea
          class="em-text-dialog"
          matInput
          [placeholder]="data.textToShow.textBoxPlaceholder"
          formControlName="description"
        ></textarea>
      </form>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!data.showTextbox">
  <div mat-dialog-title>
    <div class="title">
      <span class="label">{{ data.textToShow.titleWithoutTextBox }}</span>
      <span class="icon" mat-dialog-close>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="#0f0f24"
            fill-rule="evenodd"
            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z"
          />
        </svg>
      </span>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="content" [innerHTML]="data.textToShow.content"></div>
  </div>
</ng-container>

<div mat-dialog-actions class="actions-wrapper">
  <div class="actions-container">
    <button mat-button mat-dialog-close class="rp-button rp-filled no-background rp-small">
      <span class="label">
        {{ data.textToShow.cancelationText || 'Cancel' }}
      </span>
    </button>
    <button mat-button class="rp-button rp-filled rp-primary rp-small" (click)="shouldTriggerClose()">
      <span class="label">
        {{ data.textToShow.confirmationText || 'Save' }}
      </span>
    </button>
  </div>
</div>
