import { LandingDescriptionModule } from '../components/module-landing/models';

export const FIRST_FEATURE: LandingDescriptionModule = {
  name: 'landing.forAgencies.secondSection.content.firstSubsection.subtitle',
  description: 'landing.forAgencies.secondSection.content.firstSubsection.description',
  action: 'landing.forAgencies.secondSection.content.firstSubsection.title'
};

export const SECOND_FEATURE: LandingDescriptionModule = {
  name: 'landing.forAgencies.secondSection.content.secondSubsection.subtitle',
  description: 'landing.forAgencies.secondSection.content.secondSubsection.description',
  action: 'landing.forAgencies.secondSection.content.secondSubsection.title'
};

export const THIRD_FEATURE: LandingDescriptionModule = {
  name: 'landing.forAgencies.secondSection.content.thirdSubsection.subtitle',
  description: 'landing.forAgencies.secondSection.content.thirdSubsection.description',
  action: 'landing.forAgencies.secondSection.content.thirdSubsection.title'
};

export const FOURTH_FEATURE: LandingDescriptionModule = {
  name: 'landing.forAgencies.secondSection.content.fourthSubsection.subtitle',
  description: 'landing.forAgencies.secondSection.content.fourthSubsection.description',
  action: 'landing.forAgencies.secondSection.content.fourthSubsection.title'
};
