export enum EventCategories {
  landing = 'Landing',
  createSampling = 'Create_Sampling',
  learningHub = 'LearningHub'
}

export enum EventActions {
  login = 'Login',
  bannerClosed = 'Banner closed',
  bannerViewed = 'Banner viewed',
  demoRequestFormSubmission = 'DemoRequest form submission',
  tryForFreeFormSubmission = 'TryForFree form submission',
  contactUsFormSubmission = 'ContactUs form submission',
  clickReportView = 'Click Report_View',
  clickReportExecTab = 'Click Report_Exec tab',
  clickReportBehavTab = 'Click Report_Behav tab',
  clickGoLearningHub = 'Click Go Learning Hub'
}

export enum EventLabels {
  createSampling = 'Create_Sampling',
  loginRappi = 'Login Rappi',
  loginThirdParties = 'Login third parties',
  successfully = 'successfully',
  error = 'error'
}
