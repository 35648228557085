export const getRadsUserFromStorage = () => {
  const email = localStorage.getItem('email');
  const name = localStorage.getItem('fullName');
  const nickname = email.substr(0, email.indexOf('@'));
  const authorization = localStorage.getItem('token');

  return {
    nickname,
    name,
    authorization
  };
};
