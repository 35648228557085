import { Action, createReducer, on } from '@ngrx/store';

import { CountryState, initialState } from '../states/country.state';

import * as actions from '../actions/country.action';

const reducer = createReducer(
  initialState,
  on(actions.set, (state: CountryState, { payload }) => ({ ...state, countrySelected: payload }))
);

export const countryReducer = (state: CountryState, action: Action) => reducer(state, action);
