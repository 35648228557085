import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { AccountsService } from '../../../../services/accounts.service';
import { TierService } from '../../../../services/tier.service';
import { FiltersService } from '../../../../services/filters.service';

import { LazyScrollableDataSource } from '@rappi/common';
import { MasterDataEntity } from '../../../../../shared/enums';
import { Account, Plan, Statement } from '../../../../definitions/accounts.models';
import { COUNTRY_NAME } from '../../../../../definitions/countries.constants';
import { PLURAL_STATEMENT, SINGULAR_STATEMENT } from '../../../../definitions/account-enums';
import { AlertsService } from '../../../../../../app/services/alerts/alerts.service';

import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { CountryState } from '../../../../../store/states/country.state';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { map, skip, switchMap, takeUntil, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SelectComponent, SelectConfig } from '@rappi/ui/select';
import { Entity, Paginate, Permission } from '../../../../definitions/permissions.models';
import { ALL_CONDITION } from '../../../../definitions/permissions.constants';
import {
  AccountStatus,
  AccountStatusColor,
  AccountStatusMessage,
  PermissionAction,
  SearchCriteria
} from '../../../../definitions/permissions.enums';
import { Tier } from '../../../../definitions/tier.models';
import { FilterParams, FilterRequest, FiltersDependency } from '../../../../definitions/filters.models';
import { ACTIONS } from '../../../../definitions/actions.enums';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../../../core/confirm-modal/confirm-modal.component';
import { TypeCofirmModal } from '../../../../../core/confirm-modal/definitions/models/type-modal.model';
import { LicensesListDialogComponent } from '../licenses-list-dialog/licenses-list-dialog.component';
import { TypeModal } from '../../../../../core/confirm-modal/definitions/enums/type-modal.enum';
type ConfirmationLicensesType = [boolean, number[]];

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('currencySelect') currencySelects: QueryList<SelectComponent<Entity>>;
  @ViewChild('countriesSelect') countriesSelect: SelectComponent<Entity>;
  @ViewChildren('tierTypeSelect') tierTypeSelects: QueryList<SelectComponent<Tier>>;
  @ViewChild('citySelect') citiesSelect: SelectComponent<Entity>;
  @ViewChild('verticalSelect') verticalsSelect: SelectComponent<Entity>;
  @ViewChild('subcategorySelect') subcategoriesSelect: SelectComponent<Entity>;
  @ViewChild('makerSelect') makersSelect: SelectComponent<Entity>;
  @ViewChild('trademarkSelect') trademarksSelect: SelectComponent<Entity>;
  @ViewChild('verticalStoreSelect') verticalStoreSelect: SelectComponent<Entity>;
  @ViewChild('countriesSelectStore') countriesSelectStore: SelectComponent<Entity>;
  @ViewChild('storeSelect') storeSelect: SelectComponent<Entity>;

  readonly PAGE = 1;
  readonly LIMIT = 10;
  readonly ACTIONS = PermissionAction;

  action: string;
  form: FormGroup = new FormGroup({
    title: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
    company_id: new FormControl(null),
    plans: new FormArray([this.getPlansForm()]),
    statement: new FormGroup({
      city: new FormControl(null, Validators.required),
      vertical: new FormControl(null, Validators.required),
      subcategory: new FormControl(null, Validators.required),
      maker: new FormControl(null, Validators.required),
      trademark: new FormControl(null, Validators.required),
      store: new FormControl(null),
      verticalStore: new FormControl(null)
    })
  });
  viewUrl: string;
  tableUrl: string;
  toDay = new Date();

  plansNumber: number;

  currenciesDataSource: Entity[] = [{ name: 'USD', id: 'USD' }];

  countriesDataSource: Observable<Entity[]>;

  tierTypeDataSource = new LazyScrollableDataSource<Tier>(this.getTiers.bind(this), this.LIMIT, this.PAGE);

  citiesDataSource = new LazyScrollableDataSource<Entity>(this.getCities.bind(this), this.LIMIT, this.PAGE);

  verticalsDataSource = new LazyScrollableDataSource<Entity>(this.getVerticals.bind(this), this.LIMIT, this.PAGE);

  verticalsStoreDataSource = new LazyScrollableDataSource<Entity>(
    this.getVerticalStore.bind(this), this.LIMIT, this.PAGE
  );

  storeDataSource = new LazyScrollableDataSource<Entity>(this.getStore.bind(this), this.LIMIT, this.PAGE);


  subcategoriesDataSource = new LazyScrollableDataSource<Entity>(
    this.getSubcategoriesList.bind(this),
    this.LIMIT,
    this.PAGE
  );

  makersDataSource = new LazyScrollableDataSource<Entity>(this.getMakersList.bind(this), this.LIMIT, this.PAGE);

  trademarksDataSource = new LazyScrollableDataSource<Entity>(this.getTrademarksList.bind(this), this.LIMIT, this.PAGE);

  readonly MASTER_DATA_ENTITY = MasterDataEntity;

  readonly SELECT_CONFIG: SelectConfig = {
    includeAll: true,
    panelClass: 'one-select',
    search: true
  };
  id: number;
  tierSelected: Tier;

  readonly TIERS_TYPES_VALUES = {
    canSeeMaker: ['maker', 'internal', 'agency'],
    canSeeStore: ['stores', 'internal', 'agency']
  };

  deleteAccount: (id: number) => Observable<Permission<unknown>>;

  propagateChanges = true;

  destroySubject$: Subject<void> = new Subject();

  readonly ACCOUNT_STATUS = AccountStatus;
  readonly ACCOUNT_STATUS_COLOR = AccountStatusColor;
  readonly ACCOUNT_STATUS_MESSAGE = AccountStatusMessage;

  private readonly ACCOUNT_CREATION_MESSAGE = 'Account created successfully';
  private readonly ACCOUNT_CREATION_ERROR = 'There was an error trying to create account';

  private readonly ACCOUNT_UPDATE_MESSAGE = 'Account updated successfully';
  private readonly ACCOUNT_UPDATE_ERROR = 'There was an error trying to update account with id';

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly _accountsService: AccountsService,
    private readonly _alertsService: AlertsService,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _tierService: TierService,
    private readonly _filtersService: FiltersService,
    private readonly _dialog: MatDialog
  ) {
    const routeArr = this._router.url.split('/');

    this.id = Number(routeArr[4]);
    this.action = routeArr[3];

    routeArr.length = 3;

    this.tableUrl = routeArr.join('/');
    this.viewUrl = this.id ? `${this.tableUrl}/${PermissionAction.view}/${this.id}` : null;
    this.deleteAccount = (id: number) =>
      this._accountsService.deleteAccount(id).pipe(tap(() => this.goTo(this.tableUrl)));

    this._store
      .pipe(
        select(StoreName.country),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this.goTo(this.tableUrl))
      )
      .subscribe();
  }

  get plans(): FormArray {
    const plans = this.form.get('plans') as FormArray;
    Array.from(plans.controls).forEach((el) => el.patchValue({}));
    return plans;
  }

  get statement(): FormGroup {
    return this.form.get('statement') as FormGroup;
  }

  get maker(): FormControl {
    return this.statement.get('maker') as FormControl;
  }

  get trademark(): FormControl {
    return this.statement.get('trademark') as FormControl;
  }

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  ngAfterViewInit() {
    this.setCurrencies();
    this.countriesDataSource = this._store.pipe(
      select(StoreName.country),
      map(({ countrySelected }: CountryState) => [
        { name: COUNTRY_NAME[countrySelected] as string, id: countrySelected }
      ]),
      tap((countries: Entity[]) => {
        this.countriesSelect.setSelection(countries[0]);
        this.countriesSelectStore.setSelection(countries[0]);
      })
    );

    this._cd.detectChanges();

    if (this.action === ACTIONS.edit) {
      this._accountsService.getAccount(this.id).subscribe(
        ({ data }: Permission<Account>) => {
          this.plansNumber = data.plans.length;

          this.setPlansValues(data);

          this.setStatementsElements(data);

          this.setStoreStatements(data);

          this._cd.detectChanges();
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(message || 'There was an error trying to get account', AlertsType.error);
        }
      );
    }

    this.isCreatingPO(this.action === ACTIONS.create);

    this.getChangesEmail(this.action === ACTIONS.create);
  }

  setPlansValues(data): void {
    data.plans.forEach(({ tier_id, tier_name, expiration_status, tier_type }: Plan, i: number) => {
      if (i > 0) {
        this.addNewPO();
      }
      this.tierTypeSelects.toArray()[i].setSelection({ id: tier_id, name: tier_name, type: tier_type } as Tier);
      this.plans.controls[i].get('po_id').disable();
      this.isAvailableRangeControl(i, expiration_status);
    });
    this.form.patchValue({
      ...data,
      statement: Object.keys(data.statement).length ? this._mapStatementsValues(data.statement_values) : {}
    });
  }

  setStatementsElements(data): void {
    Object.keys(SINGULAR_STATEMENT).forEach((key: string) => {
      if (data.statement_values[key]) {
        (data.statement_values[key] as Entity[]).forEach((el: Entity, i: number) => {
          this.propagateChanges = i === (data.statement_values[key] as Entity[]).length - 1;

          if (el.id === ALL_CONDITION) {
            (this[`${key}Select`] as SelectComponent<Entity>).toggleAll();
            (this[`${key}Select`] as SelectComponent<Entity>).emitAllSelection();
          } else {
            (this[`${key}Select`] as SelectComponent<Entity>).setMultipleSelection(el);
          }
        });
      }
    });
  }

  setStoreStatements(data): void {
    const storeSelects = { store_brand: 'storeSelect', vertical: 'verticalStoreSelect' };
    Object.keys(data.store_statement_values).forEach((key: string) => {
      if (data.store_statement_values[key]) {
        data.store_statement_values[key].forEach(element => {
          if (element.id === ALL_CONDITION) {
            (this[storeSelects[key]] as SelectComponent<Entity>).toggleAll();
            (this[storeSelects[key]] as SelectComponent<Entity>).emitAllSelection();
          } else {
            (this[storeSelects[key]] as SelectComponent<Entity>).setMultipleSelection(element);
          }
        });
      }
    });
  }

  getChangesEmail(isCreate: boolean): void {
    this.email.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(() => {
      if (this.email.valid) {
        if (isCreate) {
          this.tierTypeSelects.toArray()[0].setSelection(null);
        }
        this.tierTypeDataSource.setFilterValue(null);
      }
    });
  }

  isAvailableRangeControl(i: number, status: string): void {
    if (status === this.ACCOUNT_STATUS.expired) {
      this.plans.controls[i].get('start_at').disable();
      this.plans.controls[i].get('ends_at').disable();
    } else {
      this.plans.controls[i].get('start_at').disable();
      this.plans.controls[i].get('ends_at').enable();
    }
  }

  getTiers(page: number, limit: number, name: string): Observable<Tier[]> {
    return this._tierService
      .getTiers(page, limit, name, this.email.value)
      .pipe(map(({ data: { records } }: Permission<Paginate<Tier>>) => records?.map(element=> ({...element, name: `${element.name} [${element.type}]`})) || []));
  }

  getCities(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getEntityList(page, size, name, 'city' as MasterDataEntity);
  }

  getVerticals(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.vertical);
  }

  getVerticalStore(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getStore(page, size, name, this.MASTER_DATA_ENTITY.vertical);
  }

  getSubcategoriesList(page: number, size: number, name: string): Observable<Entity[]> {
    const dependency: FiltersDependency[] = [
      {
        name: this.MASTER_DATA_ENTITY.maker,
        data: (this.maker.value || [{ id: ALL_CONDITION }]).map(({ id }: Entity) => id)
      },
      {
        name: this.MASTER_DATA_ENTITY.trademark,
        data: (this.trademark.value || [{ id: ALL_CONDITION }]).map(({ id }: Entity) => id)
      }
    ];
    const condition = this.maker.valid && this.trademark.valid;
    return condition ? this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.subcategory, dependency) : of([]);
  }

  getMakersList(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.maker);
  }

  getTrademarksList(page: number, size: number, name: string): Observable<Entity[]> {
    const dependency: FiltersDependency[] = [
      {
        name: this.MASTER_DATA_ENTITY.maker,
        data: (this.maker.value || [{ id: ALL_CONDITION }]).map(({ id }: Entity) => id)
      }
    ];
    return this.maker.valid ? this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.trademark, dependency) :
      of([]);
  }

  setPlanValue(selection: Entity | Tier, key: string, id: number, entity = null): void {
    (this.plans.controls[id] as FormGroup).patchValue({ [key]: selection });

    if (entity && selection) {
      this.tierSelected = selection as Tier;
      this.cleanFormsStoreOrMaker(this.tierSelected.type);
    }
  }

  cleanFormsStoreOrMaker(type: string): void {
    const city = this.statement.get('city');
    const vertical = this.statement.get('vertical');
    const maker = this.statement.get('maker');
    const trademark = this.statement.get('trademark');
    const subcategory = this.statement.get('subcategory');
    const verticalStore = this.statement.get('verticalStore');
    const store = this.statement.get('store');
    switch (type) {
      case 'maker':
        verticalStore.clearValidators();
        verticalStore.updateValueAndValidity();
        store.clearValidators();
        store.updateValueAndValidity();
        this.cleanAccountAssignmentControls();
        city.setValidators(Validators.required);
        city.updateValueAndValidity();
        vertical.setValidators(Validators.required);
        vertical.updateValueAndValidity();
        maker.setValidators(Validators.required);
        maker.updateValueAndValidity();
        trademark.setValidators(Validators.required);
        trademark.updateValueAndValidity();
        subcategory.setValidators(Validators.required);
        subcategory.updateValueAndValidity();
        break;
      case 'stores':
        this.cleanAccountAssignmentControls();
        city.clearValidators();
        city.updateValueAndValidity();
        vertical.clearValidators();
        vertical.updateValueAndValidity();
        maker.clearValidators();
        maker.updateValueAndValidity();
        trademark.clearValidators();
        trademark.updateValueAndValidity();
        subcategory.clearValidators();
        subcategory.updateValueAndValidity();
        verticalStore.setValidators(Validators.required);
        verticalStore.updateValueAndValidity();
        store.setValidators(Validators.required);
        store.updateValueAndValidity();
        break;
      default:
        verticalStore.setValidators(Validators.required);
        verticalStore.updateValueAndValidity();
        store.setValidators(Validators.required);
        store.updateValueAndValidity();
        this.cleanAccountAssignmentControls();
        city.setValidators(Validators.required);
        city.updateValueAndValidity();
        vertical.setValidators(Validators.required);
        vertical.updateValueAndValidity();
        maker.setValidators(Validators.required);
        maker.updateValueAndValidity();
        trademark.setValidators(Validators.required);
        trademark.updateValueAndValidity();
        subcategory.setValidators(Validators.required);
        subcategory.updateValueAndValidity();
        break;
    }
  }

  cleanAccountAssignmentControls(): void {
    this.citiesSelect.clearSelection();
    this.verticalsSelect.clearSelection();
    this.makersSelect.clearSelection();
    this.trademarksSelect.clearSelection();
    this.subcategoriesSelect.clearSelection();

    this.storeSelect.clearSelection();
    this.verticalStoreSelect.clearSelection();

    this.statement.get('city').setValue(null);
    this.statement.get('vertical').setValue(null);
    this.statement.get('maker').setValue(null);
    this.statement.get('trademark').setValue(null);
    this.statement.get('subcategory').setValue(null);

    this.statement.get('store').setValue(null);
    this.statement.get('vertical').setValue(null);
  }

  cleanStores(): void {
    this.statement.get('store').setValue(null);
    this.storeSelect.clearSelection();
  }

  evaluationFieldsRequiredMakerAndStore(isInternalOrAgency = ['internal', 'agency'].includes(this.tierSelected.type)): void {
    if (isInternalOrAgency) {
      const haveMakerFormValues = this.getHaveMakerFormValues();
      const haveStoreFormValues = this.getHaveStoreFormValues();
      this.fieldRequiredMakerForm(haveMakerFormValues, haveStoreFormValues);
      this.fieldRequiredStoreForm(haveMakerFormValues, haveStoreFormValues);
    }
  }

  getHaveMakerFormValues(form = this.statement.getRawValue()): boolean {
    return (form.city?.length || form.maker?.length || form.vertical?.length);
  }

  getHaveStoreFormValues(form = this.statement.getRawValue()): boolean {
    return (form.verticalStore?.length || form.store?.length);
  }

  fieldRequiredMakerForm(haveMakerFormValues: boolean, haveStoreFormValues: boolean): void {
    if (haveMakerFormValues) {
      const verticalStore = this.statement.get('verticalStore');
      const store = this.statement.get('store');
      const vertical = this.statement.get('vertical');
      const maker = this.statement.get('maker');
      const trademark = this.statement.get('trademark');
      const subcategory = this.statement.get('subcategory');
      this.statement.get('city').updateValueAndValidity();
      vertical.setValidators(Validators.required);
      vertical.updateValueAndValidity();
      maker.setValidators(Validators.required);
      maker.updateValueAndValidity();
      trademark.setValidators(Validators.required);
      trademark.updateValueAndValidity();
      subcategory.setValidators(Validators.required);
      subcategory.updateValueAndValidity();
      if (!haveStoreFormValues) {
        verticalStore.clearValidators();
        verticalStore.updateValueAndValidity();
        store.clearValidators();
        store.updateValueAndValidity();
      } else {
        verticalStore.setValidators(Validators.required);
        verticalStore.updateValueAndValidity();
        store.setValidators(Validators.required);
        store.updateValueAndValidity();
      }
    }
  }

  fieldRequiredStoreForm(haveMakerFormValues: boolean, haveStoreFormValues: boolean): void {
    if (haveStoreFormValues) {
      const verticalStoreControl = this.statement.get('verticalStore');
      const storeControl = this.statement.get('store');
      const cityControl = this.statement.get('city');
      const verticalControl = this.statement.get('vertical');
      const makerControl = this.statement.get('maker');
      const trademarkControl = this.statement.get('trademark');
      const subcategoryControl = this.statement.get('subcategory');
      verticalStoreControl.setValidators(Validators.required);
      verticalStoreControl.updateValueAndValidity();
      storeControl.setValidators(Validators.required);
      storeControl.updateValueAndValidity();
      if (!haveMakerFormValues) {
        cityControl.clearValidators();
        cityControl.updateValueAndValidity();
        verticalControl.clearValidators();
        verticalControl.updateValueAndValidity();
        makerControl.clearValidators();
        makerControl.updateValueAndValidity();
        trademarkControl.clearValidators();
        trademarkControl.updateValueAndValidity();
        subcategoryControl.clearValidators();
        subcategoryControl.updateValueAndValidity();
      } else {
        cityControl.setValidators(Validators.required);
        cityControl.updateValueAndValidity();
        verticalControl.setValidators(Validators.required);
        verticalControl.updateValueAndValidity();
        makerControl.setValidators(Validators.required);
        makerControl.updateValueAndValidity();
        trademarkControl.setValidators(Validators.required);
        trademarkControl.updateValueAndValidity();
        subcategoryControl.setValidators(Validators.required);
        subcategoryControl.updateValueAndValidity();
      }
    }
  }

  setStatementValue(selection: Entity[], key: string, reset?: string) {
    const allSelected = this[`${PLURAL_STATEMENT[key]}Select`].allSelected;
    const condition = (['maker', 'city', 'vertical'].includes(key)) ? allSelected && !selection.length : allSelected || !selection.length;
    this.statement.patchValue({
      [key]:
        condition ? [{ id: ALL_CONDITION }] : selection
    });

    if (reset && this.propagateChanges) {
      (this[`${reset}Select`] as SelectComponent<Entity>).clearSelection();
      setTimeout(() => {
        (this[`${reset}DataSource`] as LazyScrollableDataSource<Entity>).setFilterValue(null);
      }, 1000);
    }
  }

  goTo(url: string) {
    this._router.navigateByUrl(url);
  }

  onSubmit(f: FormGroup) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const preRequest = f.getRawValue() as Account;

    preRequest.plans.forEach((el) => {
      el.tier_id = (el.tier_id as Tier)?.id || Number(el.tier_id);
      el.po_currency = String(((el.po_currency as unknown) as Entity).id);
      el.po_value = Number(el.po_value);
      el.licenses_numbers = Number(el.licenses_numbers);
      el.start_at = new Date(el.start_at).toJSON();
      el.ends_at = new Date(el.ends_at).toJSON();
    });

    Object.keys(preRequest.statement).forEach((key) => {
      const data = (preRequest.statement[key] as Entity[]);
      if (preRequest.statement[key]) {
        preRequest.statement[key] = Array.isArray(data) ? data.map((el) => el?.id) : [data];
      }
    });

    const currentPlan: Plan = preRequest.plans[preRequest.plans.length - 2];
    const newPlan: Plan = preRequest.plans[preRequest.plans.length - 1];
    const condition = this.canShowConfirmLicenses(this.id, newPlan, currentPlan, this.plans.length, this.plansNumber);
    const preAction: Observable<ConfirmationLicensesType> =
      condition
        ? this.confirmLicenses(this.id, newPlan.licenses_numbers - 1)
        : of([true, null]);

    this.preAction(preAction, preRequest);
  }

  preAction(preAction, preRequest): void {
    const MESSAGE: string = this.id ? this.ACCOUNT_UPDATE_MESSAGE : this.ACCOUNT_CREATION_MESSAGE;
    const ERROR: string = this.id ? `${this.ACCOUNT_UPDATE_ERROR} ${this.id}` : this.ACCOUNT_CREATION_ERROR;

    preAction
      .pipe(
        switchMap(([confirmation, preLicenses]: ConfirmationLicensesType) => {
          if (confirmation) {
            const licenses = (preLicenses || []).map((id) => ({ id }));
            const body = this.getBody(this.tierSelected.type, preRequest);
            return this.id
              ? this._accountsService.editAccount(preLicenses ? { ...body, licenses } : body, this.id)
              : this._accountsService.createAccount(body);
          }

          return EMPTY;
        })
      )
      .subscribe(
        ({ data: { id } }: Permission<Account>) => {
          this._alertsService.openAlerts(MESSAGE, AlertsType.success);
          this.goTo(this.viewUrl || `${this.tableUrl}/${PermissionAction.view}/${id}`);
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(message || ERROR, AlertsType.error);
        }
      );
  }

  getBody(type, data) {
    let body;
    switch (type) {
      case 'stores':
        body = {
          store_statement: {
            vertical: data.statement.verticalStore.map(Number),
            store_brand: data.statement.store.map(Number)
          }
        };
        delete data.statement;
        Object.assign(body, data);
        break;
      case 'maker':
        delete data.statement.store;
        delete data.statement.verticalStore;
        body = data;
        break;
      default:
        body = {};
        const haveMakerFormValues = this.getHaveMakerFormValues();
        const haveStoreFormValues = this.getHaveStoreFormValues();

        if (Boolean(haveStoreFormValues)) {
          Object.assign(body, {
            store_statement: {
              vertical: data.statement.verticalStore.map(Number),
              store_brand: data.statement.store.map(Number)
            }
          });
        }

        Object.assign(body, data);
        delete body.statement.store;
        delete body.statement.verticalStore;

        if (!Boolean(haveMakerFormValues)) {
          delete body.statement;
        }
        break;
    }
    return body;
  }

  canShowConfirmLicenses(
    id: number,
    newPlan: Plan,
    currentPlan: Plan,
    currentPlansNumber: number,
    plansNumber: number
  ): boolean {
    return id &&
      newPlan &&
      newPlan.licenses_numbers < currentPlan?.licenses_numbers &&
      currentPlansNumber > plansNumber;
  }

  addNewPO() {
    this.plans.push(this.getPlansForm());
    this._cd.detectChanges();
    this.setCurrencies();
  }

  getChangesNewPO(index: number): void {
    this.plans.controls[index].get('start_at').valueChanges.pipe(takeUntil(this.destroySubject$)).subscribe(value => {
      if (value) {
        this.plans.controls[index].get('ends_at').enable();
      }
    });
  }

  isCreatingPO(isCreate: boolean) {
    if (isCreate) {
      this.getChangesNewPO(0);
    }
  }

  getPlansForm(): FormGroup {
    return new FormGroup({
      kam_email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
      licenses_numbers: new FormControl(
        1000,
        Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])
      ),
      po_currency: new FormControl(null),
      po_id: new FormControl(null, Validators.required),
      po_value: new FormControl(null, Validators.compose([Validators.min(0)])),
      tier_id: new FormControl(null, Validators.required),
      start_at: new FormControl(null, Validators.required),
      ends_at: new FormControl({ value: null, disabled: true }, Validators.required),
      id: new FormControl(null),
      expiration_status: new FormControl(null)
    });
  }

  setCurrencies() {
    Array.from(this.currencySelects).forEach((currencySelect: SelectComponent<Entity>) => {
      currencySelect.setSelection(this.currenciesDataSource[0]);
    });
  }

  confirmLicenses(accountId: number, licensesNumber: number): Observable<ConfirmationLicensesType> {
    return this._dialog
      .open(ConfirmModalComponent, {
        maxWidth: '576px',
        data: {
          type: TypeModal.confirm,
          title: 'Some licenses will be eliminated',
          message: `
            Some licenses associated to this Account will be eliminated due to the current changes, do you still want
            to continue?
          `,
          textReject: 'Continue',
          textSucces: 'Cancel'
        } as TypeCofirmModal
      })
      .afterClosed()
      .pipe(
        switchMap((confirm: boolean) => {
          if (confirm === false) {
            return this._dialog
              .open(LicensesListDialogComponent, {
                data: { id: accountId, licensesNumber },
                disableClose: true,
                maxWidth: '572px',
                width: '100%'
              })
              .afterClosed()
              .pipe(
                map((licenses: number[]) => (licenses ? [true, licenses] : [false, null]))
              ) as Observable<ConfirmationLicensesType>;
          }

          if (confirm === true || confirm === null) {
            return of([false, null]) as Observable<ConfirmationLicensesType>;
          }
        })
      );
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  getStore(page: number, size: number, name: string): Observable<Entity[]> {
    const vertical = this.statement.get('verticalStore').value;
    const dependency = {
      vertical: Array.isArray(vertical) ? vertical.map(element => element.id) : [-1]
    };
    return this._getStore(page, size, name, this.MASTER_DATA_ENTITY.store_brand, dependency);
  }

  private _mapStatementsValues(statement: Statement): { [key: string]: Entity[] | { id: number }[] } {
    return {
      city: statement.cities[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.cities,
      vertical: statement.verticals[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.verticals,
      maker: statement.makers[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.makers,
      trademark: statement.trademarks[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.trademarks,
      subcategory: statement.subcategories[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.subcategories
    };
  }

  private _getStore(
    page: number,
    limit: number,
    search: string,
    entity: MasterDataEntity,
    dependency = null
  ): Observable<Entity[]> {
    const params: FilterParams = { page, limit, search_criteria: SearchCriteria.name, search_value: search || '' };
    const body: FilterRequest = { entity, module: 'permissions' };
    if (dependency) {
      body.dependency = dependency;
    }

    return this._filtersService
      .getStores(body, params)
      .pipe(map(({ data: { records } }: Permission<Paginate<Entity>>) => records || []));
  }

  private _getEntityList(page: number, limit: number, search: string, entity: MasterDataEntity,
    dependency?: FiltersDependency[]
  ): Observable<Entity[]> {
    const params: FilterParams = { page, limit, search_criteria: SearchCriteria.name, search_value: search || '' };
    const body: FilterRequest = { entity, dependency, module: 'permissions' };

    return this._filtersService
      .getEntities(body, params)
      .pipe(map(({ data: { records } }: Permission<Paginate<Entity>>) => records || []));
  }
}
