import { Component, ElementRef, Inject, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { RouteActions, TitleBarActionsService, TitleBarRouteData, TitleBarService } from '@rappi/ui/title-bar';
import { filter } from 'rxjs/operators';
import { ExtendedRouteActions } from '../definitions/route-actions.enum';
import { HasPermissionsService } from '../../../shared/services/has-permissions/has-permissions.service';

@Component({
  selector: 'app-title-section',
  templateUrl: './title-section.component.html',
  styleUrls: ['./title-section.component.scss']
})
export class TitleSectionComponent implements OnInit {
  @ViewChild('refContentDownload', { static: false })
  refContentDownload: ElementRef;
  @Input() hideActions = false;
  @Input() useSubtitle = false;
  @Input() showDescription = false;
  title = '';
  description = '';
  actions = [];
  action: ExtendedRouteActions | RouteActions;
  actionCases = { ...ExtendedRouteActions, ...RouteActions };
  fileName = '';
  fileUrl = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  route: Array<any>;
  extras: NavigationExtras;
  actionText: string;
  requestMethod = '';
  requestBody = null;
  postMethod = 'POST';
  disabled = false;
  btnText: string;

  constructor(
    public readonly titleBarService: TitleBarService,
    @Inject('TitleBarActionsService') public actionsService: TitleBarActionsService,
    public readonly dialog: MatDialog,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _hasPermission: HasPermissionsService
  ) {}

  actionFunction = (data?: TitleBarRouteData) => {};

  ngOnInit(): void {
    this.titleBarService.routeData.subscribe((data: TitleBarRouteData) => {
      this.disabled = data.disabled;
      this.title = this.useSubtitle ? data.subtitle : data.title;
      this.description = data.description;
      this.actionsHandler(data);
    });
  }

  actionsHandler(data: TitleBarRouteData) {
    if (!this.hideActions) {
      if(data.actionsPermissions){
        setTimeout(() => {
          if(this._hasPermission.checkPermission(data.actionsPermissions)){
            this.actionAccess(data);
          }else{
            this.actions = null;
          }
        }, 500);
      }else{
        this.actionAccess(data);
      }
    }
  }

  actionAccess(data: TitleBarRouteData){
    this.actions = data.actions;
    this.action = this.actions?.length ? this.actions[0] : null;
    this.setDownloadAction(data);
    this.setLinkAction(data);
    this.setQuitAction(data);
  }

  setLinkAction(data: TitleBarRouteData) {
    if (this.action === RouteActions.link || this.action === RouteActions.report) {
      this.route = data.route;
      this.extras = data.extras;
      this.btnText = data.linkText || (this.action === RouteActions.link ? 'Create' : 'View Report');
      this.actionFunction = data.modalComponent ? () => this.openModal(data) : this.goToUrl;
    } else {
      this.route = [];
      this.extras = null;
    }
  }

  setDownloadAction(data: TitleBarRouteData) {
    if (this.action === RouteActions.download) {
      this.fileName = data.fileName;
      this.fileUrl = data.fileUrl;
      this.requestMethod = data.requestMethod || this.postMethod;
      this.requestBody = data.requestBody || null;
      this.btnText = data.downloadText || 'Data Export';
      this.actionFunction = this.downloadData;
    } else {
      this.fileName = '';
      this.fileUrl = '';
      this.requestMethod = '';
      this.requestBody = null;
    }
  }

  setQuitAction(data: TitleBarRouteData) {
    if (this.action === ExtendedRouteActions.quit) {
      this.btnText = data.linkText || 'Quit';
      this.actionFunction = () => this.openModal(data);
    }
  }

  goToUrl() {
    this.actionsService.goToUrl(this.route, this.extras);
  }

  openModal(data: TitleBarRouteData) {
    const dialogRef = this.dialog.open(data.modalComponent, {
      id: data.title,
      data: data.modalData,
      width: '576px'
    });

    dialogRef
      .afterClosed()
      .pipe(filter((result: string) => Boolean(result)))
      .subscribe((result: string) => {
        this.route = [result] || this.route;
        this.extras = { relativeTo: this._activatedRoute };
        this.goToUrl();
      });
  }

  downloadData() {
    this.actionsService.downloadFile(
      this.fileName,
      this.fileUrl,
      this.requestMethod,
      this.requestBody,
      this.refContentDownload
    );
  }
}
