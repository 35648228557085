<i class="close-btn one-icon-times" [mat-dialog-close]="true" aria-hidden="true"></i>
<div class="content-video">
  <iframe
    width="100%"
    height="100%"
    [src]="safeSrc"
    title="Video player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>
