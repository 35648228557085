<app-hero-section
  [watermark]="WATERMARK"
  [title]="HERO_TITLE"
  [description]="HERO_DESCRIPTION"
  [videoUrl]="HERO_VIDEO_URL"
  [floatingImage]="FLOATING_IMAGE"
  [backgroundImage]="BACKGROUND_VIDEO"
></app-hero-section>

<app-section [title]="TITLE" [description]="DESCRIPTION"></app-section>

<app-module-landing
  [module]="FIRST_FEATURE"
>
  <img
    class="floating-img first-floating-image"
    loading="lazy" appParallaxContent
    [src]="FIRST_FEATURE_IMAGE"
    alt="decorative image"
  />
  <video
    class="first-video"
    loop [autoplay]="true" [muted]="true" playsinline>
    <source [src]="FIRST_FEATURE_VIDEO" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</app-module-landing>

<app-module-landing
  [module]="SECOND_FEATURE"
  [reverse]="true"
>
  <img class="floating-img second-floating-image" loading="lazy" appParallaxContent [src]="SECOND_FEATURE_IMAGE" alt="decorative image" />
  <video class="second-video" loop [autoplay]="true" [muted]="true" playsinline>
    <source [src]="SECOND_FEATURE_VIDEO" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</app-module-landing>

<app-module-landing
  [module]="THIRD_FEATURE"
>
  <img class="floating-img third-floating-image" loading="lazy" appParallaxContent [src]="THIRD_FEATURE_IMAGE" alt="decorative image" />
  <video class="third-video" loop [autoplay]="true" [muted]="true" playsinline>
    <source [src]="THIRD_FEATURE_VIDEO" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</app-module-landing>

<app-module-landing
  [module]="FOURTH_FEATURE"
  [reverse]="true"
>
  <img class="floating-img fourth-floating-image" loading="lazy" appParallaxContent [src]="FOURTH_FEATURE_IMAGE" alt="decorative image" />
  <video class="fourth-video" loop [autoplay]="true" [muted]="true" playsinline>
    <source [src]="FOURTH_FEATURE_VIDEO" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</app-module-landing>

<app-section [title]="BRANDS_EXAMPLE_TITLE" [showDescription]="false"></app-section>

<app-brands-example [imageToShow]="BRANDS_EXAMPLE_IMAGE"></app-brands-example>

<app-start-using-banner
  [title]="START_USING_BANNER_TITLE"
  [description]="START_USING_BANNER_DESCRIPTION"
></app-start-using-banner>
