import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomTooltipModule } from '@rappi/ui/custom-tooltip';
import { TitleSectionComponent } from './title-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TitleSectionComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CustomTooltipModule],
  exports: [TitleSectionComponent]
})
export class TitleSectionComponentModule {}
