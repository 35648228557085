import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

export enum DirectionParallax {
  INITIAL_UP = -1,
  INITIAL_DOWN = 1
}

@Directive({
  selector: '[appParallaxContent]'
})
export class ParallaxContentDirective implements AfterViewInit {
  @Input() delay = 0.2;
  @Input() direction: DirectionParallax = DirectionParallax.INITIAL_UP;
  @Input() parallaxArea = 100;

  private _styleRef: CSSStyleDeclaration;

  constructor(private readonly _eleRef: ElementRef<HTMLElement>) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    const topElement = this._eleRef.nativeElement.getBoundingClientRect().top;
    const viewPortHeight = window.innerHeight;

    if (topElement < viewPortHeight) {
      const movementPercentage = ((viewPortHeight - topElement) * 100) / viewPortHeight;
      const transferDifference = (this.parallaxArea * movementPercentage) / 100;
      const newPosition = this.direction * (this.parallaxArea / 2 - transferDifference);

      this._styleRef.transform = `translateY(${newPosition}px)`;
    }
  }

  ngAfterViewInit(): void {
    this._styleRef = this._eleRef.nativeElement.style;
    this._styleRef.transition = `transform ${this.delay}s linear 0s`;
    this._styleRef.transform = `translateY(${(this.direction * this.parallaxArea) / 2}px)`;
  }

  get styleRef(): CSSStyleDeclaration {
    return this._styleRef;
  }
}
