<one-card>
  <div class="title" one-card-title>Change Password</div>
  <form one-card-content [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="one-input-wrapper">
      <one-input
        [type]="formFields['current_password'].type"
        label="Current Password"
        [formControlName]="'current_password'"
        appFormErrors
      >
        <i
          [class]="formFields['current_password'].icon"
          oneSuffix
          (click)="showPassword('current_password')"
          aria-hidden="true"
        ></i>
      </one-input>
    </div>
    <div class="forgot-password">
      <a routerLink="/reset-password">Forgot password?</a>
    </div>
    <div class="password-security">
      <h5>Password Security</h5>
      <p>
        {{ regexErrorMessage }}
      </p>
    </div>

    <div class="one-input-wrapper">
      <one-input
        [type]="formFields['new_password'].type"
        label="New Password"
        [formControlName]="'new_password'"
        appFormErrors
      >
        <i
          [class]="formFields['new_password'].icon"
          oneSuffix
          (click)="showPassword('new_password')"
          aria-hidden="true"
        ></i>
      </one-input>
    </div>

    <div class="one-input-wrapper">
      <one-input
        [type]="formFields['confirm_password'].type"
        label="Confirm Password"
        [formControlName]="'confirm_password'"
        appFormErrors
      >
        <i
          [class]="formFields['confirm_password'].icon"
          oneSuffix
          (click)="showPassword('confirm_password')"
          aria-hidden="true"
        ></i>
      </one-input>
    </div>

    <div one-card-footer>
      <button class="rp-button rp-filled rp-raisable brands-primary-button rp-big" [disabled]="form.invalid">
        Change Password
      </button>
    </div>
  </form>
</one-card>
