import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../../services/alerts/alerts.service';

import { DeletePermission } from '../../utils/delete';

import { Observable } from 'rxjs';
import { Permission } from '../../definitions/permissions.models';

@Component({
  selector: 'app-table-menu-trigger',
  templateUrl: './table-menu-trigger.component.html',
  styleUrls: ['./table-menu-trigger.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableMenuTriggerComponent implements OnInit {
  @Input() id: number;
  @Input() type: string;
  @Input() service: (id: number) => Observable<Permission<unknown>>;
  @Input() alertMessage: string;

  @Input() isAdmin$: Observable<boolean>;
  @Input() action: () => void;
  @Input() confirmAccount: () => void;
  @Input() sendEmail: () => void;
  @Input() resetPassword: () => void;

  deletePermission: DeletePermission;

  constructor(private readonly _dialog: MatDialog, private readonly _alertService: AlertsService) {}

  ngOnInit(): void {
    this.deletePermission = new DeletePermission(this._dialog, this._alertService, this.service);
    this.setMessage();
  }

  setMessage() {
    if (this.alertMessage) {
      this.deletePermission.alertMessage = this.alertMessage;
    }
  }
}
