import { Component, ViewEncapsulation, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FULL_PATHS } from '../definitions/landing.constant';
import { ProductDetailData } from './product-detail.model';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDetailComponent {

  readonly FULL_PATHS = FULL_PATHS;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProductDetailData) { }

}
