import { createReducer, on, Action } from '@ngrx/store';

import { AccountState, initialState } from '../states/account.state';

import * as actions from '../actions/account.actions';

export const reducer = createReducer(
  initialState,
  on(actions.set, (state: AccountState, { payload }) => ({ ...state, ...payload }))
);

export const accountReducer = (state: AccountState | undefined, action: Action): AccountState => reducer(state, action);
