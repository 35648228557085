import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  dispatchOrchestratorRoutingEvent,
  initMfe,
  killMfe,
  MfeAttribute,
  MfeEvent,
  MfeEventType,
  MfeFileSource,
  MfeSourceType,
  setMfeAttribute,
  setMfeAttributes,
  setMfeEvents
} from '@rappi/rappi-mfe-tools/lib';

import { MfeSourceLoaderService } from '../../services';
import { Subject } from 'rxjs';
import { routingEvent } from '../../utils';
import { select, Store } from '@ngrx/store';
import { StoreName } from '../../../store/definitions/store.constants';
import { takeUntil } from 'rxjs/operators';
import { CountryState } from '../../../store/states/country.state';
import { Language } from '../../../shared/enums';
import { UserState } from '../../../store/states/user.state';
import { EncryptService } from '../../../services/encrypt/encrypt.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AppState } from '../../../store/states/app.state';
import { TranslateApplicationService } from '../../../services/translate/translate-application.service';
import { STORAGE_KEY } from '../../../definitions/app.constants';
import { getRadsUserFromStorage } from '../../../trade-core/utils/utils.constant';
import { PrefixBreadcrumb } from '../../definitions/mfe.interface';

@Component({
  template: ''
})
export abstract class MicroFrontendWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  public elContainerName: string;
  public loading = false;
  public url: string;
  public key: string;
  public prefixBreadcrumb: PrefixBreadcrumb;
  public destroySubject$: Subject<void> = new Subject();

  protected element: Element;

  /**
   * The name of the dom element to be created containing the mfe web component
   */
  protected abstract elName: string;

  /**
   * The list of the sources to be loaded, this list usually contains the mfe js and css files
   *
   * eg:
   * [
   *  {
   *    name: 'rads',
   *    src: 'http://localhost:5003/static/js/main.js',
   *    type: SourceType.js
   *  },
   * {
   *    name: 'rads-css',
   *    src: 'http://localhost:5003/static/css/main.css',
   *    type: SourceType.css
   *  }
   * ]
   */
  protected abstract mfeSources: MfeFileSource[];

  /**
   * The list of the the attributes to be added to the mfe root element
   *
   * eg:
   * [
   *  {
   *    key: 'user',
   *    value: JSON.stringify(radsUser)
   *  },
   *  {
   *    key: 'country',
   *    value: radsUser.country.toLocaleLowerCase()
   *  }
   * ]
   */
  protected abstract mfeAttributes: MfeAttribute[];

  /**
   * The list of the the events to be added to the mfe root element
   *
   * eg:
   * [
   *  {
        type: MfeEventType.OrchestratorRoutingEvent,
        handler: (event) => handleEvent(event)
      }
   * ]
   */
  protected abstract mfeEvents: MfeEvent[];

  protected constructor(
    public readonly sourceLoader: MfeSourceLoaderService,
    private readonly _cd: ChangeDetectorRef,
    private readonly _encryptService: EncryptService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _store: Store<AppState>,
    private readonly _translateApplicationService: TranslateApplicationService
  ) {}

  ngOnInit(): void {
    this.elContainerName = `${this.elName}-container`;

    const timeStamp = new Date().getUTCMilliseconds();

    if(!this.key) {
      this.key = this.elName;
    }

    this.mfeSources = [
      {
        name: `${this.elName}-js`,
        src: `${this.url}/main.js?id=${timeStamp}`,
        type: MfeSourceType.js
      },
      {
        name: `${this.elName}-css`,
        src: `${this.url}/styles.css?id=${timeStamp}`,
        type: MfeSourceType.css
      }
    ];
    this.mfeAttributes = [
      {
        key: 'submodule',
        value: this._encryptService.encryptText(this._activatedRoute.snapshot.data['submodule'], this.key)
      }
    ];
    this.mfeEvents = [
      {
        type: MfeEventType.OrchestratorRoutingEvent,
        listener: routingEvent(this._router)
      }
    ];

    this._store.pipe(select(StoreName.country), takeUntil(this.destroySubject$)).subscribe((state: CountryState) => {
      const countryAttribute = {
        key: StoreName.country,
        value: this._encryptService.encryptText(state.countrySelected.toLocaleLowerCase(), this.key)
      };
      this.updateMfeAttributes({ ...countryAttribute });
      setMfeAttribute(this.elName, { ...countryAttribute });
    });

    this._translateApplicationService.language$.pipe(takeUntil(this.destroySubject$)).subscribe((lang: Language) => {
      const languageAttribute = {
        key: 'language',
        value: this._encryptService.encryptText(lang, this.key)
      };
      this.updateMfeAttributes({ ...languageAttribute });
      setMfeAttribute(this.elName, { ...languageAttribute });
    });

    this._store.pipe(select(StoreName.user), takeUntil(this.destroySubject$)).subscribe((state: UserState) => {
      const senseUser = {
        // Sense/Analyze/Autoship:
        token: localStorage.getItem(STORAGE_KEY.token),
        tier_name: state.tier_name,
        user_type: state.user_type,
        modules: state.modules || [],
        kam_email: state.kam_email,
        // Trade:
        ...getRadsUserFromStorage(),
        actions: { ...state }
      };
      const userAttribute = {
        key: StoreName.user,
        value: this._encryptService.encryptText(JSON.stringify(senseUser), this.key)
      };
      this.updateMfeAttributes({ ...userAttribute });
      setMfeAttribute(this.elName, { ...userAttribute });
    });

    this._router.events.pipe(takeUntil(this.destroySubject$)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        const moduleAttribute = {
          key: 'submodule',
          value: this._encryptService.encryptText(this._activatedRoute.snapshot.data['submodule'], this.key)
        };
        this.updateMfeAttributes({ ...moduleAttribute });
        setMfeAttribute(this.elName, { ...moduleAttribute });
      }
    });

    if(this.prefixBreadcrumb){
      const breadcrumbAttribute = {
        key: 'prefixBreadcrumb',
        value: this._encryptService.encryptText(this.prefixBreadcrumb.label, this.key)
      };
      this.updateMfeAttributes({ ...breadcrumbAttribute });
      setMfeAttribute(this.elName, { ...breadcrumbAttribute });
    }

    this.sourceLoader.redirectMfeRoute.pipe(takeUntil(this.destroySubject$)).subscribe(event => {
      this.sendRedirectEvent(event.module, event.path);
    });
  }

  sendRedirectEvent(module: string, path: string): void {
    const redirectMfeRouteAttribute = {
      key: 'redirect',
      value: this._encryptService.encryptObject({ module, path }, this.key)
    };
    this.updateMfeAttributes({ ...redirectMfeRouteAttribute });
    setMfeAttribute(this.elName, { ...redirectMfeRouteAttribute });
  }

  ngAfterViewInit(): void {
    this.loading = true;
    this._cd.detectChanges();

    this.sourceLoader.load(this.mfeSources, this.elContainerName).subscribe(
      () => {
        this.element = initMfe(this.elName, this.elContainerName);
        if (this.mfeAttributes) {
          setMfeAttributes(this.elName, this.mfeAttributes);
        }
        if (this.mfeEvents) {
          setMfeEvents(this.elName, this.mfeEvents);

          if(this.prefixBreadcrumb){
            this.mfeOutput();
          }
        }
      },
      (error) => console.log(error),
      () => {
        this.loading = false;
      }
    );
  }

  mfeOutput(): void {
    const element = document.getElementsByTagName(this.elName)[0];
    element.addEventListener(`breadcrumbEvent`, this.breadcrumbEvent.bind(this));
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();

    if (this.mfeEvents) {
      killMfe(this.elName, this.mfeEvents);
    }
  }

  private readonly breadcrumbEvent = () => {
    this._router.navigate([this.prefixBreadcrumb.navigate]);
  };

  private updateMfeAttributes(attribute: MfeAttribute): void {
    this.mfeAttributes = [...this.mfeAttributes.filter((mfea) => mfea.key !== attribute.key), attribute];
  }
}
