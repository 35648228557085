<button matDialogClose class="close-modal one-icon-times"></button>
<div class="content-image"></div>
<div mat-dialog-content class="content-form">
  <div class="content-header">
    <span class="question-title">{{ 'landing.contact.title' | translate }}</span>
    <span class="principal-title">{{ 'landing.contactUs' | translate }}</span>
    <span class="sub-title">{{ 'landing.contact.subtitle' | translate }}</span>
  </div>
  <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="sendForm()">
    <one-input
      formControlName="first_name"
      [label]="'formValues.name' | translate"
      type="text"
      aria-autocomplete="off"
    ></one-input>

    <div class="fields-in-line">
      <one-input
      type="email"
      formControlName="email"
      [label]="'formValues.workEmail' | translate"
      aria-autocomplete="off"
    ></one-input>
    <one-input
      formControlName="company"
      [label]="'formValues.company' | translate"
      aria-autocomplete="off"
    ></one-input>
    </div>
    <app-input-cellphone [formControl]="contactForm.get('phone')"></app-input-cellphone>
    <div class="input-use">
      <input formControlName="brands_users" type="checkbox" class="rp-checkbox" />
      <span>{{ 'landing.contact.checkboxMessage' | translate }}</span>
    </div>
    <textarea
      formControlName="description"
      [placeholder]="'landing.contact.textAreaMessage' | translate"
      class="input-want"
    ></textarea>
    <button type="submit" class="btn primary big">{{ 'landing.contactUs' | translate }}</button>
  </form>
</div>
