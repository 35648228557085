import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { FULL_PATHS } from '../definitions/landing.constant';

import { AuthService } from '../../../services/auth/auth.service';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  emailSend = false;

  readonly FULL_PATHS = FULL_PATHS;

  resetPasswordForm: FormGroup;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _authService: AuthService,
    private readonly _cd: ChangeDetectorRef,
    private readonly _alertsService: AlertsService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.resetPasswordForm = this._formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  get email(): AbstractControl {
    return this.resetPasswordForm.get('email');
  }

  onSubmit(f: FormGroup) {
    if (!f.valid) {
      return;
    }

    this._authService.forgot(f.getRawValue().email).subscribe((response: { message: string }) => {
      this.emailSend = true;
      this._cd.detectChanges();
      this._alertsService.openAlerts(response.message, AlertsType.success);
    });
  }
}
