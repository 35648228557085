import { Component, Input } from '@angular/core';
import { MODULES_ICON } from '../../definitions/table-modules.enums';
import { TableModules } from '../../definitions/table-modules.models';

@Component({
  selector: 'app-table-modules',
  templateUrl: './table-modules.component.html',
  styleUrls: ['./table-modules.component.scss']
})
export class TableModulesComponent {
  @Input() tableModules: TableModules[];

  readonly MODULES_ICON = MODULES_ICON;
}
