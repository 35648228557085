<div mat-dialog-title>
  <div class="title-content">
    <h1 class="title-modal">{{ titleText }} Warehouse</h1>
    <span mat-dialog-close class="one-icon-close-material-v2 close-icon"></span>
  </div>
</div>

<div class="content-dialog" mat-dialog-content>
  <form class="content-form" id="myForm" [formGroup]="warehouseForm" (ngSubmit)="submit()">
    <div class="content-in-form">
      <div class="section-content content-left">
        <div class="field-content">
          <span class="require-message">*All fields are required</span>
        </div>
        <div class="field-content">
          <one-input formControlName="name" appFormErrors type="text" [label]="labelsName.name"></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="city" appFormErrors type="text" [label]="labelsName.city"></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="address" appFormErrors type="text" [label]="labelsName.address"></one-input>
        </div>
        <div class="field-content">
          <one-input formControlName="code" appFormErrors type="number" [label]="labelsName.code"></one-input>
        </div>
        <div class="content-location">
          <div class="field-content">
            <one-input
              formControlName="location_lat"
              appFormErrors
              type="number"
              [label]="labelsName.location_lat"
            ></one-input>
          </div>
          <div class="field-content">
            <one-input
              formControlName="location_lng"
              appFormErrors
              type="number"
              [label]="labelsName.location_lng"
            ></one-input>
          </div>
        </div>

        <div class="field-content">
          <one-input
            formControlName="manager_id"
            type="number"
            [label]="labelsName.manager_id"
            appFormErrors
          ></one-input>
        </div>

        <div class="section-toggle">
          <input formControlName="status" type="checkbox" class="rp-switch" />
          <span>Active</span>
        </div>
      </div>
      <section class="section-content section-map">
        <google-map width="100%" height="100%" [center]="markerPosition" (mapClick)="addMarker($event)">
          <map-marker *ngIf="markerPosition" [position]="markerPosition"> </map-marker>
        </google-map>
      </section>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="content-buttons">
    <button class="rp-button rp-outlined rp-primary rp-small" mat-dialog-close>Cancel</button>
    <button
      [disabled]="loading || warehouseForm.invalid"
      form="myForm"
      type="submit"
      class="rp-button rp-filled rp-primary rp-small"
    >
      {{ buttonText }}
    </button>
  </div>
</div>
