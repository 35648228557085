<one-card class="no-padding">
  <div *hasPermission="PermissionsSamplingCore.AddWarehouse" class="content-add-head">
    <div class="add-content">
      <button (click)="assistWarehouse()" class="rp-button rp-filled rp-primary rp-small">
        <span class="content-icon one-icon-add"></span>
        Create warehouse
      </button>
    </div>
  </div>
  <one-dynamic-table #myTable [tableData]="tableDataSource" *ngIf="tableDataSource"></one-dynamic-table>
</one-card>

<ng-template #statusRef let-element="element">
  <div class="content-status {{ element.status }}">
    {{ element.status === status.ENABLED ? 'Active' : 'Inactive' }}
  </div>
</ng-template>

<ng-template #actionRef let-element="element">
  <div class="content-actions">
    <span
      class="pointer icon-size one-icon-outline-truck"
      appCustomTooltip="View vans"
      tooltipPosition="top"
      (click)="setWarehouse(element)"
      [routerLink]="['./' + element.id + '/vans']"
    ></span>

    <button
      *hasPermission="PermissionsSamplingCore.WarehouseMenuDeleteEdit"
      mat-icon-button
      [disableRipple]="true"
      [matMenuTriggerData]="{ element: element }"
      [matMenuTriggerFor]="appMenu"
    >
      <span class="one-icon-more-vert-v2"></span>
    </button>
  </div>
</ng-template>

<mat-menu #appMenu="matMenu" class="menu-warehouse">
  <ng-template matMenuContent let-element="element">
    <div class="content-warehouse-actions">
      <span
        *hasPermission="PermissionsSamplingCore.EditWarehouse"
        class="pointer icon-menu one-icon-outline-edit"
        (click)="assistWarehouse(element)"
      >
        Edit
      </span>

      <span
        *hasPermission="PermissionsSamplingCore.DeleteWarehouse"
        class="pointer icon-menu one-icon-trash-outline"
        (click)="deleteWarehouse(element)"
      >
        Delete
      </span>
    </div>
  </ng-template>
</mat-menu>
