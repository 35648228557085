import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { AmplifyComponent } from './amplify/amplify.component';
import { RequestHubConfigComponent } from './request-hub-config/request-hub-config.component';

const SETTINGS_MODULE = `${environment.modules.internalTools}/${environment.modules.settings}`;
const BASE_SUBMODULE_REQUEST_HUB = `${SETTINGS_MODULE}/${environment.modules.requestHubMfe}`;
const BASE_SUBMODULE_AMPLIFY = `${SETTINGS_MODULE}/${environment.modules.amplify}`;

export const routes: Routes = [
  {
    path: environment.modules.amplify,
    data: {
      isMfe: true,
      module: BASE_SUBMODULE_AMPLIFY,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: '**',
        component: AmplifyComponent,
        data: {
          isMfe: true,
          module: BASE_SUBMODULE_AMPLIFY,
          hideBreadcrumbs: true,
          submodule: 'campaigns',
          reuse: true
        },
      }
    ]
  },
  {
    path: environment.modules.requestHubMfe,
    data: {
      isMfe: true,
      module: BASE_SUBMODULE_REQUEST_HUB,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: '**',
        component: RequestHubConfigComponent,
        data: {
          isMfe: true,
          module: BASE_SUBMODULE_REQUEST_HUB,
          hideBreadcrumbs: true,
          submodule: 'campaigns',
          reuse: true
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsMfeRoutingModule { }
