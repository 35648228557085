import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlertsComponent, AlertsType } from '@rappi/ui/alerts';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  configAlerts: MatSnackBarConfig = {
    panelClass: '',
    duration: 4000,
    horizontalPosition: 'end',
    verticalPosition: 'top'
  };

  constructor(private readonly _snackBar: MatSnackBar) {}

  openAlerts(message: string, type?: AlertsType, configAlerts = this.configAlerts) {
    this._snackBar.openFromComponent(AlertsComponent, {
      data: {
        message,
        type
      },
      ...configAlerts
    });
  }
}
