<ng-template #title>Forgot your <span class="rp-text-primary">password?</span></ng-template>
<ng-template #subtitle>
  <ng-container *ngIf="!emailSend; else messageEmailSended">
    Don’t worry! To reset your password, enter the email adress you use to login to Brands By Rappi.
  </ng-container>
  <ng-template #messageEmailSended>
    A link will be emailed to this address which let you reset your password.
  </ng-template>
</ng-template>
<app-card [title]="title" [subtitle]="subtitle">
  <ng-container *ngIf="!emailSend">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit(resetPasswordForm)" novalidate>
      <div>
        <one-input label="Email Address" type="email" [formControl]="email" appFormErrors></one-input>
      </div>
      <button
        class="rp-button rp-filled rp-raisable brands-primary-button rp-big"
        [disabled]="!resetPasswordForm?.valid"
      >
        Send
      </button>
    </form>
  </ng-container>
</app-card>

<ng-container *ngIf="emailSend">
  <button
    class="trimmed-button rp-button rp-filled rp-raisable brands-primary-button rp-big"
    [routerLink]="FULL_PATHS.login"
  >
    Login
  </button>
</ng-container>
