<div mat-dialog-title>
  <div class="title-content">
    <h1 class="title-modal">{{ isEdit ? 'Edit' : 'New' }} price</h1>
    <button [mat-dialog-close]="needReloadList" class="close-btn one-icon-close-material-v2"></button>
  </div>
  <mat-progress-bar [class.hidden-loader]="!loading" mode="indeterminate" class="rp-loading-bar"></mat-progress-bar>
</div>

<div mat-dialog-content>
  <form [formGroup]="formPrice" class="body-container">
    <div
      *ngIf="isEdit || formPrice.errors?.duplicateError"
      [class.edit-message]="isEdit"
      class="error-message-top one-icon-warning-v2"
    >
      {{
        isEdit
          ? 'Campaigns already created will not be affected by price changes'
          : 'There is an overlap with this impact range in some of the cities selected'
      }}
    </div>

    <one-select
      #selectTypePrice
      label="Product type"
      [config]="filterSelectConfig"
      [dataSource]="productTypeList"
      (selectionChanged)="formPrice.patchValue({ product_type: $event.id })"
      [multiple]="false"
      [disabled]="formPrice.controls.product_type.disabled"
    ></one-select>
    <one-select
      #selectCitiesPrice
      label="Cities"
      multiple="true"
      [class.error-duplicate]="formPrice.errors?.duplicateError"
      [config]="filterSelectConfig"
      [dataSource]="citiesDataSource"
      (selectionChanged)="formPrice.patchValue({ cities: $event.map(mapCities) })"
    ></one-select>
    <div class="range-container">
      <h4>
        Impact range
        <span class="range-container__icon one-icon-info-material-v2" appCustomTooltip [contentTemplate]="tooltipRef">
        </span>
      </h4>
      <div [class.error-duplicate]="formPrice.errors?.duplicateError" class="fields-range">
        <div [class.invalid-range-error]="controlMin.touched && controlMin.invalid" class="field-content">
          <one-input
            class="custom-error-input"
            label="Minimum"
            type="number"
            formControlName="impact_range_min"
          ></one-input>
          <span class="error-message">*Invalid, do not use negative numbers</span>
        </div>
        -
        <div [class.invalid-range-error]="controlMax.touched && controlMax.invalid" class="field-content">
          <one-input
            class="custom-error-input"
            label="Maximum"
            type="number"
            formControlName="impact_range_max"
          ></one-input>
          <span class="error-message">*This value must be higher than minimum</span>
        </div>
      </div>
    </div>

    <div class="price-content">
      <h4>Price per impact</h4>
      <div class="field-content">
        <one-input
          label="USD"
          formControlName="price"
          type="number"
          appFormErrors
          [customFormatErrors]="nameErrorKey"
          [customFormatFunction]="errorMessageBind"
        ></one-input>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="content-actions">
    <button
      (click)="isEdit ? closeModal() : save(true)"
      [disabled]="loading || formPrice.invalid"
      class="rp-button rp-primary rp-outlined rp-small"
    >
      {{ isEdit ? 'Cancel' : 'Save and close' }}
    </button>

    <button
      (click)="save(false)"
      [disabled]="loading || formPrice.invalid"
      class="rp-button rp-filled rp-primary rp-small"
    >
      {{ isEdit ? 'Save' : 'Save & add new' }}
    </button>
  </div>
</div>

<ng-template #tooltipRef>
  <div class="msg-tooltip">
    {{ rangeInfo }}
  </div>
</ng-template>
