import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { ReportsMfeComponent } from './reports-mfe/reports-mfe.component';

const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: environment.modules.reportsMef,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: environment.modules.tradeCore,
        component: ReportsMfeComponent,
        data: {
          isMfe: true,
          module: environment.modules.reportsMef,
          hideBreadcrumbs: true,
          submodule: environment.modules.tradeCore,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: ReportsMfeComponent,
            data: {
              isMfe: true,
              module: environment.modules.reportsMef,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: '',
        redirectTo: environment.modules.tradeCore,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsMfeRoutingModule { }
