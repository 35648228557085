import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  PermissionsRequestParams,
  PermissionsRequestBody,
  PermissionsResponse
} from './definitions/permissions.models';

import { environment } from '../../../environments/environment';
import { StoreAuxService } from '../storeAux/store-aux.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PermissionsService {
  filtersPath = '/filters/';

  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getNewEntities(
    body: PermissionsRequestBody,
    data?: PermissionsRequestParams
  ): Observable<{ data: PermissionsResponse }> {
    if (!data.search_value) {
      delete data.search_value;
    } else {
      data.search_criteria = 'name';
    }

    const params = new HttpParams({
      fromObject: Object.assign(data)
    });
    return this._http.post<{ data: PermissionsResponse }>(this.getCountryUrl() + this.filtersPath, body, { params });
  }

  getEntities(body: PermissionsRequestBody, data?: PermissionsRequestParams): Observable<PermissionsResponse> {
    let paramsSearch: PermissionsRequestParams = data;
    if (data.search_value) {
      paramsSearch = {
        ...data,
        search_criteria: 'name'
      };
    }

    const params = new HttpParams({
      fromObject: Object.assign(paramsSearch)
    });
    return this._http
      .post<{ data: PermissionsResponse }>(this.getCountryUrl() + this.filtersPath, body, { params })
      .pipe(map((res: { data: PermissionsResponse }) => res.data));
  }

  getCountryUrl(): string {
    return this._storeAuxService.getCountry(environment.urlRevenue);
  }
}
