import { Component } from '@angular/core';
import { FEATURE_ANALYZE, FEATURE_SENSE, FEATURE_CREATE } from './products.constant';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
  readonly analyze = FEATURE_ANALYZE;
  readonly sense = FEATURE_SENSE;
  readonly create = FEATURE_CREATE;

  constructor() {}
}
