import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Observable } from 'rxjs';

import { Paginate, Permission } from '../definitions/permissions.models';
import { Admin } from '../definitions/admin.models';
import { environment } from '../../../environments/environment';

@Injectable()
export class AdminService {
  readonly BASE_PATH = 'admins/';

  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getAdmins(page: number, limit: number, name = ''): Observable<Permission<Paginate<Admin>>> {
    const params = new HttpParams({ fromObject: Object.assign({ page, limit, search_value: name }) });

    return this._http.get<Permission<Paginate<Admin>>>(`${this._getCountryUrl()}`, { params });
  }

  createAdmin(body: Admin): Observable<Permission<Admin>> {
    return this._http.post<Permission<Admin>>(`${this._getCountryUrl()}`, body);
  }

  editAdmin(body: Admin, id: number): Observable<Permission<Admin>> {
    return this._http.put<Permission<Admin>>(`${this._getCountryUrl()}${id}`, body);
  }

  getAdmin(id: number): Observable<Permission<Admin>> {
    return this._http.get<Permission<Admin>>(`${this._getCountryUrl()}${id}`);
  }

  deleteAdmin(id: number): Observable<Permission<unknown>> {
    return this._http.delete<Permission<unknown>>(`${this._getCountryUrl()}${id}`);
  }

  private _getCountryUrl(): string {
    return `${this._storeAuxService.getCountry(environment.urlRevenue)}/${this.BASE_PATH}`;
  }
}
