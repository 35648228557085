<one-card class="no-padding">
  <one-dynamic-table #table [tableData]="accountsTableData"></one-dynamic-table>
</one-card>

<ng-template #makerTemplateRef let-id="id" let-makers="makers">
  <ng-container *ngIf="makers?.length === 1; else multiple">
    {{ makers[0] }}
  </ng-container>
  <ng-template #multiple>
    <span class="one-icon-after-eye multiple-makers" (click)="openMakerListDialog(makers)"> Multiple </span>
  </ng-template>
</ng-template>

<ng-template #expiration let-id="id" let-value="value" let-status="status">
  <span
    [appCustomTooltip]="ACCOUNT_STATUS_MESSAGE[status]"
    [style.color]="ACCOUNT_STATUS_COLOR[status]"
    [showToolTip]="status === ACCOUNT_STATUS.per_expire || status === ACCOUNT_STATUS.expired"
    [class.one-icon-after-exclamation-circle]="
      status === ACCOUNT_STATUS.per_expire || status === ACCOUNT_STATUS.expired
    "
    tooltipPosition="top"
  >
    {{ value }}
  </span>
</ng-template>
