import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective implements OnDestroy, AfterViewInit {
  private readonly FORBIDDEN_KEYS = ['e', 'E', '+', '-', '.', ','];

  constructor(private readonly _element: ElementRef<HTMLElement>) {}

  ngOnDestroy(): void {
    const input = this.getInputReference();
    if (input) {
      input.removeEventListener('keydown', this.KEYDOWN_EVENT_LISTENER);
    }
  }

  ngAfterViewInit(): void {
    const input = this.getInputReference();
    if (input) {
      input.addEventListener('keydown', this.KEYDOWN_EVENT_LISTENER);
    }
  }

  private readonly KEYDOWN_EVENT_LISTENER = (ev: KeyboardEvent) => {
    const isNavigationKey =
      ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(ev.key);

    const isNumberKey = ev.key >= '0' && ev.key <= '9';

    if (!isNumberKey && !isNavigationKey) {
      ev.preventDefault();
      return false;
    }

    return true;
  };

  private getInputReference(): HTMLInputElement | null {
    return this._element.nativeElement.querySelector('input');
  }
}
