import { Injectable } from '@angular/core';

import * as Crypto from 'crypto-js';

@Injectable()
export class EncryptService {
  encryptText(text: string, key: string): string {
    return Crypto.AES.encrypt(text, key).toString();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  encryptObject(object: any, key: string): any {
    return Crypto.AES.encrypt(JSON.stringify(object), key).toString();
  }

  decryptText(textEncrypt: string, key: string): string {
    return Crypto.AES.decrypt(textEncrypt.toString(), key).toString(Crypto.enc.Utf8);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decryptObject(objectEncrypt: any, key: string): any {
    const decryptedObject = Crypto.AES.decrypt(objectEncrypt.toString(), key);

    return JSON.parse(decryptedObject.toString(Crypto.enc.Utf8));
  }
}
