import { Component } from '@angular/core';
import { MenuContentMobileService } from '../../services/menu-content-mobile/menu-content-mobile.service';
import { MenuMobileTypes } from '../../../../shared/enums';

@Component({
  selector: 'app-menu-content-mobile',
  templateUrl: './menu-content-mobile.component.html',
  styleUrls: ['./menu-content-mobile.component.scss'],
  host: {
    class: 'animated fadeInDown'
  }
})
export class MenuContentMobileComponent {
  readonly MenuMobileTypes = MenuMobileTypes;

  constructor(public readonly menuContentService: MenuContentMobileService) {}
}
