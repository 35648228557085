import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { TierService } from '../../../../services/tier.service';
import { ComponentService } from '../../../../services/component.service';

import { SelectComponent } from '@rappi/ui/select';
import { TreeComponent } from '@rappi/ui/tree';
import { Tier } from '../../../../definitions/tier.models';
import { AlertsService } from '../../../../../../app/services/alerts/alerts.service';
import { PermissionComponent } from '../../../../definitions/component.models';
import { ACTIONS } from '../../../../definitions/actions.enums';
import { MODULES_ICON } from '../../../../definitions/table-modules.enums';

import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { Observable, Subject } from 'rxjs';
import { filter, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Entity, Permission } from '../../../../definitions/permissions.models';
import { PermissionAction } from '../../../../definitions/permissions.enums';
import { ConfirmModalComponent } from '../../../../../core/confirm-modal/confirm-modal.component';
import { TypeModal } from '../../../../../core/confirm-modal/definitions/enums/type-modal.enum';
import { TypeCofirmModal } from '../../../../../core/confirm-modal/definitions/models/type-modal.model';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('typeSelect') typeSelect: SelectComponent<Entity>;
  @ViewChild('tree') tree: TreeComponent;
  action: string;
  form: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(254)])),
    components_id: new FormControl([], Validators.required)
  });

  typeDataSource: Entity[] = [];

  id: number;

  deleteTier: (id: number) => Observable<Permission<unknown>>;
  components$: Observable<PermissionComponent[]>;

  viewUrl: string;
  tableUrl: string;

  destroySubject$: Subject<void> = new Subject();

  private readonly TIER_CREATION_MESSAGE = 'Tier created successfully';
  private readonly TIER_CREATION_ERROR = 'There was an error trying to create tier';

  private readonly TIER_UPDATE_MESSAGE = 'Tier updated successfully';
  private readonly TIER_UPDATE_ERROR = 'There was an error trying to update tier with id';

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _tierService: TierService,
    private readonly _componentService: ComponentService,
    private readonly _alertsService: AlertsService,
    private readonly _store: Store<AppState>,
    private readonly _dialog: MatDialog
  ) {
    const routeArr = this._router.url.split('/');

    this.id = Number(routeArr[4]);
    this.action = routeArr[3];

    routeArr.length = 3;

    this.tableUrl = routeArr.join('/');
    this.viewUrl = this.id ? `${this.tableUrl}/${PermissionAction.view}/${this.id}` : null;
    this.deleteTier = (id: number) => this._tierService.deleteTier(id).pipe(tap(() => this.goTo(this.tableUrl)));

    this._store
      .pipe(
        select(StoreName.country),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => {
          this.goTo(this.tableUrl);
        })
      )
      .subscribe();

      this.getTiersTypes();
  }

  ngAfterViewInit() {
    this.components$ = this._componentService.getComponents().pipe(
      map(({ data }: Permission<PermissionComponent[]>) =>
        data.map((d: PermissionComponent) => {
          d.class = MODULES_ICON[d.name];
          return d;
        })
      )
    );

    this._cd.detectChanges();

    if (this.action === ACTIONS.edit) {
      this._tierService.getTier(this.id).subscribe(
        ({ data }: Permission<Tier>) => {
          this.form.patchValue({ ...data });

          this.typeSelect.setSelection({ id: data.type, name: data.type } as Entity);

          this.tree.setInitialSelection(data.components || []);

          this._cd.detectChanges();
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(
            message || 'There was an error trying to get tier or components',
            AlertsType.error
          );
        }
      );
    }
  }

  getTiersTypes(): void {
    this._tierService.getTiersTypes().pipe(
      map((response: { data: string[] }) => response.data.map(element => ({ id: element, name: element })))
    ).subscribe(response => this.typeDataSource = response);
  }

  setType({ id }: Entity) {
    this.form.get('type').patchValue(id);
  }

  setComponents(selection: number[]) {
    this.form.get('components_id').patchValue(selection);
  }

  goTo(url: string) {
    this._router.navigateByUrl(url);
  }

  onSubmit(f: FormGroup) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const request = f.getRawValue() as Tier;

    const action = this.id
      ? this.confirmEdit().pipe(
          filter((res: boolean) => res),
          switchMap(() => this._tierService.editTier(request, this.id))
        )
      : this._tierService.createTier(request);

    const MESSAGE: string = this.id ? this.TIER_UPDATE_MESSAGE : this.TIER_CREATION_MESSAGE;
    const ERROR: string = this.id ? `${this.TIER_UPDATE_ERROR} ${this.id}` : this.TIER_CREATION_ERROR;

    action.subscribe(
      ({ data: { id } }: Permission<Tier>) => {
        this._alertsService.openAlerts(MESSAGE, AlertsType.success);
        this.goTo(this.viewUrl || `${this.tableUrl}/${PermissionAction.view}/${id}`);
      },
      ({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || ERROR, AlertsType.error);
      }
    );
  }

  confirmEdit(): Observable<boolean> {
    return this._dialog
      .open(ConfirmModalComponent, {
        disableClose: true,
        width: '500px',
        autoFocus: false,
        data: {
          title: 'Confirm Edit',
          message: `The licenses and roles associated to this Tier
                    will be modified or even eliminated due to the
                    current changes, do you still want to continue?`,
          type: TypeModal.confirm
        } as TypeCofirmModal
      })
      .afterClosed();
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
