import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmUserRequest } from '../../core/landing/definitions/landing.constant';
import { environment } from '../../../environments/environment';
import { StoreAuxService } from '../storeAux/store-aux.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  confirmUser(request: ConfirmUserRequest): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getCountryUrl()}/confirm-user`, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        c_user_email: request.email
      })
    });
  }

  resetPassword(email: string): Observable<{ message: string; data: string }> {
    return this._http.patch<{ message: string; data: string }>(`${this._getCountryUrl()}/reset-password`, { email });
  }

  private _getCountryUrl(country?: string): string {
    return `${this._storeAuxService.getCountry(environment.urlAuth, country)}/support`;
  }
}
