import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { ControlPanelService } from '../../../services/control-panel/control-panel.service';
import { of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import { TypeCofirmModal } from '../../../core/confirm-modal/definitions/models/type-modal.model';
import { TypeModal } from '../../../core/confirm-modal/definitions/enums/type-modal.enum';
import { environment } from '../../../../environments/environment';

import { AlertsType } from '@rappi/ui/alerts';
import { SelectComponent, SelectConfig } from '@rappi/ui/select';
import { ControlPanelAlert } from '../../../models/user/user.model';
import { COUNTRY_NAME } from '../../../definitions/countries.constants';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements AfterViewInit {
  @Input() set alert(a: ControlPanelAlert) {
    this.form.patchValue(a);
    this.form.markAsTouched();
    this._alert = a;
    this.isSaved = true;
  }

  @Output() saved: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('countrySelect') countrySelect: SelectComponent<{ name: string; id: string }>;

  emit = false;
  isSaved = false;

  form: FormGroup = new FormGroup({
    id: new FormControl(null),
    countries: new FormControl(null, Validators.required),
    title: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(60)])),
    message: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(200)])),
    updated_at: new FormControl(null),
    creator: new FormControl(null),
    modifier: new FormControl(null)
  });

  get title(): string {
    return this.form.get('title').value;
  }

  get message(): string {
    return this.form.get('message').value;
  }

  get creator(): string {
    return this.form.get('creator').value;
  }

  get modifier(): string {
    return this.form.get('modifier').value;
  }

  get updatedAt(): string {
    return this.form.get('updated_at').value;
  }

  readonly CONFIG: SelectConfig = {
    includeAll: true,
    panelClass: null
  };

  readonly CODES = Object.entries(COUNTRY_NAME).filter(([id]) => {
    const condition = ['DEV'].includes(id);

    return environment.production ? !condition : condition;
  });
  readonly COUNTRIES: { id: string; name: string }[] = this.CODES.map(([id, name]) => ({ id, name }));

  private _alert: ControlPanelAlert = {} as ControlPanelAlert;

  constructor(
    private readonly _controlPanelService: ControlPanelService,
    private readonly _alertsServices: AlertsService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (this._alert.countries) {
      this._alert.countries.forEach((country) => {
        const c = this.COUNTRIES.find(({ id }) => id === country);
        if (c) {
          this.countrySelect.setMultipleSelection(c);
        }
      });
    }

    this.emit = true;
    this.isSaved = this.form.touched;
    this._cd.detectChanges();

    this.form.valueChanges.pipe(take(1)).subscribe(() => {
      this.isSaved = false;
    });
  }

  setCountries(c: { id: string }[], allSelected: boolean, emit: boolean) {
    if (emit) {
      const source = allSelected ? this.COUNTRIES : c;
      const countries = source.map(({ id }) => id);
      this.form.patchValue({ countries });
    }
  }

  onSubmit(f: FormGroup) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const { id, title, message, countries } = f.getRawValue();
    const alert = { title, message, countries } as ControlPanelAlert;

    this._dialog
      .open(ConfirmModalComponent, {
        maxWidth: '492px',
        data: {
          title: 'All set?',
          message: 'You are about to save your system information alert',
          type: TypeModal.confirm,
          textSucces: 'Confirm',
          textReject: 'Cancel'
        } as TypeCofirmModal
      })
      .afterClosed()
      .pipe(
        switchMap((create: boolean) => {
          if (create) {
            const obs = id
              ? this._controlPanelService.deactivateAlert({ id, ...alert, is_active: true } as ControlPanelAlert)
              : this._controlPanelService.createAlert(alert);
            return obs.pipe(map(() => true));
          }

          return of(false);
        }),
        filter((create: boolean) => create)
      )
      .subscribe(
        () => {
          this.saved.emit(true);
          this._alertsServices.openAlerts('Alert created', AlertsType.success);
          this.isSaved = true;
          this._router.navigate([`/${environment.modules.session}`]);
        },
        ({ error }: HttpErrorResponse) => {
          this._alertsServices.openAlerts(error.message, AlertsType.error);
        }
      );
  }
}
