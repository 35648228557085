<one-card>
  <div class="row">
    <div>
      <h1>System information alert</h1>
      <p>This functionality will enable you to notify the user in case of data quality issues, bugs, malfunctions, etc.</p>
    </div>
    <label>
      <input #checkbox type="checkbox" class="rp-switch" (click)="switch($event, saved)" />
    </label>
  </div>
  <app-form *ngIf="alert$ | async as alert" [alert]="alert" (saved)="saved = $event"></app-form>
</one-card>
