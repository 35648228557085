import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/states/app.state';
import { SearchCriteria, UserType } from '../definitions/permissions.enums';

import { Paginate, Permission } from '../definitions/permissions.models';
import { License, LicensesStatistics } from '../definitions/licenses.models';

import { UtilPermissions } from '../utils/util-permissions.service';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class LicensesService extends UtilPermissions {
  userType: UserType;
  accountId: number;

  constructor(
    private readonly _http: HttpClient,
    private readonly _storeAuxService: StoreAuxService,
    _store: Store<AppState>
  ) {
    super(_store);
  }

  createLicense(body: License): Observable<Permission<License>> {
    return this._http.post<Permission<License>>(
      `${this._getCountryUrl()}/licenses/`,
      body,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  editLicense(body: License, id: number): Observable<Permission<License>> {
    return this._http.put<Permission<License>>(
      `${this._getCountryUrl()}/licenses/${id}`,
      body,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  getLicense(id: number): Observable<Permission<License>> {
    return this._http.get<Permission<License>>(
      `${this._getCountryUrl()}/licenses/${id}`,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  getLicenses(page: number, limit: number, name = ''): Observable<Permission<Paginate<License>>> {
    const params = new HttpParams({
      fromObject: Object.assign({ page, limit, search_value: name, search_criteria: SearchCriteria.email })
    });

    return this._http.get<Permission<Paginate<License>>>(`${this._getCountryUrl()}/licenses/`, {
      params,
      ...this.getHeaders(this.userType, this.accountId)
    });
  }

  getLicensesStatistics(): Observable<Permission<LicensesStatistics>> {
    return this._http.get<Permission<LicensesStatistics>>(
      `${this._getCountryUrl()}/licenses/statistics`,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  deleteLicense(id: number): Observable<Permission<unknown>> {
    return this._http.delete<Permission<unknown>>(
      `${this._getCountryUrl()}/licenses/${id}`,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  private _getCountryUrl(): string {
    return this._storeAuxService.getCountry(environment.urlRevenue);
  }
}
