<div mat-dialog-title>
  <div class="title-content">
    <h1 class="title-modal" [innerHTML]="dataModal.title"></h1>
    <span mat-dialog-close class="one-icon-close-material-v2 close-btn"></span>
  </div>
</div>

<p class="content-message" mat-dialog-content [innerHTML]="dataModal.message"></p>

<div mat-dialog-actions>
  <div class="actions-content">
    <button *ngIf="dataModal.type === TypeModal.confirm" class="rp-button rp-outlined rp-primary rp-small" [mat-dialog-close]="false">
      {{ dataModal.textReject || DEFAULT_TEXT_REJECT }}
    </button>
    <button class="rp-button rp-filled rp-primary rp-small" [mat-dialog-close]="true">
      {{ dataModal.textSucces || DEFAULT_TEXT_SUCCESS }}
    </button>
  </div>
</div>
