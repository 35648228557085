<div class="header-actions">
  <button (click)="productReception()" class="rp-button rp-filled rp-primary rp-small one-icon-add-material-v2">
    Product Reception
  </button>
</div>

<one-card class="content-card-table">
  <div one-card-title class="table-header">
    <div class="content-title-tabla">
      <span class="title-table">Stock Units</span>
      <span [class.no-units]="!actualInventoryTotal" class="table-header-chip">{{ actualInventoryTotal }} units</span>
    </div>
    <one-input
      label="Search"
      (keyup)="applyFilter($event.target.value, actualInventoryTable)"
      type="search"
    ></one-input>
  </div>

  <one-dynamic-table
    #actualInventoryTableComponent
    [tableData]="actualInventoryTable"
    *ngIf="actualInventoryTable"
  ></one-dynamic-table>
</one-card>

<one-card class="content-card-table">
  <div one-card-title class="table-header">
    <div class="content-title-tabla">
      <span class="title-table">Pending to receive</span>
      <span [class.no-units]="!actualInventoryTotal" class="table-header-chip">{{ incomingInventoryTotal }} units</span>
    </div>
    <one-input
      label="Search"
      (keyup)="applyFilter($event.target.value, incomingInventoryTable)"
      type="search"
    ></one-input>
  </div>

  <one-dynamic-table
    #incomingInventoryTableComponent
    [tableData]="incomingInventoryTable"
    *ngIf="incomingInventoryTable"
  ></one-dynamic-table>
</one-card>

<one-card class="content-card-table">
  <div one-card-title class="table-header">
    <div class="content-title-tabla">
      <span class="title-table">Pending to deliver</span>
      <span [class.no-units]="!actualInventoryTotal" class="table-header-chip">{{ outgoingInventoryTotal }} units</span>
    </div>
    <one-input
      label="Search"
      (keyup)="applyFilter($event.target.value, outgoingInventoryTable)"
      type="search"
    ></one-input>
  </div>

  <one-dynamic-table
  #outgoingInventoryTableComponent
  [tableData]="outgoingInventoryTable"
  *ngIf="outgoingInventoryTable"
></one-dynamic-table>
</one-card>

<ng-template #units let-element="element">
  <div (click)="editCurrentUnit(element)" class="content-edit">
    <span class="table-icon">
      {{ element?.current_quantity }}
    </span>
    <span class="icon-edit one-icon-outline-edit"></span>
  </div>
</ng-template>

<ng-template #statusRef let-element="element">
  <div class="content-status">
    <one-select-status
      [disabled]="true"
      placeholder="Finished"
      [statusList]="ListStatus"
      [value]="statusObj[element.status]"
    >
    </one-select-status>
  </div>
</ng-template>