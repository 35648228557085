export const Icons = {
  IconEmpty: '/assets/images/marketing/campaigns/icon-empty.svg',
  IconInfo: '/assets/images/marketing/campaigns/icon-info.svg',
  PencilEdit: '/assets/images/marketing/campaigns/pencil-edit.svg',
  IconVerified: '/assets/images/marketing/campaigns/icon-verified.svg',
  IconUnverified: '/assets/images/marketing/campaigns/icon-unverified.svg',
  IconPlus: '/assets/images/marketing/campaigns/icon-plus.svg',
  IconSearch: '/assets/images/search.svg',
  IconDeeplink: '/assets/images/deeplink-icon.svg',
  IconShieldCheck: '/assets/images/marketing/campaigns/shield-check.svg',
  IconShieldMoney: '/assets/images/marketing/campaigns/shield-money.svg'
};
