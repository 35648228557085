import { Injectable, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TitleBarActionsService } from '@rappi/ui/title-bar';
import { map, filter } from 'rxjs/operators';
import { AlertsType } from '@rappi/ui/alerts';
import { NavigationExtras, Router, NavigationEnd } from '@angular/router';
import { AlertsService } from '../../../services/alerts/alerts.service';

@Injectable()
export class OneTitleBarService implements TitleBarActionsService {
  _renderer: Renderer2;
  _currentUrl: string;

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _rendererFactory: RendererFactory2,
    private readonly _router: Router,
    private readonly _alertsService: AlertsService
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
    this._router.events
      .pipe(filter((evt) => evt instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => (this._currentUrl = event.url));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public goToUrl(route: Array<any>, extras: NavigationExtras = {}) {
    if (extras?.relativeTo) {
      delete extras.relativeTo;
    }
    this._router.navigate(route, extras);
  }

  public downloadFile(
    filename: string,
    url: string,
    requestMethod: string,
    requestBody,
    downloadElementRef: ElementRef
  ) {
    const responseType = 'arraybuffer';
    const request =
      requestMethod === 'POST'
        ? this._httpClient.post(url, requestBody, { responseType })
        : this._httpClient.get(url, { responseType });

    request.pipe(map((res: ArrayBuffer) => new Uint8Array(res))).subscribe(
      (file: Uint8Array) => {
        this.download(file, filename, downloadElementRef);
        this._alertsService.openAlerts('Downloaded successfully', AlertsType.success);
      },
      () => {
        this._alertsService.openAlerts('Download Failed. Please try again', AlertsType.error);
      }
    );
  }

  download(blob: Uint8Array, filename: string, downloadElementRef: ElementRef) {
    const binaryData: Array<Uint8Array> = [];
    binaryData.push(blob);
    const url: string = URL.createObjectURL(new Blob(binaryData));
    const a: HTMLElement = this._renderer.createElement('a');
    this._renderer.setAttribute(a, 'style', 'display:none;');
    this._renderer.setAttribute(a, 'href', url);
    this._renderer.setAttribute(a, 'download', filename);
    this._renderer.appendChild(downloadElementRef.nativeElement, a);
    a.click();
    this._renderer.removeChild(downloadElementRef.nativeElement, a);
  }
}
