import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformancePredictorMfeComponent } from './performance-predictor-mfe.component';
import { PerformancePredictorMfeRoutingModule } from './performance-predictor-mfe-routing.module';

@NgModule({
  declarations: [PerformancePredictorMfeComponent],
  imports: [
    CommonModule,
    PerformancePredictorMfeRoutingModule
  ]
})
export class PerformancePredictorMfeModule { }
