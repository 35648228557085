import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateCoreComponent } from './main/create-core.component';
import { CreateRoutesMap } from './definitions/create-core-routing.constant';
import { environment } from '../../environments/environment';
import { PermissionsCreate } from './definitions/create-core.constant';

const { amplify, deeplinksMfe, audiencesMfe, samplingExt, ads, campaignManager } = environment.modules;

const routes: Routes = [
  {
    path: '',
    component: CreateCoreComponent,
    data: {
      title: CreateRoutesMap.index.label,
      hideBreadcrumbs: true
    }
  },
  {
    path: ads,
    loadChildren: () => import('../ads-mfe/ads-mfe.module').then((m) => m.AdsMfeModule),
    data: {
      module: PermissionsCreate.ads
    }
  },
  {
    path: samplingExt,
    loadChildren: () => import('../sampling-ext-mfe/sampling-ext-mfe.module').then((m) => m.SamplingExtMfeModule),
    data: {
      module: PermissionsCreate.sampling
    }
  },
  {
    path: audiencesMfe,
    loadChildren: () => import('../audiences-mfe/audiences-mfe.module').then((m) => m.AudiencesMfeModule),
    data: {
      module: PermissionsCreate.audiences + '/list'
    }
  },
  {
    path: deeplinksMfe,
    loadChildren: () => import('../deeplinks-mfe/deeplinks-mfe.module').then((m) => m.DeeplinksMfeModule),
    data: {
      module: PermissionsCreate.deeplinks
    }
  },
  {
    path: amplify,
    loadChildren: () => import('../amplify-mfe/amplify-mfe.module').then((m) => m.AmplifyMfeModule),
    data: {
      module: PermissionsCreate.amplify
    }
  },
  {
    path: campaignManager,
    loadChildren: () => import('../request-hub-mfe/request-hub-mfe.module').then((m) => m.RequestHubMfeModule),
    data: {
      module: PermissionsCreate.campaignManager
    }
  },
  {
    path: 'performance-predictor',
    loadChildren: () => import('../performance-predictor-mfe/performance-predictor-mfe.module').then((m) => m.PerformancePredictorMfeModule),
    data: {
      module: 'create/performance-predictor',
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateCoreRoutingModule { }
