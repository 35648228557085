<app-header></app-header>
<div class="layout">
  <app-menu></app-menu>
  <div class="layout-content">
    <one-title-bar *ngIf="showTitleBar">
      <one-breadcrumbs breadcrumbs *ngIf="showBreadcrumbs"></one-breadcrumbs>
      <app-title-section hideActions="!showActions" title-section *ngIf="showModuleTitle"></app-title-section>
    </one-title-bar>
    <router-outlet></router-outlet>
  </div>
</div>
