import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeeplinksMfeRoutingModule } from './deeplinks-mfe-routing.module';
import { DeeplinksComponent } from './deeplinks/deeplinks.component';


@NgModule({
  declarations: [DeeplinksComponent],
  imports: [
    CommonModule,
    DeeplinksMfeRoutingModule
  ]
})
export class DeeplinksMfeModule { }
