<div mat-dialog-title>
  <h1>
    Choose the {{ data.licensesNumber }} licenses you wish to maintain:
    <span>* This user is already using one license</span>
  </h1>
  <button class="close-btn one-icon-times" mat-dialog-close aria-label="Close modal"></button>
</div>
<div mat-dialog-content>
  <div class="one-icon-exclamation-circle alert" [style.--color]="ALERT_COLOR">
    <span> Unselected users will loose platform access </span>
  </div>
  <ul>
    <li *ngFor="let license of licenses">
      <label class="one-icon-after-user-2">
        <span>
          <input
            type="checkbox"
            class="rp-checkbox"
            (change)="this.toggleId(license.id)"
            [checked]="this.IDS.has(license.id)"
            [disabled]="!this.IDS.has(license.id) && ids.length === data.licensesNumber"
          />
          {{ license.email }}
        </span>
      </label>
    </li>
  </ul>
</div>

<div mat-dialog-actions>
  <button
    class="rp-button rp-raisable"
    [mat-dialog-close]="ids"
    [disabled]="ids.length !== (licenses.length > data.licensesNumber ? data.licensesNumber : licenses.length)"
  >
    Continue
  </button>
  <button class="rp-button rp-filled rp-raisable brands-primary-button" mat-dialog-close>Cancel</button>
</div>
