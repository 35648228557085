import { Action, createReducer, on } from '@ngrx/store';

import { initialState, ModuleState } from '../states/module.state';

import * as actions from '../actions/module.action';

const reducer = createReducer(
  initialState,
  on(actions.set, (state, { payload }) => ({ ...state, moduleSelected: payload })),
  on(actions.setSubmodule, (state, { payload }) => ({ ...state, submodule: payload }))
);

export const moduleReducer = (state: ModuleState, action: Action) => reducer(state, action);
